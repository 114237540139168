// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedCourseTitle: string;
  openConfirmDialog: boolean;
  openSuccessDialog: boolean;
  selectedValue: any;
  modalFlagState:{School:boolean,Catch:boolean,shift:boolean, MainModal:boolean};
  openCatchUpModal:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ReschedullingModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      loading: false,
      selectedCourseTitle: "",
      openConfirmDialog: false,
      openSuccessDialog: false,
      selectedValue: null,
      openCatchUpModal:false,
      modalFlagState:{School:false,Catch:false,shift:false,MainModal:true}
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();

  }
  
  submitReschedullingModal = () =>{
    this.setState({modalFlagState:{School:false,Catch:false,shift:false,MainModal:false,[this.state.selectedValue]:true}})
  }

  closeReschedullingModal = () =>{
    this.props.onCancel()
    setTimeout(() => {
      this.setState({modalFlagState:{School:false,Catch:false,shift:false,MainModal:true}})
    }, 1000);
  }

  handleChange =async (event: any) => {
    this.setState({
      selectedValue: event.target.value
    })
  };
  selectBox=async(param:any)=>{
    if (param) {
      param.click();
    }
  }
  // Customizable Area End
}
// Customizable Area End