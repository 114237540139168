// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { displayApiErrorMsg, getFieldType, getMessageData, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { IInputConfigProps } from "../../../components/src/CommonType.web";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export interface IStepperStepProps {
  id: any;
  label: string;
  optional?: boolean;
}
const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  onConfirm: () => void;
  classes?: any;
  onCancel: () => void;
  open: boolean;
  handleClose?: any;
  isEdit: boolean;
  lessonId?: number;
  handleAddNewLesson: any;
  handleUpdateLesson: any;
  courseId?: number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep: number;
  stepperScreen: string;
  steps: Array<IStepperStepProps>;
  teacherMaterialsContent: string;
  studentMaterialsContent: string;
  teacherInstructionsContent: string;
  studentInstructionsContent: string;
  lessonTitleValue: string;
  lessonTitleError: boolean;
  lessonTitileMsg: string;
  descriptionValue: string;
  descriptionError: boolean;
  descriptionErrorMsg: string;
  objectivesValue: string;
  detailsValue: string;
  tagsValue: string;
  customFieldValue: string;
  loading: boolean;
  classesCustomFieldConfigList: Array<IInputConfigProps>;
  customFieldsList: Array<any>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherAddNewLessonController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetLessonDataById: string = "";
  apiGetCustomField: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {      
      customFieldsList: [],
      activeStep: 0,
      stepperScreen: "catalog_details",
      teacherMaterialsContent: '',
      studentMaterialsContent: '',
      teacherInstructionsContent: '',
      studentInstructionsContent: '',
      lessonTitleValue: '',
      lessonTitleError: false,
      lessonTitileMsg: '',
      descriptionValue: '',
      descriptionError: false,
      descriptionErrorMsg: '',
      objectivesValue: '',
      detailsValue: '',
      tagsValue: '',
      customFieldValue: '',
      loading: false,
      classesCustomFieldConfigList: [],
      steps: [
        {
          id: "catalog_details",
          label: "Lesson Details",
        },
        {
          id: "add_courses",
          label: "Material &Instruction Details",
        },
      ],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null && apiRequestCallId === this.apiGetLessonDataById) {
        this.handleGetLessonDataByIdResponse(responseJson, errorResponse);
      }
      if(apiRequestCallId === this.apiGetCustomField) {
        this.handleGetClassCustomFieldDataResponse( responseJson,errorResponse );
      }     
      // Customizable Area End
    }
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.handleGetCustomFieldsListLesson();
    if (this.props.isEdit) {
      this.handleGetLessonDataByIdApi();
    }
  }

  handleNextSteppers = () => {
    if (this.handelFirstStepFormValidation()) {
      this.setState({ stepperScreen: "add_courses" });
    }
  };

  handleCancelStepperBtns = () => {
    this.setState({ stepperScreen: "catalog_details" }, () => {
      this.props.onCancel();
    });
  };

  handleTeacherMaterialChange = (content: any) => {
    this.setState({ teacherMaterialsContent: content });
  }

  handleStudentMaterialChange = (content: any) => {
    this.setState({ studentMaterialsContent: content });
  }

  handleTeacherInstructionChange = (content: any) => {
    this.setState({ teacherInstructionsContent: content });
  }

  handleStudentInstructionChange = (content: any) => {
    this.setState({ studentInstructionsContent: content });
  }

  handleInputChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });

    if (name === "descriptionValue" && value) {
      this.setState({ descriptionError: false, descriptionErrorMsg: "" });
    }

    if (name === "lessonTitleValue" && value) {
      this.setState({ lessonTitleError: false, lessonTitileMsg: "" });
    }
  }

  handleLessonSave = () => {
    if (this.handelFirstStepFormValidation()) {
      const {
        lessonTitleValue,
        descriptionValue,
        objectivesValue,
        detailsValue,
        tagsValue,
        teacherMaterialsContent,
        teacherInstructionsContent,
        studentInstructionsContent,
        studentMaterialsContent,
      } = this.state;

      const requestPayload = {
        lesson_title: lessonTitleValue,
        description: descriptionValue,
        details: detailsValue,
        objectives: objectivesValue,
        tags: tagsValue,
        custom_field_id: 1,
        teacher_materials: teacherMaterialsContent,
        student_materials: studentMaterialsContent,
        teacher_instructions: teacherInstructionsContent,
        student_instructions: studentInstructionsContent,
      }

      if (this.props.isEdit) {
        this.props.handleUpdateLesson(requestPayload);
      } else {
        this.props.handleAddNewLesson(requestPayload);
      }
    }
  }

  handelFirstStepFormValidation = () => {
    let isValid = true;
    const { lessonTitleValue, descriptionValue } = this.state;
    if (!descriptionValue.trim()) {
      this.setState({ descriptionError: true, descriptionErrorMsg: "Please enter description", });
      isValid = false;
    }

    if (!lessonTitleValue.trim()) {
      this.setState({ lessonTitleError: true, lessonTitileMsg: "Please enter lesson title" });
      isValid = false;
    }
    return isValid;
  }

  handlePauseStepper = () => {
    const { lessonTitleValue, descriptionValue } = this.state;
    if (!descriptionValue.trim() || !lessonTitleValue.trim()) {
      return true;
    } else {
      return false;
    }
  }

  showModalLoader = () => {
    this.setState({ loading: true });
  }

  hideModalLoader = () => {
    this.setState({ loading: false });
  }

  handleGetLessonDataByIdApi = async () => {
    this.showModalLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetLessonDataById = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postAddNewLesson}/${this.props.lessonId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetLessonDataByIdResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      const lesson = responseJson?.data?.attributes;
      this.setState({
        lessonTitleValue: lesson.lesson_title,
        descriptionValue: lesson.description,
        objectivesValue: lesson.objectives,
        detailsValue: lesson.details,
        tagsValue: lesson.tags,
        teacherMaterialsContent: lesson.teacher_materials,
        studentMaterialsContent: lesson.student_materials,
        teacherInstructionsContent: lesson.teacher_instructions,
        studentInstructionsContent: lesson.student_instructions,
      });
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    this.hideModalLoader();
  };


  handleClassCustomFieldTextChange = (event: any) => {
    const { classesCustomFieldConfigList } = this.state;
    const { name, value } = event.target;
    const updatedInputConfig = classesCustomFieldConfigList.map((item) => {
      if (item.name === name) {
        item.value = value;
      }
      return item;
    });
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
        classesCustomFieldConfigList: updatedInputConfig,
      };
    });
  };

  handleGetClassCustomFieldDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      if (responseJson.data?.length) {
        this.setState({ customFieldsList: responseJson.data });
        const customFields: Array<IInputConfigProps> = responseJson.data.map(
          (item: any, index: number) => {
            const obj = {
              id: item.id,
              name: `class-management-customField-${index + 1}`,
              fieldName: `class-management-customField-${index + 1}`,
              value: "",
              label: item?.attributes?.label,
              placeHolderText: `Please enter ${item?.attributes?.label}`,
              fieldType: getFieldType(item?.attributes?.custom_field_type),
              isDisabled: false,
            };
            return obj;
          }
        );       
        this.setState({
          classesCustomFieldConfigList: customFields,          
        });
      }
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }    
  };
  handleGetCustomFieldsListLesson = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCustomField = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customFieldEndpointTwo}?model_type=Lesson`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
// Customizable Area End
