Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.deleteApiMethod = "DELETE";
exports.patchApiMethod = "PATCH";
exports.putApiMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfcurriculmschoolyearcreation2";
exports.labelBodyText = "cfcurriculmschoolyearcreation2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.createNewSchoolYear = "bx_block_school_year/school_years"
exports.getSchoolYear = "bx_block_school_year/school_years"
exports.getDropdownData = "bx_block_school_year/school_years/fetch_dropdown_data"
exports.deleteSchoolYear = "bx_block_school_year/school_years";
exports.curriculumsEndpoint = "bx_block_curriculum/curriculums";
exports.gradesEndpoint = "bx_block_grade/grades";
exports.subjectsEndpoint = "bx_block_subject/subjects";
exports.schoolCalendarEndpoint = "bx_block_school_calendar/school_calendars";
exports.eventsEndpoint = "bx_block_school_calendar/events";
exports.classesEndpoint = "bx_block_class_management/class_managements";
exports.customFieldEndpoint = "bx_block_curriculum/curriculums/fetch_custom_fields";
exports.curriculumFetchFilters = "bx_block_curriculum/curriculums/fetch_filters";
// Customizable Area End