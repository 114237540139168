// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import {
    displayApiErrorMsg,
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
} from "../../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../../framework/src/Utilities";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./../config.js");

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  selectedTeacherClassDDValue: string;   
  loading: boolean;  
  typeOfActivity: string;
  assignedAssessmentsList: Array<{
    id: string,
    Title: string,
    Type: string,
    Submitted: string,
    AvgGrade: {
      grade: number,
      avg: number,
      infoMsg: string,
    },
    DueDate: string,
  }>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ParentTeacherDashboardViewallController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetTeacherAssignedAssessments: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      assignedAssessmentsList: [],    
      selectedTeacherClassDDValue: "all",      
      loading: false,      
      typeOfActivity: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start  
    this.handleGetAssignedAssessmentsApi()
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = getMessageData(message);

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetTeacherAssignedAssessments) {
          this.handleGetAssignedAssessmentsResponse(responseJson);
        }        
      }
    }
  }

  handleGoBack = () => {
    this.props.navigation.goBack();
  };


  handleGetAssignedAssessmentsResponse = (responseJson: {
    data: Array<{
      id: string,
      attributes: {
        subject: {
          color: string
          icon: string,
          id: string,
        },
        students:{
          class_avg:number,
        },
        grading_component: string,
        submission_percenttage: number,
        student_received_grade: {
          grade: number,
          avg: number
        },
        due_date: string,
        due_days: number,
        activities_title: string,
      }
    }>, errors: Array<{}>
  }) => {
    if (responseJson.data) {
      let assessmentList = responseJson.data.map(
        (item) => {
          const activity = item.attributes;
          return {
            Submitted: `${activity?.submission_percenttage}%`, 
            id: item.id,                      
            Title: activity?.activities_title,
            subject: {
              name: activity.activities_title,
              icon: activity.subject.icon,
              bgColor: activity.subject.color,
            },
            Type: activity.grading_component,
            subjectId: activity.subject.id,            
            AvgGrade: {
              grade: activity?.students?.class_avg|| 0,
              infoMsg: "None of the submitted response has been graded.",
              avg: activity?.students?.class_avg || 0              
            },
            DueDate: activity?.due_date ? moment(activity?.due_date).format('MMMM DD, YYYY') : 'NA',
          };
        }
      );
      this.setState({ assignedAssessmentsList: assessmentList });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }; 

  handleGetAssignedAssessmentsApi = async () => {
    showCustomLoader();    
    let apiConfig = configJSON.teacherAssessmentEndpoint;    
    const { selectedTeacherClassDDValue } = this.state;
    let requstUrl = apiConfig + "?type=assigned";
    if (selectedTeacherClassDDValue !== "all") {
      requstUrl = requstUrl + `&student_id=${selectedTeacherClassDDValue}`;
    }

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetTeacherAssignedAssessments = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requstUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };




  // Customizable Area End
}
// Customizable Area End
