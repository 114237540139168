// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
export interface  Children{  
  id: number,
  email:string,
  first_name:string,
  last_name:string,
  profile_url:string
 }
// Customizable Area End

const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  profileSettingOption: { label: string; clickHandle: () => void }[];
  userInfo: ITeacherInfoProps;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface ITeacherInfoProps {
  email: string;
  uniqId: string;
  firstName: string;
  lastName: string;
  profileImg: string;
  role: string;
  status: string;
  curriculums: { id?: number, name?: string };
  subjectsHandled: Array<{ id?: number, name?: string }>;
  classesList: Array<{ id?: number, name?: string }>;
  customFields: Array<{ id?: number, label?: string, value?: string }>;
  childrenList:Array<Children>

}

// Customizable Area Start
export default class ParentTeacherProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetParentTeacherDetails: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      loading: false,
      profileSettingOption: [],
      userInfo: {
        classesList: [],
        curriculums: {},
        customFields: [],
        email: "",
        firstName: "",
        lastName: "",
        profileImg: "",
        role: "",
        status: "",
        subjectsHandled: [],
        uniqId: "",
        childrenList:[]
      },
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      const { apiRequestCallId, responseJson } =
        getMessageData(message);
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetParentTeacherDetails) {
          this.handleGetParentTeacherDetailResponse(responseJson)
        }
      }
    }
  }

  setProfileSettingOption = async () => {
    this.setState({
      profileSettingOption: [
        {
          label: "Edit Profile",
          clickHandle: this.onEditProfileBtn
        },
        {
          label: "Change Password",
          clickHandle: this.handleGoToChange_Password
        }
      ]
    });
  };

  handleGoToChange_Password = () => {
    this.props.navigation.navigate("StudentChangePassword");
  };

  onEditProfileBtn = () => {
    this.props.navigation.navigate("EditProfile");
  };

  handleGoBack = () => {
    this.props.navigation.goBack();
  };

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetParentTeacherDetailApi();
    this.setProfileSettingOption();
    // Customizable Area End
  }

  handleGetParentTeacherDetailApi = async () => {
    showCustomLoader();
    const userId = await getStorageData("user_id");
    const token = await getStorageData("token");
    const header = { token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetParentTeacherDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetailsEndpoint}/${userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetParentTeacherDetailResponse = (responseJson: any) => {
    if (responseJson.data) {
      const userData = responseJson.data.attributes;
      const classesList: any = userData?.user_classes?.map((item: any) => {
        return {
          id: item?.id,
          name: item?.name,
        }
      });
      const childrenList = userData?.children?.map((item:Children ) => {
        return {
          id: item?.id,
          email: item?.email,
          first_name:item?.first_name,
          last_name:item?.last_name,
          profile_url:item?.profile_url
        }
      }); 

      const subjectsList: any = userData?.subject?.map((item: any) => {
        return {
          id: item?.id,
          name: item?.name,
        }
      });

      const customFields: any = userData?.user_custom_fields?.data?.map((item: any) => {
        return {
          id: item?.attributes?.id,
          label: item?.attributes?.label,
          value: item?.attributes?.field_value,
        }
      });

      const parentTeacherInfo: ITeacherInfoProps = {
        uniqId: userData?.id_number?.toString() || "NA",
        email: userData?.email || "NA",
        firstName: userData?.first_name || "",
        lastName: userData?.last_name || "",
        role: userData?.role?.name || "",
        status: userData?.status || "",
        profileImg: userData?.profile_url || "",
        curriculums: {
          id: userData?.curriculum?.id,
          name: userData?.curriculum?.name,
        },
        classesList: classesList,
        customFields: customFields,
        subjectsHandled: subjectsList,
        childrenList:childrenList
      }

      this.setState({ userInfo: parentTeacherInfo });
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }

  resetParentTeacherData = () => {
    this.setState({
      loading: false,
      profileSettingOption: [],
      userInfo: {
        classesList: [],
        curriculums: {},
        customFields: [],
        email: "",
        firstName: "",
        lastName: "",
        profileImg: "",
        role: "",
        status: "",
        subjectsHandled: [],
        uniqId: "",
        childrenList:[]
      },
    });
  }
  // Customizable Area End
}
// Customizable Area End
