// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import { getStorageData } from "../../../framework/src/Utilities";
import {
  displayApiErrorMsg,
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
} from "../../../components/src/CommonHelper.web";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
interface ITopicTableProps {
  id: string;
  label: string;
  type: string;
  columnId: number;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  studentCoursesList: any;
  selectedCourseId: any;
  selectedCourseIcon: any;
  selectedCourse: any;
  selectedSubjectTopicList: any;
  topicTableColumns: Array<ITopicTableProps>;
  pagination:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentMyCoursesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetStudentCoursesList: string = "";
  apiGetStudentCourseTopicsList: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      studentCoursesList: [],
      selectedCourseId: null,
      selectedCourse: null,
      selectedCourseIcon: "",
      selectedSubjectTopicList: [],
      topicTableColumns: [
        {
          id: "topic",
          columnId: 1,
          label: "Topic Title",
          type: tableColumnTypes.SUBJECT_ICON_NAME,
        },
        {
          id: "noOfLessons",
          columnId: 2,
          label: "No. of Lessons",
          type: tableColumnTypes.NUMBER_LESS_WIDTH,
        },
        {
          id: "noOfActivity",
          columnId: 3,
          label: "No. of Activities",
          type: tableColumnTypes.NUMBER_LESS_WIDTH,
        },
        {
          id: "topicCompletionProgress",
          columnId: 4,
          label: "Completion",
          type: tableColumnTypes.CIRCULAR_DASHED_PROGRESS,
        },
        {
          id: "topicAvgGrade",
          columnId: 5,
          label: "Avg Grade",
          type: tableColumnTypes.LINEAR_PROGRESS,
        },
      ],
      pagination:{}
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleGetStudentsAssignedCourseListData({})
    // Customizable Area End
  }

  // Customizable Area Start

  handleGetTimeSpent = (timeInSec: number) => {
    return new Date(timeInSec * 1000).toISOString().substring(11, 16);
  };

  handleSelectCourse = (courseId: any) => {
    const { studentCoursesList } = this.state;
    const selectedCourse: any = studentCoursesList.find(
      (course: any) => course?.id === courseId
    );
    this.setState({ selectedCourseId: courseId, selectedCourse, selectedCourseIcon: selectedCourse?.subjectIcon }, () => {
      if (!!courseId) this.handleGetStudentsCourseTopicsListData(courseId);
    });
  };

  handleResumeJourney = () => {
    const { selectedCourse } = this.state;
    this.props.navigation?.navigate("StudentMyCoursesSubjectDetails", {}, {
      subjectName: selectedCourse?.courseTitle || "",
      subjectId: selectedCourse?.id || "",
    });
  };

  handleSetStudentCourseTableItem = (item: any) => {
    let courseObj = {
      id: item?.id,
      courseTitle: item?.attributes?.course_title,
      subjectName: item?.attributes?.subject?.name,
      subjectMarks: "NA",
      avgGrade: "0",
      subjectIcon: item?.attributes?.subject?.icon,
      timeSpentInSec: item?.attributes?.time_spent || 0,
      subjectBgColor: item?.attributes?.subject?.color,
      completionProgress: 0,
      activityCount: item?.attributes?.activity_count,
      lessonCount: item?.attributes?.total_no_of_lessons,
      topicCount: item?.attributes?.topic_count,
    }
    return courseObj;
  }

  handleSetStudentsCoursesData = (list: any,pagination: any) => {
    const studentCourses = list?.map((item: any) =>
      this.handleSetStudentCourseTableItem(item)
    );
    this.setState({ studentCoursesList: studentCourses,pagination:pagination}, () => {
      this.handleSelectCourse(studentCourses?.[0]?.id)
    });
    console.log("students courses", studentCourses)
  };

  handleStudentsAssignedCoursesListResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      this.handleSetStudentsCoursesData(responseJson.data,responseJson?.meta?.pagination);
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleGetStudentsAssignedCourseListData = async (pagination:any) => {
    showCustomLoader();

    const token = await getStorageData("token");

    const page = pagination.page ? pagination.page : 1;
    let requestUrl = configJSON.studentCoursesEndpoint + `?per_page=9&page=${page}`;
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetStudentCoursesList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSetStudentTopicsTableItem = (item: any) => {
    const { selectedCourse } = this.state;
    let topicObj = {
      id: item?.id,
      topic: {
        name: item?.attributes?.topic_title,
        icon: selectedCourse?.subjectIcon,
        bgColor: selectedCourse?.subjectBgColor,
      },
      noOfLessons: selectedCourse?.lessonCount || 0,
      noOfActivity: selectedCourse?.activityCount || 0,
      topicCompletionProgress: parseFloat(item?.attributes?.completion_score_by_student?.toFixed(2)) || 0,
      topicAvgGrade: item?.attributes?.avg_grade || 0,
    }
    return topicObj;
  }

  handleSetStudentsTopicsData = (list: any) => {
    const studentTopics = list?.map((item: any) =>
      this.handleSetStudentTopicsTableItem(item)
    );
    this.setState({ selectedSubjectTopicList: studentTopics });
  };

  handleStudentsCourseTopicsListResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      this.handleSetStudentsTopicsData(responseJson.data)
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleGetStudentsCourseTopicsListData = async (courseId: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetStudentCourseTopicsList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postAddNewTopic}?course_id=${courseId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handlePaginationChange = (event: any, page: number) => {
    this.handleGetStudentsAssignedCourseListData({page:page});
  }
  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetStudentCoursesList:
            this.handleStudentsAssignedCoursesListResponse(
              responseJson,
              errorResponse
            );
            break;
          case this.apiGetStudentCourseTopicsList:
            this.handleStudentsCourseTopicsListResponse(
              responseJson,
              errorResponse
            );
            break;
        }
      }

      // Customizable Area Start

      // Customizable Area End
    }
  }

  // Customizable Area End
}
// Customizable Area End
