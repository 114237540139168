import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";

// Customizable Area Start
import { gradingComponent } from "../../assets/mockdata";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../../framework/src/Utilities";
import moment from "moment";
const configJSON = require("./../config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any; 
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  openModelBox: boolean;
  progressStudentsTableBody: Array<{
    id: number;
    Name: {
      icon: string;
      name: string;
      id: number;
    };
    Progress: {
      value1: number | undefined;
      value2: number | undefined;
      value3: number | undefined;
    };
    SemesterAvg: number;
    GPA: number;
    LastAccess: string;
  }>;
  assignedAssessmentsList: Array<{
    id: string,
    Title: string,
    Type: string,
    Submitted: string,
    AvgGrade: {
      grade: number,
      avg: number,
      infoMsg: string,
    },
    DueDate: string,
  }>;
  studentCoursesList: Array<{
    id: string;
    subjectName: string;
    subjectMarks: number;
    lessonsCovered: number;
    lessonsTotal: number;
    status: string;
    subjectIcon: string;
    timeSpentInSec: number;
    subjectBgColor: string;
    completionProgress: number | string;
    dueDate: string;
  }>;
  classesList: Array<{
    id: string;
    label: string;
    value: string;
  }>;
  selectedTeacherClassDDValue: string;
  selectedCourseId: any;  
  selectedSubjectTopicList: any;
  selectedCourse: any;
  gradingComponent: Array<any>;
  selectedGradingComponentDDValue: any;
  studentList:any;
  isParent: boolean;
  // Customizable Area End
}

export default class ParentTeacherDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiGetTeacherCourses: string = "";
  apiGetProgressPerformanceList: string = "";
  apiGetTeachersAssignedClasses: string = "";
  apiGetTeacherAssignedAssessments: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      openModelBox: false,
      assignedAssessmentsList: [],
      progressStudentsTableBody: [],
      studentCoursesList: [],
      classesList: [],
      selectedTeacherClassDDValue: "all",
      selectedCourseId: null,      
      selectedSubjectTopicList: [],
      selectedCourse: null,
      selectedGradingComponentDDValue: '',
      gradingComponent: gradingComponent,
      studentList:[],
      isParent: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = getMessageData(
        message
      );

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetTeacherCourses:
            {
              this.handleGetTeacherCoursesResponse(responseJson);
            }
            break;

          case this.apiGetProgressPerformanceList:
            {
              this.handleGetProgressPerformanceStudentsListResponse(responseJson)
            }
            break;

          case this.apiGetTeachersAssignedClasses:
            {
              this.handleGetTeachersClassesListResponse(responseJson);
            }
            break;
          case this.apiGetTeacherAssignedAssessments:
            {
              this.handleGetAssignedAssessmentsResponse(responseJson)
            }
        }
      }
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetTeachersClassesListApi();
    // Customizable Area End
    this.setupViewByRole();
  }

  setupViewByRole = async () => {
    const role = await getStorageData('role');
    
    if (role === "Parent") this.setState({ isParent: true })
  }

  onCatchUpCancel = () => {
    this.setState({
      openModelBox: false,
    });
  };

  handleModalOPen = () => {
    this.setState({
      openModelBox: true,
    });
  };

  handleViewActivityList = (type: string) => {
    const { studentList } = this.state;
    this.props.navigation?.navigate(
      "ParentTeacherDashboardViewall",
      {},
      {
        studentId: studentList,       
      }
    );
  };

  handleViewActivityListt = (type: string) => {
    const { studentList } = this.state;
    this.props.navigation?.navigate(
      "ParentAssinedAssesment",
      {},
      {
        studentId: studentList,       
      }
    );
  };

  handleGetApiBaseUrl = (baseUrl: string) => {
    const { classesList, selectedTeacherClassDDValue } = this.state;
    let requestUrl = baseUrl;
    if (selectedTeacherClassDDValue !== "all") {
      requestUrl = requestUrl + `?student_id=${selectedTeacherClassDDValue}`;
    }

    if (selectedTeacherClassDDValue && selectedTeacherClassDDValue == "all") {
      const classIds = classesList
        .filter(
          (classItem: { id: string; value: string; label: string }) =>
            classItem.value !== "all"
        )
        .map((item: { id: string; value: string; label: string }) => {
          return item.value;
        });     
    }
    return requestUrl;
  }

  handleGetTeachersClassesListApi = async () => {
    const requestUrl = configJSON.teacherDashboardClassesEndpoint;
    const token = await getStorageData("token");
    const header = {
      token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetTeachersAssignedClasses = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetAssignedAssessmentsApi = async () => {
    showCustomLoader();
    let apiConfig = configJSON.teacherAssessmentEndpoint;
    let requestUrl = apiConfig + "?type=assigned&per_page=10?type=assigned";
    const { selectedTeacherClassDDValue } = this.state;
    if (selectedTeacherClassDDValue !== "all") {
      requestUrl = configJSON.getAssignAssessmentEndPoint + `&student_id=${selectedTeacherClassDDValue}`;
    }

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetTeacherAssignedAssessments = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };



  handleGetTeachersClassesListResponse = (response: {
    data: Array<{      
      id: number;
      attributes:{};
      last_name:string;
      first_name: string;
    }>;
    errors: Array<{}>;
  }) => {
    if (response?.data) {
      const classesDropdownAll = [
        {
          id: "all",
          label: "All",
          value: "all",
        },
      ];
      let classesDropdownData = response.data.map(
        (item: {  id: number; attributes:{first_name?: string; last_name?:string} }) => {
          return {
            label: item?.attributes?.first_name + " " + item?.attributes?.last_name,
            value: (item?.id).toString(),
            id: (item?.id).toString(),
          };
        }
      );
      this.setState(
        {
          classesList: classesDropdownAll.concat(classesDropdownData),
          selectedCourse: null,
          selectedCourseId: "",
        },
        () => {
          this.handleGetTeacherCoursesApi();
          this.handleGetProgressPerformanceStudentsListApi();
          this.handleGetAssignedAssessmentsApi();
        }
      );
    } else {
      displayApiErrorMsg(response?.errors, this.props.navigation);
    }
  };

  handleGetTeacherCoursesApi = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = { token };
    const parentCourseProgressEndpoint = configJSON.parentCourseProgress;
    const { isParent } = this.state;
    const endpoint = !isParent ? configJSON.getTeacherCoursesEndpoint : parentCourseProgressEndpoint;
    let requestUrl = this.handleGetApiBaseUrl(endpoint);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.studentDashboardClassesMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetTeacherCourses = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetProgressPerformanceStudentsListApi = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = { token };
    const { selectedCourseId } = this.state;

    const parentPerformanceEndpoint = configJSON.teacherClassCourseStudentsApi;
    const { isParent } = this.state;
    let requestUrl = !isParent ? configJSON.teacherClassCourseStudentsApi : parentPerformanceEndpoint;

    requestUrl = this.handleGetApiBaseUrl(requestUrl);

    if(selectedCourseId) {
      requestUrl = requestUrl + `?student_id=${selectedCourseId}`;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetProgressPerformanceList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  trimDemicalNumber(value: number) {
    if (value) {
      const str = value.toString();
      const dotIndex = str.indexOf('.');
      if (dotIndex === -1) {
        return value;
      }
      const integerPart = str.slice(0, dotIndex);
      const decimalPart = str.slice(dotIndex + 1, dotIndex + 3);
      return parseFloat(integerPart + '.' + decimalPart);
    } else {
      return 0;
    }
  }

  handleGetProgressPerformanceStudentsListResponse = (responseJson: {
    data: Array<{
      id: number;
      attributes: {
        profile_url: {
          url: string;
        };
        first_name: string;
        last_name: string;
        progress: {
          completed: number;
          not_tackled: number;
          remaining: number;
          course_wise_progress: Array<{
            id: number,
            completed_percentage: number,
            not_tackled_percentage: number,
            remaining_percentage: number,
          }>;
        };
        semsester_avg:number,
        gpa:number,
        last_access: string;
      };
    }>;
    errors: Array<{}>;
  }) => {
    if (responseJson?.data) {
      const { selectedCourseId } = this.state;
      const studentList = responseJson.data.map((item) => {
        const studentDetails = item.attributes;
        const progressDetails = studentDetails?.progress?.course_wise_progress?.find((courseItem) => (courseItem.id).toString() === selectedCourseId);
        return {
          id: item.id,
          Name: {
            icon: studentDetails?.profile_url?.url || "",
            name: `${studentDetails.first_name} ${studentDetails.last_name}`,
            id: item.id,
          },
          Progress: {
            value1: !selectedCourseId ? studentDetails?.progress?.completed : progressDetails?.completed_percentage,
            value2: !selectedCourseId ? studentDetails?.progress?.remaining : progressDetails?.remaining_percentage,
            value3: !selectedCourseId ? studentDetails?.progress?.not_tackled : progressDetails?.not_tackled_percentage,
          },
          SemesterAvg: this.trimDemicalNumber(studentDetails?.semsester_avg),
          GPA: this.trimDemicalNumber(studentDetails?.gpa),
          LastAccess: studentDetails?.last_access
            ? moment(studentDetails?.last_access).format("MMMM DD, YYYY")
            : "NA",
        };
      });
      const limitStudentList = studentList.slice(0, 7);
      this.setState({ progressStudentsTableBody: limitStudentList });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleGetTeacherCoursesResponse = (responseJson: {
    data: Array<{
      id: string;
      attributes: {
        course_title: string;
        total_no_of_lessons: number;
        student_course_status: string;
        time_spent: number;
        due_date: string;
        end_date:string;
        subject: {
          name: string;
          icon: string;
          color: string;
        };
        school_year: {
          start_date: string;
          end_date: string;
        };
        completed_lessons: Array<{
          student_id: number;
        }>;
      };
    }>;
    errors: Array<{}>;
  }) => {
    const { isParent } = this.state;

    if (responseJson.data) {
      const courseList = responseJson.data.map((item) => {
        const teacherCourse = item.attributes;
        const lessonsCompleted: any = isParent ? teacherCourse.completed_lessons : teacherCourse.completed_lessons?.length || 0;
        const totalLessons = teacherCourse?.total_no_of_lessons;
        const lessonCompletionPercent =
          lessonsCompleted === 0
            ? 0
            : Math.ceil((lessonsCompleted / totalLessons) * 100);

        const parentLessonCompletionPercent = isParent ? Math.ceil((teacherCourse.completed_lessons as any / totalLessons) * 100) : 0;

        return {
          id: item.id,
          subjectName: teacherCourse?.course_title,
          subjectMarks: 10,
          lessonsCovered: lessonsCompleted,
          lessonsTotal: teacherCourse?.total_no_of_lessons,
          status: teacherCourse?.student_course_status,
          subjectIcon: teacherCourse?.subject?.icon,
          timeSpentInSec: teacherCourse?.time_spent || 0,
          subjectBgColor: teacherCourse?.subject?.color,
          completionProgress: isParent ? parentLessonCompletionPercent : lessonCompletionPercent,
          dueDate: teacherCourse?.school_year?.end_date
            ? moment(teacherCourse?.school_year?.end_date).format("MMMM DD, YYYY")
            : "NA",
        };
      });
      this.setState({ studentCoursesList: courseList });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleGetAssignedAssessmentsResponse = (responseJson: {
    data: Array<{
      id: string,
      attributes: {
        subject: {
          color: string
          icon: string,
          id: string,
        },
        students:{
          class_avg:number,
        },
        grading_component: string,
        submission_percenttage: number,
        student_received_grade: {
          grade: number,
          avg: number
        },
        due_date: string,
        due_days: number,
        activities_title: string,
      }
    }>, errors: Array<{}>
  }) => {
    if (responseJson.data) {
      let assessmentList = responseJson.data.map(
        (item) => {
          const activity = item.attributes;
          return {
            Submitted: `${activity?.submission_percenttage}%`,
            id: item.id,
            Title: activity?.activities_title,
            subject: {
              name: activity.activities_title,
              icon: activity.subject.icon,
              bgColor: activity.subject.color,
            },
            subjectId: activity.subject.id,
            Type: activity.grading_component,
            AvgGrade: {
              grade: activity?.students?.class_avg|| 0,
              avg: activity?.students?.class_avg || 0,
              infoMsg: "None of the submitted response has been graded."
            },
            DueDate: activity?.due_date ? moment(activity?.due_date).format('MMMM DD, YYYY') : 'NA',
          };
        }
      );
      const limitAssessmentList = assessmentList.slice(0, 7);
      this.setState({ assignedAssessmentsList: limitAssessmentList  });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }; 

  handleChangeDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
          selectedCourse: null,
          selectedCourseId: "",
        };
      }, () => {
        this.handleGetTeacherCoursesApi();
        this.handleGetProgressPerformanceStudentsListApi();
        this.handleGetAssignedAssessmentsApi();
      });
    }
  };

  handleSelectCourse = (courseId: any) => {
    const { studentCoursesList } = this.state;
    const selectedCourse: any = studentCoursesList.find((course: any) => course?.id === courseId);
    this.setState({ selectedCourseId: courseId, selectedCourse },()=>{
      this.handleGetProgressPerformanceStudentsListApi();
  })
  }   
  renderGradingComponenttDDValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "All";
    }
    const { classesList } = this.state;
    const selectedClass = classesList.find(
      (grade: any) => grade?.id === selected
    );
    return selectedClass?.label;
  };


  // Customizable Area End
}