// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import {
  englishSubjectIcon,
  historySubjectIcon,
  mathSubjectIcon,
  physicsSubjectIcon,
} from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import {
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
} from "../../../components/src/CommonHelper.web";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
export const completedActivityTabsList = [
  {
    id: 1,
    label: "Last Week",
    value: "lastweek",
  },
  {
    id: 2,
    label: "This Week",
    value: "thisweek",
  },
  {
    id: 3,
    label: "This Month",
    value: "month",
  },
  {
    id: 4,
    label: "YTD",
    value: "ytd",
  },
];
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

interface IProfileProps {
  email: string;
  profileUrl: string;
  idNumber: number;
  role: string;
  curriculum: {
    id: string;
    name: string;
  };
  status: string;
  schoolYear: string;
  classDetail: {
    id: number;
    name: string;
  };
  dob: string;
  gradeDetails: {
    id: string;
    name: string;
  };
  expiringDate: string;
  customFields: Array<{ id: string; label: string; value: string }>;
  parentsList: Array<{
    id: string;
    name: string;
    contactNum: string;
    relationship: string;
    role: string;
    email: string;
    profileUrl: string;
  }>;
}
// Customizable Area End
export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  progressStatisticsForGraph?: {
    statistics?: {
      completed?: number;
      remaining?: number;
      notTackled?: number;
    };
  },
  courseProgressTable?:  Array<{
    id?: string;
    Progress?: {
      value1?: number;
      value2?: number;
      value3?: number;
    };
    Course?: {
      name?: string;
      icon?: string;
      color?: string;
      courseId?: number;
    };
    courseScore?: number;
  }>
  isFirstRender?:boolean;
  selectedStudentName?: any;
  selectedStudentId?: number;
  // Customizable Area End
}

interface BtnConfig {
  id: string;
  label: string;
  type: string;
  handleClick: () => void;
}

interface S {
  // Customizable Area Start
  loading: boolean;
  profileData: IProfileProps | null;
  searchStudent: string;
  tabValue: number;
  progressStatistics: {
    remark?: string;
    statistics?: {
      completed?: number;
      remaining?: number;
      notTackled?: number;
    };
    timeSpentInSec?: number;
    noOfAttemptRemaining?: number;
  };
  studentProgressColumn: Array<any>;
  studentProgressBody: Array<{
    id?: string;
    Progress?: {
      value1?: number;
      value2?: number;
      value3?: number;
    };
    Course?: {
      name?: string;
      icon?: string;
      color?: string;
      courseId?: number;
    };
    courseScore?: number;
  }>;
  pendingActivitiesList: Array<{
    id: string;
    Title: {
      icon: string;
      name: string;
      color: string;
    };
    Type: string;
    Time_Spent: string;
    DueDate: string;
    Assigned_On: string;
    Due_Days: number;
  }>;
  studentPerformanceData: Array<{
    id: number;
    subjectName: string;
    courseMarks: number;
    courseName: string;
    subjectBgColor: string;
    progressPercentage: number;
  }>;
  studentTimeSpentTableBody: Array<{
    id: string;
    Course: {
      icon: string;
      name: string;
      color: string;
    };
    time_spent: string;
  }>;
  recentActivitiesList: Array<{
    id: string;
    Title: {
      icon: string;
      name: string;
      color: string;
    };
    Type: string;
    Time_Spent: string;
    DueDate: string;
  }>;
  totalTimeSpentInSec: number;
  totalTimeSpentInHHMM: string;
  PendingLessonsColumn: Array<any>;
  GradedActivitiesColumn: Array<any>;
  gradedActivitiesBody: Array<{
    id: string;
    Title: {
      icon: string;
      name: string;
      color: string;
    };
    Grading_Component: string;
    Graded_On: string;
    Points: number;
    Grade: number;
    Average: string;
  }>;
  selectedCompletedActivityTabValue: number;
  selectedStudentName: any;
  selectedStudentId: number;
  isRegistrar: boolean;
  schoolYearStartDate: string;
  schoolYearEndDate: string;
  progressPerformanceEndDate: string;
  progressPerformanceStartDate: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentProfileProgressController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetStudentDetails: string = "";
  apiGetRecentActivities: string = "";
  apiGetPendingActivities: string = "";
  apiGetGradedActivities: string = "";
  apiGetDateFilteredProgressPerformanceData: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      tabValue: 0,
      searchStudent: "",
      selectedStudentName: "",
      selectedStudentId: 0,
      studentProgressColumn: [
        {
          id: "Course",
          columnId: 1,
          label: "Course",
          type: tableColumnTypes.PROGRESS_TAB_ICON_COURSE,
        },
        {
          id: "Progress",
          columnId: 2,
          label: "Progress",
          type: tableColumnTypes.STUDENT_PROGRESS_BAR,
        },
      ],
      recentActivitiesList: [],
      studentProgressBody: [],
      profileData: null,
      progressStatistics: {},
      pendingActivitiesList: [],
      studentTimeSpentTableBody: [],
      totalTimeSpentInSec: 0,
      totalTimeSpentInHHMM: "",
      PendingLessonsColumn: [
        {
          id: "Title",
          columnId: 1,
          label: "Title",
          type: tableColumnTypes.PENDING_ACTIVITY_ICON_TEXT,
        },
        {
          id: "Type",
          columnId: 2,
          label: "Type",
          type: tableColumnTypes.TEXT,
        },
        {
          id: "Assigned_On",
          columnId: 3,
          label: "Assigned On",
          type: tableColumnTypes.DUE_DATE,
        },
        {
          id: "DueDate",
          columnId: 2,
          label: "Due Date",
          type: tableColumnTypes.DUE_DATE,
        },
        {
          id: "Due_Days",
          columnId: 2,
          label: "Due Days",
          type: tableColumnTypes.DUE_DATE,
        },
      ],
      GradedActivitiesColumn: [
        {
          id: "Title",
          columnId: 1,
          label: "Title",
          type: tableColumnTypes.STUD_PROGRESS_ICON,
        },
        {
          id: "Grading_Component",
          columnId: 2,
          label: "Grading Component",
          type: tableColumnTypes.TEXT,
        },
        {
          id: "Graded_On",
          columnId: 3,
          label: "Graded On",
          type: tableColumnTypes.DUE_DATE,
        },
        {
          id: "Points",
          columnId: 4,
          label: "Points",
          type: tableColumnTypes.DUE_DATE,
        },
        {
          id: "Grade",
          columnId: 5,
          label: " Grade",
          type: tableColumnTypes.DUE_DATE,
        },
        {
          id: "Average",
          columnId: 6,
          label: "Average",
          type: tableColumnTypes.DUE_DATE,
        },
      ],
      gradedActivitiesBody: [],
      studentPerformanceData: [],
      selectedCompletedActivityTabValue: 3,
      isRegistrar: false,
      progressPerformanceEndDate: "",
      progressPerformanceStartDate: "",
      schoolYearEndDate: "",
      schoolYearStartDate: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    if(!this.props.isFirstRender){
    this.handleSetStudentId();
    this.handleGetRecentActivitiesList();
    this.handleGetPendingActivitiesList();
    this.handleGetGradedActivitiesList();
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetStudentDetails:
            this.handleStudentDetailsApiResponse(responseJson);
            break;
          case this.apiGetRecentActivities:
            this.handleRecentActivitiesResponse(responseJson);
            break;
          case this.apiGetPendingActivities:
            this.handlePendingActivitiesResponse(responseJson);
            break;
          case this.apiGetGradedActivities:
            this.handleGradedActivitiesResponse(responseJson);
            break;
          case this.apiGetDateFilteredProgressPerformanceData:
            this.handleFilteredProgressPerformanceDataResponse(responseJson);
            break;
        }
      }
    }
  }

  // Customizable Area End
  handleSetStudentId = async () => {
    const studentId = this.props.navigation?.getParam("studentId");
    const token = await getStorageData("token");
    if (studentId) {
      this.setState({ selectedStudentId: studentId });
      const fromDate = moment().startOf("isoWeek").format("DD/MM/YYYY");
      const toDate = moment().endOf("isoWeek").format("DD/MM/YYYY");
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };

      const getUserStatusMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiGetStudentDetails = getUserStatusMsg.messageId;

      getUserStatusMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.studentProfileProgress + `?student_id=${studentId}&start_date=${fromDate}&end_date=${toDate}`
      );

      getUserStatusMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      getUserStatusMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
    } else {
      this.props.navigation?.goBack();
    }
  };

  getCustomFields = (
    customFields: Array<{
      id: string;
      attributes: { field_value: string; label: string };
    }>
  ) => {
    const cfFields = customFields?.map((item) => {
      return {
        id: item?.id,
        label: item?.attributes?.label,
        value: item?.attributes?.field_value || "NA",
      };
    });
    return cfFields || [];
  };

  getParentsList = (
    parentsArr: Array<{
      id: string;
      first_name: string;
      last_name: string;
      email: string;
      role: {
        name: string;
        role_title: string;
      };
      relationship: string;
      contact_number: string;
      profile_url: string;
    }>
  ) => {
    const parentsList = parentsArr?.map((item) => {
      return {
        id: item?.id,
        name: `${item?.first_name || ""} ${item?.last_name || ""}`,
        email: item?.email || "NA",
        role: item?.role?.name || "NA",
        relationship: item?.relationship || "NA",
        contactNum: item?.contact_number || "NA",
        profileUrl: item?.profile_url || "",
      };
    });
    return parentsList;
  };

  getStudentProgressData = (
    progressDetails: Array<{
      id: string;
      subject: { icon: string; color: string; id: number };
      title: string;
      course_score: number;
      completed_percentage: number;
      remaining_percentage: number;
      not_tackled_percentage: number;
    }>
  ) => {
    const progressData: Array<{
      id: string;
      Course: { name: string; icon: string; color: string };
      Progress: { value1: number; value2: number; value3: number };
    }> = progressDetails?.map((item) => {
      return {
        id: `${item?.subject?.id}-${item?.course_score?.toFixed(2)}`,
        Course: {
          name: item?.title || "",
          icon: item?.subject?.icon || "",
          color: item?.subject?.color || "",
          courseId: item?.id,
        },
        Progress: {
          value1: item.completed_percentage || 0,
          value2: item?.remaining_percentage || 0,
          value3: item?.not_tackled_percentage || 0,
        },
      };
    });
    return progressData || [];
  };

  getPerformanceData = (
    performanceData: Array<{
      course: {
        title: string;
        subject: {
          color: string;
          name: string;
          id: number;
          icon: string;
        };
      };
      course_score: number;
      percentage: number;
    }>
  ) => {
    const performanceDetails = performanceData?.map((item) => {
      return {
        id: item?.course?.subject?.id,
        subjectName: item?.course?.subject?.name,
        courseName: item?.course?.title,
        courseMarks: item?.course_score || 0,
        subjectBgColor: item?.course?.subject?.color,
        progressPercentage: item?.percentage || 0,
      };
    });
    return performanceDetails;
  };

  getTimeSpentDetails = (
    performanceData: Array<{
      course: {
        title: string;
        subject: {
          color: string;
          name: string;
          id: number;
          icon: string;
        };
      };
      time_spent: number;
    }>
  ) => {
    const timeSpentDetails: Array<{
      id: string;
      Course: {
        icon: string;
        name: string;
        color: string;
      };
      time_spent: string;
    }> = performanceData?.map((item) => {
      return {
        id: `${item?.course?.subject?.id}-${item?.time_spent}`,
        Course: {
          name: item?.course?.title,
          icon: item?.course?.subject?.icon || "",
          color: item?.course?.subject?.color,
        },
        time_spent: new Date(item?.time_spent * 1000)
          .toISOString()
          .slice(11, 19),
      };
    });
    return timeSpentDetails;
  };

  handleStudentDetailsApiResponse = (responseJson: any) => {
    if (responseJson?.data) {
      const student = responseJson?.data?.attributes;
      const progressDetails = student?.progress;
      const progressTableBody = student?.progress?.course_wise_progress;
      const performanceData = student?.performance_data;
      let totalTime = 0;
      performanceData?.forEach((item: { time_spent: number }) => {
        totalTime += item?.time_spent;
      });
      this.setState({
        selectedStudentName: `${student?.first_name} ${student?.last_name}`,
        profileData: {
          classDetail: {
            id: student?.student_class?.[0]?.id,
            name: student?.student_class?.[0]?.name || "NA",
          },
          curriculum: {
            id: student?.curriculum?.id,
            name: student?.curriculum?.name || "NA",
          },
          gradeDetails: {
            id: student?.grade?.[0]?.id,
            name: student?.grade?.[0]?.name || "NA",
          },
          customFields: this.getCustomFields(student?.user_custom_fields?.data),
          parentsList: this.getParentsList(student?.parents),
          dob: moment(student?.DOB).format("MMM DD, YYYY"),
          email: student?.email,
          expiringDate: moment(student?.expiring_on).format("DD/MM/YYYY"),
          idNumber: student?.id_number || "NA",
          profileUrl: student?.profile_url?.url || "",
          role: "Student",
          schoolYear: student?.school_year?.title,
          status: student?.status,
        },
        progressStatistics: {
          statistics: {
            completed: progressDetails?.completed || 0,
            remaining: progressDetails?.remaining || 0,
            notTackled: progressDetails?.not_tackled || 0,
          },
        },
        studentProgressBody: this.getStudentProgressData(progressTableBody),
        studentPerformanceData: this.getPerformanceData(performanceData),
        studentTimeSpentTableBody: this.getTimeSpentDetails(performanceData),
        totalTimeSpentInSec: totalTime,
        totalTimeSpentInHHMM: new Date(totalTime * 1000)
          .toISOString()
          .substring(11, 16),
        schoolYearEndDate: student?.school_year?.end_date,
        schoolYearStartDate:  student?.student_class?.[0]?.school_year?.start_date,
      }
      ,()=>{
        this.handleDYTDateRange()
      }
    );
    }
  };

  handleGetFilteredProgressPerformanceData = async () => {
    const { selectedStudentId, progressPerformanceEndDate, progressPerformanceStartDate } = this.state;
    const token = await getStorageData("token");
      showCustomLoader();
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };

      const getProgressPerformanceMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiGetDateFilteredProgressPerformanceData = getProgressPerformanceMsg.messageId;

      getProgressPerformanceMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.studentProfileProgress + `?student_id=${selectedStudentId}&start_date=${progressPerformanceStartDate}&end_date=${progressPerformanceEndDate}`
      );

      getProgressPerformanceMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      getProgressPerformanceMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(getProgressPerformanceMsg.id, getProgressPerformanceMsg);
  };

  handleFilteredProgressPerformanceDataResponse = (responseJson: any) => {
    if(responseJson?.data){
      const progressDetails = responseJson?.data?.attributes?.progress;
      const progressTableBody = responseJson?.data?.attributes?.progress?.course_wise_progress;
      const performanceData = responseJson?.data?.attributes?.performance_data;
      let totalTime = 0;
      performanceData?.forEach((item: { time_spent: number }) => {
        totalTime += item?.time_spent;
      });
      this.setState({
        progressStatistics: {
          statistics: {
            completed: progressDetails?.completed || 0,
            remaining: progressDetails?.remaining || 0,
            notTackled: progressDetails?.not_tackled || 0,
          },
        },
        studentProgressBody: this.getStudentProgressData(progressTableBody),
        studentPerformanceData: this.getPerformanceData(performanceData),
        studentTimeSpentTableBody: this.getTimeSpentDetails(performanceData),
        totalTimeSpentInSec: totalTime,
        totalTimeSpentInHHMM: new Date(totalTime * 1000)
          .toISOString()
          .substring(11, 16),
      })
    }
    hideCustomLoader();
  }

  handleGoBack = () => {
    this.props.navigation.goBack();
  };

  handleChange = (event: any, newValue: number) => {
    this.setState({
      tabValue: newValue,
    });
  };

  handleChangeCompletedActivityTab = (event: any, newValue: number) => {
    this.setState({ selectedCompletedActivityTabValue: newValue }, () => {
      this.getProgressPerformanceDateRange();
    });
  };

  handleGetStudentprogressDetails = async (e: any) => {
    let selectedCourseProgress = this?.props?.courseProgressTable?.find((item: any) => {
      return e.courseId === item.Course.courseId;
    });
    let course = selectedCourseProgress?.Course?.name;
    let courseId = selectedCourseProgress?.Course?.courseId;
    let { selectedStudentName, selectedStudentId } = this.props;
    if (selectedCourseProgress !== undefined) {
      const roleRegistrar = window.location.pathname
        .toLowerCase()
        .includes("registrar");
      if (roleRegistrar) {
        this.props.navigation?.navigate(
          "RegistrarStudentCourseProgressDetails",
          {},
          {
            studentName: selectedStudentName,
            studentId: selectedStudentId,
            subjectName: course || "",
            subjectId: courseId || "",
          }
        );
      } else {
        this.props.navigation.navigate(
          "TeacherStudentCourseJourney",
          {},
          {
            courseId: courseId,
            selfCourse: "MyCourse",
            courseName: course,
            studentName: selectedStudentName,
            studentId: selectedStudentId,
          }
        );
      }
    }
  };

  handleRecentActivitiesResponse = (responseJson: any) => {
    if (responseJson?.data) {
      const activitiesList = responseJson?.data?.slice(0, 7)?.map(
        (item: {
          id: string;
          attributes: {
            activities_title: string;
            subject: { icon: string; color: string };
            time_spent: number;
            activity_type: string;
            due_date: string;
            last_activity_perform_at: string;
          };
        }) => {
          return {
            id: item?.id,
            Title: {
              icon: item?.attributes?.subject?.icon,
              name: item?.attributes?.activities_title,
              color: item?.attributes?.subject?.color,
            },
            Type: item?.attributes?.activity_type,
            Time_Spent: new Date(item?.attributes?.time_spent * 1000)
              .toISOString()
              .substring(11, 16),
            lastActivity: item?.attributes?.last_activity_perform_at
              ? moment(item?.attributes?.last_activity_perform_at).format(
                  "MMMM DD, YYYY"
                )
              : "NA",
          };
        }
      );
      this.setState({ recentActivitiesList: activitiesList });
    } else {
      this.setState({ recentActivitiesList: [] });
    }
    hideCustomLoader();
  };

  handleGetRecentActivitiesList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const studentId = this.props.navigation?.getParam("studentId");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetRecentActivities = getUserStatusMsg.messageId;

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentWiseActivities +
        `?student_id=${studentId}&student_activity=recent_activity`
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
  };

  handlePendingActivitiesResponse = (responseJson: any) => {
    if (responseJson?.data) {
      const pendingActivitiesList = responseJson?.data?.slice(0, 7)?.map(
        (item: {
          id: string;
          attributes: {
            activities_title: string;
            subject: { icon: string; color: string };
            time_spent: number;
            activity_type: string;
            due_date: string;
            due_days: number;
            assigned_on: string;
          };
        }) => {
          return {
            id: item?.id,
            Title: {
              name: item?.attributes?.activities_title,
              icon: item?.attributes?.subject?.icon,
              color: item?.attributes?.subject?.color,
            },
            Type: item?.attributes?.activity_type,
            Time_Spent: new Date(item?.attributes?.time_spent * 1000)
              .toISOString()
              .substring(11, 16),
            DueDate: item?.attributes?.due_date
              ? moment(item?.attributes?.due_date).format("MMMM DD, YYYY")
              : "NA",
            Due_Days: item?.attributes?.due_days,
            Assigned_On: item?.attributes?.assigned_on
              ? moment(item?.attributes?.assigned_on).format("MMMM DD, YYYY")
              : "NA",
          };
        }
      );
      this.setState({ pendingActivitiesList: pendingActivitiesList });
    } else {
      this.setState({ pendingActivitiesList: [] });
    }
    hideCustomLoader();
  };

  handleGetPendingActivitiesList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const studentId = this.props.navigation?.getParam("studentId");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const getActivityListMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPendingActivities = getActivityListMsg.messageId;

    getActivityListMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentWiseActivities +
        `?student_id=${studentId}&student_activity=pending_activity`
    );

    getActivityListMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getActivityListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getActivityListMsg.id, getActivityListMsg);
  };

  handleGradedActivitiesResponse = (responseJson: any) => {
    if (responseJson?.data) {
      const gradedActivitiesList = responseJson?.data?.slice(0, 7)?.map(
        (item: {
          id: string;
          attributes: {
            activities_title: string;
            subject: { icon: string; color: string };
            time_spent: number;
            activity_type: string;
            grading_component: string;
            max_score: number;
            average: number;
            student_received_grade: {
              graded_on: string;
              grade: number;
              avg: number;
            };
          };
        }) => {
          return {
            id: item?.id,
            Title: {
              name: item?.attributes?.activities_title,
              icon: item?.attributes?.subject?.icon,
              color: item?.attributes?.subject?.color,
            },
            Type: item?.attributes?.activity_type,
            Time_Spent: new Date(item?.attributes?.time_spent * 1000)
              .toISOString()
              .substring(11, 16),
            Grading_Component: item?.attributes?.grading_component,
            Graded_On: item?.attributes?.student_received_grade?.graded_on
              ? moment(item.attributes.student_received_grade.graded_on).format(
                  "MMMM DD, YYYY"
                )
              : "NA",
            Points: item?.attributes?.max_score || 0,
            Grade: item?.attributes?.student_received_grade?.grade || 0,
            Average: item?.attributes?.student_received_grade?.avg || 0,
          };
        }
      );
      this.setState({ gradedActivitiesBody: gradedActivitiesList });
    } else {
      this.setState({ gradedActivitiesBody: [] });
    }
    hideCustomLoader();
  };

  handleGetGradedActivitiesList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const studentId = this.props.navigation?.getParam("studentId");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const getGradedActivityListMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetGradedActivities = getGradedActivityListMsg.messageId;

    getGradedActivityListMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentWiseActivities +
        `?student_id=${studentId}&student_activity=graded_activity`
    );

    getGradedActivityListMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getGradedActivityListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(
      getGradedActivityListMsg.id,
      getGradedActivityListMsg
    );
  };

  handleViewActivityList = (type: string) => {
    const { selectedStudentId } = this.state;
    this.props.navigation?.navigate(
      "StudentProfileAssignedActivitiesList",
      {},
      {
        studentId: selectedStudentId,
        activityListType: type,
      }
    );
  };

  handleLastWeekDateRange = () => {
    const fromDate = moment().subtract(1, "weeks").startOf("isoWeek");
    const toDate = moment().subtract(1, "weeks").endOf("isoWeek");
    this.setState({
      progressPerformanceStartDate: fromDate.format("DD/MM/YYYY"),
      progressPerformanceEndDate: toDate.format("DD/MM/YYYY"),
    }, () => {
      this.handleGetFilteredProgressPerformanceData();
    });
  };

  handleThisWeekDateRange = () => {
    const fromDate = moment().startOf("isoWeek");
    const toDate = moment().endOf("isoWeek");
    this.setState({
      progressPerformanceStartDate: fromDate.format("DD/MM/YYYY"),
      progressPerformanceEndDate: toDate.format("DD/MM/YYYY"),
    }, () => {
      this.handleGetFilteredProgressPerformanceData();
    });
  };

  handleThisMonthDateRange = () => {
    const fromDate = moment().startOf("month");
    const toDate = moment().endOf("month");
    this.setState({
      progressPerformanceStartDate: fromDate.format("DD/MM/YYYY"),
      progressPerformanceEndDate: toDate.format("DD/MM/YYYY"),
    }, () => {
      this.handleGetFilteredProgressPerformanceData();
    });
  };

  handleDYTDateRange = () => {
    const { schoolYearStartDate } = this.state;
    this.setState({
      progressPerformanceStartDate: moment(schoolYearStartDate).format("DD/MM/YYYY"),
      progressPerformanceEndDate: moment().format("DD/MM/YYYY"),
    }, () => {
      this.handleGetFilteredProgressPerformanceData();
    });
  };

  getProgressPerformanceDateRange = () => {
    const { selectedCompletedActivityTabValue } = this.state;
    const dateRange =
      completedActivityTabsList[selectedCompletedActivityTabValue].value;
    switch (dateRange) {
      case "lastweek":
        this.handleLastWeekDateRange();
        break;
      case "thisweek":
        this.handleThisWeekDateRange();
        break;
      case "month":
        this.handleThisMonthDateRange();
        break;
      case "ytd":
        this.handleDYTDateRange()
        break;
    }
  };
}
// Customizable Area End
