// Customizable Area Start
import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  Checkbox,
  Switch,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import CatchMeUpModalController, {
  Props,
} from "./CatchMeUpModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { NewSchoolSuccesModal } from "./NewSchoolSuccesModal.web";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./calendar.css";
import CalendarIcon from "../../cfcurriculmschoolyearcreation2/src/assets/CalendarIcon.web";
import { blue } from "@material-ui/core/colors";
import { informationIcon } from "./assets";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    checkBoxRoots: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
    daysPatternWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    schoolCheckBox: {
      color: "#FFD92E",
      borderRadius: "6px",
      padding: "0px",
      "& .MuiSvgIcon-root": {
        width: "30px",
        height: "30px",
      },
    },
    dayLables: {
      fontSize: "16px",
      fontWeight: 500,
      color: "#888888",
    },
    inputLables: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(1.5),
      color: "#888888",
      fontSize: "16px",
      fontWeight: 400,
    },
  });
const theme = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        width: "880px",
      },
      paperWidthSm: {
        maxWidth: "880px",
      },
    },
  },
});
const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 25,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    height: "22px",
    width: "20px",
    "&$checked": {
      transform: "translateX(20px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: blue[500],
        opacity: 1,
        border: "none",
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: "#cfcfcf",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
}))(Switch);
// Customizable Area End

// Customizable Area Start
export class NewSchoolModal extends CatchMeUpModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open } = this.props;
    const weekDay = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    return (
      <ThemeProvider theme={theme}>
        <Dialog
          className={`${classes.highlightBgColor} ${classes.quizViewAnswerConfirmDialog}`}
          aria-labelledby="quiz-view-answer-confirm-dialog-title"
          open={open}
          style={{ width: "100%" }}
        >
          <Box padding={"21px 30px 30px 30px"} className={`${classes.bgColor}`}>
            <Grid direction="row" spacing={2} container>
              <Grid xs={12} item>
                <Typography
                  className={`${classes.fontText24} ${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                >
                  Create New School Day
                </Typography>
                <IconButton
                  data-test-id="crossSchool"
                  style={{ float: "right" }}
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={this.props.onCancel}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <DialogContent
              style={{ padding: "0px", marginTop: "10px", overflow: "hidden" }}
            >
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                  <Box>
                    <Typography className={classes.inputLables}>
                      Select days when lessons will be conducted
                    </Typography>
                    <Box className={classes.daysPatternWrapper}>
                      {weekDay.map((day, index) => (
                        <Box className={classes.checkBoxRoots} key={index}>
                          <Checkbox
                            data-test-id="day-pattern-checkbox"
                            style={{ color: "FFD92E" }}
                            className={classes.schoolCheckBox}
                          />
                          <Typography className={classes.dayLables}>
                            {day}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} style={{ marginTop: "15px" }}>
                  <Box>
                    <Typography
                      className={`${classes.fontText18} ${classes.textCommonColor}`}
                      component={"span"}
                    >
                      {`Estimated Course Schedule`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} style={{ marginTop: "15px" }}>
                  <Box
                    display={"flex"}
                    style={{ gap: "20px", alignItems: "center" }}
                  >
                    <CustomSwitch
                      checked={undefined}
                      onChange={undefined}
                      name="ios-switch"
                    />
                    <Typography
                      className={`${classes.textCommonColor}`}
                      component={"span"}
                      style={{ fontSize: "15px" }}
                    >
                      {`Manually manage dates`}
                    </Typography>
                    <Box>
                      <Tooltip
                        classes={{ tooltip: classes.customWidth }}
                        placement="bottom"
                        title="Lorem Ipsum is simply dummy text."
                      >
                        <img src={informationIcon} alt="" />
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box display={"flex"} flexDirection={"column"}>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText14}`}
                      style={{ marginBottom: "10px" }}
                      component={"span"}
                    >
                      {`Semester 1 ends`}
                    </Typography>
                    <div className="DateContainers">
                      <span className="dateInput">
                        {" "}
                        <DatePicker
                          popperPlacement="auto"
                          data-test-id="startDate"
                          onChange={this.selectStartDate}
                          popperClassName="start-datepicker-class"
                          selected={this.state.selectStartDate}
                        />
                      </span>
                      <span className="icon">
                        <CalendarIcon />
                      </span>
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box display={"flex"} flexDirection={"column"}>
                    <Typography
                      className={`${classes.fontText14} ${classes.textCommonColor}`}
                      component={"span"}
                      style={{ marginBottom: "10px" }}
                    >
                      {`Semester 2 ends`}
                    </Typography>
                    <div className="DateContainers">
                      <span className="dateInput">
                        {" "}
                        <DatePicker
                          popperClassName="NewSchool-datepicker-class"
                          data-test-id="NewSchool"
                          selected={this.state.selectEndDate}
                          popperPlacement="auto"
                          onChange={this.selectEndDate}
                        />
                      </span>
                      <span className="icon">
                        <CalendarIcon />
                      </span>
                    </div>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Box
                  data-test-id="box"
                  display={"flex"}
                  width={"100%"}
                  mt={2}
                  pl={"12px"}
                  borderRadius={"10px"}
                  alignItems={"center"}
                  style={{ backgroundColor: "#f2faff", height: "50px" }}
                >
                  <Typography
                    data-test-id="Text"
                    className={`${classes.fontText14}`}
                    component={"span"}
                    style={{ color: "#009DFF" }}
                  >
                    {`Projected End Date: Dec 17, 2023`}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Box marginBottom={"10px"} style={{ width: "100%" }}>
                  <CustomButton
                    btnText="Save"
                    btnStyle={schoolNextBtn}
                    buttonId={`my-assessment-search-mobile`}
                    buttonVariant={"contained"}
                    type={"button"}
                    handleButtonClick={this.handleOpenSuccessDialog}
                    isDisabled={false}
                  />
                  <CustomButton
                    btnText="Cancel"
                    btnStyle={schoolCancelBtn}
                    buttonId={`my-assessment-search-mobile`}
                    buttonVariant={"outlined"}
                    type={"button"}
                    handleButtonClick={this.props.onCancel}
                    isDisabled={false}
                  />
                </Box>
              </Grid>
            </DialogContent>
          </Box>
          <NewSchoolSuccesModal
            navigation={this.props.navigation}
            onCancel={this.handleCloseSuccessDialog}
            onConfirm={() => {
              alert("enter ok");
            }}
            open={this.state.openSuccessDialog}
            classes={this.props.classes}
          />
        </Dialog>
      </ThemeProvider>
    );
  }
  // Customizable Area End
}
// Customizable Area End

// Customizable Area Start
const schoolCancelBtn = {
  padding: "10px 10px",
  width: "120px",
  height: "50px",
  fontSize: "18px",
  borderRadius: "12px",
  textAlign: "center",
  marginTop: "32px",
  fontWeight: 500,
  float: "right",
  marginLeft: "15px",
} as const;

const schoolNextBtn = {
  padding: "10px 10px",
  width: "120px",
  height: "50px",
  fontSize: "18px",
  borderRadius: "12px",
  textAlign: "center",
  marginTop: "32px",
  fontWeight: 500,
  float: "right",
  marginLeft: "15px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(NewSchoolModal);
// Customizable Area End
