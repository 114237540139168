// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
const configJSON = require("./config.js");
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import moment from "moment";



export interface IStudentInfoProps {
  dob: string;
  gradeDetails: {
    id:string | number,
    name: string,
  };
  schoolYear: string;
  classDetail: any;
  expiringDate: string;
  email: string;
  uniqId: string;
  firstName: string
  lastName: string;
  profileImg: string;
  role: string;
  status: string;
  curriculums: {id?: number, name?: string };
  subjectsHandled: Array<{id?: number, name?: string}>;
  classesList: Array<{id?: number, name?: string}>;
  customFields: Array<{id?: number, label?: string, value?: string}>;
  parentsList:any[]
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  profileSettingOptions: {label: string, clickHandle: () => void}[]
  studentInfo:IStudentInfoProps
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = "";
  apiGetStudentDetails:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      loading: false,
      profileSettingOptions: [],
      studentInfo:{
        email: "",
        uniqId: "",
        firstName: "",
        lastName: "",
        profileImg: "",
        role: "",
        status: "",
        curriculums: {
          id: undefined,
          name: undefined
        },
        subjectsHandled: [],
        classesList: [],
        customFields: [],
        dob: "",
        gradeDetails: { id: "", name: "" },
        schoolYear: "",
        classDetail: undefined,
        expiringDate: "",
        parentsList: []
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.setProfileSettingOptions();
    // Customizable Area End
  }
  // Customizable Area Start

  handleGoBack = () => {
    this.props.navigation.goBack();
  };

  onEditProfile = () => {
    this.props.navigation.navigate("EditProfile");
  };

  setProfileSettingOptions = async () => {
    const userRole = await getStorageData('role');
    if (userRole) {
      switch (userRole) {
        case 'teacher':
          this.setState({
            profileSettingOptions: [
              {
                label: 'Edit Profile',
                clickHandle: this.onEditProfile
              },
              {
                label: 'Change Password',
                clickHandle: this.handleGoToChangePassword
              }
            ]
          });
          break;
        case 'student':
          this.setState({
            profileSettingOptions: [
              {
                label: 'Change Password',
                clickHandle: this.handleGoToChangePassword
              }
            ]
          });
          break;
          case 'tenant':
            this.setState({
              profileSettingOptions: [
                {
                  clickHandle: this.handleGoToChangePassword,
                  label: 'Change Password'
                }
              ]
            });
            break;   
      }
    }
  };

  handleGoToChangePassword = () => {
    this.props.navigation.navigate("StudentChangePassword");
  };

  // Customizable Area End
  getParentsList = (
    parentsArr: Array<{
      id: string;
      first_name: string;
      last_name: string;
      email: string;
      role: {
        name: string;
        role_title: string;
      };
      relationship: string;
      contact_number: string;
      profile_url: string;
    }>
  ) => {
    const parentsList = parentsArr?.map((item) => {
      return {
        id: item?.id,
        name: `${item?.first_name || ""} ${item?.last_name || ""}`,
        email: item?.email || "NA",
        role: item?.role?.name || "NA",
        relationship: item?.relationship || "NA",
        contactNum: item?.contact_number || "NA",
        profileUrl: item?.profile_url || "",
      };
    });
    return parentsList;
  };
  handleGetStudentInfoResponse = (responseJson: any) => {
    if (responseJson?.data) {
      const subjectsList: any = responseJson?.data?.attributes?.subject?.map((item: any) => {
        return {
          id: item?.id,
          name: item?.name,
        }
      })
      const classesList: any = responseJson?.data?.attributes?.student_class?.map((item: any) => {
        return {
          id: item?.id,
          name: item?.name,
        }
      })
      const customFields: any = responseJson?.data?.attributes?.user_custom_fields?.data?.map((item: any) => {
        return {
          id: item?.attributes?.id,
          label: item?.attributes?.label,
          value: item?.attributes?.field_value,
        }
      })
      let dateOfBirth = moment(responseJson?.data.attributes.DOB).format('DD/MM/YYYY') || 'NA'
      const teacherInfo:any = {
        uniqId: responseJson?.data?.attributes?.id_number?.toString() || "NA",
        email: responseJson?.data?.attributes?.email || "NA",
        firstName: responseJson?.data?.attributes?.first_name || "",
        lastName: responseJson?.data?.attributes?.last_name || "",
        role: responseJson?.data?.attributes?.role?.name || "",
        status: responseJson?.data?.attributes?.status || "",
        profileImg: responseJson?.data?.attributes?.profile_url || "",
        curriculums: {
          id: responseJson?.data?.attributes?.curriculum?.id,
          name: responseJson?.data?.attributes?.curriculum?.name,
        },
        parentsList: this.getParentsList(responseJson?.data?.attributes?.parents_details),
        dob:dateOfBirth,
        gradeDetails: {
          id: responseJson?.data?.attributes?.grade?.[0]?.id,
          name: responseJson?.data?.attributes?.grade?.[0]?.name || "NA",
        },
        schoolYear: responseJson?.data?.attributes?.school_year?.title,
        classesList: classesList,
        classDetail: {
          id: responseJson?.data?.attributes?.student_class?.[0]?.id,
          name: responseJson?.data?.attributes?.student_class?.[0]?.name || "NA",
        },
        customFields: customFields,
        subjectsHandled: subjectsList,
        expiringDate: moment(responseJson?.data?.attributes?.expiring_on).format("DD/MM/YYYY"),
      }
      this.setState({ studentInfo: teacherInfo }) 
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }

  handleGetStudent= async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const studentId = await getStorageData("user_id");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetStudentDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetailsEndpoint}/${studentId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetStudent()
    // Customizable Area End
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } =
        getMessageData(message);
      if (apiRequestCallId != null) {
        if(apiRequestCallId === this.apiGetStudentDetails){
          this.handleGetStudentInfoResponse(responseJson)
        }
      }
    }
  }
  // Customizable Area End
}
// Customizable Area End
