// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getMessageData, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  id?: string;
  navigation?: any;
  classes?: any;
  forTeacher?: boolean;
  detail: any;
  questionList?: Array<any>;
  handleAddQuestion?: any;
  handleUpdateQuestion?: any;
  handleDeleteQuestion?: any;
  setSelectedQuestion?: any;
  isFromRegistrar?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openSubjectiveQuestionModal: boolean;
  editQuestion: boolean;
  openObjectiveQuestionModal: boolean;
  loading: boolean;
  selectedQuestionDetail: any;
  openDeleteQuestionConfirmModal: boolean;
  examDetails: any;
  isMyCoursesPath:boolean;
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ExamActivityComponentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiExamAssessmentActivityDetails: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      openSubjectiveQuestionModal: false,
      openObjectiveQuestionModal: false,
      editQuestion: false,
      loading: false,
      selectedQuestionDetail: "",
      openDeleteQuestionConfirmModal: false,
      examDetails: "",
      isMyCoursesPath:false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    this.handleResetInitialValues();
  }

  handleResetInitialValues = () => {
    this.setState({
      openSubjectiveQuestionModal: false,
      editQuestion: false,
      openObjectiveQuestionModal: false,
      loading: false,
      selectedQuestionDetail: "",
      openDeleteQuestionConfirmModal: false,
    });
  };

  handleGoToAssessmentDueScreen = (typeOfAssessment: string, title: string) => {
    this.props.navigation?.navigate(
      "StudentDueAssessmentMain",
      {},
      {
        typeOfAssessment: typeOfAssessment,
        title: title,
      }
    );
  };

  handleExamAssessmentDetailsApi = async () => {
    showCustomLoader();
    const baseUrl = `examDetails`;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiExamAssessmentActivityDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      baseUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleExamAssessmentDetailsResponse = (responseJson: any, errorResponse: any) => {
    this.setState({ examDetails: responseJson });
    console.log("exam Activity errorResponse", errorResponse)
  }

  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        if(apiRequestCallId === this.apiExamAssessmentActivityDetails){
          this.handleExamAssessmentDetailsResponse(responseJson, errorResponse)
        }
      }
    }
  }

  handleOpenSubjectiveQuestionModal = () => {
    this.setState({ openSubjectiveQuestionModal: true });
  };

  handleCloseSubjectiveQuestionModal = () => {
    this.setState({ openSubjectiveQuestionModal: false });
  };

  handleEditSubjectiveQuestion = (id: any) => {
    const { questionList } = this.props;
    const selectedExamSubjectiveQuestion = questionList?.find(
      (item: any) => parseInt(item.id) === parseInt(id)
    );
    this.setState(
      { selectedQuestionDetail: selectedExamSubjectiveQuestion },
      () => {
        this.setState({ editQuestion: true });
        this.props.setSelectedQuestion(selectedExamSubjectiveQuestion?.id);
        this.handleOpenSubjectiveQuestionModal();
      }
    );
  };

  handleCloseObjectiveQuestionModal = () => {
    this.setState({ openObjectiveQuestionModal: false });
  };

  handleAddSubjectiveQuestion = () => {
    this.setState({ editQuestion: false });
    this.handleOpenSubjectiveQuestionModal();
  };

  handleOpenObjectiveQuestionModal = () => {
    this.setState({ openObjectiveQuestionModal: true });
  };

  handleAddObjectiveQuestion = () => {
    this.setState({ editQuestion: false });
    if (this.props.detail.objectiveType === "selection") {
      this.handleOpenObjectiveQuestionModal();
    } else if (this.props.detail.objectiveType === "written") {
      this.handleOpenSubjectiveQuestionModal();
    }
  };

  handleSaveQuiz = () => {
    console.log("Save");
  };

  handleEditObjectiveQuestion = (id: any) => {
    const { questionList } = this.props;
    const selectedQuestion = questionList?.find(
      (item: any) => parseInt(item.id) === parseInt(id)
    );
    this.setState({ selectedQuestionDetail: selectedQuestion }, () => {
      this.setState({ editQuestion: true });
      this.props.setSelectedQuestion(selectedQuestion?.id);
      if (this.props.detail.objectiveType === "selection") {
        this.handleOpenObjectiveQuestionModal();
      } else if (this.props.detail.objectiveType === "written") {
        this.handleOpenSubjectiveQuestionModal();
      }
    });
  };

  handleDeleteExamObjectiveQuestion = (id: any) => {
    const { questionList } = this.props;
    const selectedQuestion = questionList?.find(
      (item: any) => parseInt(item.id) === parseInt(id)
    );
    this.setState({ selectedQuestionDetail: selectedQuestion }, () => {
      this.setState({ openDeleteQuestionConfirmModal: true });
      this.props.setSelectedQuestion(selectedQuestion?.id);
    });
  };

  handleCloseExamDeleteConfirmModal = () => {
    this.setState({ openDeleteQuestionConfirmModal: false });
  };

  handleConfirmExamDeleteQuestion = () => {
    this.setState({
      openDeleteQuestionConfirmModal: false,
      openObjectiveQuestionModal: false,
      openSubjectiveQuestionModal: false,
    });
    this.props.handleDeleteQuestion();
  };

  checkObjectiveType = () => {
    if (this.props.detail.objectiveType === "selection") {
      return false;
    } else if (this.props.detail.objectiveType === "written") {
      return true;
    } else {
      return false;
    }
  };

  resetAllModals = () => {
    this.setState({
      openSubjectiveQuestionModal: false,
      openObjectiveQuestionModal: false,
      editQuestion: false,
      loading: false,
    });
  };

  handleExamAddQuestion = (data: any) => {
    this.handleCloseObjectiveQuestionModal();
    this.handleCloseSubjectiveQuestionModal();
    this.props.handleAddQuestion(data);
  };

  handleExamUpdateQuestion = (data: any) => {
    this.handleCloseObjectiveQuestionModal();
    this.handleCloseSubjectiveQuestionModal();
    this.props.handleUpdateQuestion(data);
  };
  // Customizable Area End
}
// Customizable Area End
