// Customizable Area Start
import React from "react";
import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import StudentPageController, {
  Props,
} from "./studentPageController";
import CustomTable from "../../../../components/src/CustomTable.web";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../../components/src/CombineStyles.web";
import CustomSelectDropdown from "../../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../../components/src/SVGIcons/DropdownIcon.web";
import CustomTextField from "../../../../components/src/CustomTextField.web";
import SearchIcon from "../../../../components/src/SVGIcons/SearchIcon.web";

import Pagination from "@material-ui/lab/Pagination";
import  ReschedullingModal  from "../../../cfresumejourney/src/ReschedullingModal.web";
import  CustomEmptyResultComponent from "../../../../components/src/CustomEmptyResultComponent.web";
import  CustomPaginationComponent  from "../../../../components/src/CustomPaginationComponent.web";
import  CustomButton  from "../../../../components/src/CustomButton.web";
// Customizable Area End

// Customizable Area Start
const themes = createTheme({
  overrides: {
    MuiSelect: {
      select: {
        minWidth: "140px",
      },
      icon: {
        right: "10px",
      },
    },
    MuiTableCell: {
      body: {
        paddingBottom: "5px !important",
        paddingTop: "5px !important",
      },
    },
    MuiSvgIcon: {
      root: {
        height: "16px",
        width: "16px",
      },
    },
    MuiInputBase: {
      input: {
        "&::placeholder": {
          fontSize: "14px",
        },
      },
    },
    MuiFormControl: {
      root: {
        width: "17.1%",
        marginRight: "24px",
        display: "flex",
        height: "35px",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
});

const styles = (theme: Theme) =>
  createStyles({
    coursesMainBox: {
      padding: "15px",
      height: "180px",
      marginBottom: "5%",
      width: "85%",
      cursor: "pointer",
      borderRadius: "12px",
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
    },
    subjectIconBgColor: {
      height: "55px",
      width: "55px",
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      padding: "2px",
      alignItems: "center",
    },
    topBtnsRoot: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
    },
    topBtn: {
      height: "50px",
      width: "184px",
      backgroundColor: "#0D46BC",
      borderRadius: "12px",
      textTransform: "capitalize",
      marginRight: "30px",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#0D46BC",
      },
    },
    breadCrumbsRoot: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    statusCompletions: {
      fontSize: "14px",
      fontWeight: 500,
      backgroundColor: "#d0f5eb",
      color: "#01C996",
      padding: "6px 10px 6px 10px",
      borderRadius: "17px",
    },
    selectedBoxBorder: {
      border: "2px solid #FBD82C",
    },
    onGoingStatus: {
      color: "#FBD82C",
      fontSize: "14px",
      borderRadius: "17px",
      padding: "6px 10px 6px 10px",
      backgroundColor: "#fef8d8",
    },
    statusLateColor: {
      color: "#ED1111",
      fontSize: "14px",
      borderRadius: "17px",
      padding: "5px 10px 5px 10px",
      backgroundColor: "#fcd3d3",
    },
    tableTitle: {
      fontWeight: 500,
      fontSize: 28,
    },
    scheduledStatus: {
      color: "#4DC901",
      fontSize: "14px",
      borderRadius: "17px",
      padding: "6px 10px 6px 10px",
      backgroundColor: "#def5d0",
    },
    dividerFullWidth: {
      margin: `5px 0 0 2px`,
    },
    customTableStyle: {
      borderRadius: "10px",
    },
    hoveredButton: {
      top: "50%",
      position: "absolute",
      transform: "translate(-50%, -50%)",
      left: "50%",
      background: "#0d45bc",
      padding: theme.spacing(2),
      color: "#fff",
      borderRadius: "12px",
      zIndex: 2,
      cursor: "pointer",
      pointerEvents: "auto",
      transition: "opacity 0.3s",
      width: "216px",
      textTransform: "capitalize",
      fontSize: "16px",
      "&:hover": {
        backgroundColor: "#0D46BC",
      },
    },
    hoveredCourse: {
      position: "relative",
      "&:before": {
        content: '""',
        top: 0,
        position: "absolute",
        right: 0,
        left: 0,
        background: "rgba(0, 0, 0, 0.3)",
        bottom: 0,
        opacity: 0,
        zIndex: 1,
        borderRadius: "12px",
        pointerEvents: "none",
        transition: "background-color 0.3s ease-in-out",
      },
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#666666",
      },
      "&:hover:before": {
        opacity: 1,
      },
    },
    classManagementTable: {
      "& td": {
        paddingTop: "6px !important",
        paddingBottom: "6px !important",
      }
    },
    paginationRoot: {
      disaply: "flex",
      justifyContent: "flex-end",
      '& > *': {
        marginTop: theme.spacing(3),
      },
      '& .MuiPaginationItem-root': {
        borderRadius: '6px',
      },
      '& .MuiPaginationItem-icon': {
        color: '#0D46BC',
      },
      "& .MuiPaginationItem-page": {
        backgroundColor: "#FFFFFF",
      },
      '& .Mui-selected': {
        backgroundColor: '#FFD92E !important',
        color: "#FFFFFF",
      },
    },
    paginationBox: {
      display: 'flex',
      justifyContent: 'end',
      padding: "10px 20px 10px 21px",
      alignItems: 'center',
    },
    textEllipsis: {
      maxWidth: "250px",
      display: "block",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  });


// Customizable Area End

// Customizable Area Start
export class StudentPage extends StudentPageController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;    
    const { studentCoursesList, studentListTableBody, pagination, studentPagination } = this.state;    

    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.secondaryBgColor} ${classes.highlightBgColor}`}
        height={"100%"}
      >
        <Box className={classes.breadCrumbsRoot}>
          <Typography
            className={`${classes.fontText14} ${classes.textCommonColor}`}
            component={"span"}
          >
            {`Students`}
          </Typography>
        </Box>
        <Box marginBottom={"17px"}>
          <Box marginBottom={"13px"}>
            <Typography
              className={`${classes.fontText28} ${classes.textPrimaryColor} ${classes.fontBold600}`}
              component={"span"}
            >
              Students
            </Typography>
          </Box>
        </Box>
        <Box className={classes.topBtnsRoot}>
          <CustomSelectDropdown
            fieldId="selectGradingComponentDropdown"
            fieldName="selectedGradingComponentDDValue"
            fieldValue={this.state.selectedGradingComponentDDValue}
            renderValue={this.renderGradingComponenttDDValues}
            handleChange={this.handleChangeDropdownValues}
            fieldMenuItems={this.state.gradingComponent}
            fieldStyles={dropdownStyle}
            fieldError={false}
            iconComponent={
              <DropdownIcon
                height="24"
                width="24"
                stroke={"#888888"}
                viewBox="0 0 24 24"
                fill={"none"}
                strokeWidth="1.5"
              />
            }
          />     

          <CustomButton
          btnText="Reschedule class" 
          buttonId={`add-remark-save-button`} 
          btnStyle={{
            width: "184px",
            height: "50px",
            borderRadius: "12px",
            marginRight: "53px"
          }} 
          buttonVariant={"contained"} 
          type={"button"} 
          handleButtonClick={this.handleOpenReschedulModalBox} 
          isDisabled={false} 
          />
        </Box>
        <Grid xs={12} item>
          <Grid direction="row" container>
            {studentCoursesList.length > 0
              ? studentCoursesList.map((courseItem: any, index: number) => (
                <Grid key={courseItem.id} item xs={12} sm={6} lg={4}>
                  <Box
                    onMouseEnter={() => this.handleMouseEnter(courseItem.id)}
                    data-test-id={`student-subject-card-${index}`}
                    onMouseLeave={this.handleMouseLeave}
                    onClick={() => { this.handleLoadCourseStudents(courseItem) }}                    
                    className={`${!courseItem.isAssigned && this.isCourseAssignable(courseItem.startDate as Date) && this.state.hoveredCourse === courseItem.id ? classes.hoveredCourse : ""} ${this.getSelectedCourseBoxClass(courseItem.id)}`}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent={"center"}
                    >
                      <Box alignItems="center" display={"flex"}>
                        <Box
                          className={classes.subjectIconBgColor}
                          bgcolor={courseItem?.subjectBgColor}
                          mr={"10px"}
                        >
                          <img
                          style={{height:'50px',width:"50px"}}
                            alt="Subject Logo"                            
                            src={courseItem?.subjectIcon}
                          />
                        </Box>
                        <Box>
                          <Typography
                            component="span"
                            className={`${classes.fontText22} ${classes.textPrimaryColor}`}
                            style={{ fontWeight: "bold" }}
                          >
                            {courseItem?.subjectName}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        alignItems="center"
                        display={"flex"}
                        marginTop={"15px"}
                      >
                        <Box>
                          <Typography
                            component="span"
                            className={`${classes.fontText18} ${classes.textCommonColor}`}
                          >                          
                          
                             Due on {courseItem?.dueDate}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        alignItems="center"
                        display={"flex"}
                        marginTop={"10px"}
                      >
                        <Box>
                          <Typography
                            component="span"
                            className={`${classes.textCommonColor} ${classes.fontText18}`}
                          >
                            Lessons :
                          </Typography>
                        </Box>
                        <Box display="flex" ml={"4px"} style={{ gap: "4px" }}>
                          <Typography
                            component="span"
                            className={`${classes.fontText16} ${classes.primaryColor4}`}
                          >
                            {courseItem?.lessonsCovered}
                          </Typography>
                          <Typography
                            component="span"
                            className={`${classes.fontText16} ${classes.textCommonColor}`}
                          >
                            /
                          </Typography>
                          <Typography
                            component="span"
                            className={`${classes.fontText16} ${classes.textPrimaryColor}`}
                          >
                            {courseItem?.lessonsTotal}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        display={"flex"}
                        marginTop={"10px"}
                        justifyContent={"space-between"}
                        alignItems="center"
                      >
                        <Box>
                          <Typography
                            component="span"
                            className={`${classes.fontText16} ${classes.primaryColor4}  ${classes.fontBold500}`}
                          >         
                            {`${courseItem.completionProgress}% completed`}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            style={{
                              color: this.getAvgScoreColor(courseItem?.status),
                            }}
                            component="h5"
                            className={` ${classes.fontBold500} ${classes.fontText14} ${this.getAvgScoreColor(courseItem?.status)}`}
                          >
                            {`${courseItem?.status}`}
                          </Typography>
                        </Box>
                      </Box>

                      {!courseItem.isAssigned && this.isCourseAssignable(courseItem.startDate as Date) && this.state.hoveredCourse === courseItem.id && (
                      <CustomButton
                      btnText="Assign Course" 
                      data-test-id="assignBtn"
                      buttonId={`assign-course`} 
                      btnStyle={{
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        cursor: "pointer",
                        zIndex: 2,
                        borderRadius: "12px",
                        transition: "opacity 0.3s",
                        pointerEvents: "auto",
                        textTransform: "capitalize",
                        width: "216px",
                        position: "absolute",
                        height: "50px",
                        fontSize: "16px",
                      }} 
                      buttonVariant={"contained"} 
                      type={"button"} 
                      handleButtonClick={() => this.handleStudentCourse(courseItem.id)} 
                      isDisabled={false} 
                      />
                    )}                     
                    </Box>
                  </Box>
                </Grid>
              ))
              : <CustomEmptyResultComponent message="No courses found" />}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {studentCoursesList.length > 0 &&
            <Box className={classes.paginationBox}>
              <Box className={classes.paginationRoot}>
                <Pagination
                  shape="rounded"
                  variant="outlined"
                  count={Math.ceil(pagination?.total_count / pagination?.per_page)}
                  siblingCount={0}
                  boundaryCount={1}
                  onChange={this.handlePaginationChange}
                  page={pagination?.current_page}
                />
              </Box>
            </Box>
          }
        </Grid>
        <Box
          className={`${classes.customTableStyle} ${classes.bgColor}`}
          mt={4.5}
          style={{ width: "98%" }}
        >
          <Box
            className={classes.inputFont}
            display={"flex"}
            px={1.6}
            pt={1.5}
            alignItems="center"
            style={{ width: "98%" }}
            mb={1.5}
            justifyContent="space-between"
          >
            <Typography className={`${classes.textSecondaryColor} ${classes.tableTitle}`}>Students</Typography>
            <ThemeProvider theme={themes}>
              <CustomTextField
                fieldName="searchStudent"
                placeHolderText="Search by Students"
                fieldValue={this.state.searchStudent}
                fieldId="search-students"
                fieldType="text"
                fieldWidth={"16.1%"}
                handleChange={this.handleChange}
                startAdornmentIcon={
                  <SearchIcon
                    height="16"
                    width="16"
                    stroke={"#888888"}
                    viewBox="0 0 16 16"
                    fill={"#888888"}
                    strokeWidth="0.45"
                  />
                }
              />
            </ThemeProvider>
          </Box>
          <Divider className={classes.dividerFullWidth} />
          <Box pb={2} pr={1} className={`${classes.bgColor} ${classes.classManagementTable}`} >
            {studentListTableBody.length > 0 ?
              <CustomTable
                tableId={"student-listing-table"}
                tableHeaderColumns={this.state.studentListTableColumns}
                tableBodyData={studentListTableBody}
                showMenuIcon={false}
                isHandleCellAction
                handleAction={this.handleclick}
                handleColumnSorting={this.onColumnSort}
              />
              :
              <CustomEmptyResultComponent message="No students found" />
            }
          </Box>        
        </Box>
        <Box>
          <ReschedullingModal
            open={this.state.openRescheduleModalBox}
            onCancel={this.handleCloseAssignCourseModalBox}
            onConfirm={this.handleCloseAssignCourseModalBox}
          />
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const dropdownStyle = {
  borderRadius: "8px",
  width: "200px",
  height: "48px",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentPage);
// Customizable Area End
