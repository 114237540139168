// Customizable Area Start
import React from "react";
import {
  AppBar,
  Box,
  Grid,
  Hidden,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentProgressDetailsController, {
  Props,
} from "./StudentProgressDetailsController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import TabPanel from "../../../components/src/TabPanel.web";
import StudentProgressJourneyDesktop from "../src/StudentProgressJourneyDesktop.web";
import StudentProgressJourneyMobileView from "../src/StudentProgressJourneyMobileView.web";
import TeacherCourseMngResourseTab from "../src/TeacherCourseMngResourseTab.web";
import TeacherParticipantsTab from "./TeacherParticipantsTab.web";
import CustomButton from "../../../components/src/CustomButton.web";
import TeacherSendApprovalModal from "./TeacherSendApprovalModal.web";
import AddNewResourceModal from "./AddNewResourceModal.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    searchTextBox: {
      marginTop: "20px",
      marginBottom: "20px",
    },
    profileDetailsBreadCrumb: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    addNewActivity: {
      borderRadius: "12px",
      width: "201px",
      height: "50px",
      backgroundColor: "#0D46BC",
      color: "#ffffff",
      textTransform: "capitalize",
      marginRight: "30px",
      "&:hover": {
        backgroundColor: "#0D46BC",
      },
    },
    boxRoot: {
      marginTop: "30px",
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.spacing(2.5, 2.5, 2.5, 2.5),
    },
    columnDataList: {
      borderTop: "2px solid #f1f1f1",
    },
    profileActiveTab: {
      color: "#0D46BC",
      fontWeight: 500,
      fontSize: 20,
      textTransform: "capitalize",
    },
    detailBoxRoot: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
    },
    tabLabel: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: "23px",
      // color: "#090909",
      textTransform: "capitalize",
      padding: theme.spacing(2.5, 0),
    },
    profileNameBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    topicLessonBox: {
      borderRadius: "6px",
      maxWidth: "98%",
      width: "98%",
      overflow: "auto",
      maxHeight: "600px",
    },
    columnHeader: {
      backgroundColor: "rgba(0, 157, 255, 0.05)",
      padding: "10px 15px",
    },
    borderRight: {
      borderRight: "2px solid #f1f1f1",
    },
    appBar: {
      borderRadius: "12px 12px 0 0",
      backgroundColor: "#ffffff",
      borderBottom: "1px solid #f1f1f1",
    },
    marginRightBox: {
      marginRight: "8px",
    },
    fontBold700: {
      fontWeight: 700,
    },
    btnsContainer: {
      position: "absolute",
      right: "40px",
    },
    learnMoreLinks: {
      position: "absolute",
      right: "40px",
      color: "#0D46BC",
      fontSize: "18px",
    },
    tabButton: {
      backgroundColor: "transparent",
      padding: "10px 4px",
      textAlign: "center",
      textDecoration: "none",
      textTransform: "capitalize",
      display: "inline-block",
      fontSize: "16px",
      opacity: 1,
      minWidth: "25%",
      "&.Mui-selected": {
        backgroundColor: "transparent",
        color: "#0D46BC !important",
        outline: "none",
        borderBottom: "1px solid #0D46BC",
        fontWeight: 600,
      },
      "&.MuiTab-root": {
        minHeight: "60px",
      },
    },
  });
// Customizable Area End

// Customizable Area Start
export class StudentProgressDetails extends StudentProgressDetailsController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const {
      tabValue,
      selectedCourseSubject,
      tabName,
      openSendApprovalReqModal,
      openResourceModel
    } = this.state;
    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Box className={classes.profileDetailsBreadCrumb}>
          <Typography
            className={`${classes.textCommonColor} ${classes.fontText14}`}
            component={"span"}
          >
            {`Course Management`}{" "}
          </Typography>
          <Typography
            className={`${classes.textCommonColor} ${classes.fontText14}`}
            component={"span"}
          >
            {`/ My Courses`}{" "}
          </Typography>
          <Typography
            className={`${classes.textCommonColor} ${classes.fontText14}`}
            component={"span"}
          >
            {`/ Physics`}
          </Typography>
          <Typography
            className={`${classes.primaryColor5} ${classes.fontBold600} ${classes.fontText14}`}
            component={"span"}
          >
            {` / ${tabName}`}
          </Typography>
        </Box>
        <Grid
          className={classes.pageName}
          item
          xs={12}
          container
          alignItems="center"
        >
          <Box display={"flex"} alignItems={"center"}>
            <Box
              onClick={this.handleGoBacks}
              className={`${classes.cursorPointer}`}
              alignItems={"center"}
              display={"flex"}
            >
              <Box className={classes.marginRightBox}>
                <KeyboardBackspaceIcon
                  className={`${classes.textPrimaryColor}`}
                  fontSize="large"
                />
              </Box>
              <Typography
                className={`${classes.fontText34} ${classes.textPrimaryColor} ${classes.fontBold700}`}
                component={"span"}
              >
                {"Physics"}
              </Typography>
            </Box>
          </Box>
          {tabValue === 0 ? (
            <Box className={classes.btnsContainer}>
              <CustomButton
                btnText="Send Approval Request"
                btnStyle={addNewResourceBtnStyle}
                buttonId={`my-assessment-search-mobile`}
                buttonVariant={"contained"}
                type={"button"}
                handleButtonClick={this.handleSendApprovalReq}
                isDisabled={false}
              />
              <CustomButton
                btnText="Add New Resourse"
                btnStyle={sendApprovalBtnStyle}
                buttonId={`my-assessment-search-mobile`}
                buttonVariant={"outlined"}
                type={"button"}
                handleButtonClick={this.handleResourceModal}
                isDisabled={false}
              />
            </Box>
          ) : (
            <Typography
              className={`${classes.learnMoreLinks}`}
              component={"span"}
              data-test-id="LeranMore"
            >
              Learn More
            </Typography>
          )}
        </Grid>
        <Box className={classes.profileNameBox}>
          <Box mt={1} style={{ width: "98%" }}>
            <Box className={classes.boxRoot}>
              <AppBar
                color="default"
                position="static"
                className={`${classes.bgColor} ${classes.appBar}`}
              >
                <Tabs
                  id="tab-change-wrapper"
                  variant="fullWidth"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#0D46BC",
                    },
                  }}
                  classes={{ indicator: classes.profileActiveTab }}
                  value={tabValue}
                  onChange={this.handleTabChange}
                >
                  <Tab
                    id="resourses"
                    classes={{
                      root:
                        tabValue === 0
                          ? classes.profileActiveTab
                          : classes.tabLabel,
                    }}
                    label={"Resources"}
                    className={`${classes.textSecondaryColor} ${classes.tabButton}`}
                  />
                  <Tab
                    id="journey"
                    classes={{
                      root:
                        tabValue === 1
                          ? classes.profileActiveTab
                          : classes.tabLabel,
                    }}
                    label={"Journey"}
                    className={`${classes.textSecondaryColor} ${classes.tabButton}`}
                  />
                  <Tab
                    id="participants"
                    classes={{
                      root:
                        tabValue === 2
                          ? classes.profileActiveTab
                          : classes.tabLabel,
                    }}
                    label={"Participants"}
                    className={`${classes.textSecondaryColor} ${classes.tabButton}`}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={tabValue} index={0}>
                <TeacherCourseMngResourseTab
                  getEditResourceId={() => { }}
                  resourceData={[]}
                  pagination={{}}
                  getPaginationNumber={() => { }}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Grid container direction="row">
                  {/* Topic Lessons Activity Box */}
                  <Hidden only={["xs", "sm"]}>
                    <Grid item xs={12}>
                      <StudentProgressJourneyDesktop
                        handleSetLessonName={this.handleSetLessonName}
                        navigation={navigation}
                        handleSetTopicName={this.handleSetTopicName}
                        selectedSubjectName={selectedCourseSubject}
                        courseData={{}}
                      />
                    </Grid>
                  </Hidden>
                  <Hidden only={["md", "lg", "xl"]}>
                    <Grid item xs={12}>
                      <StudentProgressJourneyMobileView
                        selectedSubjectName={selectedCourseSubject}
                        handleSetTopicName={this.handleSetTopicName}
                        navigation={navigation}
                        handleSetLessonName={this.handleSetLessonName}
                        courseData={{}}
                      />
                    </Grid>
                  </Hidden>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <TeacherParticipantsTab />
              </TabPanel>
            </Box>
          </Box>
        </Box>
        <TeacherSendApprovalModal
          open={openSendApprovalReqModal}
          onCancel={this.handleCloseSendApprovalModal}
          onConfirm={this.handleSendApprovalReq}
        />
        <AddNewResourceModal
          open={openResourceModel}
          onCancel={this.handleCloseResouceModel}
          onConfirm={this.handleResourceModal}
          courseData={{}}
          handleAddNewResource={{}}
          handleUpdateNewResource={{}}
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const sendApprovalBtnStyle = {
  width: "max-content",
  height: "50px",
  padding: "15px 20px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 500,
  textAlign: "center",
  float: "right",
  marginLeft: "20px",
};
const addNewResourceBtnStyle = {
  width: "max-content",
  height: "50px",
  padding: "15px 20px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 500,
  textAlign: "center",
  float: "right",
  marginLeft: "20px",
};
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentProgressDetails);
// Customizable Area End
