// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { displayApiErrorMsg} from "../../../components/src/CommonHelper.web";
import { toast } from "react-toastify";
const baseURL = require("../../../framework/src/config.js").baseURL;
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
export const myPathCategoryTabsList = [
  {
    id: 1,
    label: "Students / Parents",
    value: "student_parent",
  },
  {
    id: 2,
    label: "Teachers / Parent Teachers",
    value: "teacher",
  },
  {
    id: 3,
    label: "Others",
    value: "other",
  },
];
export interface IDropdownItems {
  id: number;
  label: string;
  value: any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedUserManagementTab: number;
  openImportCSVPopup: boolean;
  openUserRoleSelectPopup: boolean;
  uploadedCSVFile: any;
  selectedAddNewUserRole: string;
  roleOptions:any[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TenantUserManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetRoleListCallId:string=""
  userImportCSVCallId:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      selectedUserManagementTab: 0,
      openImportCSVPopup: false,
      openUserRoleSelectPopup: false,
      uploadedCSVFile: null,
      selectedAddNewUserRole: "",
      roleOptions:[]
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleGetRoleListApi()
    // Customizable Area End
  }

  // Customizable Area Start

  handleChangeMyPathCategoryTab = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    this.setState({ selectedUserManagementTab: newValue });
  };

  handleOpenAddUserRoleSelectPopup = () => {
    this.setState({ openUserRoleSelectPopup: true });
  };

  handleCloseAddUserRoleSelectPopup = () => {
    this.setState({ openUserRoleSelectPopup: false });
  };

  handleAddUserFlow = (role: string,roleId:any) => {
    this.setState(
      {
        openUserRoleSelectPopup: false,
        selectedAddNewUserRole: role.toLocaleLowerCase(),
      },
      () => {
        if (role.toLocaleLowerCase() === "student") {
          this.props.navigation?.navigate("AddNewStudentDetails",{},   {
            roleId: roleId,
          });
        } else {
          this.props.navigation?.navigate(
            "TenantUserManagementAddNewUserDetails",
            {},
            {
              role: role,
              roleId: roleId,
            }
          );
        }
      }
    );
  };

  handleFileUpload = (file: any) => {
    this.setState({ uploadedCSVFile: file });
    console.log(file);
  };

  handleOpenImportCsvDialog = () => {
    this.setState({ openImportCSVPopup: true });
  };

  handleCloseImportCsvDialog = () => {
    this.setState({ openImportCSVPopup: false });
  };
  handleGetRoleListApi =async () => {
    let token =  await getStorageData("token")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: token,
    };

    this.apiGetRoleListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherParentNmaesList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetRoleListResponse =(responseJson:any)=>{
 if(responseJson.data){
  const roleOptions= responseJson?.data?.filter(
    (item: { attributes: { name: string } }) => 
      item.attributes.name !== "School Admin" && 
      item.attributes.name !== "Admin General" &&
      item.attributes.name !== "District Admin"
  ).map(
    (item: { attributes: { name: any }; id: any }) => {
      const name = item.attributes.name;

      return {
        label: name,
        id: item.id,
        value: item.id,
      };
    }
  );
  this.setState({roleOptions:roleOptions})
 }else{
  displayApiErrorMsg(responseJson?.errors, this.props.navigation);
 }

  }

  handleUserImportCSVApi =async (requestBody:any) =>{
    const requestUrl = configJSON.userImportCSVEndpoint
    const token = await getStorageData("token");
      const header = {
        token: token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.userImportCSVCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        requestBody
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.userCreateAPiMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  handleUserImportCSVResponse = async(responseJson: {
    message:any;
    errors: {message:Array<string>;}})=>{
      if (responseJson?.message) {
        let prevTabValue = this.state.selectedUserManagementTab
        let calTabValue = (prevTabValue-1)%2;
        this.setState({ selectedUserManagementTab:calTabValue }, () => {
          this.setState({ selectedUserManagementTab: prevTabValue });
        });
        this.handleCloseImportCsvDialog();
        toast.success(`${responseJson?.message}`);
      }
        else {
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      }
  }
  downloadUserCSVFile =async () => {
    const token = await getStorageData("token");
    let requestType =configJSON.userDownloadCSVEndpoint
    let requestUrl = `${requestType}?token=${token}`
    window.open(`${baseURL}/${requestUrl}`, '_blank');
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      // Customizable Area Start
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetRoleListCallId) {
            this.handleGetRoleListResponse(responseJson); 
        }else if(apiRequestCallId === this.userImportCSVCallId){
          this.handleUserImportCSVResponse(responseJson);
        }
      }
      // Customizable Area End
    }
  }

  // Customizable Area End
}
// Customizable Area End
