import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { availableSubjectListMock, subjectTabTableHeaderMock } from "../assets/themeBlockMock.web";
import { ICustomTableProps, ITableActionBtnConfigProps } from "../../../components/src/CommonType.web";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { invalidImage } from "./assets";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  subjectList: Array<Object>;
  subjectTableHeader: Array<ICustomTableProps>;
  subjectsTableActionButtonConfig: Array<ITableActionBtnConfigProps>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SubjectTabController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetSubjectsListing:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseToken),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      subjectList: [],
      subjectTableHeader: subjectTabTableHeaderMock,
      subjectsTableActionButtonConfig: [
        {
          label: "Edit",
          action: (value: any) => this.handleNavigateToSetupScreen(),
        },
      ]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetSubjectsList();
    // Customizable Area End
  }

  // Customizable Area Start
  handleNavigateToSetupScreen=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'TenantAdminSetupDashboard'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      tabOpen:2,
    })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
       if(apiRequestCallId === this.apiGetSubjectsListing) {
          this.handleGetSubjectsDataResponse(responseJson, errorResponse);
        }
      }
    }
  }
  handleSetSubjectDataItem = (item: any) => {
    return {
      id: item.id,
      subject: {
        name: item?.attributes?.name,
        icon: item?.attributes?.icon_url?.url,
        iconType: item?.attributes?.icon_url?.content_type,
        bgColor: item?.attributes?.color,
        boxWidth: "35px",
        boxHeight: "35px"
      },
      color: item?.attributes?.color,
      icon: {
        name: item?.attributes?.icon_url?.filename,
      },
      grade: item?.attributes?.grade?.map((item: any) => {
        return (item?.id).toString();
      })
    };
  };
  handleSetSubjectData = (list: any) => {
    const subjectsData = list?.map((item: any) =>
      this.handleSetSubjectDataItem(item)
    );
    this.setState({ subjectList: subjectsData });
  };
  handleGetSubjectsList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    let getSubjectsEndpoint = configJSON.subjectsEndpoint;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSubjectsListing = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getSubjectsEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetSubjectsDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      this.handleSetSubjectData(responseJson.data);
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };
  // Customizable Area End

  // Customizable Area Start
 
  // Customizable Area End
}
