import React from "react";

// Customizable Area Start
import { Grid, Box, Typography, Tab, Tabs } from "@material-ui/core";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import {
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import {
  TabPanel,
  a11yProps,
} from "../../../components/src/CustomTabPanel.web";
import AppearanceManagementMainController, {
  Props,
  appearanceMngTabsList,
} from "./AppearanceManagementMainController.web";
import BrandingWeb from "./Branding.web";
import SubjectTabWeb from "./SubjectTab.web";
import ProgressRangeColorTabWeb from "./ProgressRangeColorTab.web";
import AppearanceTheme from "./AppearanceTheme.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    appearanceMngMainContainer: {
      height: "100vh",
      overflowX: "auto",
      borderRadius: "10px",
      padding: "0px 0px 8px 0px",
    },
    tabs: {
      "& div.MuiTabs-scroller": {
        borderBottom: "1px solid #979797",
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
          justifyContent: "space-between",
        },
      },
    },
    tabButton: {
      fontSize: "16px",
      minWidth: "25%",
      textTransform: "capitalize",
      textAlign: "center",
    },
    appearanceTabPanel: {
      padding: "15px 0px 15px 0px",
    },
    appearanceMngTopHeader: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        justifyContent: "flex-start",
      },
    },
  });
// Customizable Area End

export class AppearanceManagementMain extends AppearanceManagementMainController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const { selectedTabIndex } = this.state;
    return (
        <Box
          className={`${classes.childMainWrapperPadding} ${classes.mainPagePadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
          height={"100%"}
        >
          <Grid container direction="row">
            <Grid item xs={12}>
              <Box marginTop={"1%"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText12} ${classes.smallTopHeader}`}
                  component={"span"}
                >
                  Appearance Management
                </Typography>
                <Typography
                  component={"span"}
                  className={` ${classes.fontText12} ${classes.fontBold600} ${classes.textButton}`}
                >
                  {` / ${appearanceMngTabsList[selectedTabIndex].label}`}
                </Typography>
              </Box>
            </Grid>
            <Grid className={classes.pageName} item xs={12}>
              <Box className={`${classes.appearanceMngTopHeader}`}>
                <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontText34} ${classes.fontBold600}`}
                  component={"span"}
                >
                  Appearance Management
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                className={`${classes.bgColor} ${classes.appearanceMngMainContainer}`}
              >
                <Box>
                  <Tabs
                    value={selectedTabIndex}
                    variant="scrollable"
                    TabIndicatorProps={{
                      style: {
                        background: "none",
                      },
                    }}
                    scrollButtons="auto"
                    data-testid="appearance-course-management-tabs"
                    className={`${classes.tabs}`}
                    onChange={this.handleChangeAppearanceMngTab}
                    aria-label="appearance-course-management-tabs"
                  >
                    {appearanceMngTabsList.map(
                      (tabItem: any, index: number) => (
                        <Tab
                          key={tabItem.id}
                          data-testid={`appearance-course-management-${tabItem.label}-tab`}
                          label={tabItem.label}
                          wrapped
                          {...a11yProps(index)}
                          className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`}
                        />
                      )
                    )}
                  </Tabs>
                </Box>
                <Box className={`${classes.appearanceTabPanel}`}>
                  <TabPanel value={selectedTabIndex} index={0}>
                    <BrandingWeb themeData={this.state.themeData} navigation={navigation} id={"Branding"}  handleSubmitAppearance={this.handleSubmitAppearance} />
                  </TabPanel>
                  <TabPanel value={selectedTabIndex} index={1}>
                    <AppearanceTheme
                      navigation={navigation}
                      themeData={this.state.themeData}
                      id={"AppearanceTheme"}
                      handleUpdateTheme={this.handleUpdateTheme}
                      handleSubmitAppearance={this.handleSubmitAppearance}
                    />
                  </TabPanel>
                  <TabPanel value={selectedTabIndex} index={2}>
                    <SubjectTabWeb navigation={navigation} id={"Subject"} />
                  </TabPanel>
                  <TabPanel value={selectedTabIndex} index={3}>
                    <ProgressRangeColorTabWeb
                      themeData={this.state.themeData}
                      navigation={navigation}
                      id={"ProgressRange"}
                      handleSubmitAppearance={this.handleSubmitAppearance}
                    />
                  </TabPanel>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AppearanceManagementMain);
// Customizable Area End
