// Customizable Area Start
import React from "react";
import {
    createStyles,
    withStyles,
    Theme,
    createTheme,
    ThemeProvider,
} from "@material-ui/core/styles";
import {
    Dialog,
    DialogContent,
    Box,
    Typography,
    IconButton,
} from "@material-ui/core";
import CoursesSkippedModalController, {
    Props,
} from "./CoursesSkippedModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { SuccessIcon } from "./assets";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) => createStyles({});
const markCompleteTheme = createTheme({
    overrides: {
        MuiDialog: {
            paper: {
                width: "550px",
            },
            paperWidthSm: {
                maxWidth: "550px",
            },
        },
    },
});

// Customizable Area End

// Customizable Area Start
export class CoursesMarkCompleteModal extends CoursesSkippedModalController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, open } = this.props;
        return (
            <ThemeProvider theme={markCompleteTheme}>
                <Dialog
                    data-test-id="markCompleteModal"
                    className={`${classes.highlightBgColor} ${classes.quizViewAnswerConfirmDialog}`}
                    open={open}
                    aria-labelledby="mark-complete-modal-confirm-dialog-title"
                >
                    <Box padding={"30px"} className={`${classes.bgColor}`}>
                        <Box display={"flex"} justifyContent={"center"} id="mark-complete-modal">
                            <Box display={"flex"} justifyContent={"center"} width={"94%"} data-test-id="markIcon">
                                <img src={SuccessIcon} alt="success icon" />
                            </Box>
                            <Box width={"6%"} data-test-id="markIconBtn">
                                <IconButton
                                    data-test-id="markcompleteIconBtn"
                                    aria-label="more"
                                    style={{ float: "right" }}
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={this.props.onCancel}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <DialogContent style={{ padding: "0px" }} data-test-id="markCompleteContent">
                            <Box mt={"20px"} mb={"10px"} textAlign={"center"}>
                                <Typography
                                    className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}
                                    data-test-id="Wave_Energy"
                                >
                                    Wave Energy Marked as Completed
                                </Typography>
                            </Box>
                            <Box mb={"30px"} textAlign={"center"} mt={"10px"} data-test-id="Wave_EnergyBox">
                                <Typography
                                    data-test-id="CopySubCompleted"
                                    className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                                    component={"span"}
                                    style={{ fontSize: "16px" }}
                                >
                                    {" "}
                                    Wave Energy{" "}
                                </Typography>
                                <Typography
                                    className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                                    component={"span"}
                                    data-test-id="MarkTopicFrom"
                                >
                                    {" "}
                                    topic from{" "}
                                </Typography>
                                <Typography
                                    data-test-id="markPhysics"
                                    className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                                    component={"span"}
                                >
                                    Physics?{" "}
                                </Typography>
                                <Box>
                                    <Typography
                                        className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                                        component={"span"}
                                        data-test-id="HasBeenMarked"
                                    >
                                        course has been marked as completed
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                justifyContent={"space-between"}
                                display={"flex"}
                                alignItems={"center"}
                                data-test-id="CompleteMarkedBtn"
                            >
                                <CustomButton
                                    btnStyle={markCompleteModalBtn}
                                    btnText="Okay"
                                    buttonId="mark-complete-modal-cancel-btn"
                                    buttonVariant={"outlined"}
                                    isDisabled={false}
                                    type={"button"}
                                    handleButtonClick={this.props.onCancel}
                                />
                            </Box>
                        </DialogContent>
                    </Box>
                </Dialog>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const markCompleteModalBtn = {
    padding: "5px",
    width: "100%",
    height: "50px",
    borderRadius: "10px",
    fontWeight: 600,
    fontSize: "16px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(CoursesMarkCompleteModal);
// Customizable Area End
