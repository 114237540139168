// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography, IconButton } from '@material-ui/core';
import MarkAsCompleteSuccessDialogController, {
    Props
} from "./MarkAsCompleteSuccessDialogController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from '../../../components/src/CustomButton.web';
import { combineStyles } from '../../../components/src/CombineStyles.web';
import { SuccessIcon, exclamationIcon } from './assets';
import CloseIcon from '../../../components/src/SVGIcons/CloseIcon.web';

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        dueDateSuccessDialog: {
            "& .MuiPaper-rounded": {
                borderRadius: "8px",
            }
        },
    })

// Customizable Area End

// Customizable Area Start
export class MarkAsCompleteSuccessDialog extends MarkAsCompleteSuccessDialogController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, open } = this.props;
        return (
            this.props.markAsCompleteSuccessModal ?
            <Dialog
                open={open}
                className={`${classes.highlightBgColor} ${classes.dueDateSuccessDialog}`}
                aria-labelledby="mark-as-complete-success-dialog"
            >
                <Box padding={"30px"} className={`${classes.bgColor}`}>
                    <Box justifyContent={"center"} id="mark-as-complete-success-dialog" display={"flex"}>
                        <Box>
                            <img src={SuccessIcon} alt="success icon" />
                        </Box>
                    </Box>
                    <DialogContent style={{ padding: "0px" }}>
                        <Box mb={"10px"} textAlign={"center"} mt={"20px"}>
                            <Typography className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}>
                                {`${this.props.detail?.title} Marked as Completed`}
                            </Typography>
                        </Box>
                        <Box textAlign={"center"} mt={"10px"} mb={"30px"}>
                                <>
                                    <Typography className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}>
                                        <b>{this.props.detail?.title}</b> {this.props.type} from <b>{this.props.courseData?.courseName}</b>
                                    </Typography>
                                    <Typography className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}>
                                        course has been marked as completed
                                    </Typography>
                                </>
                        </Box>
                        <Box alignItems={"center"} display={"flex"} justifyContent={"space-between"}>
                            <CustomButton
                                btnStyle={okayButton} btnText='Okay'
                                buttonVariant={"outlined"}
                                buttonId='quiz-view-answer-cancel-btn'
                                handleButtonClick={this.props.onCancel}
                                isDisabled={false} type={"button"}
                            />
                        </Box>
                    </DialogContent>
                </Box>
            </Dialog> : 
            <Dialog
            data-test-id="coursesSkippedModal"
            className={`${classes.highlightBgColor} ${classes.quizViewAnswerConfirmDialog}`}
            open={open}
            aria-labelledby="copy-course-success-confirm-dialog-title"
          >
            <Box padding={"30px"} className={`${classes.bgColor}`} data-test-id="coursesSkippedModalBox">
              <Box display={"flex"} justifyContent={"center"} id="coursesSkipIcon">
                <Box display={"flex"} justifyContent={"center"} width={"94%"} data-test-id="coursesSkippedModalOne">
                  <img src={exclamationIcon} alt="success icon" />
                </Box>
                <Box width={"6%"}>
                  <IconButton
                    data-test-id="oursesSkippedModalIcon"
                    aria-label="more"
                    style={{ float: "right" }}
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={this.props.onCancel}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
              <DialogContent style={{ padding: "0px" }} data-test-id="oursesSkippedContent">
                <Box textAlign={"center"} mt={"20px"} mb={"10px"}>
                  <Typography
                    className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}
                    data-test-id="willbe"
                  >
                    {this.props.detail?.title}{" "}will be Mark As Completed
                  </Typography>

                </Box>
                <Box mb={"30px"} textAlign={"center"} mt={"10px"}>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                    component={"span"}
                    data-test-id="SkipModals"
                  >
                    Are you sure want to mark as complete
                  </Typography>
                  <Typography
                    data-test-id="CopySub_Modal"
                    className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                    component={"span"}
                    style={{ fontSize: "16px" }}
                  >
                    {" "}
                    {this.props.detail?.title}{" "}
                  </Typography>
                  <Box>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                      component={"span"}
                      data-test-id="copiedTo_topic"
                    >
                      {" "}
                      {this.props.type}{" "}from{" "}
                    </Typography>
                    <Typography
                      data-test-id="MyCourses_physics"
                      className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                      component={"span"}
                    >
                      {this.props.courseData?.courseName}?{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  justifyContent={"space-between"}
                  display={"flex"}
                  alignItems={"center"}
                  data-test-id="SkippedBtns"
                  style={{gap:'10px'}}
                >
                  <CustomButton
                    btnStyle={skippedModalBtns}
                    btnText="Cancel"
                    buttonId="skipped-course-modal-cancel-btn"
                    buttonVariant={"outlined"}
                    isDisabled={false}
                    type={"button"}
                    handleButtonClick={this.handleCancelClick}
                  />
                  <CustomButton
                    btnStyle={skippedModalBtns}
                    btnText="Yes"
                    buttonId="skipped-course-modal-cancel-btnsd"
                    buttonVariant={"contained"}
                    isDisabled={false}
                    type={"button"}
                    handleButtonClick={this.handleConfirmClick}
                  />
                </Box>
              </DialogContent>
            </Box>
          </Dialog>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const okayButton = {
    height: "50px",
    width: "100%",
    border: "1px solid #0D46BC",
    padding: "5px",
    color: "#0D46BC",
    backgroundColor: "inherit",
    fontSize: "16px",
    fontWeight: 600,
    borderRadius: "10px",
} as const;

const skippedModalBtns = {
    padding: "5px",
    width: "100%",
    height: "50px",
    borderRadius: "10px",
    fontWeight: 600,
    fontSize: "16px",
  } as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(MarkAsCompleteSuccessDialog);
// Customizable Area End
