// Customizable Area Start
import React from "react";
import { Box, Typography, Grid, Tabs, Tab , Hidden } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentDashboardController, {
  completedActivityTabsList,
  Props
} from "./StudentDashboardController.web";
import { imgActivities, imgLessons, imgTimeSpent } from "./assets"
import CustomProgressBar from "../../../components/src/CustomProgressBar.web";
import CustomBarGraph from "../../../components/src/CustomBarGraph.web";
import CustomProgressCircle from "../../../components/src/CustomProgressCircle.web";
import CustomChip from "../../../components/src/CustomChip.web";
import CustomButton from "../../../components/src/CustomButton.web";
import moment from "moment";
import CustomNoDataComponent from "../../../components/src/CustomNoDataComponent.web";
import PrinterIcon from "../../../components/src/SVGIcons/PrinterIcon.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { TabPanel, a11yProps } from "../../../components/src/CustomTabPanel.web";

// Customizable Area End

// Customizable Area Start
/*istanbul ignore next */
const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
      marginBottom: "28px"
    },
    completionCardMainBox: {
      padding: "20px 15px",
      borderRadius: "10px",
      height: "210px",
      width: "86%",
      marginBottom: "15px",
      [theme.breakpoints.down('xs')]: {
        width: "90%",
      },
    },
    completionMainContainer: {
      marginTop: "15px"
    },
    timeSpentIconBg: {
      height: "60px",
      width: "60px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    timeBox: {
      width: "80px",
      textAlign: "center",
      padding: "7px"
    },
    timeSpentDivider: {
      marginLeft: "8px",
      marginRight: "8px"
    },
    progressBox: {
      width: "100%",
      borderRadius: "50px",
      padding: "2%",
      backgroundColor: "#FDD72F4F",
    },
    mainGridItemMarginTop: {
      marginTop: "30px"
    },
    myProgressTextHeading:{
      marginBottom:"15px"
    },
    performanceGraphMainBox: {
      padding: "2% 0%",
      borderRadius: "10px",
      maxWidth: "98%"
    },
    progressPercentageMainBox: {
      padding: "0% 5%",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap"
    },
    chartWrapper: {
      position: "relative",
      "& canvas": {
        position: "absolute",
        left: 0,
        top: 0,
        pointerEvents: "none",
        minWidth: "900px",
        width: "auto !important",
        [theme.breakpoints.down('md')]: {
          height: "auto !important",
        },
      }
    },
    chartAreaWrapper: {
      overflowX: "auto",
      width: "100%",
      position: "relative"
    },
    chartAreaSecondWrapper: {
      position: "relative",
      height: "300px",
      [theme.breakpoints.down('sm')]: {
        height: "200px !important",
      },
    },
    assignedActivityMainBox: {
      padding: "2% 3%",
      borderRadius: "10px",
      maxWidth: "97%",
      width:"92%",
      minHeight: "550px",
      maxHeight: "550px",
      overflowY: "auto",
      overflowX: "auto"
    },
    subjectLogoBg: {
      width: "32px",
      height: "32px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2px"
    },
    assignmentDetails: {
      padding: "4% 0%",
      borderBottom: "1px solid #F1F1F1"
    },
    subjectActivityContainer: {
      marginBottom: "2%"
    },
    dayLessonTextBox: {
      whiteSpace: "nowrap",
    },
    printerBox: {
      border: "1px solid #0D46BC",
      borderRadius: "6px",
      padding: "8px"
    },
    activityHeaderBox: {
      marginBottom: "2%",
      paddingRight: "2%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    completedActivityHeaderBox: {
      marginBottom: "5px",
      paddingRight: "2%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    activitySubjectLogo:{
      width:"24px",
      height:"24px",
      objectFit:"contain"
    },
    activityGrid:{
      marginTop:"3%",
    },
    tabButton: {
      backgroundColor: "transparent",
      border: "1px solid #888888",
      color: "#888888",
      padding: "10px 4px",
      textAlign: "center",
      textDecoration: "none",
      textTransform: "capitalize",
      display: "inline-block",
      fontSize: "12px",
      borderRadius: "6px",
      marginRight: "5px",
      "&.Mui-selected": {
        backgroundColor: "transparent",
        border: "1px solid #0D46BC",
        color: "#0D46BC",
        outline: "none"
      },
      "&.MuiTab-root": {
        minWidth: "50px",
        minHeight:"40px"
      }
    },
    timeSpentBgBox:{
      position:"absolute",
      bottom:0,
      width:"90px",
      height:"51%",
      borderBottomLeftRadius:"8px",
      borderBottomRightRadius:"8px",
      backgroundColor:"#0D47BE",
      opacity:0.06
    },
    timeSpentTextBgBox:{
      position:"relative",
      height:"67px",
      display:"flex",
      justifyContent:"center",
      alignItems:"center"
    },
    rightMargin5:{
      marginRight:"5px"
    },
    leftMargin8:{
      marginLeft:"8px"
    },
    activityTypo:{
      wordBreak:"break-word",
      [theme.breakpoints.down('md')]: {
        width: "215px",
      },
      [theme.breakpoints.down('sm')]: {
        width: "160px !important",
      },
    }
  })

// Customizable Area End

// Customizable Area Start
export class StudentDashboard extends StudentDashboardController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { studentProgressPerformanceData, assignedActivitiesList, currentDate, seventhDateFromCurrentDate, selectedCompletedActivityTabValue } = this.state;
    return (
      <Box className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`} height={"100%"}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
            <Typography className={`${classes.textCommonColor} ${classes.fontText12}`} component={"span"}>
              My Dashboard
            </Typography>
            </Box>
          </Grid>
          <Grid className={classes.pageName} item xs={12}>
            <Typography className={`${classes.textPrimaryColor} ${classes.fontText32} ${classes.fontBold600}`} component={"span"}>
              My Dashboard
            </Typography>
          </Grid>
          {/* Completion */}
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Typography className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText28}`} component={"span"}>
                  Completion
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid className={classes.completionMainContainer} container direction="row">
                  {/* Time Spent */}
                  <Grid item xs={12} sm={6} lg={4}>
                    <Box display={"flex"} alignItems="center" flexDirection={"column"} className={`${classes.bgColor} ${classes.completionCardMainBox}`}>
                      <Box marginBottom={"10px"}>
                        <Box bgcolor={"#0D47BE"} className={classes.timeSpentIconBg} >
                          <img src={imgTimeSpent} alt="Time Spent" />
                        </Box>
                      </Box>
                      <Box>
                        <Typography className={`${classes.textSecondaryColor} ${classes.fontText20}`} component={"span"}>
                          Time Spent
                        </Typography>
                      </Box>
                      <Box mt={"25px"} width={"100%"} display={"flex"} justifyContent="center" alignItems={"center"} >
                        <Box>
                          <Box className={classes.timeSpentTextBgBox}>
                            <Box className={classes.timeSpentBgBox}></Box>
                            <Box className={classes.timeBox} padding="2px" borderRadius={"8px"}>
                              <Typography className={`${classes.textSecondaryColor} ${classes.fontBold600} ${classes.fontText26}`} component="p">293</Typography>
                            </Box>
                          </Box>
                          <Box textAlign={"center"}>
                            <Typography className={`${classes.textCommonColor} ${classes.fontText12}`} component="p">Hours</Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Typography className={`${classes.textCommonColor} ${classes.fontText12} ${classes.timeSpentDivider}`} component="p">:</Typography>
                        </Box>
                        <Box>
                            <Box className={classes.timeSpentTextBgBox}>
                              <Box className={classes.timeSpentBgBox}></Box>
                              <Box className={classes.timeBox} padding="2px" borderRadius={"8px"}>
                                <Typography className={`${classes.textSecondaryColor} ${classes.fontBold600} ${classes.fontText26}`} component="p">04</Typography>
                              </Box>
                            </Box>
                            <Box textAlign={"center"}>
                              <Typography className={`${classes.textCommonColor} ${classes.fontText12}`} component="p">Minutes</Typography>
                            </Box>
                          </Box>
                      </Box>
                    </Box>
                  </Grid>
                  {/* Lessons */}
                  <Grid item xs={12} sm={6} lg={4}>
                    <Box display={"flex"} alignItems="center" flexDirection={"column"} className={`${classes.bgColor} ${classes.completionCardMainBox}`}>
                      <Box marginBottom={"10px"}>
                        <Box bgcolor={"#01C996"} className={classes.timeSpentIconBg} >
                          <img src={imgLessons} alt="Lessons" />
                        </Box>
                      </Box>
                      <Box>
                        <Typography className={`${classes.textSecondaryColor} ${classes.fontText20}`} component={"span"}>
                          Lessons
                        </Typography>
                      </Box>
                      <Box mt={"10px"} width="100%" display={"flex"} alignItems="center" justifyContent={"center"}>
                        <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                          {`Progress:`}
                        </Typography>
                        <Box>
                          <Typography className={`${classes.rightMargin5} ${classes.leftMargin8} ${classes.primaryColor4} ${classes.fontText16} ${classes.fontBold600}`} component={"span"}>
                            234
                          </Typography>
                          <Typography className={`${classes.rightMargin5} ${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                            /
                          </Typography>
                          <Typography className={`${classes.textSecondaryColor} ${classes.fontBold600} ${classes.fontText16}`} component={"span"}>
                            500
                          </Typography>
                        </Box>
                      </Box>
                      <Box mt={"10px"} width="100%" padding="2% 8%" display={"flex"} alignItems="center" justifyContent={"center"}>
                        <Box bgcolor={"#FDD72F"} className={classes.progressBox} >
                          <CustomProgressBar progressWidth={this.calculateProgressPercentage(234, 500)} barColor="#FDD72F" />
                        </Box>
                      </Box>
                      <Box width="100%" padding="0 8%" display={"flex"} alignItems="center" justifyContent={"space-between"}>
                        {this.handleSetLessonActivityProgressBarLabels(500).map((item: number) =>
                          <Typography key={item} className={`${classes.textCommonColor} ${classes.fontText14}`} component={"span"}>
                            {item}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  {/* Activities */}
                  <Grid item xs={12} sm={6} lg={4}>
                    <Box display={"flex"} alignItems="center" flexDirection={"column"} className={`${classes.bgColor} ${classes.completionCardMainBox}`}>
                      <Box marginBottom={"10px"}>
                        <Box bgcolor={"#019AFC"} className={classes.timeSpentIconBg} >
                          <img src={imgActivities} alt="Activities" />
                        </Box>
                      </Box>
                      <Box>
                        <Typography className={`${classes.textSecondaryColor} ${classes.fontText20}`} component={"span"}>
                          Activities
                        </Typography>
                      </Box>
                      <Box mt={"10px"} width="100%" display={"flex"} alignItems="center" justifyContent={"center"}>
                        <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                          {`Progress: `}
                        </Typography>
                        <Box>
                          <Typography className={`${classes.rightMargin5} ${classes.leftMargin8} ${classes.primaryColor4} ${classes.fontText16}`} component={"span"}>
                            1983
                          </Typography>
                          <Typography className={`${classes.rightMargin5} ${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                            /
                          </Typography>
                          <Typography className={`${classes.textSecondaryColor} ${classes.fontText16}`} component={"span"}>
                            2500
                          </Typography>
                        </Box>
                      </Box>
                      <Box mt={"10px"} width="100%" padding="2% 8%" display={"flex"} alignItems="center" justifyContent={"center"}>
                        <Box bgcolor={"#FDD72F"} className={classes.progressBox} >
                          <CustomProgressBar progressWidth={this.calculateProgressPercentage(1983, 2500)} barColor="#FDD72F" />
                        </Box>
                      </Box>
                      <Box width="100%" padding="0 8%" display={"flex"} alignItems="center" justifyContent={"space-between"}>
                        {this.handleSetLessonActivityProgressBarLabels(2500).map((item: number) =>
                          <Typography key={item} className={`${classes.textCommonColor} ${classes.fontText14}`} component={"span"}>
                            {item}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Progress & Performance */}
          <Grid className={classes.mainGridItemMarginTop} item xs={12}>
            <Grid container direction="row">
              <Grid className={classes.myProgressTextHeading} item xs={12}>
                <Typography className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText28}`} component={"span"}>
                  My Progress & Performance by Course
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box display={"flex"} flexDirection="column" className={`${classes.bgColor} ${classes.performanceGraphMainBox}`}>
                  {/* Performance Graph */}
                  <Box padding={"0% 2%"} display={"flex"} flexDirection="column" >
                    <Box padding={"8px 0px"} >
                      <Typography className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText20}`} component={"span"}>
                        Performance
                      </Typography>
                    </Box>
                    <Box className={classes.chartWrapper} maxWidth={"98%"} overflow="auto" >
                      <Box className={classes.chartAreaWrapper}>
                        <Box className={classes.chartAreaSecondWrapper}>
                          {studentProgressPerformanceData?.length > 0 && <CustomBarGraph graphData={{
                            labels: this.getProgressPerformanceGraphLabels(),
                            datasets: [
                              {
                                data: this.getProgressPerformanceGraphData(),
                                borderRadius: 50,
                                barPercentage: 0.6,
                                backgroundColor: this.getProgressPerformanceGraphBarBgColor(),
                              },
                            ],
                          }}
                            chartLabel="Performance"
                            titleColor={this.handleGetGraphColor()}
                            gridlineColor="#f1f1f1"
                          />}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box padding={"0% 2%"} borderTop={"1px solid #F1F1F1"} mt={"2%"} mb={"2%"} display={"flex"} flexDirection="column">
                    {/* Progress */}
                    <Box pt={"12px"} pb={"15px"} >
                      <Typography className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText20}`} component={"span"}>
                        Progress
                      </Typography>
                    </Box>
                    <Box className={classes.progressPercentageMainBox} mt={"15px"} >
                      {studentProgressPerformanceData?.length > 0 && studentProgressPerformanceData.map((data: any) =>
                        <React.Fragment key={data.id}>
                          <CustomProgressCircle centerTextFontSize="12px" progressDataPosition={{
                            top: "6px",
                            left: "6px"
                          }} progressCircleDimensions={60} progressData={{
                            hoverText: data?.subjectName || "",
                            progressPercentage: data?.progressPercentage || 0
                          }} customClasses={classes} />
                        </React.Fragment>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/* Assigned Activities & Completed Activities */}
          <Grid className={`${classes.mainGridItemMarginTop} ${classes.activityGrid}`} item xs={12} sm={12} md={6}>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Box className={classes.activityHeaderBox}>
                  <Typography className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText28}`} component={"span"}>
                    Assigned Activities
                  </Typography>
                  <Box className={classes.printerBox} >
                    <PrinterIcon width="20" height="20" viewBox="0 0 20 20" stroke="#0D46BC" strokeWidth="0.045" fill="#0D46BC" />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display={"flex"} flexDirection="column" className={`${classes.bgColor} ${classes.assignedActivityMainBox}`}>
                  {assignedActivitiesList?.length > 0 && assignedActivitiesList.map((activityData: any, index: number) =>
                    <Grid className={classes.subjectActivityContainer} key={activityData.id} container>
                      <Grid item xs={12}>
                        <Box paddingTop={"2%"} display={"flex"} alignItems="center" justifyContent="space-between">
                          <Box>
                            <CustomChip subjectData={{
                              subjectName: activityData?.subjectName,
                              subjectBgColor: activityData?.subjectBgColor
                            }} />
                          </Box>
                          <Box display={"flex"} alignItems="center">
                            <CustomButton isDisabled={moment(activityData?.selectedDate).isSame(currentDate)} btnText="< Previous Day" btnStyle={prevNextButtonStyle} buttonId={`assigned-activity-prev-btn-${index}`} buttonVariant="outlined" handleButtonClick={() => { this.handlePrevDayBtnClick(activityData.id) }} type="button" />
                            &nbsp;
                            <CustomButton isDisabled={moment(activityData?.selectedDate).isSame(seventhDateFromCurrentDate)} btnText="Next Day >" btnStyle={prevNextButtonStyle} buttonId={`assigned-activity-next-btn-${index}`} buttonVariant="outlined" handleButtonClick={() => { this.handleNextDayBtnClick(activityData.id) }} type="button" />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        {
                          activityData?.assignmentDetails?.length > 0 ?
                            <>
                              {
                                activityData.assignmentDetails.map((assignmentData: any) =>
                                  <Box className={classes.assignmentDetails} key={assignmentData.id} display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                                    <Box display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                                      <Box marginRight={"10px"}>
                                        <Box bgcolor={activityData?.subjectBgColor} className={classes.subjectLogoBg}>
                                          <img src={activityData?.subjectIcon} className={classes.activitySubjectLogo} alt="Logo" />
                                        </Box>
                                      </Box>
                                      <Box className={classes.activityTypo} display={"flex"} flexDirection={"column"}>
                                        <Typography className={`${classes.textPrimaryColor} ${classes.fontText14}`} component={"span"}>
                                          {this.getAssignmentDate(assignmentData.dueDate)}
                                        </Typography>
                                        <Typography className={`${classes.textPrimaryColor} ${classes.fontText14} ${classes.fontBold600}`} component={"span"}>
                                          {assignmentData?.assignmentName}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                                      <Hidden only={["xs"]}>
                                        <Box className={classes.dayLessonTextBox}>
                                          <Typography className={`${classes.textCommonColor} ${classes.fontText14}`} component={"span"}>
                                            {`Day ${assignmentData?.dayCount}`}
                                          </Typography>
                                        </Box>
                                        <Box ml={"10%"} className={classes.dayLessonTextBox}>
                                          <Typography className={`${classes.primaryColor5} ${classes.cursorPointer} ${classes.fontText14} ${classes.fontBold500}`} component={"span"}>
                                            View Lesson
                                          </Typography>
                                        </Box>
                                      </Hidden>
                                      <Box ml={"10%"}>
                                        <CustomProgressCircle centerTextFontSize="12px" progressDataPosition={{
                                          top: "6px",
                                          left: "6px"
                                        }} progressCircleDimensions={60} progressData={{
                                          hoverText: activityData.subjectName,
                                          progressPercentage: activityData.progressPercentage,
                                        }} customClasses={classes} />
                                      </Box>
                                    </Box>
                                  </Box>
                                )
                              }
                            </>
                            :
                            <CustomNoDataComponent noDataText="No assigned activities yet" />
                        }
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={`${classes.mainGridItemMarginTop} ${classes.activityGrid}`} item xs={12} sm={12} md={6}>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Box className={classes.completedActivityHeaderBox}>
                  <Typography className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText28}`} component={"span"}>
                    Completed Activities
                  </Typography>
                  <Box>
                    <Tabs
                      value={selectedCompletedActivityTabValue}
                      variant="scrollable"
                      scrollButtons="auto"
                      TabIndicatorProps={{
                        style: {
                          background: "none"
                        }
                      }}
                      onChange={this.handleChangeCompletedActivityTab}
                      aria-label="student-completed-activity-tabs"
                    >
                      {completedActivityTabsList.map((tabItem: any, index: number) =>
                        <Tab
                          key={tabItem.id}
                          label={tabItem.label}
                          wrapped
                          {...a11yProps(index)}
                          className={classes.tabButton}
                        />
                      )}
                    </Tabs>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display={"flex"} flexDirection="column" className={`${classes.bgColor} ${classes.assignedActivityMainBox}`}>
                  <TabPanel value={selectedCompletedActivityTabValue} index={0}>
                   <CustomNoDataComponent noDataText="No completed activities yet" />
                  </TabPanel>
                  <TabPanel value={selectedCompletedActivityTabValue} index={1}>
                    {assignedActivitiesList?.map((completedActivityData: any, index: number) =>
                      <Grid className={classes.subjectActivityContainer} key={completedActivityData.id} container>
                        <Grid item xs={12}>
                          <Box paddingTop={"2%"} display={"flex"} alignItems="center" justifyContent="space-between">
                            <Box>
                              <Typography className={`${classes.textPrimaryColor} ${classes.fontText18} ${classes.fontBol5600}`} component={"span"}>
                                {completedActivityData?.subjectName}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          {
                            completedActivityData?.assignmentDetails?.length > 0 ?
                              <>
                                {
                                  completedActivityData.assignmentDetails.map((assignmentData: any) =>
                                    <Box className={classes.assignmentDetails} key={assignmentData.id} display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                                      <Box display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                                        <Box marginRight={"10px"}>
                                         <Box bgcolor={completedActivityData?.subjectBgColor} className={classes.subjectLogoBg}>
                                         <img className={classes.activitySubjectLogo} src={completedActivityData?.subjectIcon} alt="Logo" />
                                         </Box>
                                        </Box>
                                        <Box className={classes.activityTypo} display={"flex"} flexDirection={"column"}>
                                          <Typography className={`${classes.textPrimaryColor} ${classes.fontText14} ${classes.fontBold600}`} component={"span"}>
                                            {assignmentData?.assignmentName}
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                                        <Box className={classes.dayLessonTextBox}>
                                          <Typography className={`${classes.primaryColor5} ${classes.cursorPointer} ${classes.fontText14} ${classes.fontBold500}`} component={"span"}>
                                            View Lesson
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  )
                                }
                              </>
                              :
                              <CustomNoDataComponent noDataText="No completed activities yet" />
                          }
                        </Grid>
                      </Grid>
                    )}
                  </TabPanel>
                  <TabPanel value={selectedCompletedActivityTabValue} index={2}>
                  <CustomNoDataComponent noDataText="No completed activities yet" />
                  </TabPanel>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const prevNextButtonStyle = {
  border: "1px solid rgba(136, 136, 136, 0.4)",
  backgroundColor: "inherit",
  borderRadius: "6px",
  padding: "2px 4px",
  fontSize: "12px",
  color: "#888888"
} as const

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentDashboard);
// Customizable Area End