import React from "react";

// Customizable Area Start
import { Grid, Box, Typography } from "@material-ui/core";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import {
  createStyles,
  createTheme,
  Theme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import TenantRolesAndPermissionsController, {
  Props,
} from "./TenantRolesAndPermissionsController.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomTableWeb from "../../../components/src/CustomTable.web";
import { rolePermissionsTableHeaderMock } from "../assets/tenantRolesAndPermissionsMock.web";
import DeleteRolesPermissionsModalWeb from "./DeleteRolesPermissionsModal.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
      marginBottom: "15px",
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    tenantRolesAndPermissionsTopHeader: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        justifyContent: "flex-start",
      },
    },
    dropdownGrid: {
      gap: "12px",
      marginBottom: "15px",
    },
    rolePermissionsTable: {
      padding: "8px 20px",
    },
    rolePermissionsBox: {
      marginTop: "20px",
      borderRadius: "10px",
    },
    rolePermissionsLabel: {
      padding: "15px 20px",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
  });

const reportTableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "6px !important",
        paddingBottom: "6px !important",
        paddingRight: "0px !important",
        paddingLeft: "0px !important",
      },
      head: {
        padding: "16px 0px",
      },
    },
  },
});
// Customizable Area End

export class TenantRolesAndPermissions extends TenantRolesAndPermissionsController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      selectedRoleTitleDDValue,
      rolesPermissionsList,
      roleTitleDDMenuItems,
      tableActionBtnConfig,
      openDeleteConfirmModal,
      selectedRoleFromList
    } = this.state;
    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${
          classes.mainPagePadding
        } ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Grid container direction="row">
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText12} ${
                  classes.smallTopHeader
                }`}
                component={"span"}
              >
                Roles and Permissions
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.pageName} item xs={12}>
            <Box className={`${classes.tenantRolesAndPermissionsTopHeader}`}>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText34} ${
                  classes.fontBold600
                }`}
                component={"span"}
              >
                Roles and Permissions
              </Typography>
              <CustomButton
                btnText="Add New Role"
                btnStyle={btnStyle}
                buttonId={`role-permissions-add-new-role`}
                buttonVariant={"contained"}
                type={"button"}
                handleButtonClick={() => this.handleAddNewRole()}
                isDisabled={false}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              className={classes.dropdownGrid}
              display={"flex"}
              alignItems={"flex-end"}
            >
              <Box>
                <Box marginBottom={"10px"}>
                  <Typography
                    className={`${classes.textCommonColor} ${
                      classes.fontText16
                    }`}
                    component={"span"}
                  >
                    Role Title
                  </Typography>
                </Box>
                <Box>
                  <CustomSelectDropdown
                    fieldId="roles-and-permissions-select-dropdown"
                    fieldValue={selectedRoleTitleDDValue}
                    fieldName="selectedRoleTitleDDValue"
                    handleChange={this.handleSelectRoleDropdownValue}
                    renderValue={this.renderRoleDDMenuItem}
                    fieldError={false}
                    fieldMenuItems={roleTitleDDMenuItems}
                    fieldStyles={selectDropdownStyles}
                  />
                </Box>
              </Box>
              <Box />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <ThemeProvider theme={reportTableTheme}>
                <Box
                  className={`${classes.rolePermissionsBox} ${classes.bgColor}`}
                >
                  <Box className={`${classes.rolePermissionsLabel}`}>
                    <Typography
                      className={`${classes.textPrimaryColor} ${
                        classes.fontText28
                      } ${classes.fontBold600}`}
                      component={"span"}
                    >
                      Roles and Permissions
                    </Typography>
                  </Box>
                  <Box className={`${classes.rolePermissionsTable}`}>
                    <CustomTableWeb
                      showMenuIcon={true}
                      actionButtonConfig={tableActionBtnConfig}
                      tableBodyData={rolesPermissionsList}
                      tableHeaderColumns={rolePermissionsTableHeaderMock}
                      tableId="role-permissions-list-table"
                    />
                  </Box>
                </Box>
              </ThemeProvider>
            </Box>
          </Grid>
        </Grid>
        <DeleteRolesPermissionsModalWeb selectedId={selectedRoleFromList?.id} handleDelete={this.handleDeleteRolesandPermissionApi} handleClose={this.handleCloseDeletePopup} open={openDeleteConfirmModal} role={selectedRoleFromList?.name} />
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const btnStyle = {
  width: "160px",
  padding: "10px 10px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
} as const;

const selectDropdownStyles = {
  width: "235px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TenantRolesAndPermissions);
// Customizable Area End
