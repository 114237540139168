import React from "react";
import { Chip, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"

interface IProps {
    subjectData: any;
}

const useStyles = makeStyles(() => ({
    chipTextColor: {
        color: "#ffffff"
    },
    fontText20: {
        fontSize: "18px"
    },
    boldFont: {
        fontWeight: 500
    },
}))

export const CustomChip: React.FC<IProps> = (props) => {
    const classes = useStyles();
    const { subjectData } = props;

    return (
        <Chip style={{
            backgroundColor: `${subjectData?.subjectBgColor}`
        }} label={
            <Tooltip title={subjectData?.subjectName} placement={"top"}>
                <Typography className={`${classes.chipTextColor} ${classes.fontText20} ${classes.boldFont}`} component={"span"}>
                    {`${subjectData?.subjectName}`}
                </Typography>
            </Tooltip>
        } />
    );
}

export default CustomChip;
