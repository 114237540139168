// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import TenantCourseMngCreateCatalogStep1Controller, {
  Props,
} from "./TenantCourseMngCreateCatalogStep1Controller.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import {
  FIELD_TYPES,
  IInputConfigProps,
} from "../../../components/src/CommonType.web";
import CustomTextField from "../../../components/src/CustomTextField.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    addDetailMainBox: {
      height: "100vh",
      overflowX: "auto",
      borderRadius: "10px",
      padding: "0px 0px 8px 0px",
    },
    createCatalogStep1SelectDD: {
      marginBottom: "20px",
    },
    createCatalogStep1SelectDDWidth: {
      width: "92%",
      [theme.breakpoints.down("xs")]: {
        width: "96% !important",
      },
    },
    createCatalogStep1MainGridItem: {
      borderBottom: "4px solid #f0f0f0",
      padding: "24px 26px 24px 22px",
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    textCapital: {
      textTransform: "capitalize",
    },
    labelBox: {
      display: "flex",
      justifyContent: "space-between",
    },
    step1MainGrid: {
      padding: "15px 6px 0px 32px",
      borderRadius: "10px",
    },
  });
// Customizable Area End

export class TenantCourseMngCreateCatalogStep1 extends TenantCourseMngCreateCatalogStep1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, inputConfigs } = this.props;

    return (
      <Grid className={`${classes.step1MainGrid}`} container direction="row">
        {inputConfigs?.map((inputConfigItem: IInputConfigProps) => (
          <Grid key={inputConfigItem?.id} item xs={12} sm={6} md={6} lg={6}>
            <Box className={`${classes.createCatalogStep1SelectDD}`}>
              <Box marginBottom={"10px"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  id={`tenant-course-mng-create-catalog-${inputConfigItem?.id}`}
                  component={"span"}
                >
                  {inputConfigItem.label}
                </Typography>
              </Box>
              <Box className={`${classes.createCatalogStep1SelectDDWidth}`}>
                {inputConfigItem.fieldType === FIELD_TYPES.SELECT && (
                  <CustomSelectDropdown
                    fieldId={inputConfigItem.id}
                    fieldStyles={dropdownStyle}
                    fieldValue={inputConfigItem.value}
                    fieldName={inputConfigItem.name}
                    handleChange={(e: any) => this.props.handleSelectChange(e)}
                    renderValue={(selected: any) =>
                      this.props.renderDDCatalogMenuItem(
                        selected,
                        inputConfigItem.fieldName,
                        inputConfigItem.placeHolderText
                      )
                    }
                    fieldMenuItems={inputConfigItem?.menuItems || []}
                    fieldError={inputConfigItem.fieldError}
                    helperText={inputConfigItem.helperText}
                    isDisabled={inputConfigItem?.isDisabled || false}
                  />
                )}
                {inputConfigItem.fieldType === FIELD_TYPES.TEXT && (
                  <CustomTextField
                    fieldName={inputConfigItem.name}
                    fieldWidth={"100%"}
                    fieldId={inputConfigItem.id}
                    fieldValue={inputConfigItem.value}
                    isDisabled={inputConfigItem?.isDisabled}
                    textFieldStyles={textfieldStyles}
                    placeHolderText={inputConfigItem.placeHolderText}
                    fieldType="text"
                    handleChange={(e: any) => this.props.handleTextChange(e)}
                    fieldError={inputConfigItem.fieldError}
                    helperText={inputConfigItem.helperText}
                  />
                )}
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const textfieldStyles = {
  width: "100%",
  padding: "8px 0px",
} as const;

const dropdownStyle = {
  width: "100%",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TenantCourseMngCreateCatalogStep1);
// Customizable Area End
