import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import {
  createStyles,
  createTheme,
  Theme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
      marginBottom: "15px",
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    tenantRolesAndPermissionsTopHeader: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        justifyContent: "flex-start",
      },
    },
    dropdownGrid: {
      gap: "12px",
      marginBottom: "15px",
    },
    rolePermissionsTable: {
      padding: "8px 20px",
    },
    rolePermissionsBox: {
      borderRadius: "10px",
    },
    rolePermissionsLabel: {
      padding: "15px 20px",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    subjectLogoBg: {
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "35px",
      minHeight: "35px",
      height: "35px",
      flexShrink:0,
      width: "35px",
  },
  subjectIcon: {
      width: "20px",
      height: "20px",
      objectFit: "contain",
  },
  });
// Customizable Area End

import Emailnotifications2Controller, {
  Props,
  configJSON,
} from "./Emailnotifications2Controller";
import { deleteIcon } from "./assets";

export default class Emailnotifications2 extends Emailnotifications2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box
        className={`${this.props.classes.childMainWrapperPadding} ${
          this.props.classes.mainPagePadding
        } ${this.props.classes.highlightBgColor} ${this.props.classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Grid container direction="row">
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography
                className={`${this.props.classes.textCommonColor} ${this.props.classes.fontText12} ${
                  this.props.classes.smallTopHeader
                }`}
                component={"span"}
              >
                Notifications
              </Typography>
            </Box>
          </Grid>
          <Grid className={this.props.classes.pageName} item xs={12}>
            <Box className={`${this.props.classes.tenantRolesAndPermissionsTopHeader}`}>
              <Typography
                className={`${this.props.classes.textPrimaryColor} ${this.props.classes.fontText34} ${
                  this.props.classes.fontBold600
                }`}
                component={"span"}
              >
                Notifications
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
                <Box
                  className={`${this.props.classes.rolePermissionsBox} ${this.props.classes.bgColor}`}
                >
                  {
                    this.state.notificationData && this.state.notificationData.length > 0 && this.state.notificationData.map((element, index)=> (
                      <Box
                        style={{
                          ...webStyle.notificationDetailsMain,
                          borderBottom: index === this.state.notificationData.length - 1 ? "none" : "1px solid rgba(224, 224, 224, 1)",
                        }}
                        key={index}>
                        <Box
                          className={this.props.classes.subjectLogoBg}
                          bgcolor={element.attributes.color}
                          mr={"10px"}
                        >
                          <img
                            src={element.attributes.icon}
                            alt="Subject Logo"
                            className={this.props.classes.subjectIcon}
                          />
                        </Box>
                        <Box style={webStyle.notificationDetailsBox}>
                          <Box style={webStyle.notificationDetailTitle}>
                          <Typography 
                             component="span"
                             className={`${this.props.classes.textPrimaryColor} 
                             ${this.props.classes.fontText14} ${this.props.classes.commanFontSize}`} 
                          style={webStyle.notificationDesc}  
                          dangerouslySetInnerHTML={{ __html: element.attributes.contents }}></Typography>
                          </Box>
                        
                          <Box style={webStyle.notificationBottomLink}>
                            <img data-test-id='btnDeleteNotif' style={webStyle.deleteIcon} src={deleteIcon} alt="" />
                          </Box>
                        </Box>
                      </Box>
                    ))
                  }
                  {
                    (!this.state.notificationData || this.state.notificationData.length <= 0) && <Box style={webStyle.noNotificationBox}>
                     "no data"
                    </Box>
                  }
                </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainBox: {
    width: '760px',
    margin: '32px auto 32px auto',
  },
  notificationTopBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  allNotification: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
    fontFamily: 'Poppins',
    color: '#282829',
  },
  clearAll: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '32px',
    color: '#2B3D7D',
    cursor: 'pointer',
    textDecoration: 'underline',
    "text-underline-offset": '2px',
  },
  closeNotificationIcon: {
    cursor: 'pointer',
  },
  notificationDetailsMain: {
    display: 'flex',
    position: 'relative' as 'relative',
    marginBottom: '8px',
    padding: '16px 28px',
    boxSizing: 'border-box' as 'border-box',
    alginItem:"center"
  },
  notificationDetailsMainUnread: {
    backgroundColor: '#2B3D7D0F',
  },
  notificationImg: {
    height: '80px',
    width: '80px',
    boxShadow: '0px 1px 3px 0px #00000014',
    borderRadius: '4px',
    marginRight: '14px',
  },
  notificationTitle: {
    color: '#282829',
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: 500,
    lineHeight: '16px',
    flex: 1,
    marginRight: "10px",
  },
  notificationDesc: {
    color: '#282829',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    'overflow': 'hidden',
    'word-wrap': 'break-word',
    'white-space': 'normal',
  },
  notificationMarkAsRead: {
    fontSize: '12px',
    color: '#2B3D7D',
    width: 'fit-content',
    lineHeight: '16px',
    fontWeight: 400,
    fontFamily: 'Poppins',
    textDecoration: 'underline',
    "text-underline-offset": '2px',
    marginTop: '8px',
    cursor: 'pointer',
  },
  notificationTime: {
    color: '#282829',
    position: 'absolute' as 'absolute',
    right: 24,
    fontFamily: 'Poppins',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
  },
  deleteIcon: {
    marginLeft: "10px",
    cursor: 'pointer',
  },
  noNotificationBox: {
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '32px',
    fontFamily: 'Poppins',
    color: '#282829',
    textAlign: 'center' as 'center',
    marginTop: '150px',
  },
  notificationDetailTitle: {
    display: "flex",
    justifyContent: "space-between"
  },
  notificationBottomLink: {
    display: "flex",
    justifyContent: "space-between"
  },
  notificationDetailsBox: {
    flex: 1,
    display: "flex",
  alignItems: "center",
  justifyContent:"space-between"

  }
};
const Emailnotifications = Emailnotifications2
const combinedStyle = combineStyles(customThemeStyles, styles);
const StyledEmailNotifications2 = withStyles(combinedStyle)(Emailnotifications2);
export { StyledEmailNotifications2 };
// Customizable Area End
