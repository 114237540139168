// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CourseManagementDetailsTopHeaderController, {
  Props,
  courseDetailsTabList,
} from "./CourseManagementDetailsTopHeaderController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
  });
// Customizable Area End

// Customizable Area Start
export class CourseManagementDetailsTopHeader extends CourseManagementDetailsTopHeaderController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      tabValue,
      isTeacherCourse,
      courseName,
      selectedLessonName,
      selectedTopicName,
      isFromStudentTeacher,
      studentName,
    } = this.props;

    return (
      <>
        {!isFromStudentTeacher ? (
          <Box marginTop={"1%"}>
            <Typography
              className={`${classes.textCommonColor} ${classes.fontText14}`}
              component={"span"}
              id={`tenant-course-detail-breadcrumb-header`}
            >
              {`Course Management`}{" "}
            </Typography>
            <Typography
              className={`${classes.textCommonColor} ${classes.fontText14}`}
              component={"span"}
            >
              {`/ ${isTeacherCourse ? "Teacher Courses" : "My Courses"}`}{" "}
            </Typography>
            <Typography
              className={`${classes.textCommonColor} ${classes.fontText14}`}
              component={"span"}
            >
              {`/ ${courseName.trim().replace(/%2C/g, ",")}`}
            </Typography>
            <Typography
              className={
                tabValue === 1
                  ? `${classes.textCommonColor} ${classes.fontText14}`
                  : `${classes.primaryColor5} ${classes.fontBold600} ${classes.fontText14}`
              }
              component={"span"}
            >
              {` / ${courseDetailsTabList[tabValue].label}`}
            </Typography>
            {selectedTopicName && (
              <Typography
                className={
                  selectedLessonName
                    ? `${classes.textCommonColor} ${classes.fontText14}`
                    : `${classes.primaryColor5} ${classes.fontBold600} ${classes.fontText14}`
                }
                component={"span"}
              >
                {` / ${selectedTopicName}`}
              </Typography>
            )}
            {selectedLessonName && (
              <Typography
                className={`${classes.primaryColor5} ${classes.fontBold600} ${classes.fontText14}`}
                component={"span"}
              >
                {` / ${selectedLessonName}`}
              </Typography>
            )}
          </Box>
        ) : (
          <Box data-test-id="student-teacher-course-journey" marginTop={"1%"}>
            <Typography
              className={`${classes.textCommonColor} ${classes.fontText14}`}
              id={`student-teacher-course-detail-breadcrumb-header`}
              component={"span"}
            >
              {`Students`}{" "}
            </Typography>
            <Typography
              className={`${classes.textCommonColor} ${classes.fontText14}`}
              id={`student-teacher-course-detail-student-name-breadcrumb-header`}
              component={"span"}
            >
              {`/ ${studentName}`}{" "}
            </Typography>
            <Typography
              className={`${classes.textCommonColor} ${classes.fontText14}`}
              id={`student-teacher-course-detail-course-name-breadcrumb-header`}
              component={"span"}
            >
              {`/ ${courseName.trim().replace(/%2C/g, ",")}`}
            </Typography>
            <Typography
              className={`${classes.primaryColor5} ${classes.fontBold600} ${classes.fontText14}`}
              component={"span"}
            >
              {` / Journey`}
            </Typography>
          </Box>
        )}
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(CourseManagementDetailsTopHeader);
// Customizable Area End
