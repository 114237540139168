// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
  id:any;
  label:string;
  value:any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  assessmentList:any;
  assessmentCategory:string;
  paginationDueList?:any
  handlePaginationChange?: any
  selectedSortBy?: any;
  sortByList?: IDropdownItems[];
  renderSortDDValue?: (selected: any) => string | undefined;
  handleChangeDropdownValueGradedAssessment?: (event: React.ChangeEvent<{
    name?: any;
    value: unknown;
}>, child: React.ReactNode) => void;
  handleSearchGradedAssessment?: () => void;
  selectedGrade?: any;
  renderGradeDDValue?: (selected: any) => string | undefined;
  gradeList?: IDropdownItems[]

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AssessmentItemCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  handleNavigateToAssessmentDueScreen = (title : string , typeOfAssessment : string,evaluationType:string,objectiveType:string,activityId:string) => {
    const { assessmentCategory } = this.props;
    if(assessmentCategory === "due"){
      this.props.navigation?.navigate("StudentDueAssessmentMain" , {} , {
        typeOfAssessment : typeOfAssessment,
        evaluationType,
        objectiveType: objectiveType || evaluationType,
        title : title,
        activityId
      });
    } else if(assessmentCategory === "completed") {
      this.props.navigation?.navigate("StudentCompletedAssessmentMain" , {} , {
        typeOfAssessment : typeOfAssessment, 
        title : title,
        assessmentCategory:"submitted",
        activityId,
        evaluationType,
        objectiveType: objectiveType || evaluationType,
      });
    } else{
        this.props.navigation?.navigate("StudentGradedAssessmentMain" , {} , {
          typeOfAssessment : typeOfAssessment,
          title : title,
          assessmentCategory:"graded",
          activityId,
          evaluationType,
          objectiveType: objectiveType || evaluationType,
        });
    }
  };

  // Customizable Area End
}
// Customizable Area End
