// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

interface IProps {
  id: any;
  completionProgress: any;
  title: string;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  index?: any;
  navigation?: any;
  id?: string;
  showInfo: boolean;
  classes?: any;
  handleSelectedItem: any;
  detail: IProps;
  selectedItemId?: any;
  key: any;
  menuType?: any;
  isFromTenant?: any;
  isStudent?: boolean;
  getMenuItem: any;
  handleInfoDialog?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  menuAnchorEl: any;
  loadings: boolean;
  openChangeDueDateModal: boolean;
  openChangeDueDatelessonModal: boolean;
  openSkippedModal: boolean;
  openMarkCompletedModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentCourseJourneyBoxController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetStudentCoursesJourneyDetails: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      menuAnchorEl: null,
      loadings: false,
      openChangeDueDateModal: false,
      openChangeDueDatelessonModal: false,
      openSkippedModal: false,
      openMarkCompletedModal: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleResetInitialsValue();
    // Customizable Area End
  }

  // Customizable Area Start
  handleResetInitialsValue = () => {
    this.setState({
      menuAnchorEl: null,
      loadings: false,
      openChangeDueDateModal: false,
      openChangeDueDatelessonModal: false,
      openSkippedModal: false,
      openMarkCompletedModal: false,
    })
  }

  handleEditActionClick = (e: any, id: any) => {
    this.setState({ menuAnchorEl: e?.currentTarget });
  };
  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null });
  };

  handleMenuModalOpen = () => {
    if (this.props.menuType === "Topic") {
      this.setState({
        openChangeDueDateModal: true,
      });
    } else if (this.props.menuType === "lessons") {
      this.setState({
        openChangeDueDatelessonModal: true,
      });
    }
  };

  handleCloseChangeDueDateModal = () => {
    if (this.props.menuType === "Topic") {
      this.setState({
        openChangeDueDateModal: false,
        menuAnchorEl: null
      });
    } else if (this.props.menuType === "lessons") {
      this.setState({
        openChangeDueDatelessonModal: false,
        menuAnchorEl: null
      });
    }
  };

  handleSkippedModalOpen = () => {
    if (this.props.menuType === "Topic" || this.props.menuType === "lessons") {
      this.setState({
        openSkippedModal: true,
        menuAnchorEl: null
      })
    }
  }

  handleCloseSkippedModal = () => {
    if (this.props.menuType === "Topic" || this.props.menuType === "lessons") {
      this.setState({
        openSkippedModal: false,
      })
    }
  }
  handleCoursesMarkCompleteModal = () => {
    if (this.props.menuType === "Topic" || this.props.menuType === "lessons") {
      this.setState({
        openMarkCompletedModal: true,
        menuAnchorEl: null
      })
    }
  }
  handleCloseMarkCompletedModal = () => {
    this.setState({
      openMarkCompletedModal: false,
    })
  }

  handleEditMenuOption = () => {
    const menuData = {
      menu: "Edit",
      itemId: this.props.detail.id,
      itemName: this.props.detail.title,
      itemType: this.props.menuType,
    }
    if (menuData) {
      this.props.getMenuItem(menuData);
    }
    this.handleMenuClose();
  }

  handleDeleteMenuOption = () => {
    const menuData = {
      menu: "Delete",
      itemId: this.props.detail.id,
      itemName: this.props.detail.title,
      itemType: this.props.menuType,
    }
    if (menuData) {
      this.props.getMenuItem(menuData);
    }
    this.handleMenuClose();
  }

  handleInfoIconClick = (event: any) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
      const { handleInfoDialog, detail } = this.props;
      handleInfoDialog(detail);
    }
  }
  // Customizable Area End
}
// Customizable Area End
