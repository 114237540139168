// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import moment from "moment";
import { mathSubjectIcon, physicsSubjectIcon } from "./assets";
// Customizable Area End

// Customizable Area Start
export const completedActivityTabsList = [
  {
    id:1,
    label:"Yesterday",
    value:"yesterday"
  },
  {
    id:2,
    label:"Today",
    value:"today"
  },
  {
    id:3,
    label:"This Week",
    value:"week"
  }
]
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  fullName: string;
  studentProgressPerformanceData:any;
  assignedActivitiesList:any;
  seventhDateFromCurrentDate:any;
  currentDate:any;
  selectedCompletedActivityTabValue:number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      loading: false,
      fullName: "",
      studentProgressPerformanceData:[
        {
          id:1,
          subjectName:"Algebra",
          subjectMarks:70,
          subjectBgColor:"#0D46BC",
          progressPercentage:49
        },
        {
          id:2,
          subjectName:"Computer",
          subjectMarks:85,
          subjectBgColor:"#DF9D33",
          progressPercentage:56
        },
        {
          id:3,
          subjectName:"Physics",
          subjectMarks:58,
          subjectBgColor:"#01C996",
          progressPercentage:55
        },
        {
          id:4,
          subjectName:"French",
          subjectMarks:25,
          subjectBgColor:"#5FD9C4",
          progressPercentage:10
        },
        {
          id:5,
          subjectName:"Roman History",
          subjectMarks:40,
          subjectBgColor:"#FBD82C",
          progressPercentage:68
        },
        {
          id:6,
          subjectName:"Victorian Literature",
          subjectMarks:67,
          subjectBgColor:"#019AFC",
          progressPercentage:68
        },
        {
          id:7,
          subjectName:"Creative Writing",
          subjectMarks:67,
          subjectBgColor:"#019AFC",
          progressPercentage:90
        },
        {
          id:8,
          subjectName:"English Poetry",
          subjectMarks:67,
          subjectBgColor:"#019AFC",
          progressPercentage:68
        },
        {
          id:9,
          subjectName:"Social Behaviour",
          subjectMarks:98,
          subjectBgColor:"#FC9301",
          progressPercentage:68
        }
      ],
      assignedActivitiesList:[
        {
          id:1,
          subjectName:"Algebra",
          subjectMarks:70,
          subjectBgColor:"#0D46BC",
          subjectIcon:mathSubjectIcon,
          progressPercentage:49,
          selectedDate:moment(new Date()),
          assignmentDetails:[
            {
              id:1,
              dueDate:"21/04/2023",
              assignmentName:"Adding Simple Fractions",
              assignmentProgress:68,
              dayCount:1
            },
            {
              id:2,
              dueDate:"20/04/2023",
              assignmentName:"Adding a Fraction and Mixed Number",
              assignmentProgress:68,
              dayCount:1
            },
            {
              id:3,
              dueDate:"24/04/2023",
              assignmentName:"Adding a Fraction and Mixed Number",
              assignmentProgress:68,
              dayCount:1
            }
          ]
        },
        {
          id:2,
          subjectName:"Science",
          subjectMarks:85,
          subjectBgColor:"#01C996",
          subjectIcon:physicsSubjectIcon,
          progressPercentage:56,
          selectedDate:moment(new Date()),
          assignmentDetails:[
            {
              id:1,
              dueDate:"20/04/2023",
              assignmentName:"Basic Graphing",
              assignmentProgress:68,
              dayCount:1
            },
            {
              id:2,
              dueDate:"20/04/2023",
              assignmentName:"Periodic Table Alkaline",
              assignmentProgress:46,
              dayCount:1
            },
            {
              id:3,
              dueDate:"20/04/2023",
              assignmentName:"Periodic Table Alkaline",
              assignmentProgress:78,
              dayCount:1
            }
          ]
        },
        {
          id:3,
          subjectName:"Computer",
          subjectMarks:58,
          subjectBgColor:"#5FD9C4",
          subjectIcon:physicsSubjectIcon,
          progressPercentage:55,
          selectedDate:moment(new Date()),
          assignmentDetails:[
            {
              id:1,
              dueDate:"20/04/2023",
              assignmentName:"Basic Computer Architecture",
              assignmentProgress:68,
              dayCount:1
            },
            {
              id:2,
              dueDate:"27/04/2023",
              assignmentName:"Programming C++",
              assignmentProgress:8,
              dayCount:1
            }
          ]
        }
      ],
      currentDate:moment(new Date()),
      seventhDateFromCurrentDate:moment(new Date()).add(7,"days"),
      selectedCompletedActivityTabValue:1
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    localStorage.setItem("role", "student");
    // Customizable Area End
  }

  // Customizable Area Start

  handleGetGraphColor = () => {
    const isDarkMode = localStorage.getItem("dark_mode") || JSON.stringify(false);
    if(JSON.parse(isDarkMode)) return "#ffffff"
    return "#000"
  }

  calculateProgressPercentage = (completed:number , total:number) => {
    const percentage = (completed/total)*100;
    return Number(percentage.toFixed(2));
  }

  handleSetLessonActivityProgressBarLabels = (maxValue : number) => {
    const labelList : Array<number> = [];
    labelList.push(0);
    labelList.push((maxValue/2));
    labelList.push(maxValue);
    return labelList;
  }

  getProgressPerformanceGraphLabels = () => {
    const { studentProgressPerformanceData } = this.state;
    const labelsList : Array<string> = studentProgressPerformanceData?.map((data:any)=>data.subjectName);
    return labelsList || [];
  }

  getProgressPerformanceGraphData = () => {
    const { studentProgressPerformanceData } = this.state;
    const graphDataList : Array<number> = studentProgressPerformanceData?.map((data:any)=>data.subjectMarks);
    return graphDataList || [];
  }

  getProgressPerformanceGraphBarBgColor = () => {
    const { studentProgressPerformanceData } = this.state;
    const graphBarBgColor : Array<string> = studentProgressPerformanceData?.map((data:any)=>data.subjectBgColor);
    return graphBarBgColor || [];
  }

  handleNextDayBtnClick = (activityId : any) => {
    const { assignedActivitiesList } = this.state;
    const updatedActivityList = assignedActivitiesList.map((item:any)=> {
      if(item.id === activityId){ 
        item.selectedDate = moment(item.selectedDate).add(1,"day");
        item?.assignmentDetails?.map((assignmentData:any)=>{
         if(assignmentData.dayCount <= 7) assignmentData.dayCount += 1;
         return assignmentData;
        })
    }
      return item
    })
    this.setState({ assignedActivitiesList : updatedActivityList})
  }

  handlePrevDayBtnClick = (activityId : any) => {
    const { assignedActivitiesList } = this.state;
    const updatedActivityList = assignedActivitiesList.map((item:any)=> {
      if(item.id === activityId) {
        item.selectedDate = moment(item.selectedDate).subtract(1,"day");
        item?.assignmentDetails?.map((assignmentData:any)=>{
          if(assignmentData.dayCount >= 1) assignmentData.dayCount -= 1;
          return assignmentData;
         })
      }
      return item
    })
    this.setState({ assignedActivitiesList : updatedActivityList})
  }

  getAssignmentDate = (dueDate : any) => {
    return moment(dueDate , "DD-MM-YYYY").format("MMM D")
  }

  handleChangeCompletedActivityTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({selectedCompletedActivityTabValue : newValue});
  };

  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      console.log("apiRequestCallId", apiRequestCallId);

      // Customizable Area Start

      // Customizable Area End
    }
  }

  // Customizable Area End
}
// Customizable Area End