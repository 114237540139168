// Customizable Area Start
import React from "react";
import { Box, ThemeProvider, createTheme } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomTable, { tableColumnTypes } from "../../../components/src/CustomTable.web";
import TenantAdminGradeBookListController, { Props } from "./TenantAdminGradeBookListController.web";
import TenantAdminGradeBook from "./TenantAdminGradeBook.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        textPaddingBox: {
            padding: "27px"
        },
    });
const tableBodyHead = [
    {
        type: tableColumnTypes.TEXT,
        columnId: 1,
        id: "titles",
        label: "Title",
        width: "250px",
        maxWidth: "350px",
    },
    {
        columnId: 2,
        id: "reportingType",
        type: tableColumnTypes.TEXT,
        label: "Reporting Type",
        width: "200px",
        maxWidth: "300px",
    },
    {
        columnId: 3,
        id: "numOfGradingComponent",
        type: tableColumnTypes.NUMBER,
        label: "No of Grading Component",
        width: "150px",
        maxWidth: "200px",
    },
];

const gradesTableActionButtonConfig = [
    {
        label: "Edit",
        action: (value: any) => console.warn("edit"),
    },
    {
        label: "Delete",
        action: (value: any) => console.warn("delete"),
    },
]
const schoolYearTableTheme = createTheme({
    overrides: {
      MuiTableCell: {
        body: {
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
          paddingRight: "0px !important",
          paddingLeft: "0px !important",
        },
        head: {
          padding: "16px 0px",
        },
      },
    },
  });

// Customizable Area End

// Customizable Area Start
export class TenantAdminGradeBookList extends TenantAdminGradeBookListController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        const { classes, showNewGrade ,gradeId} = this.props;
        const {tableBodyData,gradesTableActionButtonConfig} = this.state
        return (

            <>
                {showNewGrade ?
                    (
                        <TenantAdminGradeBook 
                            handleClose={this.props.handleClose} 
                            gradeId={gradeId}  
                            navigation={this.props.navigation} 
                        />
                    )
                    :
                    (

                     
                        <Box className={`${classes.bgColor} ${classes.textPaddingBox}`} padding={"0px 20px"}>
                        <ThemeProvider theme={schoolYearTableTheme}>
                            <CustomTable
                                tableHeaderColumns={tableBodyHead}
                                tableBodyData={tableBodyData}
                                tableId={`gradebook-list`}
                                showMenuIcon={true}
                                actionButtonConfig={gradesTableActionButtonConfig}
                                showEditMenuIcon={false}
                            />
                        
                        </ThemeProvider>
                        </Box>
                    )

                }
            </>

        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TenantAdminGradeBookList);
// Customizable Area End
