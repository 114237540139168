// Customizable Area Start
import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomTable, {
  tableColumnTypes,
} from "../../../components/src/CustomTable.web";
import TenantAdminSetupSchoolCalendarController, {
  Props,
} from "./TenantAdminSetupSchoolCalendarController.web";
import CustomButtonWeb from "../../../components/src/CustomButton.web";
import NewSchoolCalendarDialogWeb from "./NewSchoolCalendarDialog.web";
import DeleteEventDialogWeb from "./DeleteEventDialog.web";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";
import { weekdaysList } from "../../../components/src/CommonType.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    searchBox: {
      display: "flex",
      alignItems: "flex-end",
      padding: "24px",
    },
    inputLabel: {
      fontSize: 14,
      fontWeight: 400,
      color: "#888888",
      marginBottom: "6px",
      marginTop: "10px",
    },
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    searchBtnGrid: {
      display: "flex", 
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        marginTop: "20px"
      }
    },
    paginationBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 20px 10px 21px",
    },
  });

const schoolCalendarHead = [
  {
    id: "schoolYearTitle",
    columnId: 1,
    label: "School Year Title",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "curriculum",
    columnId: 2,
    label: "Curriculum",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "daysPattern",
    columnId: 3,
    label: "Days Pattern",
    type: tableColumnTypes.DAY_PATTERN_SM,
    width: "250px",
    maxWidth: "280px",
  },
  {
    id: "createdOn",
    columnId: 4,
    label: "Created On",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "modifiedOn",
    columnId: 5,
    label: "Modified On",
    type: tableColumnTypes.TEXT,
  },
];

const calendarTableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
        paddingRight: "0px !important",
        paddingLeft: "0px !important",
      },
      head: {
        padding: "16px 0px",
      },
    },
  },
});

// Customizable Area End

// Customizable Area Start
export class TenantAdminSetupSchoolCalendar extends TenantAdminSetupSchoolCalendarController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, openNewSchoolCalendarDialog } = this.props;
    const {
      selectSchoolYear,
      selectSchoolYearItems,
      selectCurriculum,
      selectCurriculumItems,
      schoolCalendarList,
      schoolCalendarTableActionButtonConfig,
      openDeleteSchoolCalendarPopup,
      openEditNewSchoolCalendarPopup,
      selectedSchoolCalendarData,
      currentPage,
      perPageRecords,
      totalNumberOfSchoolCalendarRecords,
    } = this.state;
    return (
      <Box style={{ paddingTop: "1px" }} className={`${classes.bgColor}`}>
        <Grid container className={`${classes.searchBox}`}>
          <Grid item lg={3} md={6} sm={12}>
            <Box style={{ marginRight: "20px" }}>
              <Typography className={classes.inputLabel} data-test-id='school'>
                School Year Title
              </Typography>
              <CustomSelectDropdown
                fieldId="school-calendar-school-year-dropdown"
                fieldValue={selectSchoolYear}
                fieldName="selectSchoolYear"
                handleChange={this.handleChangeDropdownValue}
                renderValue={this.renderSchoolYearDDValue}
                fieldMenuItems={selectSchoolYearItems}
                fieldStyles={{ width: "96%" }}
                placeHolderText="Select Option"
              />
            </Box>
          </Grid>
          <Grid item lg={3} md={6} sm={12}>
            <Box style={{ marginRight: "20px" }}>
              <Typography className={classes.inputLabel}>Curriculum</Typography>
              <CustomSelectDropdown
                fieldId="school-calendar-curriculum-dropdown"
                fieldValue={selectCurriculum}
                fieldName="selectCurriculum"
                handleChange={this.handleChangeDropdownValue}
                renderValue={this.renderCurriculumDDValue}
                fieldMenuItems={selectCurriculumItems}
                fieldStyles={{ width: "96%" }}
                placeHolderText="Select Option"
              />
            </Box>
          </Grid>
          <Grid
            item
            lg={3}
            md={6}
            sm={12}
            className={`${classes.searchBtnGrid}`}
          >
            <CustomButtonWeb
              buttonId="school-calendar-search-btn"
              buttonVariant="contained"
              btnStyle={{
                padding: "10px 10px",
                width: "130px",
                fontSize: "16px",
                fontWeight: 600,
                borderRadius: "10px",
                textAlign: "center",
              }}
              btnText="Search"
              handleButtonClick={() => this.handleGetSchoolCalendarList(true)}
              isDisabled={false}
              type={"button"}
            />
          </Grid>
        </Grid>
        <Box
          style={{ backgroundColor: "#F1F1F1", height: "4px", width: "100%" }}
        />
        {schoolCalendarList.length > 0 ? (
          <>
            <Box
              className={`${classes.bgColor}`}
              borderBottom={"1px solid rgba(224, 224, 224, 1)"}
              p={"0px 20px"}
            >
              <ThemeProvider theme={calendarTableTheme}>
                <CustomTable
                  tableId={"tenant-school-calendar-table"}
                  tableHeaderColumns={schoolCalendarHead}
                  tableBodyData={schoolCalendarList}
                  showMenuIcon={true}
                  actionButtonConfig={schoolCalendarTableActionButtonConfig}
                />
              </ThemeProvider>
            </Box>
            <Box className={classes.paginationBox}>
              <Box width={"50%"} marginTop={"20px"}>
                <Box display={"flex"} gridGap={"6px"}>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText18}`}
                    component="span"
                  >
                    Records
                  </Typography>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold600}`}
                    component="span"
                  >
                    {totalNumberOfSchoolCalendarRecords > perPageRecords
                      ? `${currentPage * perPageRecords - perPageRecords}-
                ${currentPage * perPageRecords}`
                      : `${
                          currentPage * totalNumberOfSchoolCalendarRecords -
                          totalNumberOfSchoolCalendarRecords
                        }-
                ${currentPage * totalNumberOfSchoolCalendarRecords}`}
                  </Typography>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText18}`}
                    component="span"
                  >
                    of {totalNumberOfSchoolCalendarRecords}
                  </Typography>
                </Box>
              </Box>
              {totalNumberOfSchoolCalendarRecords > perPageRecords && (
                <Box className={classes.paginationRoot}>
                  <Pagination
                    count={Math.ceil(
                      totalNumberOfSchoolCalendarRecords / perPageRecords
                    )}
                    variant="outlined"
                    shape="rounded"
                    boundaryCount={1}
                    siblingCount={0}
                    page={currentPage}
                    onChange={this.handlePageChange}
                  />
                </Box>
              )}
            </Box>
          </>
        ) : (
          <CustomEmptyResultComponent message="No Records yet." />
        )}

        {(openEditNewSchoolCalendarPopup || openNewSchoolCalendarDialog) && (
          <NewSchoolCalendarDialogWeb
            open={openEditNewSchoolCalendarPopup || openNewSchoolCalendarDialog}
            handleClose={() => this.handleCloseEditSchoolCalendarPopup()}
            handleAddNewSchoolCalendar={(data: any) =>
              this.handleAddNewSchoolCalendar(data)
            }
            handleUpdateSchoolCalendar={(data: any) =>
              this.handleUpdateSchoolCalendar(data)
            }
            selectedSchoolCalendarData={selectedSchoolCalendarData}
            isEdit={openEditNewSchoolCalendarPopup}
            schoolYearList={selectSchoolYearItems}
            curriculumList={selectCurriculumItems}
            weekdaysList={weekdaysList}
          />
        )}
        <DeleteEventDialogWeb
          open={openDeleteSchoolCalendarPopup}
          onCancel={() => this.handleCloseDeleteSchoolCalendarConfirmModal()}
          onConfirm={() => this.handleDeleteSchoolCalendar()}
          title={""}
          isEvent={false}
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TenantAdminSetupSchoolCalendar);
// Customizable Area End
