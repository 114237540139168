// Customizable Area Start
import React from "react";
import { Box, Grid, Hidden, Tooltip, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import TeacherDashboardController, {
  Props,
} from "./TeacherDashboardController.web";
import CustomTable, {
  tableColumnTypes,
} from "../../../components/src/CustomTable.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CatchMeUpModal from "../../cfresumejourney/src/CatchMeUpModal.web";
import CustomImageComponentWeb from "../../../components/src/CustomImageComponent.web";
import CustomButtonWeb from "../../../components/src/CustomButton.web";

// Customizable Area End

// Customizable Area Start
const tenantSubjectColumn = [
  {
    id: "Name",
    columnId: 1,
    label: "Name",
    type: tableColumnTypes.STUDENT_ICON,
    width: "250px",
    maxWidth: "280px",
  },
  {
    id: "Progress",
    columnId: 2,
    label: "Progress",
    type: tableColumnTypes.STUDENT_PROGRESS_BAR,
  },
];
const progressTableColumns = [
  {
    id: "Name",
    columnId: 1,
    label: "Name",
    type: tableColumnTypes.STUDENT_ICON,
  },
  {
    id: "TermAvg",
    columnId: 2,
    label: "Term Avg",
    type: tableColumnTypes.PROGRESS_TEXT,
  },
  {
    id: "YTD",
    columnId: 3,
    label: "YTD",
    type: tableColumnTypes.PROGRESS_TEXT,
  },
];
const assignedTableColumns = [
  {
    id: "Title",
    columnId: 1,
    label: "Title",
    type: tableColumnTypes.ASSIGNED_TITLE,
  },
  {
    id: "Type",
    columnId: 2,
    label: "Type",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "Submitted",
    columnId: 3,
    label: "Submitted %",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "AvgGrade",
    columnId: 4,
    label: "Avg Grade",
    type: tableColumnTypes.ASSIGNED_ASSESSMENT_AVG_GRADE,
  },
  {
    id: "DueDate",
    columnId: 2,
    label: "Due Date",
    type: tableColumnTypes.DUE_DATE,
  },
];
const styles = (theme: Theme) =>
  createStyles({
    subjectLogoBg: {
      width: "55px",
      height: "55px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2px",
    },
    courseMainBox: {
      height: "180px",
      padding: "15px",
      width: "85%",
      marginBottom: "5%",
      borderRadius: "12px",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
    },
    breadCrumbRoot: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    topBtnRoot: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
    },
    topBtn: {
      borderRadius: "12px",
      backgroundColor: "#0D46BC",
      color: "#ffffff",
      marginRight: "30px",
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: "#0D46BC",
      },
    },
    pageTitle: {
      fontSize: 28,
      fontWeight: "bold",
    },
    selectedBoxBorder: {
      border: "2px solid #FBD82C",
    },
    statusCompletion: {
      fontWeight: 500,
      fontSize: "14px",
      color: "#01C996",
      backgroundColor: "#d0f5eb",
      borderRadius: "17px",
      padding: "6px 10px 6px 10px",
    },
    statusLate: {
      fontSize: "14px",
      color: "#ED1111",
      backgroundColor: "#fcd3d3",
      borderRadius: "17px",
      padding: "5px 10px 5px 10px",
    },
    statusOnGoing: {
      fontSize: "14px",
      color: "#FBD82C",
      backgroundColor: "#fef8d8",
      borderRadius: "17px",
      padding: "6px 10px 6px 10px",
    },
    statusScheduled: {
      fontSize: "14px",
      color: "#4DC901",
      backgroundColor: "#def5d0",
      borderRadius: "17px",
      padding: "6px 10px 6px 10px",
    },
    tableTitle: {
      fontSize: 28,
      fontWeight: "bold",
    },
    customTableStyles: {
      marginRight: "8px",
      borderRadius: "10px",
    },
    firstGrid: {
      width: "53%",
      marginRight: "13px",
      [theme.breakpoints.down("md")]: {
        width: "98%",
        alignItems: "center",
      },
      [theme.breakpoints.up("lg")]: {
        width: "53%",
        alignItems: "center",
      },
    },
    firstGrid2: {
      width: "46%",
      [theme.breakpoints.down("md")]: {
        width: "98%",
        alignItems: "center",
        marginTop: "13px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "46%",
        alignItems: "center",
      },
    },
    container: {
      width: "99%",
      marginTop: "17.2px",
      display: "flex",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        flexDirection: "column",
      },
    },
    commanFontSize: {
      fontWeight: "bold",
    },
    progresstableHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "98%",
    },
    viewAllBtn: {
      color: "#0D46BC",
      fontWeight: "bold",
      cursor: "pointer",
    },
    assignedAssessments: {
      "& td": {
        height: "50px",
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },
    cursorPointer: {
      cursor: "pointer",
    },
  });
// Customizable Area End

// Customizable Area Start
export class TeacherDashboard extends TeacherDashboardController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      teacherCoursesList,
      selectedCourseId,
      progressStudentsTableBody,
      assignedAssessmentsList,
    } = this.state;

    const getSelectedCourseBoxClass = (courseId: string) => {
      if (parseInt(selectedCourseId) === parseInt(courseId))
        return `${classes.bgColor} ${classes.selectedBoxBorder} ${classes.courseMainBox}`;
      return `${classes.bgColor} ${classes.courseMainBox}`;
    };
    const getAvgScoreColor = (status: string) => {
      if (status === "Completed") return `${classes.statusCompletion} `;
      else if (status === "Late") return `${classes.statusLate} `;
      else if (status === "On Going") return `${classes.statusOnGoing} `;
      else return `${classes.statusScheduled}`;
    };
    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Box className={classes.breadCrumbRoot}>
          <Typography
            className={`${classes.textCommonColor} ${classes.fontText14}`}
            component={"span"}
          >
            {`Teachers Dashboard `}
          </Typography>
          <Typography
            className={`${classes.primaryColor5} ${classes.fontText14}`}
            component={"span"}
          ></Typography>
        </Box>
        <Box marginBottom={"17px"}>
          <Box marginBottom={"13px"}>
            <Typography
              className={`${classes.textPrimaryColor} ${classes.fontText32} ${classes.fontBold600}`}
              component={"span"}
            >
              Teachers Dashboard
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"flex-start"}>
            <CustomSelectDropdown
              fieldId="select-dropdown-class"
              fieldValue={this.state.selectedTeacherClassDDValue}
              fieldName="selectedTeacherClassDDValue"
              handleChange={this.handleChangeDropdownValue}
              renderValue={this.renderGradingComponenttDDValue}
              fieldError={false}
              fieldMenuItems={this.state.classesList}
              fieldStyles={selectDropdownStyles}
            />
          </Box>
        </Box>

        <Box className={classes.topBtnRoot}>
          <Typography
            className={`${classes.textSecondaryColor} ${classes.pageTitle}`}
          >
            Course Progress
          </Typography>
          <CustomButtonWeb
            btnStyle={{
              borderRadius: "12px",
              marginRight: "30px",
            }}
            btnText="Catch Up"
            handleButtonClick={this.handleModalOPen}
            buttonId="teacher-dashboard-catch-up"
            buttonVariant="contained"
            isDisabled={false}
            type={"button"}
          />
        </Box>

        <Grid item xs={12}>
          <Grid container direction="row">
            {teacherCoursesList?.length > 0
              ? teacherCoursesList.map((courseItem, index: number) => (
                  <Grid key={courseItem.id} item xs={12} sm={6} lg={4}>
                    <Box
                      data-test-id={`student-subject-card-${index}`}
                      onClick={() => this.handleSelectCourse(courseItem?.id)}
                      className={getSelectedCourseBoxClass(courseItem?.id)}
                    >
                      <Box
                        display="flex"
                        justifyContent={"center"}
                        flexDirection="column"
                      >
                        {/* Subject Icon & name */}
                        <Box display={"flex"} alignItems="center">
                          <Box
                            mr={"10px"}
                            bgcolor={courseItem?.subjectBgColor}
                            className={classes.subjectLogoBg}
                          >
                            <CustomImageComponentWeb
                              altText="Subject Logo"
                              src={courseItem?.subjectIcon}
                              imgStyles={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                            />
                          </Box>
                          <Box textOverflow={"ellipsis"} whiteSpace={"nowrap"} overflow={"hidden"} display={"block"}>
                            <Tooltip title={courseItem.subjectName} placement="top">
                            <Typography
                              className={`${classes.textPrimaryColor} ${classes.fontText22} ${classes.commanFontSize}`}
                              component="span"
                            >
                              {courseItem.subjectName}
                            </Typography>
                            </Tooltip>
                          </Box>
                        </Box>
                        <Box
                          marginTop={"15px"}
                          display={"flex"}
                          alignItems="center"
                        >
                          <Box>
                            <Typography
                              className={`${classes.textCommonColor} ${classes.fontText18}`}
                              component="span"
                            >
                              Due on {courseItem?.dueDate}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          marginTop={"10px"}
                          display={"flex"}
                          alignItems="center"
                        >
                          <Box>
                            <Typography
                              className={`${classes.textCommonColor} ${classes.fontText18}`}
                              component="span"
                            >
                              Lessons :
                            </Typography>
                          </Box>
                          <Box ml={"4px"} display="flex" style={{ gap: "4px" }}>
                            <Typography
                              className={`${classes.primaryColor4} ${classes.fontText16}`}
                              component="span"
                            >
                              {courseItem.lessonsCovered}
                            </Typography>
                            <Typography
                              className={`${classes.textCommonColor} ${classes.fontText16}`}
                              component="span"
                            >
                              /
                            </Typography>
                            <Typography
                              className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                              component="span"
                            >
                              {courseItem.lessonsTotal}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          marginTop={"10px"}
                          display={"flex"}
                          alignItems="center"
                          justifyContent={"space-between"}
                        >
                          <Box>
                            <Typography
                              className={`${classes.primaryColor4} ${classes.fontText16} ${classes.fontBold500}`}
                              component="span"
                            >
                              {`${courseItem.completionProgress}% completed`}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              style={{
                                color: getAvgScoreColor(courseItem.status),
                              }}
                              className={`${classes.fontText14} ${
                                classes.fontBold500
                              } ${getAvgScoreColor(courseItem.status)}`}
                              component="h5"
                            >
                              {`${courseItem.status}`}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))
              : ""}
          </Grid>
        </Grid>
        <Box
          display={"flex"}
          flexDirection={"row"}
          className={classes.container}
        >
          <Box className={classes.firstGrid}>
            <Box className={classes.progresstableHeader} mb={2}>
              <Typography
                data-test-id="Progress"
                className={`${classes.textSecondaryColor} ${classes.tableTitle}`}
              >
                {" "}
                Progress
              </Typography>
              <Hidden only={["xs", "sm"]}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  style={{ width: "58%" }}
                >
                  <Box
                    width={20}
                    height={20}
                    data-test-id="Completed-box"
                    style={{ backgroundColor: "#01C996", borderRadius: "4px" }}
                  ></Box>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText14}`}
                    data-test-id="Completed"
                    component={"span"}
                  >
                    {`Completed`}
                  </Typography>
                  <Box
                    height={20}
                    width={20}
                    data-test-id="Remaining-box"
                    style={{ backgroundColor: "#0D46BC", borderRadius: "4px" }}
                  ></Box>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText14}`}
                    data-test-id="Remaining"
                    component={"span"}
                  >
                    {`Remaining`}
                  </Typography>
                  <Box
                    width={20}
                    data-test-id="Not-Tackled-box"
                    height={20}
                    style={{ backgroundColor: "#FBD82C", borderRadius: "4px" }}
                  ></Box>
                  <Typography
                    data-test-id="Not-Tackled-box"
                    className={`${classes.textCommonColor} ${classes.fontText14}`}
                    component={"span"}
                  >
                    {`Not Tackled`}
                  </Typography>
                </Box>
              </Hidden>
              <Typography
                className={`${classes.fontText14} ${classes.fontBold600} ${classes.textButton} ${classes.cursorPointer}`}
                component={"span"}
                onClick={() => this.handleViewActivityList("Progress")}
              >
                {`View All`}
              </Typography>
            </Box>
            <Box
              maxHeight={"450px"}
              overflow={"auto"}
              className={`${classes.bgColor} ${classes.customTableStyles}`}
            >
              <CustomTable
                tableId={"teacher-dashboard-students-progress"}
                tableHeaderColumns={tenantSubjectColumn}
                tableBodyData={progressStudentsTableBody}
                showMenuIcon={false}
              />
            </Box>
          </Box>
          <Box className={classes.firstGrid2}>
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent="space-between"
              style={{ width: "98%" }}
              mb={1.5}
            >
              <Typography
                className={`${classes.textSecondaryColor} ${classes.tableTitle}`}
              >
                {" "}
                Performance
              </Typography>
              <Typography
                className={`${classes.fontText14} ${classes.fontBold600} ${classes.textButton} ${classes.cursorPointer}`}
                style={{ marginLeft: "9px" }}
                component={"span"}
                onClick={() => this.handleViewActivityList("Performance")}
              >
                {`View All`}
              </Typography>
            </Box>
            <Box
              className={`${classes.bgColor} ${classes.customTableStyles}`}
              mt={"16px"}
              maxHeight={"450px"}
              overflow={"auto"}
            >
              <CustomTable
                tableId={"teacher-dashboard-students-performance"}
                tableHeaderColumns={progressTableColumns}
                tableBodyData={progressStudentsTableBody}
                showMenuIcon={false}
              />
            </Box>
          </Box>
        </Box>
        <Box mt={4.5} style={{ width: "99%" }}>
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent="space-between"
            style={{ width: "99%" }}
            mb={1.5}
          >
            <Typography
              className={`${classes.textSecondaryColor} ${classes.tableTitle}`}
            >
              {" "}
              Assigned Assessments
            </Typography>
            <Typography
              className={`${classes.fontText14} ${classes.fontBold600} ${classes.textButton} ${classes.cursorPointer}`}
              component={"span"}
              onClick={() => this.handleViewActivityListt("Assinedassessment")}
            >
              {`View All`}
            </Typography>
          </Box>
          <Box
            className={`${classes.bgColor} ${classes.customTableStyles} ${classes.assignedAssessments}`}
          >
            <CustomTable
              tableId={"teacher-assigned-assessments-table"}
              tableHeaderColumns={assignedTableColumns}
              tableBodyData={assignedAssessmentsList}
              showMenuIcon={false}
            />
          </Box>
        </Box>
        <CatchMeUpModal
          open={this.state.openModelBox}
          onCancel={this.onCatchUpCancel}
          onConfirm={this.onCatchUpCancel}
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const selectDropdownStyles = {
  width: "200px",
  borderRadius: "8px",
  height: "48px",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherDashboard);
// Customizable Area End
