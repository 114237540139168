// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData  } from "../../../framework/src/Utilities";
import { IDropdownMenuItemProps, ITableActionBtnConfigProps } from "../../../components/src/CommonType.web";
import { restrictedRoles, roleOptionsMock } from "../assets/tenantRolesAndPermissionsMock.web";
import { displayApiErrorMsg, getMessageData, handleInvalidToken, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
import moment from "moment";
const configJSON = require("./config.js");
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  rolesPermissionsList: Array<{
    id: string;
    name: string;
    base_role:string;
    modifiedOn:string;
  }>;
  loading: boolean;
  selectedRoleTitleDDValue: any;
  roleTitles: Array<any>;
  roleTitleDDMenuItems: Array<IDropdownMenuItemProps>; 
  tableActionBtnConfig: Array<ITableActionBtnConfigProps>;
  openDeleteConfirmModal: boolean;
  selectedRoleFromList: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TenantRolesAndPermissionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetRolesPermission:string = "";
  apiDeleteRolesPermission:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      rolesPermissionsList: [],
      loading: false,
      selectedRoleTitleDDValue: "",
      roleTitles: [],
      roleTitleDDMenuItems: roleOptionsMock,      
      tableActionBtnConfig: [],
      openDeleteConfirmModal: false,
      selectedRoleFromList: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    handleInvalidToken(this.props.navigation);
    this.handleSetTableActionConfig();
    this.handleGetRolesandPermissionApi();
    // Customizable Area End
  }

  // Customizable Area Start


  handleGetRolesPermissionResponse = (responseJson: {
    data: Array<{
      id: string;
      attributes: {
        name: string;       
        base_role: string;
        updated_at:number;        
      };
    }>;
    errors: Array<{}>;
  }) => {
    if (responseJson.data) {
      const courseList = responseJson.data.map((item) => {
        const teacherCourse = item.attributes;       
        

        return {
          id: item.id,
          name: teacherCourse.name,
          base_role:teacherCourse.base_role,        
          modifiedOn:moment(teacherCourse.updated_at).format("MMMM DD, YYYY")      
        };
      });
      const transformedRoles =  responseJson.data.filter(role => !role.attributes.base_role)
      .map(role => {
        const name = role.attributes.name;
        return {
          id: name.toLowerCase(),
          label: name,
          value: name.toLowerCase()
        };
      });
      transformedRoles.push({
        id:"Tenant Admin".toLowerCase(),
        label: "Tenant Admin",
        value: "Tenant Admin".toLowerCase()
      })
      this.setState({ rolesPermissionsList: courseList,roleTitleDDMenuItems:transformedRoles });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleGetRolesandPermissionApi = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = { token };

    let requestUrl = configJSON.getrolesandpermissionEndPoint;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetRolesPermission = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = getMessageData(
        message
      );

      if (apiRequestCallId != null) {

        if (apiRequestCallId === this.apiGetRolesPermission) {
        this.handleGetRolesPermissionResponse(responseJson);
       
      }
      else if (apiRequestCallId === this.apiDeleteRolesPermission) {
        this.handleDeleteRolesPermissionResponse(responseJson);
       
      }
    }
    }
  } 

  handleSetTableActionConfig = () => {
    this.setState({
      tableActionBtnConfig: [
        {
          label: "View",
          action: (value: any) => this.handleViewRolePermission(value),
        },
        {
          label: "Edit",
          action: (value: any) => this.handleEditRolesPermission(value),
        },
        {
          label: "Delete",
          action: (value: any) => this.handleDeleteRolePermission(value),
        },
      ],
    });
  };

  handleEditRolesPermission = (value: any) => {
    const { rolesPermissionsList } = this.state;
    const selectedRole:any = rolesPermissionsList.find((item: any) => parseInt(item.id) === parseInt(value))
    if (restrictedRoles.includes(selectedRole?.name)) {
      toast.error("Default roles cannot be edited.");
    }else{
    this.props.navigation?.navigate("EditTenantRolesAndPermissions", {}, {
      roleId: selectedRole?.id
    });
  }
  }

  handleViewRolePermission = (value: any) => {
    const { rolesPermissionsList } = this.state;
    const selectedRole:any = rolesPermissionsList.find((item: any) => parseInt(item.id) === parseInt(value))
    if (restrictedRoles.includes(selectedRole?.name)) {
      toast.error("Default roles cannot be viewed.");
    }
    else{
    this.props.navigation?.navigate("ViewTenantRolesAndPermissions", {}, {
      roleId: selectedRole?.id
    });
    }

  }

  handleDeleteRolePermission = (value: any) => {
    const { rolesPermissionsList } = this.state;
    const selectedRole:any = rolesPermissionsList.find((item: any) => parseInt(item.id) === parseInt(value))
    if (restrictedRoles.includes(selectedRole?.name)) {
      toast.error("Default roles cannot be deleted.");
    }else{
    this.setState({ selectedRoleFromList: selectedRole }, () => {
      this.setState({ openDeleteConfirmModal: true })
    })
  }
  }

  handleCloseDeletePopup = () => {
    this.setState({ openDeleteConfirmModal: false, selectedRoleFromList: "" })
  }

  handleAddNewRole = () => {
    this.props.navigation?.navigate("AddNewTenantRolesAndPermissions");
  }

  renderRoleDDMenuItem = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Role Title";
    }
    const { roleTitleDDMenuItems } = this.state;
    const selectedItem = roleTitleDDMenuItems.find(
      (item: IDropdownMenuItemProps) => item.value === selected
    );
    return selectedItem?.label;
  };

  handleSelectRoleDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  handleDeleteRolesPermissionResponse = (responseJson: {
    message:string
    errors: Array<{}>;
  }) => {
    if (responseJson.message) {
      toast.success(responseJson?.message)
      this.handleCloseDeletePopup()
      this.handleGetRolesandPermissionApi()
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleDeleteRolesandPermissionApi = async (selectedId:any) => {
    const token = await getStorageData("token");
    const header = { token };

    let requestUrl = `${configJSON.getrolesandpermissionEndPoint}/${selectedId}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiDeleteRolesPermission = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  // Customizable Area End
}
// Customizable Area End
