// Customizable Area Start
import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomTable, {
  tableColumnTypes,
} from "../../../components/src/CustomTable.web";
import TenantAdminSetupClassManagementController, {
  Props,
} from "./TenantAdminSetupClassManagementController.web";
import NewClassDialogWeb from "./NewClassDialog.web";
import CustomButtonWeb from "../../../components/src/CustomButton.web";
import Pagination from "@material-ui/lab/Pagination";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";
import RemoveStudentDialogWeb from "./RemoveStudentDialog.web";
import DeleteConfirmDialogWeb from "./DeleteConfirmDialog.web";
import CustomTextField from "../../../components/src/CustomTextField.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    searchBox: {
      display: "flex",
      alignItems: "flex-end",
      padding: "24px",
    },
    paginationBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 20px 10px 21px",
    },
    inputLabel: {
      fontSize: 14,
      fontWeight: 400,
      color: "#888888",
      marginBottom: "6px",
      marginTop: "10px",
    },
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    searchBtnGrid: {
      display: "flex", 
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        marginTop: "20px"
      }
    },
  });

const classMngTableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
        paddingRight: "0px !important",
        paddingLeft: "0px !important",
      },
      head: {
        padding: "16px 0px",
      },
    },
  },
});

// Customizable Area End

// Customizable Area Start
export class TenantAdminSetupClassManagement extends TenantAdminSetupClassManagementController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, openNewClassManagementDialog } = this.props;
    const {
      selectGrade,
      selectGradeItems,
      selectSchoolYear,
      selectSchoolYearItems,
      customFieldsList,
      classTitleMenuList,
      classTitleList,
      selectedClassTitle,
      openEditClassManagementDialog,
      currentPage,
      perPageRecords,
      totalNumberOfClassRecords,
      selectSubjectsBasedOnGradeItems,
      selectCurriculumsBasedOnGradeSubjectItems,
      selectedClassDetails,
      availableStudentsList,
      selectedStudentsList,
      showLoaderInDialog,
      openRemoveStudentConfirmPopup,
      selectedRemovedStudent,
      classDetailsFromStep1,
      openDeleteClassDialog,
      classesTableActionButtonConfig,
      classManagementTableHeader,
      classesCustomFieldConfigList,
    } = this.state;
    return (
      <Box style={{ paddingTop: "1px" }} className={`${classes.bgColor}`}>
        <Grid container className={`${classes.searchBox}`}>
          <Grid item lg={2} md={6} sm={12}>
            <Box style={{ marginRight: "20px" }}>
              <Typography className={classes.inputLabel}>Class Name</Typography>
              <CustomSelectDropdown
                fieldId="select-dropdown-class-title"
                fieldValue={selectedClassTitle}
                fieldName="selectedClassTitle"
                handleChange={this.handleChangeDropDownValue}
                renderValue={this.renderClassName}
                fieldMenuItems={classTitleMenuList}
                fieldStyles={{ width: "96%" }}
                placeHolderText="Select Teacher"
              />
            </Box>
          </Grid>
          <Grid item lg={2} md={6} sm={12}>
            <Box style={{ marginRight: "20px" }}>
              <Typography className={classes.inputLabel}>Grade</Typography>
              <CustomSelectDropdown
                fieldId="select-dropdown-class-grade"
                fieldValue={selectGrade}
                fieldName="selectGrade"
                handleChange={this.handleChangeDropDownValue}
                renderValue={this.renderGrade}
                fieldMenuItems={selectGradeItems}
                fieldStyles={{ width: "96%" }}
                placeHolderText="Select Grade"
              />
            </Box>
          </Grid>
          <Grid item lg={2} md={6} sm={12}>
            <Box style={{ marginRight: "20px" }}>
              <Typography className={classes.inputLabel}>
                School Year Title
              </Typography>
              <CustomSelectDropdown
                fieldId="select-dropdown-class-school-year-title"
                fieldValue={selectSchoolYear}
                fieldName="selectSchoolYear"
                handleChange={this.handleChangeDropDownValue}
                renderValue={this.renderSchoolYear}
                fieldMenuItems={selectSchoolYearItems}
                fieldStyles={{ width: "96%" }}
                placeHolderText="Select Option"
              />
            </Box>
          </Grid>
          {/* Custom Fields */}
          {classesCustomFieldConfigList?.map((classesCustomFieldItem) => (
            <Grid
              key={`${classesCustomFieldItem?.label}-${classesCustomFieldItem?.id}`}
              item
              lg={3}
              md={6}
              sm={12}
            >
              <Box style={{ marginRight: "20px" }}>
                <Typography className={classes.inputLabel}>
                  {classesCustomFieldItem?.label}
                </Typography>
                <CustomTextField
                  fieldName={classesCustomFieldItem?.name}
                  fieldWidth={"100%%"}
                  fieldId={`class-mng-custom-field-${classesCustomFieldItem?.id}`}
                  fieldValue={classesCustomFieldItem?.value}
                  isDisabled={classesCustomFieldItem?.isDisabled}
                  textFieldStyles={textfieldStyles}
                  placeHolderText={classesCustomFieldItem?.placeHolderText}
                  fieldType="text"
                  handleChange={(e: any) =>
                    this.handleClassCustomFieldTextChange(e)
                  }
                />
              </Box>
            </Grid>
          ))}
          <Grid
            item
            lg={2}
            md={6}
            sm={12}
            className={`${classes.searchBtnGrid}`}
          >
            <CustomButtonWeb
              buttonId="setup-class-filter-search"
              buttonVariant="contained"
              btnStyle={{
                padding: "10px 10px",
                width: "130px",
                fontSize: "16px",
                fontWeight: 600,
                borderRadius: "10px",
                textAlign: "center",
              }}
              btnText="Search"
              handleButtonClick={() => this.handleGetClassesList(true)}
              isDisabled={false}
              type={"button"}
            />
          </Grid>
        </Grid>
        <Box
          style={{ backgroundColor: "#F1F1F1", height: "4px", width: "100%" }}
        />
        {classTitleList?.length > 0 ? (
          <>
            <Box
              className={`${classes.bgColor} ${classes.classManagementTable}`}
              borderBottom={"1px solid rgba(224, 224, 224, 1)"}
              p={"0px 20px"}
            >
              <ThemeProvider theme={classMngTableTheme}>
                <CustomTable
                  tableId={"tenant-setup-classes-table"}
                  tableHeaderColumns={classManagementTableHeader}
                  tableBodyData={classTitleList}
                  showMenuIcon={true}
                  actionButtonConfig={classesTableActionButtonConfig}
                />
              </ThemeProvider>
            </Box>
            <Box className={classes.paginationBox}>
              <Box width={"50%"} marginTop={"20px"}>
                <Box display={"flex"} gridGap={"6px"}>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText18}`}
                    component="span"
                  >
                    Records
                  </Typography>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold600}`}
                    component="span"
                  >
                    {totalNumberOfClassRecords > perPageRecords
                      ? `${currentPage * perPageRecords - perPageRecords}-
                ${currentPage * perPageRecords}`
                      : `${
                          currentPage * totalNumberOfClassRecords -
                          totalNumberOfClassRecords
                        }-
                ${currentPage * totalNumberOfClassRecords}`}
                  </Typography>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText18}`}
                    component="span"
                  >
                    of {totalNumberOfClassRecords}
                  </Typography>
                </Box>
              </Box>
              {totalNumberOfClassRecords > perPageRecords && (
                <Box className={classes.paginationRoot}>
                  <Pagination
                    count={Math.ceil(
                      totalNumberOfClassRecords / perPageRecords
                    )}
                    variant="outlined"
                    shape="rounded"
                    boundaryCount={1}
                    siblingCount={0}
                    page={currentPage}
                    onChange={this.handlePageChange}
                  />
                </Box>
              )}
            </Box>
          </>
        ) : (
          <CustomEmptyResultComponent message="No Records yet." />
        )}
        {(openNewClassManagementDialog || openEditClassManagementDialog) && (
          <NewClassDialogWeb
            open={openNewClassManagementDialog || openEditClassManagementDialog}
            handleClose={() => this.handleCloseClassManagementDialog()}
            classDetails={selectedClassDetails}
            curriculumListBasedOnGradeSubject={
              selectCurriculumsBasedOnGradeSubjectItems
            }
            customFields={customFieldsList}
            gradesList={selectGradeItems}
            isEdit={openEditClassManagementDialog}
            schoolYears={selectSchoolYearItems}
            showLoader={showLoaderInDialog}
            subjectsListBasedOnGrade={selectSubjectsBasedOnGradeItems}
            handleAddNewClassDetails={(isSaveAsDraft?: boolean) =>
              this.handleAddNewClass(isSaveAsDraft) 
            }
            handleUpdateClassDetails={(isSaveAsDraft?: boolean) =>
              this.handleUpdateClass(isSaveAsDraft)
            }
            handleGetCurriculumsBasedOnGradeSubjects={(
              grade: any,
              subjectIds: any
            ) => this.handleGetCurriculumsList(grade, subjectIds)}
            handleGetSubjectsBasedOnGrade={(grade: any) =>
              this.handleGetSubjectsList(grade)
            }
            handleGetStudentsList={(gradeId: any, curriculumId: any) =>{
              this.handleGetStudents(gradeId, curriculumId)
            }
            }
            handleUpdateAddedClassDetails={(details: any) =>
              this.handleUpdateAddedClassDetails(details)
            }
            studentsAvailable={availableStudentsList}
            studentsSelected={selectedStudentsList}
          />
        )}
        <RemoveStudentDialogWeb
          open={openRemoveStudentConfirmPopup}
          handleClose={this.handleCloseRemoveStudentConfirmPopup}
          selectedClassTitle={classDetailsFromStep1?.title}
          selectedStudentDetails={selectedRemovedStudent}
          handleConfirm={() => this.handleRemoveStudentFromSelectedList()}
        />
        <DeleteConfirmDialogWeb
          headingTitle="Class"
          open={openDeleteClassDialog}
          onCancel={() => this.handleCloseDeleteClassConfirmModal()}
          itemTitle={selectedClassDetails?.className}
          onConfirm={() => this.handleDeleteClass()}
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const textfieldStyles = {
  width: "96%",
  padding: "8px 0px",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TenantAdminSetupClassManagement);
// Customizable Area End
