// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import MyPathItemCardController, {
  Props,
} from "./MyPathItemCardController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CalendarIcon from "../../../components/src/SVGIcons/CalendarIcon.web";
import moment from "moment";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    courseMainBox: {
      padding: "15px",
      width: "88%",
      marginBottom: "5%",
      borderRadius: "12px",
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        width: "85%",
      },
    },
    subjectLogoBg: {
      width: "55px",
      height: "55px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2px",
      flexShrink:0
    },
    myPathItemCardHeight: {
      height: "185px",
      [theme.breakpoints.down("sm")]: {
        height: "225px",
      },
    },
    itemGrid: {
      display: "flex",
      justifyContent: "center",
    },
    myPathTopic: {
      padding: "8px 2px 5px 8px",
    },
    leftMargin: {
      marginLeft: "3px",
    },
    myPathScore: {
      position: "absolute",
      top: "14px",
      right: "8px",
      [theme.breakpoints.down("sm")]: {
        top: "2px",
      },
    },
    myPathTitle: {
      fontSize: "20px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
      },
    },
    topMargin: {
      marginTop: "8px",
    },
  });

// Customizable Area End

// Customizable Area Start
export class MyPathItemCard extends MyPathItemCardController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, myPathItem, myPathCourseCategory } = this.props;

    return (
      <Grid
        data-testid={`my-path-course-card-${myPathItem?.id}`}
        className={`${classes.itemGrid}`}
        item
        xs={12}
        sm={6}
        lg={4}
      >
        <Box
          position={"relative"}
          className={`${classes.courseMainBox} ${classes.cardBgColor02} ${classes.myPathItemCardHeight}`}
        >
          <Box display="flex" justifyContent={"center"} flexDirection="column">
            {/* Subject Icon & name */}
            <Box display={"flex"} alignItems="center">
              <Box
                mr={"10px"}
                bgcolor={myPathItem?.subjectBgColor}
                className={classes.subjectLogoBg}
              >
                <img 
                  src={myPathItem?.subjectIcon} 
                  style={{ width: "30px",
                  height: "30px",
                  objectFit: "contain"
                }}
                  alt="Subject Logo" 
                />
              </Box>
              <Box>
                <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.myPathTitle}`}
                  component="span"
                >
                  {myPathItem?.assessmentTitle}
                </Typography>
              </Box>
            </Box>
            <Box
              position={"relative"}
              className={classes.myPathTopic}
              display={"flex"}
              flexDirection={"column"}
            >
              <Box
                className={classes.topMargin}
                display={"flex"}
              >
                <Typography
                  className={`${classes.textLightGreyColor} ${classes.fontText16}`}
                >
                  Topic:{" "}
                </Typography>
                <Typography
                  className={`${classes.textCommonColor} ${classes.leftMargin} ${classes.fontText16} ${classes.fontBold600}`}
                >
                  {myPathItem?.topicName}
                </Typography>
              </Box>
              <Box
                className={classes.topMargin}
                display={"flex"}
                alignItems="center"
              >
                <Typography
                  className={`${classes.textLightGreyColor} ${classes.fontText16}`}
                >
                  Course:{" "}
                </Typography>
                <Typography
                  className={`${classes.textCommonColor} ${classes.leftMargin} ${classes.fontText16} ${classes.fontBold600}`}
                >
                  {myPathItem?.courseName}
                </Typography>
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <Box
                  className={classes.topMargin}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <CalendarIcon
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    stroke={"#0D46BC"}
                    strokeWidth="1.5"
                    fill={"none"}
                  />
                  <Typography
                    component={"span"}
                    className={`${classes.textLightGreyColor} ${classes.fontText16} ${classes.leftMargin}`}
                  >
                    {myPathCourseCategory === "review"
                      ? "Last Attempt:"
                      : "Due:"}
                  </Typography>
                  <Typography
                    component={"span"}
                    className={
                      myPathCourseCategory === "overdue"
                        ? `${classes.textPrimaryColor} ${classes.leftMargin} ${classes.fontBold600} ${classes.fontText16}`
                        : `${classes.textCommonColor} ${classes.leftMargin} ${classes.fontBold600} ${classes.fontText16}`
                    }
                  >
                    {myPathCourseCategory !== "review" &&
                    moment(myPathItem?.dueDate, "DD-MM-YYYY").format(
                      "MMM DD, yyyy"
                    )}
                    {myPathCourseCategory === "review" && (myPathItem?.submittedDate !== "N/A"
                    ? moment(myPathItem?.submittedDate, "DD-MM-YYYY").format("MMM DD, yyyy")
                    : "N/A")}
                  
                  </Typography>
                </Box>
              </Box>
              {myPathCourseCategory === "review" && (
                <Box className={classes.myPathScore}>
                  <Typography
                    component={"span"}
                    className={`${classes.primaryColor6} ${classes.leftMargin} ${classes.fontText18} ${classes.fontBold600}`}
                  >
                    {myPathItem?.percentage === 100 || myPathItem?.percentage === 0 
                    ? `${myPathItem?.percentage}%` 
                    : `${myPathItem?.percentage?.toFixed(2)}%`}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(MyPathItemCard);
// Customizable Area End
