// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Grid,
  Tooltip,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
} from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import GradebookGradesTabController, {
  ITableProps,
  Props,
} from "./GradebookGradesTabController";

import Checkbox from '@material-ui/core/Checkbox';

import { combineStyles } from "../CombineStyles.web";
import { customThemeStyles } from "../CustomThemeStyles.web";
import { alert } from "./assets";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    myGradebookMainContainer: {
      overflowX: "auto",
      maxHeight: "100%",
      padding: "15px 20px",
      borderRadius: "10px",
    },
    gradeMainBox: {
      width: "100%",
      display: "flex",
    },
    subjectCoursesTableBox: {
      // padding: "15px 0px 15px 16px",
      display: "flex",
      // width: "85%",
      borderRadius: "12px",
      overflow: "auto",
      maxHeight: "600px",
      // [theme.breakpoints.down("xs")]: {
      //   width: "85% !important",
      // },
    },
    subjectLogoBg: {
      height: "40px",
      width: "40px",
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      padding: "2px",
      alignItems: "center",
    },
    subjectLogo: {
      objectFit: "contain",
      height: "30px",
      width: "30px",
    },
    gradeTableHeadingRow: {
      alignItems: "center",
      display: "flex",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    subjectItemRow: {
      flexDirection: "column",
      display: "flex",
    },
    courseItemRow: {
      flexDirection: "column",
      display: "flex",
      "&:nth-last-child(n + 2)": {
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
      },
    },
    cell: {
      padding: "10px 16px 16px 16px",
      textTransform: "capitalize"
    },
    bodyCellPadding: {
      padding: "10px 16px",
    },
    subjectTitleCell: {
      display: "flex",
      alignItems: "center",
      minWidth: 230,
      maxWidth: 230
      
    },
    courseTitleCell: {
      display: "flex",
      alignItems: "center",
      minWidth: 230,
      maxWidth: 230
    },
    weightCell: {
      display: "flex",
      alignItems: "center",
      minWidth: 230,
      maxWidth: 230
    },
    courseQuaterCell: {
      display: "flex",
      alignItems: "center",
      width:"100%"
    },
    courseQuaterCell2: {
      display: "flex",
      alignItems: "center",
      minWidth: "95px",
      maxWidth: "95px",
    },
    courseTotalAvgCell: {
      display: "flex",
      alignItems: "center",
      minWidth: 100,
      maxWidth: 100
    },
    subjectName: {
      overflow: "hidden",
      display: "block",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    quarterCell: {
      display: "flex",
      alignItems: "center",
     width:"100%"
    },
    quarterCellGrade: {
      display: "flex",
      alignItems: "center",
      minWidth: 150,
      maxWidth: 150
    },
    totalAvgCell: {
      display: "flex",
      alignItems: "center",
      minWidth: 100,
      maxWidth: 100
      // width:"100%"
    },
    progressCell: {
      display: "flex",
      alignItems: "center",
      minWidth: 230,
  maxWidth: 230
   
    },
    totalCell: {
      display: "flex",
      width: "100px",
      paddingTop: "23px !important",
      alignItems: "center",
      paddingRight: "0px !important",
      paddingLeft: "13px !important",
      paddingBottom: "20px !important",
    },
    totalGradeCell: {
      alignItems: "flex-start",
      display: "flex",
      "&:nth-last-child(n + 2)": {
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
      },
    },
    subjectCoursesDetailsTable: {
      // width: "94%",
      overflowX: "hidden",
      marginTop: "15px",
      marginBottom: "12px",
    },
    show: {
      transition: "display 0.3s ease-out",
      display: "block",
    },
    hide: {
      transition: "display 0.3s ease-in",
      display: "none",
    },
    avgBox: {
      borderRadius: "10px",
      padding: "20px 20px",
      flexDirection: "column",
      width: "92%",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "20px",
        width: "auto",
      },
    },
    cellWithCheckbox: {
      display: "flex",
      flexFlow: "column",
      "& .MuiCheckbox-colorPrimary.Mui-checked": {
        color: "#FFD92E",
      },
      "& .MuiCheckbox-root": {
        color: "#888"
      }
    },
    highlightBar: {
      width: "96px",
      background: "#CCF4EA",
      opacity: "0.3",
      borderRadius: "12px",
      position: "absolute",
      left:-15,

    },
    customWidth: {
      maxWidth: '150px',
      color: '#ED1111',
      padding: '12px 30px 12px 6px',
      backgroundColor: '#FFE4E4',
    },
    borderRight: {
      borderRight: "1px solid rgba(224, 224, 224, 1)",
    },
    dividerLine: {
      height: "1px",
      background: "#F1F1F1",
      marginTop: "25px",
      marginBottom: "25px",
      marginLeft: "-40px",
      marginRight: "-40px",
      width: "100%",
      left: "40px",
      position: "relative",
    },
    gradeTextColor: {
      color: "#01C996"
    },
    tableContainer: {
      // width:"100%",
      overflowY: "hidden",
      position: "relative",
    },
  });

const tableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingBottom: "20px !important",
        paddingTop: "20px !important",
        paddingLeft: "10px !important",
        paddingRight: "10px !important",
      },
      head: {
        padding: "16px 10px",
      },
    },
  },
});
// Customizable Area End

// Customizable Area Start
export class GradebookGradesTab extends GradebookGradesTabController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      selectedSubjectId,
      schoolSubjectsList,
      subjectTableColumns,
      selectedSubjectCoursesGradesDetails,
      subjectCoursesDetailsTableColumns,
      highlightBarHeight,
      selectedHighlightBar,
    } = this.state;
    const { classes } = this.props;
    
    const getStatusColor = (status: string) => {
      switch (status) {
          case "open":
              return `${classes.fontSize18} ${classes.capitalText} ${classes.textPrimaryColor}`;

          case "waiting_teacher_approval":
              return `${classes.fontSize18} ${classes.capitalText} ${classes.primaryColor17}`;

          case "waiting_registrar_approval":
              return `${classes.fontSize18} ${classes.capitalText} ${classes.primaryColor18}`;

          case "lockedin":
              return `${classes.fontSize18} ${classes.capitalText} ${classes.primaryColor4}`;

          case "rejected":
              return `${classes.fontSize18} ${classes.capitalText} ${classes.primaryColor19}`;
      }
  };

    return (
      <Box height={"100%"}  className={`${classes.bgColor}`}>
        <Grid direction="row" container >
          <Grid xs={12} item>
            <Box  className={`${classes.myGradebookMainContainer} ${classes.bgColor}`}>
              <Box className={`${classes.gradeMainBox}`}>
                <TableContainer  className={classes.tableContainer}>
                  <Table  ref={this.tableRef} >
                   
                    <TableHead ref={this.rowRef}>
                      <TableRow  className={`${classes.gradeTableHeadingRow}`}>
                       
                        {this.props.subjectTableColumns?.map((heading: any) => (
                          <TableCell className={`${heading.id === "totalAvg" ? classes.borderRight : ''}`} key={heading.id}
                          style={{ minWidth: heading.width, maxWidth: heading.maxWidth, padding: "0px 16px 16px 16px",width:"100%",position:'relative' }}>
                            <Box
                              className={`${classes.cellWithCheckbox}`}
                              key={heading.columnId}
                              width={"100%"}
                             
                              // position="relative"
                    // width={"100%"}
                    // display="flex"
                    // flexDirection="column"
                    // alignItems="center"
                    // justifyContent="center"
                            >
                              <Box height={"25px"} width={"100%"} mb={"25px"}    
                    //           display="flex"
                    // flexDirection="column"
                    // alignItems="center"
                    // justifyContent="center" 
                    // textAlign={"center"}
                    // position="relative"
                    // top={0}
                    >
                                {
                          heading.isSemester ? (
                                    <>
                                      <Checkbox
                                        checked={this.props.selectedHighlightBar.includes(heading.columnId)}
                                        color="primary"
                                        onChange={(event: any) => this.props.handleHighlightBar(event, heading.columnId)}
                                        inputProps={{ 'aria-label': 'semester checkbox' }}
                                      />
                                      {
                                        this.props.selectedHighlightBar.includes(heading.columnId) ?
                                          <Box style={{height:`${this.state.tableHeight}px`}} className={classes.highlightBar}></Box>
                                          : ''
                                      }
                                    </>
                          )
                                    : ''
                                }
                              </Box>
                              <Box>
                                <Typography
                                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                                  component={"span"}
                                >
                                  {heading.label}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.schoolSubjectsList.map((subjectItem: any) => {
                       const username =  "User"; 
                       const rejectionMessage = this.getRejectedQuartersAndMessage(subjectItem, username);
                      return (
                        <TableRow className={classes.subjectItemRow} key={subjectItem.subjectId}>
                          <Box display={"flex"}>
                            <TableCell  
                            className={`${classes.subjectTitleCell} 
                            ${classes.bodyCellPadding}`}>
                              <Box
                                className={classes.subjectLogoBg}
                                bgcolor={subjectItem?.subject?.bgColor}
                                mr={"10px"}
                              >
                                <img
                                  src={subjectItem?.subject?.icon}
                                  alt="Subject Logo"
                                  className={classes.subjectLogo}
                                />
                              </Box>
                              <Box className={classes.subjectName}>
                                <Tooltip
                                  placement="top"
                                  title={subjectItem?.subject?.name}
                                >
                                  <Typography
                                    component="span"
                                    className={`${classes.fontText15} ${classes.textPrimaryColor}`}
                                  >
                                    {subjectItem?.subject?.name}
                                  </Typography>
                                </Tooltip>
                              </Box>
                              <Box
                                onClick={() =>
                                  this.props.handleSetSelectedSchoolSubject(
                                    subjectItem.subjectId
                                  )
                                }
                                data-testid={`subject-course-details-expand-${subjectItem.subjectId}`}
                              >
                                {subjectItem.subjectId === this.props.selectedSubjectId ? (
                                  <RemoveIcon className={`${classes.cursorPointer} ${classes.textCommonColor}`} />
                                ) : (
                                  <AddIcon className={`${classes.textCommonColor} ${classes.cursorPointer}`} />
                                )}
                              </Box>
                            
                              {(!this.props.isRegistrar && rejectionMessage) && (

                                  <Box ml={"4px"}>
                                    <Tooltip classes={{ tooltip: classes.customWidth }} placement="right" title={rejectionMessage}>
                                      <img src={alert} />
                                    </Tooltip>
                                  </Box> 
                              )}
                                {(this.props.isRegistrar && subjectItem.errorMessage) && (

                              <Box ml={"4px"}>
                                <Tooltip classes={{ tooltip: classes.customWidth }} placement="right" title={subjectItem.errorMessage}>
                                  <img src={alert} />
                                </Tooltip>
                              </Box> 
                              )}
                              
                            </TableCell>
                            <TableCell  className={`${classes.progressCell} ${classes.bodyCellPadding}`}>
                              <Box id="gradebook-progress-bar" display={'flex'} alignItems="center">
                            <Typography component="span" style={{ fontSize: "15px", marginRight: "10px", width: "45px" }} className={classes.textPrimaryColor}>{subjectItem?.progress}</Typography>
                            <Box minWidth={120} maxWidth={120} display={'flex'} mr={1} style={{ background: "#D2D2D2", borderRadius: "6px",overflow:"hidden" }}>
                                <Box width={`${subjectItem?.progress}`} height={30} style={{ backgroundColor: '#01C996', borderTopLeftRadius: '7px', borderBottomLeftRadius: '7px' }}></Box>
                            </Box>
                        </Box>
                            </TableCell >
                            {Object.keys(subjectItem) 
                            .filter(key => key.startsWith('quarterScore') && !key.endsWith('semesterId') && !key.endsWith('Status')) 
                            .map((quarterKey, index) => (
                              <TableCell key={index}  className={`${classes.quarterCell} ${classes.bodyCellPadding}`}>
                                <Typography component="span" className={getStatusColor(subjectItem[`${quarterKey}Status`])} >
                                  {subjectItem[quarterKey]}
                                </Typography>
                              </TableCell>
                            ))}                       
                            <TableCell className={`${classes.borderRight} ${classes.totalAvgCell} ${classes.bodyCellPadding}`}>
                              <Typography
                                component="span"
                                className={`${classes.fontText20} ${classes.fontBold500} ${classes.textPrimaryColor}`}
                              >
                                {subjectItem?.totalScore}
                              </Typography>
                            </TableCell>
                            <TableCell className={`${classes.quarterCellGrade} ${classes.fontBold500} ${classes.bodyCellPadding}`}>
                              <Typography
                                component="span"
                                className={`${classes.fontText20} ${classes.textPrimaryColor}`}
                              >
                                {subjectItem?.grade}
                              </Typography>
                            </TableCell>
                          </Box>
                          <Box
                          style={{width:`${this.state.rowWidth}px`}}
                            className={
                              subjectItem.subjectId === this.props.selectedSubjectId
                                ? `${classes.show} ${classes.subjectCoursesDetailsTable} ${classes.subjectCoursesTableBox} ${classes.secondaryBgColor}`
                                : `${classes.hide} ${classes.subjectCoursesDetailsTable} ${classes.subjectCoursesTableBox} ${classes.secondaryBgColor}`
                            }
                          >

                            {/* table colum start */}
                            <Box className={`${classes.gradeTableHeadingRow}`}>
                              {this.props.subjectCoursesDetailsTableColumns.map((column: any) => (
                                
                                <Box className={`${classes.cell} ${classes.textSecondaryColor}`}
                                  key={column.columnId}
                                  minWidth={column.width}
                                  maxWidth={column.maxWidth}
                                  width={"100%"}
                                  >
                                  <Typography className={`${classes.textCommonColor} ${classes.fontText16}`}>
                                    {column.label}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                            {this.props.selectedSubjectCoursesGradesDetails.map((item: any) => (
                              <Box className={classes.courseItemRow} key={item.id}>
                                <Box display={"flex"}>
                                  <Box className={`${classes.courseTitleCell} ${classes.cell}`}>
                                    <Typography component="span" className={`${classes.textPrimaryColor} ${classes.fontText15}`}>
                                      {item?.title}
                                    </Typography>
                                  </Box>
                                  <Box className={`${classes.weightCell} ${classes.cell}`}>
                                    <Typography component="span" className={`${classes.textPrimaryColor} ${classes.fontText15}`}>
                                      {item?.weight}
                                    </Typography>
                                  </Box>
                                  {Object.keys(item).map((key) => {
                                    if (key.startsWith('quarter')) {
                                      return (
                                        <Box key={key} className={`${classes.cell} ${classes.courseQuaterCell}`}>
                                          <Typography component="span" className={`${classes.textPrimaryColor} ${classes.fontText15}`}>
                                            {item[key]}
                                          </Typography>
                                        </Box>
                                      );
                                    }
                                    return null;
                                  })}
                                  <Box className={`${classes.courseTotalAvgCell} ${classes.cell}`}>
                                    <Typography component="span" className={`${classes.textPrimaryColor} ${classes.fontText15}`}>
                                      {item?.totalScore}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </TableRow>
                      )
  })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.dividerLine} />
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid sm={12} xs={12} md={6} style={{ padding: "25px" }} item>
                <Box className={`${classes.secondaryBgColor} ${classes.avgBox}`}>
                  <Typography
                    className={`${classes.fontBold500} ${classes.textPrimaryColor} ${classes.fontText24}`}
                    component={"span"}
                  >
                    Total Grade Point Average
                  </Typography>
                  <Typography
                    className={`${classes.primaryColor4} ${classes.fontBold400}`}
                    style={{ fontSize: "46px" }}
                    component={"span"}
                  >
                    {this.props.totalAvgOfAllCourses}%
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(GradebookGradesTab);
// Customizable Area End
