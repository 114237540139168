// Customizable Area Start
import React from "react";
import { Box, Typography, Grid, Hidden } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import AssessmentItemCardController, {
  Props
} from "./AssessmentItemCardController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomNoDataComponent from "../../../components/src/CustomNoDataComponent.web";
import CalendarIcon from "../../../components/src/SVGIcons/CalendarIcon.web";
import moment from "moment";
import CustomButton from "../../../components/src/CustomButton.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import Pagination from "@material-ui/lab/Pagination";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    subjectLogoBg: {
      width: "55px",
      height: "55px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2px",
      flexShrink:0
    },
    courseMainBox: {
      padding: "15px",
      width: "88%",
      marginBottom: "5%",
      borderRadius: "12px",
      cursor: "pointer",
      [theme.breakpoints.down('md')]: {
        width: "85%",
      }
    },
    assessmentHeightCardBig:{
      height:"255px",
      [theme.breakpoints.down('sm')]: {
        height:"295px",
      },
    },
    assessmentHeightCardSmall:{
      height:"200px",
      [theme.breakpoints.down('xs')]: {
        height:"210px",
      },
    },
    itemGrid:{
      display:"flex",
      justifyContent:"center"
    },
    assessmentTopic:{
      padding:"8px 2px 5px 8px"
    },
    leftMargin:{
      marginLeft:"3px"
    },
    assessmentScore:{
      position:"absolute",
      top:"14px",
      right:"8px",
      [theme.breakpoints.down('sm')]: {
        top:"2px",
      },
    },
    assessmentTitle:{
      fontSize:"20px",
      [theme.breakpoints.down('xs')]: {
        fontSize:"16px",
      },
    },
    assessmentCategoryTag:{
      position:"absolute",
      top:"8px",
      right:"0px",
      minWidth:"62px",
      width:"fit-content",
      borderTopLeftRadius:"8px",
      borderBottomLeftRadius:"8px",
      padding:"5px 3px",
      backgroundColor:"#FDB06A",
      color:"#ffffff",
      textAlign:"center",
      textTransform:"capitalize"
    },
    topMargin:{
      marginTop:"8px"
    },
    dropdownGrid:{
      gap:"12px",
    }
  });

// Customizable Area End

// Customizable Area Start
export class AssessmentItemCard extends AssessmentItemCardController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  renderBoxItem(classes: { topMargin: string | undefined; textCommonColor: any; fontText16: any; leftMargin: any; fontBold600: any; assessmentScore: string | undefined; primaryColor5: any; fontText18: any; }, assessmentCategory: string, assessmentItem: { points: number; score: number; assignedDate: moment.MomentInput; dueDate: moment.MomentInput; submittedDate: moment.MomentInput;GradeOn:moment.MomentInput }) {
    return (
      <>
        <Box className={classes.topMargin} display={"flex"} alignItems="center">
          <Typography className={`${classes.textCommonColor} ${classes.fontText16}`}>Points: </Typography>
          {assessmentCategory !== "graded" ?
            <Typography className={`${classes.textCommonColor} ${classes.leftMargin} ${classes.fontText16} ${classes.fontBold600}`}>{assessmentItem?.points}</Typography>
            :
            <Typography className={`${classes.textCommonColor} ${classes.leftMargin} ${classes.fontText16} ${classes.fontBold600}`}>{assessmentItem?.score}/{assessmentItem?.points}</Typography>
          }
        </Box>
        <Box display={"flex"} flexDirection={"column"}>
          {assessmentCategory === "completed" &&
            <Box className={classes.topMargin} display={"flex"} alignItems={"center"}>
              <CalendarIcon width="22" height="22" viewBox="0 0 22 22" stroke={"#0D46BC"} strokeWidth="1.5" fill={"none"} />
              <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText16} ${classes.leftMargin}`}>Assigned:</Typography>
              <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText16} ${classes.leftMargin}`}>{moment(assessmentItem?.assignedDate, "DD-MM-YYYY").format("MMM DD, yyyy")}</Typography>
            </Box>
          }
          {assessmentCategory !== "graded" ?
          <Box className={classes.topMargin} display={"flex"} alignItems={"center"}>
            <CalendarIcon width="22" height="22" viewBox="0 0 22 22" stroke={"#0D46BC"} strokeWidth="1.5" fill={"none"} />
            <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText16} ${classes.leftMargin}`}>Due:</Typography>
            <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText16} ${classes.leftMargin}`}>{moment(assessmentItem?.dueDate, "DD-MM-YYYY").format("MMM DD, yyyy")}</Typography>
          </Box>
          :
          <Box className={classes.topMargin} display={"flex"} alignItems={"center"}>
          <CalendarIcon width="22" height="22" viewBox="0 0 22 22" stroke={"#0D46BC"} strokeWidth="1.5" fill={"none"} />
          <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText16} ${classes.leftMargin}`}>Graded On:</Typography>
          <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText16} ${classes.leftMargin}`}>{moment(assessmentItem?.GradeOn, "DD-MM-YYYY").format("MMM DD, yyyy")}</Typography>
        </Box>
          }
          {assessmentCategory === "completed" &&
            <Box className={classes.topMargin} display={"flex"} alignItems={"center"}>
              <CalendarIcon width="22" height="22" viewBox="0 0 22 22" stroke={"#0D46BC"} strokeWidth="1.5" fill={"none"} />
              <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText16} ${classes.leftMargin}`}>Submitted on:</Typography>
              <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText16} ${classes.leftMargin}`}>{moment(assessmentItem?.submittedDate, "DD-MM-YYYY").format("MMM DD, yyyy")}</Typography>
            </Box>
          }
        </Box>
        {assessmentCategory === "graded" &&
          <Box className={classes.assessmentScore}>
            <Typography component={"span"} className={`${classes.primaryColor5} ${classes.fontText18} ${classes.leftMargin} ${classes.fontBold600}`}>{(assessmentItem?.score / assessmentItem?.points) * 100 === 100
              ? '100%'
              : `${((assessmentItem?.score / assessmentItem?.points) * 100).toFixed(2)}%`}</Typography>
          </Box>
        }
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, assessmentList , assessmentCategory,paginationDueList } = this.props;

    return (
      <Grid container direction="row">
        <Grid item xs={12}>
          {
            assessmentCategory === "graded" &&
            <Box margin={"10px 10px 30px 10px"}>
              <Hidden only={["xs", "sm"]}>
                <Box className={classes.dropdownGrid} display={"flex"} alignItems={"flex-end"}>
                  <Box>
                    <Box marginBottom={"10px"}>
                      <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                        Grade Period
                      </Typography>
                    </Box>
                    <Box>
                      <CustomSelectDropdown
                        fieldId="select-dropdown-grade-assessment-option"
                        fieldValue={this.props.selectedGrade}
                        fieldName="selectedGrade"
                        handleChange={this.props.handleChangeDropdownValueGradedAssessment}
                        renderValue={this.props.renderGradeDDValue}
                        fieldError={false}
                        fieldMenuItems={this.props.gradeList}
                        fieldStyles={selectDropdownStyles}
                        iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Box marginBottom={"10px"}>
                      <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                        Sort By
                      </Typography>
                    </Box>
                    <Box>
                      <CustomSelectDropdown
                        fieldId="select-dropdown-graded-assessment-sorting"
                        fieldValue={this.props.selectedSortBy}
                        fieldMenuItems={this.props.sortByList}
                        fieldName="selectedSortBy"
                        handleChange={this.props.handleChangeDropdownValueGradedAssessment}
                        renderValue={this.props.renderSortDDValue}
                        fieldError={false}
                        fieldStyles={selectDropdownStyles}
                        iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <CustomButton btnText="Search" btnStyle={btnStyle} buttonId={`my-graded-assessment-search`} buttonVariant={"contained"} type={"button"} handleButtonClick={this.props.handleSearchGradedAssessment} isDisabled={false} />
                  </Box>
                </Box>
              </Hidden>
              <Hidden only={["md", "lg", "xl"]}>
                <Box marginBottom={"30px"} display={"flex"} flexDirection={"column"} justifyContent={"flex-start"}>
                  <Box marginBottom={"10px"}>
                    <Box marginBottom={"8px"}>
                      <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                        Grade Period
                      </Typography>
                    </Box>
                    <Box>
                      <CustomSelectDropdown
                        fieldValue={this.props.selectedGrade}
                        fieldId="select-dropdown-graded-assessment-mobile"
                        fieldName="selectedGrade"
                        renderValue={this.props.renderGradeDDValue}
                        handleChange={this.props.handleChangeDropdownValueGradedAssessment}
                        fieldError={false}
                        fieldStyles={selectDropdownStyles}
                        fieldMenuItems={this.props.gradeList}
                        iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                      />
                    </Box>
                  </Box>
                  <Box marginBottom={"10px"}>
                    <Box marginBottom={"8px"}>
                      <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                        Sort By
                      </Typography>
                    </Box>
                    <Box display={"flex"} justifyContent={"flex-start"}>
                      <CustomSelectDropdown
                        fieldId="select-dropdown-graded-assessment-sorting-mobile"
                        fieldValue={this.props.selectedSortBy}
                        fieldName="selectedSortBy"
                        handleChange={this.props.handleChangeDropdownValueGradedAssessment}
                        renderValue={this.props.renderSortDDValue}
                        iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                        fieldError={false}
                        fieldMenuItems={this.props.sortByList}
                        fieldStyles={selectDropdownStyles}
                      />
                    </Box>
                  </Box>
                  <Box marginBottom={"10px"}>
                      <CustomButton btnText="Search" buttonId={`my-graded-assessment-search-mobile`} buttonVariant={"contained"} type={"button"} handleButtonClick={this.props.handleSearchGradedAssessment}  btnStyle={btnStyle} isDisabled={false} />
                  </Box>
                </Box>
              </Hidden>
            </Box>
          }
        </Grid>
        {assessmentList?.length > 0 ? assessmentList.map((assessmentItem: any, index: number) =>
          <Grid
            key={assessmentItem?.id}
          data-testid={`assessment-card-${index+1}`} 
            onClick={assessmentItem?.evaluationType !== 'manual' ? () =>
              this.handleNavigateToAssessmentDueScreen(
              assessmentItem.assessmentTitle , 
                assessmentItem.typeOfAssessment,
                assessmentItem.evaluationType,
                assessmentItem.objectiveType,
              assessmentItem.id) :undefined
            }
            className={`${classes.itemGrid}`} item xs={12} sm={6} lg={4}>
            <Box position={"relative"} data-test-id={`student-subject-card-${index}`} className={assessmentCategory === "completed" ? `${classes.courseMainBox} ${classes.cardBgColor02} ${classes.assessmentHeightCardBig}` : `${classes.courseMainBox} ${classes.cardBgColor02} ${classes.assessmentHeightCardSmall}`}>
              <Box display="flex" justifyContent={"center"} flexDirection="column" >
                {/* Subject Icon & name */}
                <Box display={"flex"} alignItems="center">
                  <Box mr={"10px"} bgcolor={assessmentItem?.subjectBgColor} className={classes.subjectLogoBg}>
                    <img
                      style={{ width: "30px",
                        height: "30px", 
                        objectFit: "contain"
                      }}
                      src={assessmentItem?.subjectIcon}
                      alt="Subject Logo"
                    />
                  </Box>
                  <Box>
                    <Typography className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.assessmentTitle}`} component="span">
                      {assessmentItem?.assessmentTitle}
                    </Typography>
                  </Box>
                </Box>
                <Box position={"relative"} className={classes.assessmentTopic} display={"flex"} flexDirection={"column"}>
                  <Box className={classes.topMargin} display={"flex"} alignItems="center">
                    <Typography className={`${classes.textCommonColor} ${classes.fontText16}`}>Topic: </Typography>
                    <Typography className={`${classes.textCommonColor} ${classes.leftMargin} ${classes.fontText16} ${classes.fontBold600}`}>{assessmentItem?.topicName}</Typography>
                  </Box>
                  <Box className={classes.topMargin} display={"flex"} alignItems="center">
                    <Typography className={`${classes.textCommonColor} ${classes.fontText16}`}>Course: </Typography>
                    <Typography className={`${classes.textCommonColor} ${classes.leftMargin} ${classes.fontText16} ${classes.fontBold600}`}>{assessmentItem?.courseName}</Typography>
                  </Box>
                  {this.renderBoxItem(classes, assessmentCategory, assessmentItem)}
                </Box>
              </Box>
              <Box className={classes.assessmentCategoryTag}>
                <Typography component={"span"} className={`${classes.fontText14}`}>{assessmentItem?.typeOfAssessment}</Typography>
              </Box>
            </Box>
          </Grid>) :
          <CustomNoDataComponent noDataText="No assessments yet." />
        }
        <Grid item xs={12}>
          {console.log("hello world",paginationDueList)}
          {assessmentList.length > 0 &&
            <Box className={classes.paginationBox}>
              <Box className={classes.paginationRoot}>
                <Pagination
                  shape="rounded"
                  variant="outlined"
                  count={Math.ceil(paginationDueList?.total_count / paginationDueList?.per_page)}
                  siblingCount={0}
                  boundaryCount={1}
                  onChange={this.props.handlePaginationChange}
                  page={paginationDueList?.current_page}
                />
              </Box>
            </Box>
          }
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const btnStyle = {
  width:"130px",
  padding:"10px 10px",
  borderRadius:"10px",
  fontSize:"16px",
  fontWeight:600,
  textAlign:"center"
} as const;

const selectDropdownStyles={
  width:"235px"
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AssessmentItemCard);
// Customizable Area End
