import React from "react";

// Customizable Area Start
import { Grid, Box, Typography } from "@material-ui/core";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import ProgressRangeColorTabController, {
  IProgressRangeProps,
  Props,
} from "./ProgressRangeColorTabController.web";
import {
  FIELD_TYPES,
  IInputConfigProps,
} from "../../../components/src/CommonType.web";
import CustomTextFieldWeb from "../../../components/src/CustomTextField.web";
import CustomButtonWeb from "../../../components/src/CustomButton.web";
import CustomColorPickerFieldWeb from "../../../components/src/CustomColorPickerField.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    mainProgressRangeBox: {
      padding: "10px 20px",
    },
    textfieldBox: {
      width: "92%",
      [theme.breakpoints.down("xs")]: {
        width: "98%",
      },
    },
    progressRangeMainBox: {
      marginTop: "20px",
    },
    saveBtnBox: {
      display: "flex",
      justifyContent: "flex-end",
      paddingBottom: "15px",
      paddingTop: "100px"
    },
    labelBottomMargin: {
      marginBottom: "10px",
    },
    inputConfigItem: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: "15px",
      },
    },
  });
// Customizable Area End

export class ProgressRangeColorTab extends ProgressRangeColorTabController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { rangeList } = this.state;
    return (
      <Box className={`${classes.mainProgressRangeBox}`}>
        <Box>
          <Typography
            id="appearance-management-progress-range"
            className={`${classes.fontText18} ${classes.textCommonColor}`}
          >
            This range and color related to it represents progress of the course
            for the student.
          </Typography>
        </Box>
        {rangeList.map((rangeItem: IProgressRangeProps, indexOne: number) => (
          <Box
            className={`${classes.progressRangeMainBox}`}
            key={rangeItem.title}
          >
            <Box>
              <Typography
                id={`appearance-management-progress-range-label-${
                  rangeItem.title
                }`}
                className={`${classes.fontText18} ${classes.labelBottomMargin} ${classes.fontBold600} ${
                  classes.textPrimaryColor
                }`}
              >
                {rangeItem.title}
              </Typography>
            </Box>
            <Grid container direction="row">
              {rangeItem.inputConfigs.map((inputItem: IInputConfigProps) => (
                <Grid
                  className={`${classes.inputConfigItem}`}
                  key={inputItem.id}
                  item
                  xs={12}
                  sm={6}
                  md={3}
                >
                  <Box marginBottom={"10px"}>
                      <Typography
                        className={`${classes.textCommonColor} ${
                          classes.fontText16
                        }`}
                        id={`appearance-management-progress-range-${inputItem.id}`}
                        component={"span"}
                      >
                        {inputItem.label}
                      </Typography>
                    </Box>
                  <Box className={`${classes.textfieldBox}`}>
                    {inputItem.fieldType === FIELD_TYPES.TEXT && (
                      <CustomTextFieldWeb
                        fieldName={inputItem.fieldName}
                        fieldWidth={"100%"}
                        fieldId={inputItem.id}
                        fieldValue={inputItem.value}
                        isDisabled={inputItem.isDisabled}
                        textFieldStyles={textfieldStyles}
                        placeHolderText={inputItem.placeHolderText}
                        fieldType="text"
                        onKeyDown={this.handleKeyDown}
                        handleChange={(e: any) =>
                          this.handleTextChange(e, indexOne)
                        }
                      />
                    )}
                    {inputItem.fieldType === FIELD_TYPES.COLOR && (
                      <CustomColorPickerFieldWeb
                        fieldId={inputItem.id}
                        fieldName={inputItem.name}
                        handleColorChange={(color: string) =>
                          this.handleSetRangeColor(
                            color,
                            inputItem.name,
                            indexOne
                          )
                        }
                        fieldValueHexCode={inputItem.value}
                        fieldWidth="100%"
                        isDisabled={inputItem.isDisabled}
                        fieldValue={inputItem.fieldName}
                        placeHolderText={"Select Color"}
                        
                      />
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
         {this.state.error && (
          <p style={{ color: "red" }}>{this.state.error}</p>
        ) }
        <Box className={`${classes.saveBtnBox}`}>
          <CustomButtonWeb
            btnStyle={saveBtn}
            btnText="Save"
            buttonId="appearance-management-progress-range-save-btn"
            buttonVariant="contained"
            isDisabled={false}
            handleButtonClick={this.handleSaveDetails}
            type={"button"}
          />
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const textfieldStyles = {
  width: "100%",
  padding: "8px 0px",
} as const;

const saveBtn = {
  width: "160px",
  fontSize: "16px",
  padding: "10px 10px",
  fontWeight: 600,
  height: "45px",
  borderRadius: "10px",
  textAlign: "center",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ProgressRangeColorTab);
// Customizable Area End
