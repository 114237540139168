import React from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography, Modal } from '@material-ui/core';
import { customThemeStyles } from '../../../components/src/CustomThemeStyles.web';
import { combineStyles } from '../../../components/src/CombineStyles.web';
import AssignedDetailsModalController, {
  Props,
} from './AssignedDetailsModalController.web';
import CustomTable, {
  tableColumnTypes,
} from '../../../components/src/CustomTable.web';
import CustomButton from '../../../components/src/CustomButton.web';
import DeleteConfirmDialog from './DeleteConfirmDialog.web';
import MarkAsCompleteSuccessDialog from './MarkAsCompleteSuccessDialog.web';

export class AssignedDetailsModal extends AssignedDetailsModalController {
  constructor(props: Props) {
    super(props);
  }

  /**
   * Renders cofnrim dialog. Uses a generic confirm dialog
   */
  renderConfirmDialog = () => {
    const {
      catalog: { name: catalogName },
    } = this.props;
    const teacherName = this.getSelectedRow().teacher;

    const customDescription = (
      <>
        Are you sure you want to remove <b>{teacherName}</b> from{' '}
        <b>{catalogName}</b>
      </>
    );

    return (
      <DeleteConfirmDialog
        open={true}
        onCancel={this.hideConfirmDialog}
        onConfirm={this.onDeleteConfirm}
        itemTitle={''}
        headingTitle=""
        customHeading="Sure want to Remove Teacher?"
        customDescription={customDescription}
      />
    );
  };

  /**
   * Renders success dialog. Uses a generic success dialog
   */
  renderSuccessDialog = () => {
    const {
      catalog: { name: catalogName },
    } = this.props;
    const teacherName = this.getSelectedRow().teacher;

    const customDescription = (
      <>
        Teacher <b>{teacherName}</b> removed from <b>{catalogName}</b>{' '}
        successfully
      </>
    );

    return (
      <MarkAsCompleteSuccessDialog
        onCancel={this.onSuccessOkay}
        onConfirm={() => {}}
        open
        customHeading={`${this.getSelectedRow().teacher} Removed Successfully`}
        customDescription={customDescription}
      />
    );
  };

  render = () => {
    const { isOpen, handleClose, onAssignToTeacherClick } = this.props;
    const classes = this.props.classes as any;
    const { shouldRenderConfirmDialog, shouldRenderSuccessDialog } = this.state;

    if (shouldRenderConfirmDialog) return this.renderConfirmDialog();

    if (shouldRenderSuccessDialog) return this.renderSuccessDialog();

    return (
      <Modal
        open={isOpen}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClose={handleClose}
      >
        <Box
          className={`${classes.bgColor}`}
          sx={{
            width: 'fit-content',
            minWidth: '768px',
            maxWidth: '100vw',
            height: 'fit-content',
            maxHeight: '90vh',
            borderRadius: '8px',
            padding: '32px',
          }}
        >
          <Typography
            className={`${classes.fontText24} ${classes.fontBold600} ${classes.textPrimaryColor}`}
            component={'p'}
          >
            Assigned Details
          </Typography>

          {this.renderTable()}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '32px',
            }}
          >
            <CustomButton
              btnStyle={{
                fontWeight: 'bold',
              }}
              btnText="Assign to Teacher"
              buttonId="assignToTeacher"
              buttonVariant="text"
              isDisabled={false}
              handleButtonClick={onAssignToTeacherClick}
              type={'button'}
            />

            <Box sx={{ display: 'flex' }} style={{ gap: '16px' }}>
              <CustomButton
                btnStyle={{
                  fontWeight: 'bold',
                }}
                btnText="Cancel"
                buttonId="assignToTeacher"
                buttonVariant="outlined"
                isDisabled={false}
                handleButtonClick={handleClose}
                type={'button'}
              />
              <CustomButton
                btnStyle={{
                  fontWeight: 'bold',
                }}
                btnText="Done"
                buttonId="assignToTeacher3"
                buttonVariant="contained"
                isDisabled={false}
                handleButtonClick={handleClose}
                type={'button'}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };

  /**
   * Renders a table
   * @returns CustomTable
   */
  renderTable = () => {
    const tableColumns = [
      {
        columnId: 1,
        id: 'teacher',
        label: 'Teacher',
        type: tableColumnTypes.TEXT,
        width: '33%',
      },
      {
        columnId: 2,
        id: 'assignedOn',
        label: 'Assigned On',
        type: tableColumnTypes.TEXT,
        width: '33%',
        justifyContent: 'center',
        align: 'center',
      },
      {
        columnId: 3,
        id: 'action',
        label: 'Action',
        type: tableColumnTypes.BUTTON,
        width: '33%',
        justifyContent: 'flex-end',
        align: 'right',
      },
    ];

    const action = {
      btnStyle: {
        color: 'red',
        fontWeight: 'bold',
      },
      btnText: 'Remove',
      btnId: '',
      btnVariant: 'text',
      isDisable: false,
      btnType: 'button',
    };

    const { tableData } = this.state;

    const dataWithAction = tableData.map(datum => {
      const actionColumn = {
        action: {
          ...action,
          handleAction: () => {
            this.showConfirmDialog();
            this.selectRow(datum.id);
          },
        },
      };

      return {
        ...datum,
        ...actionColumn,
      };
    });

    return (
      <CustomTable
        showMenuIcon={false}
        tableHeaderColumns={tableColumns}
        tableBodyData={dataWithAction}
        tableId="assigned-details-modal"
      />
    );
  };
}

const styles = (theme: Theme) => createStyles({});
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AssignedDetailsModal);
