// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { displayApiErrorMsg, getFieldType, getMessageData, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import _ from "lodash";
import { IInputConfigProps } from "../../../components/src/CommonType.web";
const ntc = require("ntcjs");
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  isEdit?: boolean;
  handleUpdateCourse: any;
  handleAddNewCourse: any;
  courseId: number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedRow:any;
  selectedCourseType: string;
  openBrowseCatalogModal: boolean;
  selectedCurriculamDDValue: string;
  selectedSubjectDDValue: number;
  selectedGradeDDValue: number;
  selectedStatusDDValue: string;
  selectedSchoolYearDDValue: string;
  selectedTermDDValue: string;
  selectedCustomFieldDDValue: string;
  selectedIcon: any;
  selectedColor: string;
  selectedColorName: string;
  searchStudentTitle: string;
  token: string;
  schoolYearDropdownList: Array<any>;
  termDataList: Array<any>;
  curriculumDropdownList: Array<any>;
  subjectsDropdownList: Array<any>;
  gradesDropdownList: Array<any>;
  courseTitleValue: string;
  topicCountValue: number;
  activityCountValue: number;
  noOfLessonsADayValue: string;
  decriptionValue: string;
  statusDropdownList: Array<any>;
  courseTitleError: boolean;
  courseTitleErrorMsg: string;
  loading: boolean;
  statusError: boolean;
  statusErrorMsg: string;
  lessonPerDayError: boolean;
  lessonPerDayErrorMsg: string;
  customFieldsList: Array<any>;
  classesCustmFieldConfigList: Array<IInputConfigProps>;
  // classManagementTableHeader: Array<any>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherAddNewCourseController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetSchoolYearDropdownData: string = "";
  apiGetCurriculumDropdownData: string = "";
  apiGetSubjectsDropdownData: string = "";
  apiGetCourseDataById: string = "";
  apiGetGradesDropdownData: string = "";
  apiGetCustomFieldss: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      customFieldsList: [],
      classesCustmFieldConfigList: [],
      selectedRow:{},
      selectedCurriculamDDValue: "",
      selectedSubjectDDValue: -1,
      selectedGradeDDValue: -1,
      selectedStatusDDValue: "",
      selectedSchoolYearDDValue: "",
      selectedTermDDValue: "",
      selectedCustomFieldDDValue: "",
      selectedCourseType: "New",
      openBrowseCatalogModal: false,
      selectedIcon: "",
      selectedColor: "",
      selectedColorName: "",
      searchStudentTitle: "",
      token: "",
      schoolYearDropdownList: [],
      termDataList: [],
      curriculumDropdownList: [],
      subjectsDropdownList: [],
      gradesDropdownList: [],
      courseTitleValue: "",
      topicCountValue: 0,
      activityCountValue: 0,
      noOfLessonsADayValue: "",
      decriptionValue: "",
      statusDropdownList: [
        {
          label: "Active",
          value: "Active",
          id: "active",
        },
        {
          label: "Draft",
          value: "Draft",
          id: "draft",
        },
        {
          label: "Archived",
          value: "Archived",
          id: "archived",
        },
      ],
      courseTitleError: false,
      courseTitleErrorMsg: "",
      loading: false,
      statusError: false,
      statusErrorMsg: "",
      lessonPerDayError: false,
      lessonPerDayErrorMsg: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetCurriculumDropdownData:
            {
              this.handleCurriculumDropdownResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiGetSubjectsDropdownData:
            {
              this.handleSubjectsDropdownResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiGetSchoolYearDropdownData:
            {
              this.handleSchoolYearDropdownResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiGetCourseDataById:
            {
              this.handleGetCourseByIdResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiGetGradesDropdownData:
            {
              this.handleGradesDropdownResponse(
                responseJson,
                errorResponse
              );
            }
            break;
            case this.apiGetCustomFieldss:
            {
              this.handleGetClassCustomFieldDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;
        }
      }
    }
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.handleGetCustomFieldsListTwo();
    this.handleGetGradesDropdownListApi();
    this.handleGetSchoolYearDropdownList();
    if (this.props.isEdit) {
      this.handleGetCourseByIdApi(this.props.courseId);
    }
  }

  checkAndGetSelectedValue = (
    selected: any,
    dropdownName: string,
    dropdownItems: any
  ) => {
    if (!selected || selected.length === 0 || selected === -1) {
      return `Select ${dropdownName}`;
    }
    const selctedOption = dropdownItems.find(
      (item: any) => item?.value === selected
    );
    return selctedOption?.label;
  };

  renderCurriculamDDValues = (selected: any) => {
    const dropdownName = "Curriculam";
    const dropdownItems = this.state.curriculumDropdownList;
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderSubjectDDValues = (selected: any) => {
    const dropdownName = "Subject";
    const dropdownItems = this.state.subjectsDropdownList;
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  handleSetEditValues = () => {
    const { isEdit } = this.props;
    if (isEdit) {
      this.setState({
        selectedCourseType: "New",
        selectedCurriculamDDValue: "",
        selectedCustomFieldDDValue: "Custom Field1",
        selectedGradeDDValue: 1,
        selectedSchoolYearDDValue: "2021",
        selectedStatusDDValue: "Status1",
        selectedSubjectDDValue: 1,
        selectedTermDDValue: "03",
        searchStudentTitle: "Test",
      });
    }
  };

  handleClosePopup = () => {
    this.handleEmptyField();
    this.props.onCancel();
  };

  handleEmptyField = () => {
    this.setState({
      selectedCourseType: "",
      selectedCurriculamDDValue: "",
      selectedCustomFieldDDValue: "",
      selectedGradeDDValue: 1,
      selectedSchoolYearDDValue: "",
      selectedStatusDDValue: "",
      selectedSubjectDDValue: -1,
      selectedTermDDValue: "",
      searchStudentTitle: "",
    });
  };

  renderStatusDDValues = (selected: any) => {
    const dropdownName = "Status";
    const dropdownItems = this.state.statusDropdownList;
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderSchoolYearDDValue = (selected: any) => {
    const dropdownName = "School Year";
    const dropdownItems = this.state.schoolYearDropdownList;
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  setTermDataListForSelectedSchoolYear = (selected: string) => {
    let termDropdownList = [];
    if (this.state.schoolYearDropdownList.length > 0 && selected) {
      const termData = this.state.schoolYearDropdownList.find((item: any) => item.value === selected);
      if (termData?.term && termData?.term.length) {
        termDropdownList = termData.term.map(
          (item: any, index: number) => {
            return {
              id: _.uniqueId(item.id),
              value: item.id,
              label: item.title,
            };
          }
        );
      }
    }
    this.setState({ termDataList: termDropdownList });
  }

  renderTermDDValues = (selected: any) => {
    const dropdownName = "Term";
    const dropdownItems = this.state.termDataList;
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderCustomFieldDDValue = (selected: any) => {
    const dropdownName = "Custom Field";
    const dropdownItems = this.getDropdownItemArray("Custom Field");
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderGradeDDValues = (selected: any) => {
    const dropdownName = "Grade";
    const dropdownItems = this.state.gradesDropdownList;
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  setGradeDataListForSelectedSubject = (selected: string) => {
    let gradeDropdownList = [];
    if (this.state.subjectsDropdownList.length > 0 && selected) {
      const gradeData = this.state.subjectsDropdownList.find((item: any) => item.value === selected);
      if (gradeData?.grade && gradeData?.grade.length) {
        gradeDropdownList = gradeData.grade.map(
          (item: any, index: number) => {
            return {
              id: _.uniqueId(item.id),
              value: item.id,
              label: item.name,
            };
          }
        );
      }
    }
    this.setState({ gradesDropdownList: gradeDropdownList });
  }

  getDropdownItemArray = (dropdownName: string) => {
    const itemArray = [1, 2, 3, 4, 5];
    let result: Array<any> = [];
    itemArray.forEach((obj) => {
      result.push({
        id: obj,
        value: `${dropdownName}${obj}`,
        label: `${dropdownName}${obj}`,
      });
    });
    return result;
  };

  handleSetColor = (color: string) => {
    const n_match = ntc.name(color);
    this.setState({
      selectedColor: color,
      selectedColorName: n_match[1],
    });
  };

  handleSetFile = (file: any) => {
    this.setState({ selectedIcon: file?.name || "" });
  };

  handleCourseTypesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedCourseType: event.target.value });
  };

  handleOpenBrowseCourseCatalog = () => {
    this.setState({ openBrowseCatalogModal: true });
  };

  handleCloseBrowseCourseCatalog = () => {
    this.setState({ openBrowseCatalogModal: false });
  };

  handleDropdownValues = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });

      switch (name) {
        case "selectedSchoolYearDDValue":
          this.setTermDataListForSelectedSchoolYear(fieldValue);
          break;

        case "selectedGradeDDValue":
          this.handleGetSubjectsDropdownList({ grade_id: fieldValue });
          break;

        case "selectedSubjectDDValue":
          this.handleGetCurriculumDropdownList({ grade_id: this.state.selectedGradeDDValue, subject_id: fieldValue });
          break;

        default:
          break;
      }

    }
  };

  handleChange = (event: any) => {
    const { name, value } = event.target;
    switch (name) {
      case "courseTitleValue":
        if (value.length) {
          this.setState({ courseTitleError: false, courseTitleErrorMsg: "" })
        }
        break;

      case "selectedStatusDDValue":
        if (value.length) {
          this.setState({ statusError: false, statusErrorMsg: "" })
        }
        break;

      case "noOfLessonsADayValue":
        if (value.length) {
          this.setState({ lessonPerDayError: false, lessonPerDayErrorMsg: "" })
        }
        break;
    }  

    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  handleRequestBody = () => {
    if (this.handleFormValidation()) {
      const {
        courseTitleValue,
        selectedCurriculamDDValue,
        selectedSubjectDDValue,
        selectedGradeDDValue,
        topicCountValue,
        activityCountValue,
        noOfLessonsADayValue,
        selectedSchoolYearDDValue,
        selectedTermDDValue,
        decriptionValue,
        selectedStatusDDValue,
        subjectsDropdownList,
        gradesDropdownList,
      } = this.state;

      const requestBody = {
        payload: {
          courses: {
            course_title: courseTitleValue,
            curriculum_id: parseInt(selectedCurriculamDDValue),
            subject_id: selectedSubjectDDValue,
            grade_id: selectedGradeDDValue,
            topic_count: topicCountValue,
            activity_count: activityCountValue,
            no_of_lession_a_day: noOfLessonsADayValue,
            school_year_id: selectedSchoolYearDDValue,
            term: selectedTermDDValue,
            description: decriptionValue,
            custom_field_id: 1,
            status: selectedStatusDDValue,
          }
        },
        subjectName: subjectsDropdownList.find((item: any) => parseInt(item.value) === selectedSubjectDDValue)?.label,
        gradeName: gradesDropdownList.find((item: any) => (item.value).toString() === selectedGradeDDValue)?.label,
      };
      if (this.props.isEdit) {
        this.props.handleUpdateCourse(requestBody);
      } else {
        this.props.handleAddNewCourse(requestBody);
      }
    }
  }

  handleGetCurriculumDropdownList = async (query: any) => {
    this.showModalLoader();
    this.setState({ curriculumDropdownList: [] })
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCurriculumDropdownData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurriculumDropdownData + `?grade_id=${query.grade_id}&subject_id=[${query.subject_id}]`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetSubjectsDropdownList = async (query: any) => {
    this.showModalLoader();
    this.setState({ subjectsDropdownList: [], curriculumDropdownList: [] })
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSubjectsDropdownData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubjectsDropdwonData + `?grade_id=${query.grade_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetSchoolYearDropdownList = async () => {
    this.showModalLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetSchoolYearDropdownData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSchoolYearDropdownData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCurriculumDropdownResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const curriculumDropdownList = responseJson?.data?.map(
        (item: any) => {
          return {
            id: _.uniqueId(item.attributes.id),
            label: item.attributes.name,
            value: item.id,
          };
        }
      );
      this.setState({
        curriculumDropdownList: curriculumDropdownList || [],
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    this.hideModalLoader();
  };

  handleSubjectsDropdownResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const subjectsDDList = responseJson?.data?.map(
        (item: any, index: number) => {
          return {
            id: _.uniqueId(item.attributes.id),
            value: item.attributes.id,
            label: item.attributes.name,
            grade: item.attributes.grade,
          };
        }
      );
      this.setState({
        subjectsDropdownList: subjectsDDList || [],
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    this.hideModalLoader();
  };

  handleSchoolYearDropdownResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const schoolYearDDList = responseJson?.data?.map(
        (item: any, index: number) => {
          return {
            id: _.uniqueId(item.attributes.id),
            value: item.attributes.id,
            label: item.attributes.title,
            term: item.attributes.semesters,
          };
        }
      );
      this.setState({
        schoolYearDropdownList: schoolYearDDList || [],
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    this.hideModalLoader();
  };

  handleFormValidation = () => {
    let isValid = true;
    const {
      courseTitleValue,
      selectedStatusDDValue,
      noOfLessonsADayValue,
    } = this.state;

    if (!courseTitleValue.trim()) {
      this.setState({ courseTitleError: true, courseTitleErrorMsg: "Please enter course title" });
      isValid = false;
    }

    if (!selectedStatusDDValue.trim()) {
      this.setState({ statusError: true, statusErrorMsg: "Please select status" });
      isValid = false;
    }

    if (!noOfLessonsADayValue.trim()) {
      this.setState({ lessonPerDayError: true, lessonPerDayErrorMsg: "Please select status" });
      isValid = false;
    }


    return isValid;
  };

  showModalLoader = () => {
    this.setState({ loading: true });
  }

  hideModalLoader = () => {
    this.setState({ loading: false });
  }

  handleGetCourseByIdApi = async (courseId:number) => {
    this.showModalLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    let editCourseId = 0;

    if(courseId){
      editCourseId = courseId;
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCourseDataById = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCourseListData}/${editCourseId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetCourseByIdResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      const courseDataItem = responseJson?.data?.attributes;
      this.setState({
        courseTitleValue: courseDataItem?.course_title,
        selectedCurriculamDDValue: courseDataItem?.curriculum?.id.toString(),
        selectedSubjectDDValue: courseDataItem?.subject?.id,
        selectedGradeDDValue: (courseDataItem?.grade?.id).toString(),
        topicCountValue: courseDataItem?.topic_count,
        activityCountValue: courseDataItem?.activity_count,
        noOfLessonsADayValue: courseDataItem?.no_of_lession_a_day,
        selectedSchoolYearDDValue: courseDataItem?.school_year?.id,
        selectedTermDDValue: courseDataItem?.term,
        decriptionValue: courseDataItem?.description,
        selectedStatusDDValue: courseDataItem?.status,
        selectedColor: courseDataItem?.subject?.color,
        selectedIcon: courseDataItem?.subject?.filename,
      });

      if (courseDataItem?.subject?.color) {
        this.handleSetColor(courseDataItem?.subject?.color);
      }

      if (courseDataItem?.grade?.id) {
        this.handleGetSubjectsDropdownList({ grade_id: courseDataItem?.grade?.id });
      }

      if (courseDataItem?.grade?.id && courseDataItem?.subject?.id) {
        this.handleGetCurriculumDropdownList({ grade_id: courseDataItem?.grade?.id, subject_id: courseDataItem?.subject?.id });
      }

      if (courseDataItem?.school_year?.id) {
        this.setTermDataListForSelectedSchoolYear(courseDataItem?.school_year?.id);
      }
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    this.hideModalLoader();
  };

  handleGetGradesDropdownListApi = async () => {
    this.showModalLoader();
    const token = await getStorageData("token");
    const header = { token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetGradesDropdownData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGradesDropdownData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGradesDropdownResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const gradesList = responseJson?.data?.map(
        (item: any, index: number) => {
          return {
            id: _.uniqueId(item.id),
            value: item.id,
            label: item?.attributes.name,
          };
        }
      );
      this.setState({
        gradesDropdownList: gradesList,
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleSelectedRowId = (id:number) => {    
    this.handleCloseBrowseCourseCatalog();
    this.handleGetCourseByIdApi(id);    
  } 

  handleClassCustomFieldTextChange = (event: any) => {
    const { classesCustmFieldConfigList } = this.state;
    const { name, value } = event.target;
    const updatedInputConfig = classesCustmFieldConfigList.map((item) => {
      if (item.name === name) {
        item.value = value;
      }
      return item;
    });
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
        classesCustmFieldConfigList: updatedInputConfig,
      };
    });
  };

  handleGetClassCustomFieldDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      if (responseJson.data?.length) {
        this.setState({ customFieldsList: responseJson.data });
        const custmFields: Array<IInputConfigProps> = responseJson.data.map(
          (item: any, index: number) => {
            const obj = {
              id: item.id,
              name: `class-management-customField-${index + 1}`,
              fieldName: `class-management-customField-${index + 1}`,
              value: "",
              label: item?.attributes?.label,
              placeHolderText: `Please enter ${item?.attributes?.label}`,
              fieldType: getFieldType(item?.attributes?.custom_field_type),
              isDisabled: false,
            };
            return obj;
          }
        );       
        this.setState({
          classesCustmFieldConfigList: custmFields,          
        });
      }
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }    
  };


  handleGetCustomFieldsListTwo = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCustomFieldss = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customFieldEndpointTwo}?model_type=Course`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
// Customizable Area End
