// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import ArticleActivityTaskBox from "./ArticleActivityTaskBox.web";
import QuizActivityComponent from "./QuizActivityComponent.web";
import ExamActivityComponent from "./ExamActivityComponent.web";
import AssignmentActivityComponent from "./AssignmentActivityComponent.web";
import ProjectActivityComponent from "./ProjectActivityComponent.web";
import { Message } from "../../../framework/src/Message";
import {
  displayApiErrorMsg,
  getMessageData,
  handleGetOptionLetter,
  hideCustomLoader,
  showCustomLoader,
} from "../../../components/src/CommonHelper.web";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { TOASTER_NOTIFICATION_MSG } from "../../../components/src/CommonType.web";
import { toast } from "react-toastify";
import AssessmentActivityTaskBoxWeb from "./AssessmentActivityTaskBox.web";

const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  goBack: () => void;
  selectedTopic?: string;
  selectedLesson?: string;
  selectedActivity?: any;
  courseDetails?: any;
  activityList: Array<any>;
  openAddNewActivity: () => void;
  isFromTenant?: boolean;
  isStudent?: boolean;
  isFromRegistrar?: boolean;
  handleMenuAction: any;
  refreshSelectedActivity?: boolean;
  getSelectedActivityId?: any;
  handleMarkActivityAsCompleted: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  currentLessonActivityList: any;
  selectedTopic: any;
  selectedTopicId: any;
  selectedLesson: any;
  selectedtLessonId: any;
  selectedActivity: any;
  selectedActivityId: any;
  selectedActivityTaskDetail: any;
  selectedActivityTaskDetailId: any;
  questionsList: Array<any>;
  selectedQuestion: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class JourneyCourseActivityDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetActivityDetails: string = "";
  apiAddQuestion: string = "";
  apiUpdateQuestion: string = "";
  apiDeleteQuestion: string = "";
  apiGetQuestionsList: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      selectedActivity: null,
      selectedtLessonId: null,
      selectedLesson: null,
      selectedTopicId: null,
      selectedTopic: null,
      currentLessonActivityList: [],
      selectedActivityId: null,
      selectedActivityTaskDetailId: null,
      selectedActivityTaskDetail: null,
      questionsList: [],
      selectedQuestion: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);
      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetActivityDetails:
            {
              this.handleGetActivityDataByIdResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiGetQuestionsList:
            {
              this.handleGetQuestionsListDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiAddQuestion:
            {
              this.handlePostQuestionDataResponse(responseJson, errorResponse);
            }
            break;
          case this.apiDeleteQuestion:
            {
              this.handleDeleteQuestionResponse(responseJson);
            }
            break;
          case this.apiUpdateQuestion:
            {
              this.handleUpdateQuestionResponse(responseJson, responseJson);
            }
            break;
        }
      }
      // Customizable Area End
    }
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    const { activityList, selectedActivity } = this.props;
    this.handleResetValues();
    this.setState(
      {
        currentLessonActivityList: activityList,
        selectedActivity: selectedActivity,
        selectedActivityId: selectedActivity?.id,
      },
      () => {
        this.handleSelectActivityTasks(this.state.selectedActivityId);
      }
    );
    // Customizable Area End
  }

  handleResetValues = () => {
    this.setState({
      loading: false,
      selectedtLessonId: null,
      selectedLesson: null,
      selectedTopicId: null,
      selectedTopic: null,
      selectedActivityTaskDetailId: null,
      selectedActivityTaskDetail: null,
      questionsList: [],
      selectedQuestion: "",
    });
  };

  async componentDidUpdate(prevProps: any) {
    if (this.props.refreshSelectedActivity) {
      this.handleGetActivityByIdApi();
    }
  }

  handleGoBacks = () => {
    this.props.goBack();
  };

  handleSelectActivityTasks = (activityTaskId: any) => {
    this.setState({ selectedActivityTaskDetail: null });
    const {
      selectedActivityTaskDetailId,
      currentLessonActivityList,
      selectedActivityTaskDetail,
    } = this.state;
    const updatedCurrentLessonActivityLists = currentLessonActivityList.map(
      (itemTask: any) => {
        if (
          itemTask.id === selectedActivityTaskDetailId &&
          (itemTask.typeOfActivity === "audio" ||
            itemTask.typeOfActivity === "video")
        ) {
          itemTask.mediaProgress = selectedActivityTaskDetail?.mediaProgress;
        }
        return itemTask;
      }
    );

    const currentSelectedActivityTaskDetails: any = [];
    const selectedActivityObject = updatedCurrentLessonActivityLists.find(
      (item: any) => item.id === activityTaskId
    );
    
    this.setState(
      {
        selectedActivity: selectedActivityObject,
        selectedActivityId: activityTaskId,
        selectedActivityTaskDetailId: activityTaskId,
        currentLessonActivityList: updatedCurrentLessonActivityLists,
        selectedActivityTaskDetail: currentSelectedActivityTaskDetails,
      },
      () => {
        this.handleGetActivityByIdApi();
        this.props.getSelectedActivityId(selectedActivityObject);
        setStorageData("selectedActivityId", activityTaskId);
      }
    );
  };

  handleMarksActivityCompleted = async(timeSpent: number, isCompleted?: boolean) => {
    const { selectedActivityTaskDetail, currentLessonActivityList } =
      this.state;
    const activityId = await getStorageData("selectedActivityId");
    if (selectedActivityTaskDetail?.activityCompletionProgress !== 100) {
      const updatedActivityCompletionProgress = 100;
      const updatedActivity = {
        ...selectedActivityTaskDetail,
        activityCompletionProgress: updatedActivityCompletionProgress,
      };
      const updatedActivitiesList = currentLessonActivityList.map(
        (activity: any) => {
          if (
            parseInt(activity.id) ===
            parseInt(selectedActivityTaskDetail.activityId)
          ) {
            activity.activityCompletionProgress =
              updatedActivityCompletionProgress;
          }
          return activity;
        }
      );
      this.setState({
        currentLessonActivityList: updatedActivitiesList,
        selectedActivityTaskDetail: updatedActivity,
      });
      if(this.props.isStudent){
        this.props.handleMarkActivityAsCompleted(
          activityId,
          timeSpent,
          isCompleted
        );
      }
     
    }
  };

  handleUpdateAudioProgressTimes = (timeUpdate: any) => {
    const { selectedActivityTaskDetail } = this.state;
    const formattedTimeUpdate = Number(timeUpdate.toFixed(2));
    const updatedActivity = {
      ...selectedActivityTaskDetail,
      mediaProgress: formattedTimeUpdate,
    };
    this.setState({ selectedActivityTaskDetail: updatedActivity });
  };

  handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const reorderedActivities = Array.from(
      this.state.currentLessonActivityList
    );
    const [movedActivity] = reorderedActivities.splice(result.source.index, 1);
    reorderedActivities.splice(result.destination.index, 0, movedActivity);

    this.setState({ currentLessonActivityList: reorderedActivities });
  };

  handleActivityDetailsRenders = (
    selectedActivityTaskDetail: any,
    smallScreen: boolean
  ) => {
    const { questionsList } = this.state;
    const { navigation, isStudent, courseDetails, isFromRegistrar } = this.props;
    let isInDom: boolean = false;
    if (smallScreen) isInDom = selectedActivityTaskDetail?.expanded;

    let switchKey = selectedActivityTaskDetail?.typeOfActivity;
    if (selectedActivityTaskDetail?.typeOfActivity === "assessment") {
      switchKey = selectedActivityTaskDetail?.gradingComponent;
    }
    if (
      selectedActivityTaskDetail?.typeOfActivity === "task" ||
      selectedActivityTaskDetail?.typeOfActivity === "audio" ||
      selectedActivityTaskDetail?.typeOfActivity === "video"
    ) {
      switchKey = "taskActivity";
    }

    switch (switchKey) {
      case "quiz":
        return (
          <>
            {isStudent ? (
              <AssessmentActivityTaskBoxWeb
                detail={{
                  ...selectedActivityTaskDetail,
                  isCompleted: false,
                  courseDetails: courseDetails,
                }}
                navigation={this.props.navigation}
                isFromRegistrar={isFromRegistrar}
              />
            ) : (
              <QuizActivityComponent
                forTeacher={false}
                navigation={navigation}
                detail={selectedActivityTaskDetail}
                questionList={questionsList}
                setSelectedQuestion={this.setSelectedQuestion}
                handleAddQuestion={this.handleAddQuestion}
                handleUpdateQuestion={this.handleUpdateQuestion}
                handleDeleteQuestion={this.handleDeleteQuestion}
                isFromRegistrar={isFromRegistrar}
              />
            )}
          </>
        );

      case "exam":
        return (
          <>
            {isStudent ? (
              <AssessmentActivityTaskBoxWeb
                detail={{
                  ...selectedActivityTaskDetail,
                  isCompleted: false,
                  courseDetails: courseDetails,
                }}
                navigation={this.props.navigation}
                isFromRegistrar={isFromRegistrar}
              />
            ) : (
              <ExamActivityComponent
                forTeacher={false}
                navigation={navigation}
                detail={selectedActivityTaskDetail}
                questionList={questionsList}
                setSelectedQuestion={this.setSelectedQuestion}
                handleAddQuestion={this.handleAddQuestion}
                handleUpdateQuestion={this.handleUpdateQuestion}
                handleDeleteQuestion={this.handleDeleteQuestion}
                isFromRegistrar={isFromRegistrar}
              />
            )}
          </>
        );

      case "assignment":
        return (
          <>
            {isStudent ? (
              <AssessmentActivityTaskBoxWeb
                navigation={this.props.navigation}
                detail={{
                  ...selectedActivityTaskDetail,
                  isCompleted: false,
                  courseDetails: courseDetails,
                }}
                isFromRegistrar={isFromRegistrar}
              />
            ) : (
              <AssignmentActivityComponent
                detail={selectedActivityTaskDetail}
                navigation={navigation}
                forTeacher={false}
                questionList={questionsList}
                setSelectedQuestion={this.setSelectedQuestion}
                handleAddQuestion={this.handleAddQuestion}
                handleUpdateQuestion={this.handleUpdateQuestion}
                handleDeleteQuestion={this.handleDeleteQuestion}
                isFromRegistrar={isFromRegistrar}
              />
            )}
          </>
        );

      case "project":
        return (
          <>
            {isStudent ? (
              <AssessmentActivityTaskBoxWeb
                detail={{
                  ...selectedActivityTaskDetail,
                  courseDetails: courseDetails,
                  isCompleted: false,
                }}
                navigation={this.props.navigation}
                forTeacher={false}
                isFromRegistrar={isFromRegistrar}
              />
            ) : (
              <ProjectActivityComponent
                forTeacher={false}
                navigation={navigation}
                detail={selectedActivityTaskDetail}
                setSelectedQuestion={this.setSelectedQuestion}
                questionList={questionsList}
                handleAddQuestion={this.handleAddQuestion}
                handleUpdateQuestion={this.handleUpdateQuestion}
                handleDeleteQuestion={this.handleDeleteQuestion}
                isFromRegistrar={isFromRegistrar}
              />
            )}
          </>
        );

      case "taskActivity":
        return (
          <ArticleActivityTaskBox
            detail={selectedActivityTaskDetail}
            hideReadCheckbox={!isStudent}
            handleMarkActivityComplete={this.handleMarksActivityCompleted}
            isInDom={isInDom}
            isStudent={isStudent}
            handleUpdateMediaProgressTime={this.handleUpdateAudioProgressTimes}
          />
        );
      default: 
      return (<></>)
    }
  };

  handleAddNewActivityButtonClick = () => {
    this.props.openAddNewActivity();
  };

  handleMenuAction = (menu: any) => {
    this.props.handleMenuAction(menu);
  };

  handleGetActivityByIdApi = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetActivityDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postAddNewActivity}/${this.state.selectedActivityId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetActivityDataByIdResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    showCustomLoader();
    if (responseJson?.data) {
      const { isStudent } = this.props;
      const activity = responseJson?.data?.attributes;
      const activityDetail = {
        activityTitle: activity?.activities_title,
        typeOfActivity: activity?.activity_type,
        evaluationType: activity?.evolution_type,
        objectiveType: activity?.objective_type,
        gradingComponent: activity?.grading_component,
        maxScore: activity?.max_score,
        allowedAttempts: activity?.allowed_attempts || 0,
        dueDate: activity?.due_date,
        description: activity?.description,
        instruction: activity?.instruction,
        task: activity?.task,
        videoDescPlacement: activity?.video_desc_placement,
        audioDescPlacement: activity?.audio_desc_placement,
        duration: activity?.duration,
        showCorrectAnswer: activity?.correct_answer,
        mediaFile: activity?.media_file_url,
        xapiFile: activity?.xapi_file_url,
        lessonTitle: this.props.selectedLesson,
        activityId: parseInt(responseJson?.data?.id),
        totalMarks:activity?.student_activity?.total_marks || null,
        activityCompletionProgress: activity?.is_completed ? 100 : 0,
      };
      this.setState({ selectedActivityTaskDetail: activityDetail }, () => {
        if (!isStudent) {
          this.handleGetQuestionsListing();
        }
      });
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  setSelectedQuestion = (questionId: any) => {
    const { questionsList } = this.state;
    const selectedQuestion = questionsList?.find(
      (item: any) => parseInt(item.id) === parseInt(questionId)
    );
    this.setState({ selectedQuestion });
  };

  handleGetQuestionsListing = async () => {
    showCustomLoader();
    this.setState({ questionsList: [] });
    const { selectedActivityTaskDetail } = this.state;
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetQuestionsList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.questionEndpoint}?id=${selectedActivityTaskDetail.activityId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleAddQuestion = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddQuestion = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.questionEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleUpdateQuestion = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const { selectedQuestion } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateQuestion = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.questionEndpoint}/${selectedQuestion?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSetQuestionDataItem = (data: any) => {
    const optionsList: Array<any> = [];
    data?.attributes?.options?.forEach((item: any, index: number) => {
      const lastOptionLetter = optionsList[index - 1]?.optionLetter;
      const obj = {
        id: item?.id,
        optionId: item?.id,
        optionLetter: handleGetOptionLetter(index, lastOptionLetter),
        label: `Option ${handleGetOptionLetter(index, lastOptionLetter)}`,
        value: handleGetOptionLetter(index, lastOptionLetter),
        inputValue: item?.text,
        error: false,
        errorMsg: "",
      };
      optionsList.push(obj);
    });
    const questionObj = {
      question: data?.attributes?.question,
      id: data.id,
      options: optionsList,
      correctAnswer: data?.attributes?.correct_option,
      marks: data?.attributes?.marks,
      wordLimit: data?.attributes?.word_limit,
      explanation: data?.attributes?.explanation,
    };
    return questionObj;
  };

  handleSetQuestionData = (list: any) => {
    const questionsData = list?.map((item: any) =>
      this.handleSetQuestionDataItem(item)
    );
    this.setState({ questionsList: [...questionsData] });
  };

  handleGetQuestionsListDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      this.handleSetQuestionData(responseJson.data);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handlePostQuestionDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data?.attributes) {
      const { questionsList } = this.state;
      const addedSubjectItem = this.handleSetQuestionDataItem(
        responseJson.data
      );
      this.setState({
        questionsList: [...questionsList, { ...addedSubjectItem }],
      });
      toast.success(TOASTER_NOTIFICATION_MSG.QUESTION_CREATION_SUCCESS);
    } else if (responseJson?.errors) {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleUpdateQuestionResponse = (responseJson: any, errorResponse: any) => {
    const { questionsList } = this.state;
    if (responseJson?.data?.attributes) {
      const updatedQuestionItem: any = this.handleSetQuestionDataItem(
        responseJson.data
      );
      const updatedQuestionsList = questionsList.map((item) => {
        if (item.id === updatedQuestionItem.id) {
          item = { ...updatedQuestionItem };
        }
        return item;
      });
      this.setState({ questionsList: updatedQuestionsList });
      toast.success(TOASTER_NOTIFICATION_MSG.QUESTION_UPDATE_SUCCESS);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleDeleteQuestion = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const { selectedQuestion } = this.state;
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteQuestion = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.questionEndpoint}/${selectedQuestion.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDeleteQuestionResponse = (responseJson: any) => {
    const { questionsList, selectedQuestion } = this.state;
    if (!responseJson?.errors) {
      const updatedQuestionList = questionsList.filter(
        (item) => item.id !== selectedQuestion.id
      );
      this.setState({
        questionsList: updatedQuestionList,
        selectedQuestion: "",
      });
      toast.success(TOASTER_NOTIFICATION_MSG.QUESTION_DELETE_SUCCESS);
    } else {
      toast.error(TOASTER_NOTIFICATION_MSG.QUESTION_DELETE_FAILURE);
    }
    hideCustomLoader();
  };
  // Customizable Area End
}
