import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { displayApiErrorMsg, getMessageData, handleInvalidToken } from "../../../components/src/CommonHelper.web";
import { toast } from "react-toastify";

export const appearanceMngTabsList = [
  {
    id: 1,
    label: "Branding",
    value: "branding",
  },
  {
    id: 2,
    label: "Appearance & Themes",
    value: "appearance_themes",
  },
  {
    id: 3,
    label: "Subjects",
    value: "subjects",
  },
  {
    id: 4,
    label: "Progress Range & Color",
    value: "progress_range_color",
  },
];
export const configJSON = require("./config");

interface CustomizedData {
  email: string;
  address: string;
  company: string;
  website: string;
  contact_number: string;
}

interface ProgressRangeColour {
  to: number;
  from: number;
  colour: string;
}

interface LogoUrl {
  url: string;
  filename: string;
  content_type: string;
}

export interface ThemeAttributes {
  customized_branding: boolean;
  customized_data: CustomizedData;
  higlights_color: string;
  dark_mode: boolean;
  accent_color: string;
  progress_range_colour: ProgressRangeColour[];
  logo_url: LogoUrl;
}

export interface GetResponseTheme {
  id: string;
  type: string;
  attributes: ThemeAttributes;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTabIndex: number;
  themeData:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AppearanceManagementMainController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPostSubmitAppearance:string=""
  apiGetAppearanceDetailsId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionResponseToken),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedTabIndex: 0,
      themeData:{}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    handleInvalidToken(this.props.navigation);
    this.handleGetAppearance()
    this.getToken();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = getMessageData(
        message
      );
 
      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiPostSubmitAppearance:
              this.handleSubmitAppearanceResponse(responseJson)
              break;
          case this.apiGetAppearanceDetailsId:
              this.handleGetAppearanceResponse(responseJson)
        }
      }
    }
  }

  handleChangeAppearanceMngTab = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    this.setState({ selectedTabIndex: newValue });
  };

  handleUpdateTheme = async(themeType: string) => {
    if (themeType === "dark_mode") {
      await setStorageData("dark_mode", JSON.stringify(true));
    } else {
      await setStorageData("dark_mode", JSON.stringify(false));
    }
    window.dispatchEvent(new Event("storage"));
  };

  handleSubmitAppearance = async (requestBody:any) => {
   
    const token = await getStorageData("token");
    const header = {
      token,
      "Content-Type": configJSON.validationApiContentType,
    };

 
    let requestUrl = configJSON.SubmitAppearanceEndPoint;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostSubmitAppearance = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSubmitAppearanceResponse =async (responseJson: {
   data: any,
   errors: Array<{}>;
  }) => {
    if (responseJson?.data) {
    await setStorageData("appearanceTheme",JSON.stringify(responseJson.data.attributes))
    window.dispatchEvent(new Event('appearanceTheme'));
    this.handleGetAppearance()
    toast.success("updated successfully!");
} else {
  displayApiErrorMsg(responseJson?.errors, this.props.navigation);
}
  };

  handleGetAppearance = async () => {
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAppearanceDetailsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.SubmitAppearanceEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetAppearanceResponse = async(responseJson:{
    data:GetResponseTheme
  })=>{
    if(responseJson?.data){
      this.setState({themeData:responseJson.data.attributes})
    }
  }

 
  // Customizable Area End

  // Customizable Area Start

  // Customizable Area End
}
