// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getMessageData, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  detail: any;
  handleUpdateMediaProgressTime?: any;
  handleMarkActivityComplete: any;
  isInDom: boolean;
  hideReadCheckbox?: boolean;
  isStudent?: boolean;
  isFromRegistrar?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  openInfoDialog: boolean;
  articleAssignmentDetails: any;
  studentActivityAttemptTimeInSec: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ArticleActivityTaskBoxController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiArticleActivityDetails: string = "";
  activityTimer: any = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      loading: false,
      openInfoDialog: false,
      articleAssignmentDetails: "",
      studentActivityAttemptTimeInSec: 0,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleStartActivityTimer();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillUnmount() {
    clearInterval(this.activityTimer);
    this.handleMarkActivityComplete();
  }

  handleUpdateActivityTime = () => {
    this.setState((state) => ({
      studentActivityAttemptTimeInSec: state.studentActivityAttemptTimeInSec + 1,
    }));
  };

  handleStartActivityTimer = () => {
    this.activityTimer = setInterval(() => {
      this.handleUpdateActivityTime()
    }, 1000);
  }

  handleGetArticleAssignmentDetailsApi = async () => {
    showCustomLoader();
    const baseUrl = `articleAssignmentDetails`;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiArticleActivityDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      baseUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleArticleAssignmentDetailsResponse = (responseJson: any, errorResponse: any) => {
    this.setState({ articleAssignmentDetails: responseJson });
    console.log("article errorResponse", errorResponse)
  }
  
  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiArticleActivityDetails:
            this.handleArticleAssignmentDetailsResponse(responseJson, errorResponse)
        }
      }
    }
  }

  handleOpenInfoDialogs = () => {
    this.setState({ openInfoDialog: true })
  }

  handleCloseInfoDialogs = () => {
    this.setState({ openInfoDialog: false })
  }

  getClassToArrangeVideoContent = (detail: any) => {
    let videoPosition = '';
    let textPosition = '';
    const getPositions = this.setVideoAndTextPosition(detail);
    if (getPositions) {
      videoPosition = getPositions.videoPosition;
      textPosition = getPositions.textPosition;
    }


    const classes = this.props.classes;
    if (videoPosition === "bottom" && textPosition === "top") {
      return `${classes.articlePreviewContent} ${classes.videoBottomTextTop}`;
    } else if (videoPosition === "top" && textPosition === "bottom") {
      return `${classes.articlePreviewContent} ${classes.videoTopTextBottom}`;
    } else if (videoPosition === "right" && textPosition === "left") {
      return `${classes.articlePreviewContent} ${classes.videoRightTextLeft}`;
    } else if (videoPosition === "left" && textPosition === "right") {
      return `${classes.articlePreviewContent} ${classes.videoLeftTextRight}`;
    }
  }

  handleMarkActivityComplete = (isCompleted?: boolean) => {
    const { studentActivityAttemptTimeInSec } = this.state;
    this.props.handleMarkActivityComplete(studentActivityAttemptTimeInSec, isCompleted)
    if(isCompleted) {
      this.setState({ studentActivityAttemptTimeInSec: 0 });
      clearInterval(this.activityTimer);
    }
  }

  setVideoAndTextPosition = (detail: any) => {
    switch (detail?.videoDescPlacement) {
      case "video_desc_above":
        return { videoPosition: "bottom", textPosition: "top" };

      case "video_desc_below":
        return { videoPosition: "top", textPosition: "bottom" };

      case "video_desc_left":
        return { videoPosition: "right", textPosition: "left" };

      case "video_desc_right":
        return { videoPosition: "left", textPosition: "right" };

      default:
        return { videoPosition: "top", textPosition: "bottom" };
    }
  }

  getWidthOfVideoBox = (detail: any) => {
    const videoPosition = this.setVideoAndTextPosition(detail).videoPosition;
    if (videoPosition === 'left' || videoPosition === 'right') {
      return "70%";
    } else {
      return "100%";
    }
  }

  getWidthOfVideoTextBox = (detail: any) => {
    const textPosition = this.setVideoAndTextPosition(detail).textPosition;
    if (textPosition === 'left' || textPosition === 'right') {
      return "30%";
    } else {
      return "100%";
    }
  }

  getClassToArrangeAudioContent = (detail: any) => {
    let audioPosition = '';
    let textPosition = '';
    const getPositions = this.setAudioAndTextPosition(detail);
    if (getPositions) {
      audioPosition = getPositions.audioPosition;
      textPosition = getPositions.textPosition;
    }

    console.log("audioPosition: ", audioPosition);
    console.log("textPosition: ", textPosition);

    const classes = this.props.classes;
    if (audioPosition === "bottom" && textPosition === "top") {
      return `${classes.articlePreviewContent} ${classes.videoBottomTextTop}`;
    } else if (audioPosition === "top" && textPosition === "bottom") {
      return `${classes.articlePreviewContent} ${classes.videoTopTextBottom}`;
    }
  }

  setAudioAndTextPosition = (detail: any) => {
    switch (detail?.audioDescPlacement) {
      case "audio_desc_above":
        return { audioPosition: "bottom", textPosition: "top" };

      case "audio_desc_below":
        return { audioPosition: "top", textPosition: "bottom" };

      default:
        return { audioPosition: "top", textPosition: "bottom" };
    }
  }
  // Customizable Area End
}

// Customizable Area End