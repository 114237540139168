import React from "react";
// Customizable Area Start
import { Box, Typography, Grid, Tabs, Tab, Button } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CourseManagementMainController, {
  Props,
} from "./CourseManagementMainController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import {
  TabPanel,
  a11yProps,
} from "../../../components/src/CustomTabPanel.web";
import TenantCourseManagementMainTabComponentWeb from "./TenantCourseManagementMainTabComponent.web";
import CustomTextFieldWeb from "../../../components/src/CustomTextField.web";
import SearchIcon from "../../../components/src/SVGIcons/SearchIcon.web";
import TenantCourseMngCreateCatalogModalWeb from "./TenantCourseMngCreateCatalogModal.web";
import TeacherAddNewCourseModalWeb from "./TeacherAddNewCourseModal.web";
import CreateCourseConfirmDialog from "./CreateCourseConfirmDialog.web";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";
import PublishCourseConfirmDialog from "./PublishCourseConfirmDialog.web";
import ConfirmSendApprovalModal from "./ConfirmSendApprovalModal.web";
import TeacherCopyCourseSuccessModal from "./TeacherCopyCourseSuccessModal.web";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TenantAssignCatalogToTeacherModal from "./TenantAssignCatalogToTeacherModal.web";
import AssignCourseModal from "./parentteacher/AssignCourseModal.web";
import AssignCourseSuccessDialog from "./parentteacher/AssignCourseSuccessDialog.web";
import AssignedDetailsModal from "./AssignedDetailsModal.web";

const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    courseMngMainContainer: {
      height: "100%",
      overflowX: "auto",
      borderRadius: "10px",
      padding: "0px 0px 8px 0px",
    },
    tabs: {
      "& div.MuiTabs-scroller": {
        borderBottom: "1px solid #979797",
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
          justifyContent: "space-between",
        },
      },
    },
    tabButton: {
      fontSize: "16px",
      minWidth: "50%",
      textTransform: "capitalize",
      textAlign: "center",
    },
    tabPanel: {
      padding: "15px 0px 15px 0px",
    },
    courseMngTopHeader: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        justifyContent: "flex-start",
      },
    },
    topHeaderBox: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "start",
      },
    },
    dividerLine: {
      height: "3px",
      background: "#F1F1F1",
      marginTop: "25px",
      marginBottom: "25px",
      marginLeft: "-25px",
      marginRight: "-25px",
    },
  });

// Customizable Area End

export class CourseManagementMain extends CourseManagementMainController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      selectedCourseManagementTab,
      searchTitleText,
      openCreateNewCatalogModal,
      ddConfig,
      tableHeaderColumns,
      openCreateNewCoursePopup,
      openCreateCourseConfirmPopup,
      isEditCourse,
      coursesTableData,
      editCourseId,
      createUpdateSubject,
      createUpdateGrade,
      createUpdateTitle,
      loading,
      paginationData,
      catalogTableData,
      catalogPaginationData,
      courseMngTabsList,
      openPublishCourseConfirmPopup,
      openConfirmSendApprovalDialog,
      openCopyCourseSuccessDialog,
      viewCatalogCourses,
      viewCatalogCoursesName,
      isEditCatalog,
      editCatalogId,
      openAssignCatalogToTeacherPopup,
      openAssignCourseModal,
      assignCourseDetail,
      openAssignCourseSuccessDialog,
      assignCatalogDetail,
      rowWiseTableActionConfig,
      isAssignedDetailsModalOpen
    } = this.state;

    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.mainPagePadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Grid container direction="row">
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText12} ${classes.smallTopHeader}`}
                component={"span"}
              >
                Course Management
              </Typography>
              <Typography
                component={"span"}
                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.textButton}`}
              >
                {` / ${courseMngTabsList[selectedCourseManagementTab].label}`}
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.pageName} item xs={12}>
            <Box className={`${classes.courseMngTopHeader}`}>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText34} ${classes.fontBold600}`}
                component={"span"}
                data-test-id="course"
              >
                Course Management
              </Typography>
              <Box className={`${classes.topHeaderBox}`}>
                <CustomTextFieldWeb
                  fieldId="searchTitleText"
                  fieldName="searchTitleText"
                  fieldType="text"
                  placeHolderText="Search by Title"
                  fieldValue={searchTitleText}
                  handleChange={this.handleChangeTextFieldValue}
                  fieldWidth="170px"
                  startAdornmentIcon={
                    <SearchIcon
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      stroke={"#888888"}
                      strokeWidth="0.45"
                      fill={"#888888"}
                    />
                  }
                  textFieldStyles={textFieldStyle}
                />
                {this.rightSideButtons()}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box className={`${classes.bgColor} ${classes.courseMngMainContainer}`}>
              <Box>
                <Tabs
                  value={selectedCourseManagementTab}
                  variant="scrollable"
                  TabIndicatorProps={{
                    style: {
                      background: "none",
                    },
                  }}
                  scrollButtons="auto"
                  data-testid="tenant-course-management-tabs"
                  className={`${classes.tabs}`}
                  onChange={this.handleChangeMyPathCategoryTab}
                  aria-label="tenant-course-management-tabs"
                >
                  {courseMngTabsList.map((tabItem: any, index: number) => (
                    <Tab
                      key={tabItem.id}
                      data-testid={`tenant-course-management-${tabItem.label}-tab`}
                      label={tabItem.label}
                      wrapped
                      {...a11yProps(index)}
                      className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`}
                    />
                  ))}
                </Tabs>
              </Box>

              <Box className={`${classes.tabPanel}`}>
                <TabPanel value={selectedCourseManagementTab} index={0}>
                  {coursesTableData.length === 0 && !loading ?
                    <CustomEmptyResultComponent message="No record found" />
                    :
                    <TenantCourseManagementMainTabComponentWeb
                      handleSearch={this.handleSearchCourses}
                      handleSelectDropdownValue={this.handleSelectDropdownValue}
                      inputDDConfigs={ddConfig}
                      rowWiseTableActionConfig={rowWiseTableActionConfig}
                      showTablePagination={false}
                      renderDDMenuItem={this.renderDDMenuItem}
                      tableBodyData={coursesTableData}
                      showRowWiseActionMenu={true}
                      addTablePadding
                      tableHeaderColumns={tableHeaderColumns}
                      showMenuIconInTable
                      tenantCourseMngTabCategory="teacher_courses"
                      pagination={paginationData}
                      getPaginationNumber={(page: number) => this.handleCoursePaginationChange(page)}
                    />
                  }
                </TabPanel>
                <TabPanel value={selectedCourseManagementTab} index={1}>
                  {catalogTableData.length === 0 && !loading ?
                    <CustomEmptyResultComponent message="You haven't been assigned any catalog" />
                    :
                    <Box>
                      {viewCatalogCourses ?
                        <Box style={{ overflowX: "hidden", overflowY: "clip", paddingBottom: "10px" }}>
                          <Grid direction="row" container style={{ marginTop: "10px" }}>
                            <Grid xs={12} item>
                              <Button onClick={this.closeCatalogCoursesView}>
                                <ArrowBackIcon className={classes.textPrimaryColor} fontSize={"medium"} />
                              </Button>
                              <Typography className={`${classes.fontText16} ${classes.fontBold500} ${classes.textPrimaryColor}`} component={"span"}>
                                {viewCatalogCoursesName}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Box className={classes.dividerLine} style={{ marginTop: "15px" }} />
                          <Grid direction="row" container spacing={3} >
                            {coursesTableData.length === 0 && !loading ?
                              <CustomEmptyResultComponent message="No record found" />
                              :
                              <TenantCourseManagementMainTabComponentWeb
                                handleSearch={this.handleSearchCourses}
                                handleSelectDropdownValue={this.handleSelectDropdownValue}
                                inputDDConfigs={ddConfig}
                                showTablePagination={false}
                                showRowWiseActionMenu={true}
                                renderDDMenuItem={this.renderDDMenuItem}
                                tableBodyData={coursesTableData}
                                addTablePadding
                                tableHeaderColumns={tableHeaderColumns}
                                rowWiseTableActionConfig={rowWiseTableActionConfig}
                                showMenuIconInTable
                                tenantCourseMngTabCategory="teacher_courses"
                                pagination={paginationData}
                                getPaginationNumber={(page: number) => this.handleCoursePaginationChange(page)}
                              />
                            }
                          </Grid>
                        </Box>
                        :
                        <TenantCourseManagementMainTabComponentWeb
                          tenantCourseMngTabCategory="catalog"
                          handleSearch={this.handleSearchCatalogs}
                          showTablePagination={false}
                          handleSelectDropdownValue={this.handleSelectDropdownValue}
                          inputDDConfigs={ddConfig}
                          renderDDMenuItem={this.renderDDMenuItem}
                          rowWiseTableActionConfig={rowWiseTableActionConfig}
                          showRowWiseActionMenu={true}
                          tableBodyData={catalogTableData}
                          tableHeaderColumns={tableHeaderColumns}
                          showMenuIconInTable={this.showOnlyForTenantAdmin()}
                          addTablePadding
                          pagination={catalogPaginationData}
                          getPaginationNumber={(page: number) => this.handleCatalogPaginationChange(page)}
                          tableRowClickAction={(id: string) => { this.handleCatalogNameClick(id) }}
                        />
                      }
                    </Box>
                  }
                </TabPanel>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {openCreateNewCatalogModal && (
          <TenantCourseMngCreateCatalogModalWeb
            open={openCreateNewCatalogModal}
            handleClose={this.handleCloseCreateNewCatalogPopup}
            isEdit={isEditCatalog}
            catalogId={editCatalogId}
            handleAddNewCatalog={(data: any) => { this.handleAddNewCatalogEvent(data) }}
            handleUpdateCatalog={(data: any) => { this.handleUpdateCatalogEvent(data) }}
          />
        )}

        {openCreateNewCoursePopup && (
          <TeacherAddNewCourseModalWeb
            open={openCreateNewCoursePopup}
            onCancel={this.handleCloseCreateNewCoursePopup}
            onConfirm={this.handleSubmitAddCourse}
            isEdit={isEditCourse}
            courseId={editCourseId}
            handleAddNewCourse={(data: any) => this.openConfirmDialogAndHandleApiCall(data)}
            handleUpdateCourse={this.handleUpdateCourse}
          />
        )}

        <AssignedDetailsModal isOpen={isAssignedDetailsModalOpen} handleClose={this.closeAssignedDetailsModal} onAssignToTeacherClick={this.navigateToAssignCatalog} catalog={{name: this.state.viewCatalogCoursesName, id: Number(this.state.viewCatalogCoursesId)}}/>

        <CreateCourseConfirmDialog
          open={openCreateCourseConfirmPopup}
          detailsForConfirmation={{ topic: createUpdateTitle, grade: createUpdateGrade, subject: createUpdateSubject }}
          handleCancel={this.handleCloseCreateCourseConfirmPopup}
          handleClose={this.handleCloseCreateCourseConfirmPopup}
          handleYes={this.handleYesCreateCoursePopup}
        />

        <PublishCourseConfirmDialog
          open={openPublishCourseConfirmPopup}
          handleCancel={this.handleClosePublishCourseConfirmPopup}
          handleClose={this.handleClosePublishCourseConfirmPopup}
          handleYes={this.handleYesPublishCoursePopup}
          detailsForConfirmation={{ topic: createUpdateTitle, grade: createUpdateGrade, subject: createUpdateSubject }}
        />

        <ConfirmSendApprovalModal
          open={openConfirmSendApprovalDialog}
          onCancel={this.handleCloseConfirmSendApprovalDialog}
          onConfirm={this.handleYesConfirmSendApproval}
        />

        <TeacherCopyCourseSuccessModal
          open={openCopyCourseSuccessDialog}
          onCancel={this.handleCloseCourseSuccessDialog}
          onConfirm={this.handleCloseCourseSuccessDialog}
          courseName={createUpdateTitle}
        />

        {(openAssignCatalogToTeacherPopup &&
          <TenantAssignCatalogToTeacherModal
            open={openAssignCatalogToTeacherPopup}
            onClose={this.handleCloseAssignCatalogModal}
            onConfirm={this.handleCloseAssignCatalogModal}
            getTeacherIds={(data: any) => this.handleAssignCatalogToTeacher(data)}
            assignData={assignCatalogDetail}
          />
        )}
     
        {(openAssignCourseModal &&
          <AssignCourseModal
            open={openAssignCourseModal} 
            onCancel={this.handleCloseAssignCourseModal}
            role={this.state.role}
            onConfirm={this.handleCloseAssignCourseModal}
            assignData={assignCourseDetail}
            getSelectedClassName={(data: string) => this.handleGetSelectedClassName(data)}
            getAssignCoursePaylod={(data: any) => this.handleAssignCourseOperation(data)}
          />
        )}

        <AssignCourseSuccessDialog
          open={openAssignCourseSuccessDialog}
          onCancel={this.handleCloseAssignCourseSuccessDialog}
          onConfirm={this.handleCloseAssignCourseSuccessDialog}
          classTitle={assignCourseDetail.classTitle}
          courseName={assignCourseDetail.courseName}
        />
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const textFieldStyle = {
  height: "35px",
  marginRight: "15px",
  marginBottom: "10px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(CourseManagementMain);
// Customizable Area End
