// Customizable Area Start
import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ParentTeacherDashboardController, {
  Props
} from "./ParentTeacherDashboardController";
import CustomTable, { tableColumnTypes } from "../../../../components/src/CustomTable.web";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../../components/src/CombineStyles.web";
import CustomSelectDropdown from "../../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../../components/src/SVGIcons/DropdownIcon.web";
import CustomButtonWeb from "../../../../components/src/CustomButton.web";
// Customizable Area End

// Customizable Area Start
const tenantSubjectColumn = [
  {
    id: "Name",
    columnId: 1,
    label: "Name",
    type: tableColumnTypes.STUDENT_ICON,
    width: "250px",
    maxWidth: "280px",
  },
  {
    id: "Progress",
    columnId: 2,
    label: "Progress",
    type: tableColumnTypes.STUDENT_PROGRESS_BAR,
  },
];

const progressTableColumns = [
  {
    id: "Name",
    columnId: 1,
    label: "Name",
    type: tableColumnTypes.STUDENT_ICON,
  },
  {
    id: "SemesterAvg",
    columnId: 2,
    label: "Term Avg",
    type: tableColumnTypes.PROGRESS_TEXT,
  },
  {
    id: "GPA",
    columnId: 3,
    label: "YTD",
    type: tableColumnTypes.PROGRESS_TEXT,
  },
];

const assignedTableColumns = [
  {
    id: "Title",
    columnId: 1,
    label: "Title",
    type: tableColumnTypes.ASSIGNED_TITLE,
  },
  {
    id: "Type",
    columnId: 2,
    label: "Type",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "Submitted",
    columnId: 3,
    label: "Submitted %",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "AvgGrade",
    columnId: 4,
    label: "Avg Grade",
    type: tableColumnTypes.ASSIGNED_ASSESSMENT_AVG_GRADE,
  },
  {
    id: "DueDate",
    columnId: 5,
    label: "Due Date",
    type: tableColumnTypes.DUE_DATE,
  },
];


const styles = (theme: Theme) =>
  createStyles({
    courseMainBox: {
      padding: "15px",
      height: "180px",
      marginBottom: "5%",
      width: "85%",
      cursor: "pointer",
      borderRadius: "12px",
      [theme.breakpoints.down('xs')]: {
        width: "90%",
      },
    },
    subjectLogoBg: {
      height: "55px",
      width: "55px",
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      padding: "2px",
      alignItems: "center",
    },
    topBtn: {
      backgroundColor: "#0D46BC",
      borderRadius: "12px",
      marginRight: '30px',
      textTransform: "capitalize",
      color: "#ffffff",
      '&:hover': {
        backgroundColor: '#0D46BC',
      },
    },
    topBtnRoot: {
      justifyContent: "space-between",
      marginBottom: "20px",
      display: "flex",
    },
    breadCrumbRoot: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    selectedBoxBorder: {
      border: "2px solid #FBD82C"
    },
    pageTitle: {
      fontWeight: 'bold',
      fontSize: 28,
    },
    statusLate: {
      color: '#ED1111',
      fontSize: '14px',
      borderRadius: '17px',
      padding: '5px 10px 5px 10px',
      backgroundColor: '#fcd3d3',
    },
    statusCompletion: {
      fontSize: '14px',
      fontWeight: 500,
      backgroundColor: '#d0f5eb',
      color: '#01C996',
      padding: '6px 10px 6px 10px',
      borderRadius: '17px',
    },
    statusScheduled: {
      color: '#4DC901',
      fontSize: '14px',
      borderRadius: '17px',
      padding: '6px 10px 6px 10px',
      backgroundColor: '#def5d0',
    },
    statusOnGoing: {
      color: '#FBD82C',
      fontSize: '14px',
      borderRadius: '17px',
      padding: '6px 10px 6px 10px',
      backgroundColor: '#fef8d8',
    },
    customTableStyles: {
      borderRadius: '10px',
      marginRight: '8px',
    },
    tableTitle: {
      fontWeight: 'bold',
      fontSize: 28,
    },
    firstGrid2: {
      width: '46%',
      [theme.breakpoints.down('md')]: {
        width: '98%',
        alignItems: 'center',
        marginTop: '13px'
      },
      [theme.breakpoints.up('lg')]: {
        width: '46%',
        alignItems: 'center'
      },
    },
    firstGrid: {
      marginRight: '13px',
      width: '53%',
      [theme.breakpoints.down('md')]: {
        width: '98%',
        alignItems: 'center'
      },
      [theme.breakpoints.up('lg')]: {
        width: '53%',
        alignItems: 'center'
      },
    },
    commanFontSize: {
      fontWeight: 'bold',
    },
    container: {
      marginTop: '17.2px',
      display: 'flex',
      width: '99%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        width: '100%',
      },
    },
    progresstableHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '98%',
      alignItems: 'center',
    },
    assignedAssessments: {
      "& td": {
        paddingTop: "0px",
        paddingBottom: "0px",
        height: "50px",
      }
    },
    viewAllBtn: {
      fontWeight: 'bold',
      cursor: 'pointer',
      color: '#0D46BC',
    },
  });
// Customizable Area End

// Customizable Area Start
export class ParentTeacherDashboard extends ParentTeacherDashboardController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { studentCoursesList, selectedCourseId,progressStudentsTableBody,assignedAssessmentsList } = this.state;
    const { classes } = this.props;

    const getAvgScoreColor = (status: string) => {
      if (status === 'Completed') return `${classes.statusCompletion} `;
      else if (status === 'Late') return `${classes.statusLate} `;
      else if (status === 'On Going') return `${classes.statusOnGoing} `
      else return `${classes.statusScheduled}`;
    }

    const getSelectedCourseBoxClass = (courseId: any) => {
      if (parseInt(selectedCourseId) === parseInt(courseId)) return `${classes.bgColor} ${classes.selectedBoxBorder} ${classes.courseMainBox}`;
      return `${classes.bgColor} ${classes.courseMainBox}`
    }

    const isParent = this.state.isParent;

    return (
      <Box className={`${classes.highlightBgColor} ${classes.childMainWrapperPadding} ${classes.secondaryBgColor}`} height={"100%"}>
        <Box className={classes.breadCrumbRoot}>
          <Typography component={"span"} className={`${classes.fontText14} ${classes.textCommonColor}`}>
            {isParent ? "Parents Dashboard" : "Teachers Dashboard"}

          </Typography>
          <Typography component={"span"} className={` ${classes.fontText14} ${classes.primaryColor5}`}>
          </Typography>
        </Box>
        <Box marginBottom={"17px"}>
          <Box marginBottom={"13px"}>
            <Typography component={"span"} className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText32}`}>
              {isParent ? "Parents Dashboard" : "Parent Teacher Dashboard"}
            </Typography>
          </Box>
          { !isParent && 
            <Box justifyContent={"flex-start"} display={"flex"}>            
              <CustomSelectDropdown
                fieldId="select-dropdown-class"
                fieldValue={this.state.selectedTeacherClassDDValue}
                fieldName="selectedTeacherClassDDValue"
                handleChange={this.handleChangeDropdownValue}
                renderValue={this.renderGradingComponenttDDValue}
                fieldError={false}
                iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                fieldMenuItems={this.state.classesList}
                fieldStyles={DropdownStyles}
              />
            </Box>
          }
        </Box>

        <Box className={classes.topBtnRoot}>
          <Typography className={`${classes.pageTitle} ${classes.textSecondaryColor}`}>Course Progress</Typography>      
          { !isParent &&     
            <CustomButtonWeb
              btnStyle={{
                borderRadius: "12px",
                marginRight: "30px",
              }}
              btnText="Catch Up"
              handleButtonClick={this.handleModalOPen}
              buttonId="teacher-dashboard-catch-up"
              buttonVariant="contained"
              isDisabled={false}
              type={"button"}
            />
          }
        </Box>
        <Grid item xs={12}>
          <Grid direction="row" container>
            {studentCoursesList?.length > 0 ? (studentCoursesList.map((courseItem: any, index: number) => <Grid key={courseItem.id} item xs={12} sm={6} lg={4}>
              <Box data-test-id={`student-subject-card-${index}`} className={getSelectedCourseBoxClass(courseItem?.id)} onClick={() => this.handleSelectCourse(courseItem?.id)}>
                <Box display="flex" justifyContent={"center"} flexDirection="column" >
                  {/* Subject Icon & name */}
                  <Box alignItems="center" display={"flex"}>
                    <Box mr={"10px"} className={classes.subjectLogoBg} bgcolor={courseItem?.subjectBgColor}>
                      <img style={{height:'50px',width:"50px"}} alt="Subject Logo" src={courseItem?.subjectIcon} />
                    </Box>
                    <Box>
                      <Typography component="span" className={`${classes.textPrimaryColor} ${classes.commanFontSize} ${classes.fontText22}`}>
                        {courseItem?.subjectName}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display={"flex"} marginTop={"15px"} alignItems="center">
                    <Box>
                      <Typography component="span" className={`${classes.fontText18} ${classes.textCommonColor}`}>
                        Due on {courseItem?.dueDate}
                      </Typography>
                    </Box>
                  </Box>
                  <Box marginTop={"10px"} display={"flex"} alignItems="center">
                    <Box>
                      <Typography component="span" className={`${classes.fontText18} ${classes.textCommonColor}`}>
                        Lessons :
                      </Typography>
                    </Box>
                    <Box ml={"4px"} style={{ gap: '4px' }} display='flex'>
                      <Typography component="span" className={`${classes.fontText16} ${classes.primaryColor4}`}>
                        {courseItem.lessonsCovered}
                      </Typography>
                      <Typography component="span" className={`${classes.fontText16} ${classes.textCommonColor}`}>
                        /
                      </Typography>
                      <Typography component="span" className={`${classes.fontText16} ${classes.textPrimaryColor}`}>
                        {courseItem.lessonsTotal}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display={"flex"} marginTop={"10px"} justifyContent={"space-between"} alignItems="center">
                    <Box>
                      <Typography component="span" className={`${classes.primaryColor4} ${classes.fontBold500} ${classes.fontText16}`}>
                        {`${courseItem.completionProgress}% completed`}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography component="h5" style={{
                        color: getAvgScoreColor(courseItem.status),
                      }} className={`${classes.fontBold500} ${classes.fontText14} ${getAvgScoreColor(courseItem.status)}`}>
                        {`${courseItem.status}`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>)) : ''
            }
          </Grid>
        </Grid>
        <Box className={classes.container} display={'flex'} flexDirection={'row'}>
          <Box className={classes.firstGrid} >
            <Box mb={2} className={classes.progresstableHeader}>
              <Typography className={`${classes.tableTitle} ${classes.textSecondaryColor}`} > Progress</Typography>
              <Box display={'flex'} style={{ width: '58%' }} justifyContent={'space-between'}>
                <Box height={20} width={20} style={{ backgroundColor: '#01C996', borderRadius: '4px', }}></Box>
                <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText14}`} style={{}}>
                  {`Completed`}
                </Typography>
                <Box height={20} width={20} style={{ backgroundColor: '#0D46BC', borderRadius: '4px', }}></Box>
                <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText14}`} style={{}}>
                  {`Remaining`}
                </Typography>
                <Box height={20} width={20} style={{ backgroundColor: '#FBD82C', borderRadius: '4px', }}></Box>
                <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText14}`} style={{}}>
                  {`Not Tackled`}
                </Typography>
              </Box>
              <Typography component={"span"} className={`${classes.fontText14} ${classes.viewAllBtn} ${classes.fontBold600} ${classes.textButton} ${classes.cursorPointer}`}
              onClick={() => this.handleViewActivityList("Progress")}
              >
                {`View All`}
              </Typography>
            </Box>
            <Box className={`${classes.customTableStyles} ${classes.bgColor}`}>              
              <CustomTable
                tableId={"teacher-dashboard-students-progress"}
                tableHeaderColumns={tenantSubjectColumn}
                tableBodyData={progressStudentsTableBody}
                showMenuIcon={false}
              />
            </Box>
          </Box>
          <Box className={classes.firstGrid2} >
            <Box mb={1.5} alignItems="center" display={'flex'} justifyContent="space-between" style={{ width: '98%' }}>
              <Typography className={`${classes.tableTitle} ${classes.textSecondaryColor}`} > Performance</Typography>
              <Typography component={"span"} className={`${classes.viewAllBtn}  ${classes.fontText14}  ${classes.fontBold600} ${classes.textButton} ${classes.cursorPointer}`} style={{ marginLeft: '9px' }}
              onClick={() => this.handleViewActivityList("Performance")}>
                {`View All`}
              </Typography>
            </Box>
            <Box mt={"16px"} className={`${classes.customTableStyles} ${classes.bgColor}`}>              
              <CustomTable
                tableId={"teacher-dashboard-students-performance"}
                tableHeaderColumns={progressTableColumns}
                tableBodyData={progressStudentsTableBody}
                showMenuIcon={false}
              />

                 
            </Box>
          </Box>
        </Box>
        <Box style={{ width: '99%' }} mt={4.5} >
          <Box mb={1.5} display={'flex'} justifyContent="space-between" alignItems="center" style={{ width: '99%' }}>
            <Typography className={`${classes.tableTitle} ${classes.textSecondaryColor}`}> Assigned Assessments</Typography>
            <Typography component={"span"} className={` ${classes.fontText14}  ${classes.viewAllBtn} ${classes.fontBold600} ${classes.textButton} ${classes.cursorPointer}`}
            onClick={() => this.handleViewActivityListt("Performance")}>
              {`View All`}
            </Typography>
          </Box>
          <Box className={`${classes.bgColor} ${classes.assignedAssessments} ${classes.customTableStyles}`} >
            <CustomTable
              tableHeaderColumns={assignedTableColumns}              
              tableId={"teacher-assigned-assessments-table"}
              showMenuIcon={false}
              tableBodyData={assignedAssessmentsList}
            />
          </Box>
        </Box>       
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const DropdownStyles = {
  borderRadius: '8px',
  height: '48px',
  width: "200px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ParentTeacherDashboard);
// Customizable Area End


