import { toast } from "react-toastify";
import {
  getStorageData,
  removeStorageData,
} from "../../framework/src/Utilities";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { FIELD_TYPES } from "./CommonType.web";

const getFileSrc = (file: any) => {
  if (typeof file === "string") {
    return file; // fileUrl
  } else {
    return window.URL.createObjectURL(file);
  }
};

const handleLogoutUser = async (navigation: any, logoutType?: string) => {
  await removeStorageData("token");
  await removeStorageData("role");
  await removeStorageData("theme");
  await removeStorageData("dark_mode");
  await removeStorageData("user_profile_img");
  await removeStorageData("user_name");
  await removeStorageData("appearanceTheme")
  //NavigationService.resetTo('primaryStack', { screen: 'Login' });
  if (logoutType == "force") {
    toast.error(
      "Session expired, Your session has expired. Please login again to continue."
    );
  }
  navigation?.navigate("AccountLogin");
};

const handleInvalidToken = async (navigation: any) => {
  const token = await getStorageData("token");
  if(!token){
    toast.error(
      "Invalid token. Please login again to continue."
    );
    navigation?.navigate("AccountLogin");
  }
}

const getBgColor = (data: string) => {
  if (data?.toLowerCase() === "active") return "#CFF5EB";
  else if (data?.toLowerCase() === "inactive") return "#f6d3cd";
  else if (data?.toLowerCase() === "draft") return "#E8E8E8";
  else if (data?.toLowerCase() === "for_approval") return "#CFECFD";
  else if (data?.toLowerCase() === "approved") return "#CFF5EB";
  else if (data?.toLowerCase() === "scheduled") return "#CAEFE5";
  else if (data?.toLowerCase() === "completed") return "#D7EFCA";
  else if (data?.toLowerCase() === "graded") return "#CFF5EB";
  else if (data?.toLowerCase() === "assigned") return "#fff7d8";
  else if (data?.toLowerCase() === "new") return "#fbeccd";
  else if (data?.toLowerCase() === "modified") return "#d2e4ef";
  else if (data === "") return "transparent";
  else return "#FBD2D2";
};

const getColor = (data: string) => {
  if (data?.toLowerCase() === "active") return "#01C996";
  else if (data?.toLowerCase() === "inactive") return "#FF6B6B";
  else if (data?.toLowerCase() === "draft") return "#888888";
  else if (data?.toLowerCase() === "for_approval") return "#029CFE";
  else if (data?.toLowerCase() === "approved") return "#01C996";
  else if (data?.toLowerCase() === "scheduled") return "#4DC901";
  else if (data?.toLowerCase() === "completed") return "#01C996";
  else if (data?.toLowerCase() === "graded") return "#01C996";
  else if (data?.toLowerCase() === "assigned") return "#FFD92E";
  else if (data?.toLowerCase() === "new") return "#dc9e4c";
  else if (data?.toLowerCase() === "modified") return "#3479b3";
  else if (data === "") return "transparent";
  else return "#ED1111";
};

const getAuthToken = async () => {
  const token = await getStorageData("token", true);
  return token;
};

const getMessageData = (message: Message) => {
  const apiRequestCallId = message.getData(
    getName(MessageEnum.RestAPIResponceDataMessage)
  );

  const responseJson = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );

  const errorResponse = message.getData(
    getName(MessageEnum.RestAPIResponceErrorMessage)
  );

  return { apiRequestCallId, responseJson, errorResponse };
};

const showCustomLoader = () => {
  const loader = document.getElementById("custom-loader");
  if (loader) {
    loader.style.display = "block"
  }
}

const hideCustomLoader = () => {
  const loader = document.getElementById("custom-loader");
  if (loader) {
    loader.style.display = "none"
  }
}

const displayApiErrorMsg = (error: any, navigationObj: any) => {
  if (typeof error === "string") {
    toast.error(error);
    if ((error?.toLowerCase() === "invalid token") || (error?.toLowerCase() === "token has expired")) {
      navigationObj?.navigate("AccountLogin");
      return;
    }
  } else if (Array.isArray(error) && error.length == 1) {
    toast.error(error[0])
  } else if (Array.isArray(error)) {
    error.forEach((element: any) => {
      Object.keys(element).map((msg) => {
        toast.error(element[msg])
        if ((element?.[msg]?.toLowerCase() === "invalid token") || (element?.[msg]?.toLowerCase() === "token has expired")) {
          navigationObj?.navigate("AccountLogin");
          return;
        }
      })
    });
  } else if (typeof error === "object") {
    Object.keys(error).map((msgKey) => {
      if (typeof error[msgKey] === "string") {
        toast.error(error[msgKey]);
        if ((error?.[msgKey]?.toLowerCase() === "invalid token") || (error?.[msgKey]?.toLowerCase() === "token has expired")) {
          navigationObj?.navigate("AccountLogin");
          return;
        }
      }
      else {
        toast.error(error?.[msgKey]?.[0])
        if ((Array.isArray(error[msgKey]) && error[msgKey][0]?.toLowerCase() === "invalid token") || 
        (Array.isArray(error[msgKey]) && error[msgKey][0]?.toLowerCase() === "token has expired")) {
          navigationObj?.navigate("AccountLogin");
          return;
        }
      }
    })
  } else {
    toast.error("Something went wrong!")
  }
}

const toDataURL = (url: any) => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))

const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const convertDataURLtoFile = (dataurl: any, filename: any) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

const hideEmail = (email: string) => {
  let hiddenEmail = "";
  if (email && email.length) {
    for (let i = 0; i < email.length; i++) {
      if (i > 1 && i < email.indexOf("@")) {
        hiddenEmail += "*";
      } else {
        hiddenEmail += email[i];
      }
    }
  }
  return hiddenEmail;
}

const getFieldType = (tag: string) => {
  switch (tag) {
    case "text_tag":
      return FIELD_TYPES.TEXT;
    default:
      return FIELD_TYPES.TEXT
  }
}
const handleGetOptionLetter = (index: number, lastLetter: any) => {
  if (index === 0) return "A";
  else {
    return String.fromCharCode(lastLetter.charCodeAt(0) + 1);
  }
};

const tagsRemoveReg = /<[^>]*?>/g;

export {
  getFileSrc,
  handleLogoutUser,
  getAuthToken,
  getMessageData,
  getBgColor,
  getColor,
  showCustomLoader,
  hideCustomLoader,
  displayApiErrorMsg,
  toBase64,
  toDataURL,
  convertDataURLtoFile,
  hideEmail,
  getFieldType,
  handleGetOptionLetter,
  handleInvalidToken,
  tagsRemoveReg,
};
