import { toast } from "react-toastify";

import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {schoolSubjectsList,subjectCoursesDetailsTableColumns,subjectTableColumns} from "./assets/mockDataTemp"
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { ITableProps } from "./GradebookGradesTabController";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../CommonHelper.web";
import { tableColumnTypes } from "../CustomTable.web";
import { ErrorMessage } from "formik";

// Customizable Area End

export const configJSON = require("./config");
export const courseDetailTabs = [
    {
        id: 1,
        label: "Grades",
        value: "grades"
    },
    {
        id: 2,
        label: "Attendance & Behaviour",
        value: "attendance_behaviour"
    },
]
export interface AttendanceDataColumns {
  columnId: number;
  id: string;
  label: string;
  type: string;
  value: string;
}[]
interface EvaluationKeysData {
  title: string;
  [key: string]: string; 
}

interface EvaluationKey {
  id: number;
  title: string;
  behaviour_id: number;
  created_at: string;
  updated_at: string;
}

interface SubSkill {
  id: number;
  title: string;
  skill_id: number;
  created_at: string;
  updated_at: string;
}

interface Skill {
  id: number;
  skill_title: string;
  created_at: string;
  updated_at: string;
  sub_skills: SubSkill[];
}

interface BehaviourAttributes {
  id: number;
  obeservation_title: string;
  grade_book_id: number;
  school_id: number;
  evaluation_keys: EvaluationKey[];
  created_at: string;
  updated_at: string;
  skills: Skill[];
}

interface BehaviourData {
  id: string;
  type: "behaviour";
  attributes: BehaviourAttributes;
}

interface BehaviourResponse {
  map(arg0: (behavior: { attributes: { evaluation_keys: any[]; obeservation_title: any; id: any; skills: any[]; }; }) => any): unknown;
  data: BehaviourData[];
}

interface GetBehaviourItem {
  id: number;
  student_id: number;
  teacher_id: number;
  total_subskill_data: string; 
  subskill_id: number;
  skill_id: number;
  semester_type: string | null; 
  teacher_input:boolean
}


interface GetAttributes {
  id: number;
  total: number;
  quarterly_attendance: string; 
  teachers_comment: string;
  teacher_input:boolean,
  behaviour: GetBehaviourItem[];
}

interface GetBehaviourData {
  id: string;
  type: string;
  attributes: GetAttributes;
}


export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtSavedValue: string;
    selectedTabIndex: number;
    selectedTabName: string;
    openSubmitDialog: boolean;
    // Customizable Area Start
    userRole: string;
    isDataToBeSubmittedIncomplete: boolean;
    openReturnGradesDialog: boolean;
    gradeStudenData:any
    selectedHighlightBar: number[],
    semesterAvgSubmitted:any,
    subjectTableColumns: Array<ITableProps>;
    schoolSubjectsList: any;
    totalAvgOfAllCourses:number
    selectedSubjectId: any;
    highlightBarHeight:string;
    selectedSubjectCoursesGradesDetails: any,
    subjectCoursesDetailsTableColumns: Array<ITableProps>;
    isAnyColumnSelected:boolean,
    buttonOptionText:string;
    attendanceData: AttendanceDataColumns[]
    commentData: string
    quarters:any[],
    studentBehaviorData:any[],
    evaluationKeysData:any[],
    maxEvaluationKeys:number,
    teacherComment:boolean,
    SubjectNameList:any[];
    existingReturnData:any[]
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class GradebookDetailPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiPostSubmitGradebook:string=""
    apiGetGradebookDetails: string = "";
    apiGetSchoolYearList:string = "";
    apiGetGradebookAssignmentDetails:string="";
    apiRegistrarLockingGradebook:string="";
    apiRegistrarRejectGradebook:string=""
    apiSubmitAttendanceBehaviour:string=""
    apiGetGradebookBehaviourTeacher:string=""
    apiGetGradebookBehaviourTeacherDetails:string=""
    apiRejectGradeId:string=""
    apiGetReturnCommentsId:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];
        
        this.state = {
            txtSavedValue: "A",
            selectedTabIndex: 0,
            selectedTabName: "Grades",
            openSubmitDialog: false,
            // Customizable Area Start
            userRole: "",
            isDataToBeSubmittedIncomplete: false,
            openReturnGradesDialog: false,
            gradeStudenData: {},
            selectedHighlightBar: [],
            semesterAvgSubmitted:[],
            subjectTableColumns: subjectTableColumns,
            attendanceData:[{
              label: "Total",
              columnId: 0,
              id: "Total",
              type: "number",
              value: ""
            }],
            schoolSubjectsList: [],
            totalAvgOfAllCourses:0,
            selectedSubjectId: null,
            highlightBarHeight:"68vh",
            selectedSubjectCoursesGradesDetails: [],
            subjectCoursesDetailsTableColumns: subjectCoursesDetailsTableColumns,
            isAnyColumnSelected:false,
            buttonOptionText:"",
            commentData: "",
            quarters:[],
            studentBehaviorData:[],
            evaluationKeysData:[],
            maxEvaluationKeys:0,
            teacherComment:false,
            SubjectNameList:[],
            existingReturnData:[]
            // Customizable Area End
        };
        // runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        await super.componentDidMount();
        
        this.handleSetRole()
        await setStorageData("incompleteGradesSubmittedByTeacher", JSON.stringify(false));
        await setStorageData("incompleteBehaviourSubmittedByTeacher", JSON.stringify(false));
        const storedGradebookData = await this.getGradebookDataFromStorage();
        if (storedGradebookData) {
          const storageData = JSON.parse(await getStorageData("selectedArray"));
          if(storageData){
            this.setState({selectedHighlightBar:storageData})
          }
            this.setState({ gradeStudenData: storedGradebookData });
            this.handleGetTeacherSchoolYearList();

        }
        
    }
    handleSetRole = async() => {
      const getUser = await getStorageData("role");
      if(getUser){
        this.setState({userRole:getUser})
      }
    } 

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const { apiRequestCallId, responseJson } = getMessageData(message);
  
        if (apiRequestCallId != null) {
          switch (apiRequestCallId) {
            case this.apiPostSubmitGradebook:
              {
                this.handlePostTeacherGradebookResponse(responseJson);
              }
              break;
              case this.apiGetGradebookDetails:
                {
                  this.handleGetGradeBookDataResponse(responseJson);
                }
                break;
              case this.apiGetSchoolYearList:
                  {
                    this.handleGetTeacherSchoolYearDataResponse(responseJson);
                  }
                  break;
                  case this.apiGetGradebookAssignmentDetails:
                    {
                        this.handleGetGradeBookAssignmentDetailsDataResponse(responseJson);
                      }
                      break;
                  case this.apiRegistrarLockingGradebook:
                    {
                      this.handlePostRegistrarLockingResponse(responseJson);
                  }
                  break;
                  case this.apiRegistrarRejectGradebook:{
                    this.handlePostRegistrarRejectResponse(responseJson);
                  }
                  break;
                  case this.apiSubmitAttendanceBehaviour:{
                    this.handleSubmitOfAttendanceAndBehaviourResponse(responseJson)
                  }
                  break;
                  case this.apiGetGradebookBehaviourTeacher:{
                    this.handleGetAttendanceAndBehaviourResponse(responseJson)
                  }
                  break;
                  case this.apiGetGradebookBehaviourTeacherDetails:{
                    this.handleGetAttendanceAndBehaviourDetailsResponse(responseJson)
                  }
                  break;
                  case this.apiRejectGradeId:{
                    this.handleRejectGradesResponse(responseJson)
                  }
                  break;
                  case this.apiGetReturnCommentsId:{
                    this.handleGetReturnResponse(responseJson)
                  }
                  break;

          }
        }
      }

  }
  handleGetTeacherSchoolYearList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSchoolYearList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherSchoolYear
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetTeacherSchoolYearDataResponse = (responseJson: {
    data: Array<{
      id: string;
      attributes: {
        title: string;
        semesters: Array<{
          id: number;
          title: string;
        }>;
      };
    }>;
    errors: Array<{}>;
  }) => {
    if (responseJson?.data) {
   

    const studentId = this.state.gradeStudenData.SchoolId; 

const studentData = responseJson?.data.find(item => item.id === studentId);

if (studentData) {
     const semesterList:any= studentData.attributes.semesters.map(

        (semester) => {
          return {
            columnId: semester.id, 
            id: semester.title,
            label: semester.title,
            type: tableColumnTypes.GRADEBOOK_AVG,
            isSelected: false,
            isSortingColumn: true,
            sortByOrder: "",
            isSemester: true, 
          };
        }
      );
      const semesterListForAttendance= studentData.attributes.semesters.map(

        (semester) => {
          return {
            columnId: semester.id, 
            id: semester.title,
            label: semester.title,
            type: tableColumnTypes.GRADEBOOK_AVG,
            value:""
          };
        }
      );
      
      const columns:any = [... subjectTableColumns];
      columns.splice(2, 0, ...semesterList);
      const columnDetails = [...subjectCoursesDetailsTableColumns]
      columnDetails.splice(2, 0, ...semesterList);
      const attendanceDataColumns= [...this.state.attendanceData]
      attendanceDataColumns.splice(0, 0, ...semesterListForAttendance);
     
      this.setState(
        {
          quarters:semesterListForAttendance,
          attendanceData:attendanceDataColumns,
          subjectTableColumns: [...columns],
          subjectCoursesDetailsTableColumns:[...columnDetails]

        },
        async() => {
          await this.handleGetGradebookStudentsDetails(this.state.gradeStudenData.StudentId,this.state.gradeStudenData.SchoolId);
          this.handleGetReturnApi(this.state.gradeStudenData.StudentId,this.state.gradeStudenData.SchoolId)
           await this.handleGetAttendanceAndBehaviour(this.state.gradeStudenData.StudentId)
         
        }
      );
    }
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
  };
  transformDataForAssignment = (responseJson:any) => {
    const data = responseJson.data;
  const selectedSubjectCoursesGradesDetails:any = {};

 
  const mapSemesterScoresToQuarters = (gradingData:any) => {
    const quarterScores:any = {};

    gradingData.forEach((grade:any, index:any) => {
      quarterScores[`quarter${index + 1}Score`] = grade.semester_avg || 0;
    });

    return quarterScores;
  };


  data.forEach((item:any) => {
    const gradingComponent = item.attributes.grading_component;
    const weightage = item.attributes.weightage
    const gradingComponentAverages = item.attributes.grading_component_averages;

   
    if (gradingComponentAverages && gradingComponentAverages.grading && Array.isArray(gradingComponentAverages.grading)) {
     
      gradingComponentAverages.grading.forEach((average:any) => {
        if (!selectedSubjectCoursesGradesDetails[gradingComponent]) {
          selectedSubjectCoursesGradesDetails[gradingComponent] = {
            id: average.id,
            title: gradingComponent,
            totalScore: gradingComponentAverages.total_grading_average,
            weight: `${weightage}%`,
            ...mapSemesterScoresToQuarters(gradingComponentAverages.grading), 
          };
        } 
      });
    } else {
      console.error('grading_component_averages.grading is not an array:', gradingComponentAverages);
    }
  });

  
  const transformedDataArray = Object.values(selectedSubjectCoursesGradesDetails);

  return transformedDataArray;
  };

  handleChangeAttendanceData = (index:any, event:any) => {
    const newValue = event.target.value;
    this.setState(prevState => {
        const updatedData = [...prevState.attendanceData];
        updatedData[index].value = newValue;
        return { attendanceData: updatedData };
    });
};
  calculateTotal = () => {
    const { attendanceData } = this.state;
    return attendanceData
        .filter(att => att.label !== "Total")  
        .reduce((total, att) => total + parseFloat(att.value || "0"), 0);
  };
  handleGetGradeBookAssignmentDetailsDataResponse = (responseJson: any) => {
    if (responseJson?.data) {
        const selectedSubjectCoursesGradesDetails = this.transformDataForAssignment(responseJson);
        
        this.setState({
          selectedSubjectCoursesGradesDetails: selectedSubjectCoursesGradesDetails,
        });
    } 
    else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };
  handleGetGradeBookDataResponse = (responseJson: any) => {
    if (responseJson?.data) {
     
      const tablebodydata = this.transformData(responseJson)
      const SubjectNameList = this.transformDataToGetSubjectName(responseJson)
      let totalAvgOfAllCoursesData = responseJson.data.attributes.courses_averages.find(
        (course: any) => course.total_avgs_of_all_courses
      )?.total_avgs_of_all_courses || 0;
      totalAvgOfAllCoursesData = parseFloat(totalAvgOfAllCoursesData.toFixed(2));

     
     this.setState({SubjectNameList:SubjectNameList,schoolSubjectsList:tablebodydata,totalAvgOfAllCourses:totalAvgOfAllCoursesData})
        
    } 
    else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };
  componentDidUpdate(prevProps:any, prevState:any) {
    if (prevState.selectedHighlightBar !== this.state.selectedHighlightBar) {
      const isAnyColumnSelectedOption = this.state.selectedHighlightBar.length > 0;
    
      this.setState({ isAnyColumnSelected:isAnyColumnSelectedOption?false:true }); 
    }
  }

  transformData = (apiData:any) => {
    const courses = apiData.data.attributes.courses_averages.filter((course:any) => !course.total_avgs_of_all_courses && course.avgs_of_courses);
  
    return courses.map((course:any, index:any) => {
      const mapSemesterScoresToQuarters = (avgs:any) => {
        const quarterScores:any = [];
        if (Array.isArray(avgs)) {
          avgs.forEach((avg, i) => {
            quarterScores.push({
              quarter: `quarterScore${i + 1}`,
              score: avg.semester_avg || 0,
              status:avg.status,
              semesterId:avg.semester_id 
            });
          });
        }
  
        return quarterScores;
      };
  
      
      const quarters = mapSemesterScoresToQuarters(course.avgs_of_courses || []);
      const quarterScores = quarters.reduce((acc:any, curr:any) => {
        acc[curr.quarter] = curr.score;
        acc[`${curr.quarter}Status`] = curr.status;
        acc[`${curr.quarter}semesterId`] = curr.semesterId
        return acc;
      }, {});
   
      return {
        id: index + 1,
        subject: {
          name: course.course_title,
          bgColor:course.course_image.color,
          icon:course.course_image.icon || "https://thynker-lms-uploads.s3.ap-south-1.amazonaws.com/g12hnj4c2ktc7m602i1rv2jjdl5u?response-content-disposition=inline%3B%20filename%3D%224289665-200.png%22%3B%20filename%2A%3DUTF-8%27%274289665-200.png&response-content-type=image%2Fpng&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAZ47SKL2YALUIHO6K%2F20240710%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240710T154347Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Signature=20572a025995087034bd5a624e4b894165c67c2ab225410080c46e636a144a5f"
        },
        errorMessage:course.error_msg === "No message" ? "" : course.error_msg,
        currentSemesterId:course.current_semester_id,
        subjectId: course.course_id,
        ...quarterScores,
        progress:course.course_progress?.completed === 100 ? "100%" : `${(Math.round((course.course_progress?.completed || 0) * 100) / 100).toFixed(2)}%`,
        totalScore: course.total_avg_of_course,
        grade: course.grade === "No grading_system for this value" ? "NA" : course.grade, 
        coursesDetails: {
          assignment: {
            firstQuarterScore: 0,
            secondQuarterScore: 0,
            thirdQuarterScore: 0,
            fourthQuarterScore: 0,
            totalScore: 0,
            weight: 15,
          },
        },
      };
    });
  };
  transformDataToGetSubjectName = (apiData: any) => {
    const courses = apiData.data.attributes.courses_averages.filter(
      (course: any) => !course.total_avgs_of_all_courses && course.avgs_of_courses
    );
  
    return courses.map((course: any, index: any) => ({
      id: index + 1,
      subject: course.course_title, 
      subjectId: course.course_id,
      value:""
    }));
  };
  handleGetGradebookStudentsDetails = async (student_id:any,school_id:any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetGradebookDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.gradebookStudentDetailsEndpoint}?student_id=${student_id}&school_year_id=${school_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetGradebookStudentsAssignmentDetails = async (student_id:any,course_id:any,school_year_id:any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetGradebookAssignmentDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.gradebookStudentAssignmentDetailsEndpoint}?student_id=${student_id}&course_id=${course_id}&school_year_id=${school_year_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleSetSelectedSchoolSubject = async (subId:any) => {
    const { selectedSubjectId, gradeStudenData } = this.state;
  
    if (subId === selectedSubjectId) {
      this.setState({
        selectedSubjectId: "",
        selectedSubjectCoursesGradesDetails: [],
        highlightBarHeight: "68vh"
      });
    } 
    else {
     
      this.setState({
        selectedSubjectId: subId,
        selectedSubjectCoursesGradesDetails: [], 
      }, async () => {
        
        await this.handleGetGradebookStudentsAssignmentDetails(gradeStudenData.StudentId, subId, gradeStudenData.SchoolId);
  
        this.setState({ highlightBarHeight: "130vh" });
      });
    }
  };

  

    handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ selectedTabIndex: newValue });
        if (newValue === 0) {
            this.setState({ selectedTabName: "Grades" });
        } else if (newValue === 1) {
            this.setState({ selectedTabName: "Attendance & Behaviour" });
        }
    };

    handleRedirectBack = () => {
        this.props.navigation?.goBack();
    }

    handleOpenSubmitDialog = async () => {
      const isDataIncomplete = await getStorageData('incompleteGradesSubmittedByTeacher');
      const isDataIncomplete2 = await getStorageData('incompleteBehaviourSubmittedByTeacher');
      const handleValue = JSON.parse(isDataIncomplete) || JSON.parse(isDataIncomplete2)
      this.setState({buttonOptionText:"lock", openSubmitDialog: true, isDataToBeSubmittedIncomplete: handleValue});
    }
    handleOpenrejectDialog = async () => {
      const isDataIncomplete = await getStorageData('incompleteGradesSubmittedByTeacher');
      this.setState({ buttonOptionText:"reject",openSubmitDialog: true, isDataToBeSubmittedIncomplete: isDataIncomplete === 'true'});
    }

    handleCloseSubmitDialog = () => {
        this.setState({ openSubmitDialog: false });
    }

    validateDataToBeSubmitted = (isDataToBeSubmittedIncomplete: boolean) => {
        this.setState({isDataToBeSubmittedIncomplete})
    };

    handleReturnGradesDialogOpen = () => {
        this.setState({openReturnGradesDialog: true});
    };

    closeReturnGradesDialog = () => {
        this.setState({openReturnGradesDialog: false});
    };

    setGradebookDataToStorage = async (data: any) => {
      await setStorageData("gradebookData", JSON.stringify(data));
  };

  getGradebookDataFromStorage =async () => {
      const gradebookData =await getStorageData("gradebookData");
      return gradebookData ? JSON.parse(gradebookData) : null;
  };
  handleHighlightBar = (event: any, columnId: number) => {
    
    const checkbox = event.target.checked;
    this.setState((prevState) => { 
      let selectedHighlightBar;
      if (checkbox) {
        selectedHighlightBar = [...prevState.selectedHighlightBar, columnId];
        
      } else {
        selectedHighlightBar = prevState.selectedHighlightBar.filter((id) => id !== columnId);
        
      }
      
      this.checkImplementation(this.state.schoolSubjectsList,selectedHighlightBar)
      const updatedBehaviorData = this.updateBehaviorData(this.state.studentBehaviorData, this.state.quarters, selectedHighlightBar);
      this.setState({studentBehaviorData:updatedBehaviorData},()=>{
        this.showInEditableMode();
      })
      return { selectedHighlightBar };
    });
  };

  checkImplementation(data: any[], selected: string | any[]) {
    const hasIncompleteGrades = data.some(item => {
      const { currentSemesterId, errorMessage } = item;
      return selected.includes(currentSemesterId) && errorMessage;
    });
    if (hasIncompleteGrades) {
      setStorageData("incompleteBehaviourSubmittedByTeacher", JSON.stringify(true));
    } else {
      setStorageData("incompleteBehaviourSubmittedByTeacher", JSON.stringify(false));
    }
  }

   updateBehaviorData = (data:any, quarters:any, selectedQuarterIds:any) => {
    // Create a map of columnId to quarter ID
    const quarterIdToPropertyMap = new Map(quarters.map((q: { columnId: any; id: any; }) => [q.columnId, q.id]));
  
    // Determine which properties should be set to true based on selectedQuarterIds
    const selectedQuarterIdsSet = new Set(selectedQuarterIds);
  
    return data.map((behavior: any) => {
      const updatedBehavior = { ...behavior };
  
      // Update each dynamic property based on quarter values
      quarterIdToPropertyMap.forEach((quarterId, columnId) => {
        const propertyName = `is${quarterId}Selected`; // e.g., "Mid Selected", "Final Selected"
        const isSelected = selectedQuarterIdsSet.has(columnId);
        
        updatedBehavior[propertyName] = isSelected;
      });
  
      return updatedBehavior;
    });
  };
  submitStudentGrades = async () => {

    const { selectedHighlightBar,userRole,buttonOptionText } = this.state;

  
    const storedSemesters = JSON.parse(await getStorageData("semesters") || "[]");
  
    const semesterAvgList = storedSemesters
      .filter((semester: { semesterId: string; semesterAvgId: number }) => 
        selectedHighlightBar.includes(parseInt(semester.semesterId))
      )
      .map((semester: { semesterId: string; semesterAvgId: number }) => semester.semesterAvgId);

    const dynamicFlags = this.state.studentBehaviorData.reduce((flags, item) => {
      Object.keys(item).forEach(key => {
        if (key.startsWith("is") && key.endsWith("Selected")) {
          const quarter = key.replace("is", "").replace("Selected", "").trim();
          if (item[key]) {
            flags.push(quarter);
          }
        }
      });
      return flags;
    }, []);
    
    
    const selectedQuarters = this.state.quarters.filter(quarter =>
      dynamicFlags.includes(quarter.id.trim())
    );
    const requestBody2 = this.state.studentBehaviorData.flatMap(item =>
      item.data.flatMap((skill: { subSkills: any[]; id: any; }) =>
        skill.subSkills.flatMap(subSkill => {
          return selectedQuarters.map(quarter => {
            const quarterValue = subSkill[quarter.id];
            const teacherInputValue = subSkill[`${quarter.id}teacherInput`]
            return {
              subskill_id: subSkill.id,
              total_subskill_data: quarterValue,
              skill_id: skill.id,
              semester_type: quarter.label,
              teacher_input:userRole === "Parent Teacher" ? teacherInputValue
              :(quarterValue.length>0 ?true:false)
            } 
          }).filter(Boolean);
        })
      )
    )
  
    const values = this.state.attendanceData
    .filter(att => att.label !== "Total")
    .map(att => parseFloat(att.value) || 0);

    const quarterlyAttendance = JSON.stringify(values);
    const totalAttendance = this.calculateTotal();
    const requestBodyForAttendance = {
      student_id:this.state.gradeStudenData.StudentId,
      total_attendance:totalAttendance,
      quarterly_attendance:quarterlyAttendance,
      teachers_comment:this.state.commentData,
      data:requestBody2
    }

    
    this.setState({
      semesterAvgSubmitted: semesterAvgList,
 
    },
     async() => 
      {
        if(userRole === "Registrar"){
          if(buttonOptionText === "lock"){
            this.handlePostRegistrarLocking()
          }
          else if(buttonOptionText === "reject"){
            this.handlePostRegistrarRejecting()
          }
          
        }
        else{
          this.handlePostTeacherGradebook()
          this.handleSubmitOfAttendanceAndBehaviour(requestBodyForAttendance)
        }
       
  }
);
    
  };
  handlePostTeacherGradebook = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
      "Content-Type": configJSON.validationApiContentType,
    };

    const { semesterAvgSubmitted } = this.state;

    const requestPayload: {
      data: {
        semesteravgs_id: Array<number>
      }
    } = {
      data: {
        semesteravgs_id: semesterAvgSubmitted
      }
    };

    let requestUrl = configJSON.teacherSubmitGradebookEndpoint;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostSubmitGradebook = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestPayload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePostTeacherGradebookResponse =async (responseJson: {
   message: Array<string>,
   errors: Array<{}>;
  }) => {
    if (responseJson?.message) {

      this.handleGetGradebookStudentsDetails(this.state.gradeStudenData.StudentId,this.state.gradeStudenData.SchoolId);

      this.setState({openSubmitDialog: false}) 
      toast.success(responseJson.message?.[0]);
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };



  handlePostRegistrarLocking = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
      "Content-Type": configJSON.validationApiContentType,
    };

    const { semesterAvgSubmitted } = this.state;

    const requestPayload: {
      data: {
        semesteravgs_id: Array<number>
      }
    } = {
      data: {
        semesteravgs_id: semesterAvgSubmitted
      }
    };

    let requestUrl = configJSON.RegistrarLockingGradebookEndpoint;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiRegistrarLockingGradebook = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestPayload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePostRegistrarLockingResponse = (responseJson: {
   message: Array<string>,
   errors: Array<{}>;
  }) => {
    if (responseJson?.message) {

      this.handleGetGradebookStudentsDetails(this.state.gradeStudenData.StudentId,this.state.gradeStudenData.SchoolId);
      this.setState({openSubmitDialog: false}) 
      toast.success(responseJson.message?.[0]);
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handlePostRegistrarRejecting = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
      "Content-Type": configJSON.validationApiContentType,
    };

    const { semesterAvgSubmitted } = this.state;

    const requestPayload: {
      data: {
        semesteravgs_id: Array<number>
      }
    } = {
      data: {
        semesteravgs_id: semesterAvgSubmitted
      }
    };

    let requestUrl = configJSON.RegistrarRejectingGradebookEndpoint;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiRegistrarRejectGradebook = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestPayload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePostRegistrarRejectResponse = (responseJson: {
   message: Array<string>,
   errors: Array<{}>;
  }) => {
    if (responseJson?.message) {

      this.handleGetGradebookStudentsDetails(this.state.gradeStudenData.StudentId,this.state.gradeStudenData.SchoolId);
      this.setState({openSubmitDialog: false}) 
      toast.success(responseJson.message?.[0]);
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  onCommentEdit = (commentData: any) => {
    this.setState({ commentData });
  };

  onSelectDeselectQuarters = (behaviourItem: any, quarterNumber: string) => {
    const behaviorData = this.state.studentBehaviorData.map((item: any) => {
      if (item.id === behaviourItem.id) {
        const updatedItem = { ...item };
        
       
        const quarterFlagKey = `is${quarterNumber}Selected`;
        updatedItem[quarterFlagKey] = !item[quarterFlagKey];
  
        return updatedItem;
      }
      return item;
    });
  
    const isChecked = !behaviourItem[`is${quarterNumber}Selected`];
  
    // Get the columnId from quarters based on the quarterNumber
    const quarter = this.state.quarters.find(q => q.id === quarterNumber);
  
    // If the quarter is found, get its columnId
    const columnId = quarter ? quarter.columnId : null;
  
    // Update selectedHighlightBar based on whether the checkbox is checked or unchecked
    const updatedSelectedQuarterIds = isChecked
      ? [...this.state.selectedHighlightBar, columnId]
      : this.state.selectedHighlightBar.filter(id => id !== columnId);

    this.checkImplementation(this.state.schoolSubjectsList,updatedSelectedQuarterIds)
    this.setState({ studentBehaviorData: behaviorData ,selectedHighlightBar:updatedSelectedQuarterIds});
  };
  updateBehaviourTableCellData = (
    newData: any,
    cellTitle: string,
    subskill: any,
    mainSkillItemId: number,
    behaviorItem: any,
    popoverId?: string
  ) => {
   
    const studentBehaviorData = this.state.studentBehaviorData;
    const subSkillItem = studentBehaviorData.find((behavior) => behavior.id === behaviorItem.id)?.data.find((mainskillItem: any) => mainskillItem.id === mainSkillItemId)?.subSkills.find((subskillItem: any) => subskillItem.id === subskill.id);
    if (subSkillItem) {
      subSkillItem[cellTitle] = newData;
      this.setState({ studentBehaviorData });
      if (popoverId) {
        setTimeout(() => {
          const popoverPlane = document.getElementById(popoverId);
          if (popoverPlane) {
            const popoverDropdown: any = Array.from(popoverPlane.children).find(
              (node) => Array.from(node.classList).includes("MuiPopover-paper")
            );
            if (popoverDropdown) {
              popoverDropdown.style.minWidth = newData.clientWidth;
              popoverDropdown.style.top = popoverDropdown.offsetTop - 10 + "px";
            }
          }
        });
      }
    }
  };

  updateBehaviourValueAndCloseDropdown = (
    newData: any,
    cellTitle: string,
    subskill: any,
    mainSkillId: number,
    behaviorItem: any,
    dropdownTarget: string
  ) => {
    this.updateBehaviourTableCellData(
      newData,
      cellTitle,
      subskill,
      mainSkillId,
      behaviorItem
    );
    this.updateBehaviourTableCellData(
      null,
      dropdownTarget,
      subskill,
      mainSkillId,
      behaviorItem
    );
  };
            
  handleSubmitOfAttendanceAndBehaviour = async (requestBodyForAttendance:any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
      "Content-Type": configJSON.validationApiContentType,
    };


 
    let requestUrl = configJSON.SubmitAttendanceBehaviourEndPoint;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostSubmitGradebook = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBodyForAttendance)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSubmitOfAttendanceAndBehaviourResponse =async (responseJson: {
   message: Array<string>,
   errors: Array<{}>;
  }) => {
    if (responseJson?.message) {
      this.handleGetAttendanceAndBehaviour(this.state.gradeStudenData.StudentId)
      this.setState({openSubmitDialog: false}) 
      toast.success(responseJson.message?.[0]);
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };
                  

  handleGetAttendanceAndBehaviour= async (studentId:number) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetGradebookBehaviourTeacher = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetAttendanceDetails}?student_id=${studentId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
     
    requestMessage.addData(  
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetAttendanceAndBehaviourResponse = (responseJson: {
    data: BehaviourResponse;
    errors: Array<{}>;
    teacher_comment :boolean;
  }) => {
    if (responseJson?.data) {
      const behaviourData:any= this.transformAttendanceAndBehaviourResponseData(responseJson.data)
      
      const dataObj:any = responseJson.data
      const maxEvaluationKeys = Math.max(...dataObj.map((d: { attributes: { evaluation_keys: string | any[]; }; }) => d.attributes.evaluation_keys.length));

      
      const evaluationKeysData:any =  responseJson.data.map(d => {
        const evalKeys = d.attributes.evaluation_keys;
        const obj: EvaluationKeysData = {
          title: d.attributes.obeservation_title
        };

       
        for (let i = 0; i < maxEvaluationKeys; i++) {
          obj[`key${i + 1}`] = evalKeys[i] ? evalKeys[i].title : 'NA';
        }

        return obj;
      });
     
   this.setState({studentBehaviorData:behaviourData,evaluationKeysData:evaluationKeysData,maxEvaluationKeys:maxEvaluationKeys,teacherComment:responseJson.teacher_comment },()=>{
     this.handleGetAttendanceAndBehaviourDetails(this.state.gradeStudenData.StudentId)
   })

    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
  };
  transformAttendanceAndBehaviourResponseData = (inputData:BehaviourResponse)=>{
    const studentBehaviorData = inputData.map((behavior: { attributes: { evaluation_keys: any[]; obeservation_title: any; id: any; skills: any[]; }; }) => {
      const menu = behavior.attributes.evaluation_keys.map((key: { title: any; }) => key.title);

      const quarterFlags = this.state.quarters.reduce((acc, quarter) => {
        acc[`is${quarter.id}Selected`] = false; 
        return acc;
      }, {});
    
      return {
        name: behavior.attributes.obeservation_title,
        id: behavior.attributes.id,
        data: behavior.attributes.skills.map((skill: { id: any; skill_title: any; sub_skills: any[]; }):any => ({
          id: skill.id,
          title: skill.skill_title,
          subSkills: skill.sub_skills.map((subSkill: { id: any; title: any; }) => ({
            id: subSkill.id,
            title: subSkill.title,
            ...this.state.quarters.reduce((acc, quarter) => {
              acc[quarter.id] = ""; 
              acc[`${quarter.id}teacherInput`] = false; 
              acc[`${quarter.id}Target`] = ""; 
              return acc;
            }, {})
          }))
        })),
        menu,
        ...quarterFlags 
      };
    });
    return studentBehaviorData
  }

  handleGetAttendanceAndBehaviourDetails= async (studentId:number) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetGradebookBehaviourTeacherDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetAttendanceDetailsAndBehaviour}?student_id=${studentId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetAttendanceAndBehaviourDetailsResponse = (responseJson: {
    data: GetBehaviourData;
    errors: Array<{}>;
    teacher_comment :boolean;
  }) => {
    if (responseJson.data !== null) {

 
  
      const quarterlyAttendance = JSON.parse(responseJson.data.attributes.quarterly_attendance);
      const total = responseJson.data.attributes.total; 
      const updatedCommentData = responseJson.data.attributes.teachers_comment
      const updatedAttendanceData = this.state.attendanceData.map((quarter) => {
        if (quarter.id.trim() === "Total") {
          return {
            ...quarter,
            value: total 
          };
        }
        if (quarter.id.trim() !== "Total" && this.state.quarters.some(q => q.id.trim() === quarter.id.trim())) {
          const index = this.state.quarters.findIndex(q => q.id.trim() === quarter.id.trim());
          return {
            ...quarter,
            value: quarterlyAttendance[index] || "" 
          };
        }
        return quarter;
      });
      const requestBody ={
        data:responseJson.data
      }
     const updatedSkillsDataWithQuarters = this.mapBehaviorToSkillsArrayWithDynamicQuarters2(requestBody, this.state.studentBehaviorData, this.state.quarters);
      this.setState({commentData:updatedCommentData,studentBehaviorData:updatedSkillsDataWithQuarters,attendanceData:updatedAttendanceData })
    
    
    }else if(responseJson.data === null){
      console.error("data is empty")
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
  };  
  mapBehaviorToSkillsArrayWithDynamicQuarters2 = (inputData: {data:GetBehaviourData},skillData: any[],quarters: any[]) =>{
  const transformedData = skillData.map(skill => {
    const newData = skill.data.map((skillItem: { subSkills: any[]; id: any; }) => {
      const subSkills = skillItem.subSkills.map(subSkill => {
        const behaviourItems = inputData.data.attributes.behaviour.filter((b: { skill_id: any; subskill_id: any; }) =>
          b.skill_id === skillItem.id && b.subskill_id === subSkill.id
        );
    
        const subSkillData = { ...subSkill };
        quarters.forEach(quarter => {
          const behaviourItem = behaviourItems.find((b: { semester_type: any; }) => b.semester_type === quarter.id);
          if (behaviourItem) {
            subSkillData[quarter.id] = behaviourItem.total_subskill_data;
            subSkillData[`${quarter.id}teacherInput`] = behaviourItem.teacher_input;
          }
        });
  
        return subSkillData;
      });
  
      return {
        ...skillItem,
        subSkills
      };
    });
  
    return {
      ...skill,
      data: newData
    };
  });
  return transformedData
}
showInEditableMode = () => {
  const isRegistrar=this.state.userRole === "Registrar" ? true : false
  let quarterYetToBeFilled = false;
  this.state.studentBehaviorData.forEach(student => {
    this.state.quarters.forEach(quarter => {
      const quarterId = quarter.id
      
      if (student[`is${quarterId}Selected`]) {
        student.data.forEach((skill: { subSkills: any[]; }) => {
          skill.subSkills.forEach(subSkill => {
           
            if (subSkill[quarterId] === "" ) {
             
              quarterYetToBeFilled = true;
            }
          });
        });
      }
    });
  });
  setStorageData("incompleteGradesSubmittedByTeacher", JSON.stringify(quarterYetToBeFilled));
  return isRegistrar ? false : true
};

handleRejectGradesApi = async (requestBody:any) => {
  showCustomLoader();
  const token = await getStorageData("token");
  const header = {
    token,
    "Content-Type": configJSON.validationApiContentType,
  };

  let requestUrl = configJSON.RejectGradesEdnpoint;
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiRejectGradeId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    requestUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(requestBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

handleRejectGradesResponse =async (responseJson: {
 data: any,
 errors: Array<{}>;
}) => {
  if (responseJson?.data) {
    this.handleGetReturnApi(this.state.gradeStudenData.StudentId,this.state.gradeStudenData.SchoolId)
    this.closeReturnGradesDialog()
    this.handleOpenrejectDialog()
  } else {
    displayApiErrorMsg(responseJson?.errors, this.props.navigation);
  }
  hideCustomLoader();
};
handleReasonChange = (subjectId: any, newValue: any) => {
  this.setState((prevState) => ({
    SubjectNameList: prevState.SubjectNameList.map((subject) =>
      subject.subjectId === subjectId ? { ...subject, value: newValue } : subject
    )
  }));
};
handleRejectGrades=()=>{
  const { SubjectNameList, existingReturnData } = this.state;

const rejectionData = SubjectNameList
  .filter(subject => subject.value)
  .map(subject => {
    const matchedData = existingReturnData.find(
      existingItem => existingItem.courseId === subject.subjectId
    );

    return {
      rejection_reason: subject.value,
      course_id: subject.subjectId,
      ...(matchedData ? { id: matchedData.id } : {}) 
    };
  });

  const requestBody = {
      school_year_id:this.state.gradeStudenData.SchoolId,
      student_id: this.state.gradeStudenData.StudentId,
      return_grade_reasons_attributes: rejectionData
  }
  this.handleRejectGradesApi(requestBody);
}
handleGetReturnApi= async (studentId:number,schoolId:number) => {
  showCustomLoader();
  const token = await getStorageData("token");
  const header = {
    token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiGetReturnCommentsId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.RejectGradesEdnpoint}?student_id=${studentId}&school_year_id=${schoolId}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
   
  requestMessage.addData(  
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};
handleGetReturnResponse = (responseJson: {
  data:any;
  errors: Array<{}>;
  teacher_comment :boolean;
}) => {
  if (responseJson?.data) {
    const formattedData = responseJson.data?.attributes?.return_grade_reasons?.map((reason: { course: {id:any; course_title: any; }; rejection_reason: any;id:any }) => {
      return {
        id:reason.id,
        courseId:reason.course.id,
        subjectName: reason.course.course_title, 
      };
    }) || [];
    this.setState({existingReturnData:formattedData})
  } else {
    displayApiErrorMsg(responseJson?.errors, this.props.navigation);
  }
};
    // Customizable Area End
}
