Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfthresholdforgrades2";
exports.labelBodyText = "cfthresholdforgrades2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.btnExample="Sumbit"
exports.btnReturn="Lock" 
exports.btnText="Parent Teacher" 
exports.MessagePopup = "Submit"
exports.getTeacherSchoolYear = "bx_block_school_year/school_years";
exports.teacherCurriculumsEndpoint = "bx_block_curriculum/curriculums";
exports.teacherGradebookDDEndpoint = "account_block/users/get_student_for_teacher_gradebook";
exports.teacherGradebookStudentsListEndpoint = "account_block/users/get_student_gradedata_semester_wise";
exports.teacherSubmitGradebookEndpoint = "account_block/users/teacher_submit_student_grade_to_registrar";
exports.gradebookStudentDetailsEndpoint = "account_block/users/get_grades_for_particular_student_coursewise"
exports.gradebookStudentAssignmentDetailsEndpoint ="/account_block/users/get_assignment_details_of_course_student"
exports.registrarSubmitGradebookEndpoint="account_block/users/registrar_locking_the_grades"
exports.getTemplateDataEndpoint ="bx_block_cfviewreportcardofficialandunofficial/grade_book_templates/get_grade_book_data_for_student"
// Customizable Area End