// Customizable Area Start
import React from "react";
import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import TenantAdminSetupDashboardController, {
  Props
} from "./TenantAdminSetupDashboardController.web"; 
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import SchoolYear from "../../cfcurriculmschoolyearcreation2/src/SchoolYear.web";
import TabPanel from "../../../components/src/TabPanel.web";
import TenantAdminSetupGrade from "../../cfgradingsystem2/src/TenantAdminSetupGrade.web";
import TenantAdminSetupSubjects from "../../cfcolorindicators5/src/TenantAdminSetupSubjects.web";
import TenantAdminSetupCurriculum from "../../cfcurriculmschoolyearcreation2/src/TenantAdminSetupCurriculum.web";
import TenantAdminSetupClassManagement from "../../cfcoursemanagement/src/TenantAdminSetupClassManagement.web";
import NewClassDialog from "../../cfcoursemanagement/src/NewClassDialog.web";
import CustomDragAndDropFileUpload from "../../../components/src/CustomDragAndDropFileUpload";
import CustomButton from "../../../components/src/CustomButton.web";
import TenantAdminSetupSchoolCalendar from "../../cfcurriculmschoolyearcreation2/src/TenantAdminSetupSchoolCalendar.web";
import TeacherAssignment from "../../assessmenttest/src/TeacherAssignment/TeacherAssignment.web";
import NewTeacherAssignmentDialog from "../../assessmenttest/src/TeacherAssignment/NewTeacherAssignmentDialog.web";
import  TenantAdminGradeBookList  from "./TenantAdminGradeBookList.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    btnRoot: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    mainPagePadding: {
      paddingRight: "20px"
    },
    pageTitle: {
      fontSize: 34,
      fontWeight: 700,
    },
    tabLabel: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: "23px",
      color: "#090909",
      textTransform: "capitalize",
      padding: theme.spacing(2.5, 0)
    },
    inputLabelText: {
      fontSize: 14,
      fontWeight: 400,
      color: "#888888",
    },
    breadCrumbRoot: {
      marginBottom: theme.spacing(2),
    },
    topBtnRoot: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "25px",
    },
    topBtn: {
      borderRadius: "12px",
      backgroundColor: "#0D46BC",
      color: "#ffffff",
      padding: "15px",
      textTransform: "capitalize",
      fontSize: 18,
      fontWeight: 500,
    },
    appBar: {
      borderRadius: "12px 12px 0 0",
      backgroundColor: "#ffffff",
      boxShadow: "none",
      borderBottom: "2px solid #F1F1F1",
      '& .MuiSvgIcon-root': {
        color: "#0D46BC",
        fontSize: "xx-large",
      },
    },
    tabs: {
      "& div.MuiTabs-scroller": {
        borderBottom: "1px solid rgb(241, 241, 241)",
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
          justifyContent: "space-between",
        },
      },
    },
    tabButton: {
      fontSize: "16px",
      minWidth: "14.28%",
      textTransform: "capitalize",
      textAlign: "center",
    },
    tenantSetupDashboardMainContainer: {
      height: "132vh",
      overflowX: "auto",
      borderRadius: "10px",
      padding: "0px 0px 8px 0px",
    },
    activeTab: {
      color: "#0D46BC",
      fontWeight: "bold",
      fontSize: 20,
      textTransform: "capitalize",
    },
    importCsvBtn: {
      marginRight: "20px",
      padding: "15px",
      color: "#0D46BC",
      border: "1px solid #0D46BC",
      borderRadius: "12px",
      textTransform: "capitalize",
      fontSize: 18,
      fontWeight: 600,
    },
  })
// Customizable Area End

// Customizable Area Start
export class TenantAdminSetupDashboard extends TenantAdminSetupDashboardController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const handleFileDrop = (file: File) => {
      // Handle the dropped file here
      console.warn('Dropped file:', file);
    };
    const { classes } = this.props;
    const { tabValue, newSchoolYearDialog, newGradeDialogOpen, newSubjectDialog, newCurriculumDialog, buttonConfig, newClassManagementDialog, csvDialog, newSchoolCalendarDailog, newAssignmentDialog ,showNewGrade} = this.state;
    return (
      <Box className={`${classes.childMainWrapperPadding} ${classes.mainPagePadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`} height={"100%"}>
        <Box className={classes.breadCrumbRoot}>
          <Typography className={`${classes.textCommonColor} ${classes.fontText12}`} component={"span"}>
            {`Setup `}
          </Typography>
          <Typography className={`${classes.textButton} ${classes.fontBold600} ${classes.fontText12}`} component={"span"}>
            {`/ ${buttonConfig[tabValue].type}`}
          </Typography>
        </Box>
        <Box className={classes.topBtnRoot}>
          <Typography className={`${classes.textPrimaryColor} ${classes.fontText34} ${classes.fontBold600}`}>Setup</Typography>
          <Box>
            {(tabValue === 4 || tabValue === 5 )&& (
              <CustomButton
                buttonId={'csv-dialog-btn'}
                buttonVariant="outlined"
                btnStyle={manageEventbtnStyle}
                isDisabled={false}
                type={"button"}
                handleButtonClick={() => this.setState({ csvDialog: true })}
                btnText="Import CSV"
              />
            )}
            {tabValue == 7 && (
              <CustomButton
                btnText={"Manage Events"}
                buttonId={"manage-event-btn"}
                buttonVariant={"outlined"}
                isDisabled={false}
                type={"button"}
                handleButtonClick={this.handleNavigate}
                btnStyle={manageEventbtnStyle} />
            )}
            {(tabValue == 6 && showNewGrade) ? 
            " ":
            <CustomButton
              btnText={buttonConfig[tabValue].label}
              buttonId={buttonConfig[tabValue].id}
              buttonVariant={"contained"}
              isDisabled={false}
              type={"button"}
              handleButtonClick={buttonConfig[tabValue].handleClick}
              btnStyle={btnStyle} />
            }
          </Box>
        </Box>
        <Box>
          <Box className={`${classes.bgColor} ${classes.tenantSetupDashboardMainContainer}`}>
              <Tabs
                id="tab-change-wrapper"
                variant="scrollable"
                TabIndicatorProps={{
                  style: {
                    background: "none",
                  },
                }}
                scrollButtons="auto"
                className={`${classes.tabs} ${classes.customTabs}`}
                value={tabValue}
                onChange={this.handleChange}
              >
                <Tab id="school-year-tab" className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`} label={"School Year"} />
                <Tab id="grade-tab" className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`} label={"Grade"} />
                <Tab id="subject-tab" className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`} label={"Subjects"} />
                <Tab id="curriculum-tab" className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`} label={"Curriculum"} />
                <Tab id="class-management-tab" className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`} label={"Class Management"} />
                <Tab id="teacher-assignment-tab" className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`} label={"Teacher Assignment"} />
                <Tab id="grade-book-tab" className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`} label={"Grade book"} />
                <Tab id="school-calendar-tab" className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`} label={"School Calendar"} />
              </Tabs>
            <TabPanel value={tabValue} index={0}>
              <SchoolYear navigation={this.props.navigation} openNewSchoolYearDialog={newSchoolYearDialog} handleCloseSchoolYearDialog={() => this.setState({ newSchoolYearDialog: false })} />
            </TabPanel> 
            <TabPanel value={tabValue} index={1}>
              <TenantAdminSetupGrade navigation={this.props.navigation} openNewGradeDialog={newGradeDialogOpen} handleCloseNewGradePopup={() => this.setState({ newGradeDialogOpen: false })} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <TenantAdminSetupSubjects navigation={this.props.navigation} openNewSubjectDialog={newSubjectDialog} handleCloseNewSubjectPopup={() => this.setState({ newSubjectDialog: false })} />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <TenantAdminSetupCurriculum navigation={this.props.navigation} openNewCurriculumDialog={newCurriculumDialog} handleCloseCurriculumDialog={() => this.setState({ newCurriculumDialog: false })} />
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
              <TenantAdminSetupClassManagement navigation={this.props.navigation} openNewClassManagementDialog={newClassManagementDialog} handleCloseNewClassManagementDialog={() => this.setState({ newClassManagementDialog: false })}  />
            </TabPanel>
            <TabPanel value={tabValue} index={7}>
              <TenantAdminSetupSchoolCalendar navigation={this.props.navigation} openNewSchoolCalendarDialog={newSchoolCalendarDailog} handleCloseSchoolCalendarDialog={() => this.setState({ newSchoolCalendarDailog: false })} />
            </TabPanel>
            <TabPanel value={tabValue} index={5}>
              <TeacherAssignment navigation={this.props.navigation} openNewTeacherAssignmentDialog={newAssignmentDialog} handleCloseTeacherAssignmentDialog={() => this.setState({ newAssignmentDialog: false })} />
            </TabPanel>
            <TabPanel value={tabValue} index={6}>
              <TenantAdminGradeBookList navigation={this.props.navigation} showNewGrade={showNewGrade} handleClose={this.handleCloseGradebook} handleEditGrade={this.handleEditGrade} gradeId={this.state.gradeId} />
            </TabPanel>
          </Box>
        </Box>
        <CustomDragAndDropFileUpload 
          open={csvDialog} 
          data-testid="import-csv-file"
          handleClose={this.handleCloseCsv} 
          onFileDrop={handleFileDrop} 
          onConfirm={this.handleImportCSVApi}
          downloadCSVFile={this.downloadCSVFile}
          message={tabValue === 4 ? "Class Mangement" : "Teacher Assignment"}
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const btnStyle = {
  borderRadius: "12px",
  padding: "15px",
  textTransform: "capitalize",
  fontSize: 18,
  fontWeight: 600,
} as const;


const manageEventbtnStyle = {
  borderRadius: "12px",
  padding: "15px",
  textTransform: "capitalize",
  fontSize: 18,
  fontWeight: 600,
  marginRight: "15px",
  boxShadow: "none",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TenantAdminSetupDashboard);
// Customizable Area End


