import React from "react";
// Customizable Area Start
import { Box, Typography, Grid, Tabs, Tab } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import TenantCourseManagementManageRequestMainController, {
  Props,
  manageRequestsTabsList,
} from "./TenantCourseManagementManageRequestMainController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import {
  TabPanel,
  a11yProps,
} from "../../../components/src/CustomTabPanel.web";
import TenantCourseManagementMainTabComponentWeb from "./TenantCourseManagementMainTabComponent.web";
import TenantCourseManagementAssignCourseToCatalogModalWeb from "./TenantCourseManagementAssignCourseToCatalogModal.web";
import TenantCourseManagementRejectAcceptRequestModalWeb from "./TenantCourseManagementRejectAcceptRequestModal.web";
import TenantCourseManagementRejectReasonModalWeb from "./TenantCourseManagementRejectReasonModal.web";
import TenantCourseManagementAssignSuccessModalWeb from "./TenantCourseManagementAssignSuccessModal.web";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";

const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    manageRequestsMainContainer: {
      height: "100%",
      overflowX: "auto",
      borderRadius: "10px",
      padding: "0px 0px 8px 0px",
    },
    tabs: {
      "& div.MuiTabs-scroller": {
        borderBottom: "1px solid #979797",
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
          justifyContent: "space-between",
        },
      },
    },
    tabButton: {
      fontSize: "16px",
      minWidth: "33.33%",
      textTransform: "capitalize",
      textAlign: "center",
    },
    tabPanel: {
      padding: "15px 0px 15px 0px",
    },
    courseMngTopHeader: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  });

// Customizable Area End

export class TenantCourseManagementManageRequestMain extends TenantCourseManagementManageRequestMainController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      selectedManageRequestTabIndex,
      manageRequestInputConfig,
      tableActionButtonConfig,
      tableBodyData,
      tableHeaderColumns,
      assignCourseToCatalogInputConfig,
      openAssignCourseToCatalogModal,
      openAssignConfirmModal,
      rejectRequest,
      isFromAssignCourseToCatalog,
      openRejectReasonPopup,
      requestRejectionReason,
      openAssignSuccessPopup,
      paginationData,
      loading,
      acceptRejectRequestData,
      assignCourseCatalogName,
      rowWiseTableActionConfig,      
    } = this.state;

    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.mainPagePadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        id="manage-request-main-box"
        height={"100%"}
      >
        <Grid container direction="row">
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText12} ${classes.smallTopHeader}`}
                component={"span"}
              >
                {`Course Management / Teacher Courses / My Requests`}
              </Typography>
              <Typography
                component={"span"}
                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.textButton}`}
              >
                {` / ${manageRequestsTabsList[selectedManageRequestTabIndex].label}`}
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.pageName} item xs={12}>
            <Box className={`${classes.courseMngTopHeader}`}>
              <Box
                id="manage-request-back-icon"
                mr={"8px"}
                onClick={() => this.handleGoBackToCourseMngMain()}
              >
                <KeyboardBackspaceIcon
                  fontSize="large"
                  className={`${classes.textPrimaryColor} ${classes.cursorPointer}`}
                />
              </Box>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText34} ${classes.fontBold600}`}
                component={"span"}
              >
                My Requests
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              className={`${classes.bgColor} ${classes.manageRequestsMainContainer}`}
            >
              <Box>
                <Tabs
                  value={selectedManageRequestTabIndex}
                  variant="scrollable"
                  TabIndicatorProps={{
                    style: {
                      background: "none",
                    },
                  }}
                  scrollButtons="auto"
                  data-testid="tenant-course-management-manage-request-tabs"
                  className={`${classes.tabs}`}
                  onChange={this.handleChangeManageRequestTab}
                  aria-label="tenant-course-management-manage-request-tabs"
                >
                  {manageRequestsTabsList.map((tabItem: any, index: number) => (
                    <Tab
                      key={tabItem.id}
                      data-testid={`tenant-course-management-manage-request-${tabItem.label}-tab`}
                      label={tabItem.label}
                      wrapped
                      {...a11yProps(index)}
                      className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`}
                    />
                  ))}
                </Tabs>
              </Box>
              <Box className={`${classes.tabPanel}`}>
                <TabPanel value={selectedManageRequestTabIndex} index={0}>
                  {tableBodyData.length === 0 && !loading ?
                    <CustomEmptyResultComponent message="No pending requests found" />
                    :
                    <TenantCourseManagementMainTabComponentWeb
                      handleSearch={this.handleSearchRequests}
                      rowWiseTableActionConfig={rowWiseTableActionConfig}
                      showRowWiseActionMenu={true}
                      handleSelectDropdownValue={this.handleSelectDropdownValue}
                      inputDDConfigs={manageRequestInputConfig}
                      renderDDMenuItem={this.renderDDMenuItem}
                      tableBodyData={tableBodyData}
                      addTablePadding
                      tableHeaderColumns={tableHeaderColumns}
                      showMenuIconInTable
                      showTablePagination={false}
                      tenantCourseMngTabCategory="pending_requests"
                      pagination={paginationData}
                      getPaginationNumber={(page: number) => this.handleRequestPaginationChange(page)}
                    />
                  }
                </TabPanel>
                <TabPanel value={selectedManageRequestTabIndex} index={1}>
                  {tableBodyData.length === 0 && !loading ?
                    <CustomEmptyResultComponent message="No approved requests found" />
                    :
                    <TenantCourseManagementMainTabComponentWeb
                      tenantCourseMngTabCategory="approved_requests"
                      handleSearch={this.handleSearchRequests}
                      showTablePagination={false}
                      handleSelectDropdownValue={this.handleSelectDropdownValue}
                      inputDDConfigs={manageRequestInputConfig}
                      renderDDMenuItem={this.renderDDMenuItem}
                      rowWiseTableActionConfig={rowWiseTableActionConfig}
                      showRowWiseActionMenu={true}
                      tableBodyData={tableBodyData}
                      tableHeaderColumns={tableHeaderColumns}
                      showMenuIconInTable
                      addTablePadding
                      pagination={paginationData}
                      getPaginationNumber={(page: number) => this.handleRequestPaginationChange(page)}
                    />
                  }
                </TabPanel>
                <TabPanel value={selectedManageRequestTabIndex} index={2}>
                  {tableBodyData.length === 0 && !loading ?
                    <CustomEmptyResultComponent message="No rejected requests found" />
                    :
                    <TenantCourseManagementMainTabComponentWeb
                      tenantCourseMngTabCategory="rejected_requests"
                      handleSelectDropdownValue={this.handleSelectDropdownValue}
                      handleSearch={this.handleSearchRequests}
                      inputDDConfigs={manageRequestInputConfig}
                      renderDDMenuItem={this.renderDDMenuItem}
                      rowWiseTableActionConfig={rowWiseTableActionConfig}
                      showRowWiseActionMenu={true}
                      tableBodyData={tableBodyData}
                      tableHeaderColumns={tableHeaderColumns}
                      showMenuIconInTable
                      showTablePagination={false}
                      addTablePadding
                      pagination={paginationData}
                      getPaginationNumber={(page: number) => this.handleRequestPaginationChange(page)}
                      requestIcons={true}
                    />
                  }
                </TabPanel>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <TenantCourseManagementAssignCourseToCatalogModalWeb
          open={openAssignCourseToCatalogModal}
          approvalDetailsConfig={assignCourseToCatalogInputConfig}
          handleAssign={this.handleAssignConfirm}
          handleCancel={this.handleCloseAssignCourseToCatalogModal}
          handleClose={this.handleCloseAssignCourseToCatalogModal}
          handleSelect={this.handleSelectAssignCourseToCatalogDDValue}
          renderMenu={this.renderDDMenuItem}
          handleText={(e: any) => e.preventDefault()}
        />
        <TenantCourseManagementRejectAcceptRequestModalWeb
          open={openAssignConfirmModal}
          handleClose={this.handleAssignRejectApproveConfirmModalClose}
          detailsForApproval={{
            grade: acceptRejectRequestData?.grade,
            subject: acceptRejectRequestData?.subjectName,
            topic: acceptRejectRequestData?.title?.name,
            catalog: assignCourseCatalogName,
          }}
          rejectRequest={rejectRequest}
          handleCancel={this.handleAssignRejectApproveConfirmModalClose}
          handleYes={this.handleAssignRejectApproveConfirmModalConfirm}
          isFromAssignCourseToCatalog={isFromAssignCourseToCatalog}
        />
        <TenantCourseManagementRejectReasonModalWeb
          open={openRejectReasonPopup}
          handleText={this.handleRejectReason}
          handleAdd={this.handleAddRejectReason}
          handleCancel={this.handleRejectReasonModalClose}
          handleClose={this.handleRejectReasonModalClose}
          rejectionReason={requestRejectionReason}
        />
        <TenantCourseManagementAssignSuccessModalWeb
          open={openAssignSuccessPopup}
          handleClose={this.handleAssignSuccessModalClose}
          handleYes={this.handleAssignSuccessModalClose}
          mainInfoText={acceptRejectRequestData?.title?.name + ' Added Successfully'}
          successDetails={{
            catalog: assignCourseCatalogName,
            topic: acceptRejectRequestData?.title?.name,
          }}
        />
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(
  TenantCourseManagementManageRequestMain
);
// Customizable Area End
