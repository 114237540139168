// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedCourseTitle: string;
  openConfirmDialog: boolean;
  openSuccessDialog: boolean;
  selectedValue: any;
  selectStartDate: any;
  selectEndDate:any;
  selectedGradingComponentDDValue:any;
  gradingComponent: Array<any>;
  selectedLessonDDValue: any;
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class CatchMeUpModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      loading: false,
      selectedCourseTitle: "",
      openConfirmDialog: false,
      openSuccessDialog: false,
      selectedValue: null,
      selectStartDate: new Date(),
      selectEndDate:new Date(),
      selectedGradingComponentDDValue:"",
      selectedLessonDDValue:'',
      gradingComponent: [
        {
          value: 1,
          label: "Lessons 10A",
          id: 1,
        },
        {
          value: 2,
          label: "Lessons 10B",
          id: 2,
        },
        {
          id: 3,
          label: "Lessons 9A",
          value: 3,
        },
        {
          value: 4,
          label: "Assignment",
          id: 4,
        },
      ]
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();

  }
  handleOpenSuccessDialog = () => {
    this.setState({ openSuccessDialog: true });
  }

  handleCloseSuccessDialog = () => {
    this.setState({ openConfirmDialog: false });
    this.setState({ openSuccessDialog: false });
  }

  handleChange = async(event: any) => {
    this.setState({
      selectedValue: event.target.value
    })
  };

  selectStartDate = (date: any) => {
    this.setState({
      selectStartDate: date
    })
  }
  selectEndDate = (date: any) => {
    this.setState({
      selectEndDate: date
    })
  }

  handleShiftLessonDropdownValues = (event: React.ChangeEvent<{
    name?: any;
    value: unknown;
}>, child: React.ReactNode) => {
    const { name, value } = event.target;
    let fieldValues : any = value ;
    if(fieldValues){
      this.setState((prev) => {
        return {
            ...prev,
            [name] : fieldValues,
        };
     });
    }
  };
  renderShiftLessonComponenttDDValues = (selected:any) => {
    if (!selected || selected.length === 0) {
      return "3 lessons per day";
    }
    const { gradingComponent } = this.state;
    const selectedGradingComponents = gradingComponent.find((grade : any) => grade?.id === selected);
    return selectedGradingComponents?.label;
  };
  // Customizable Area End
}
// Customizable Area End