// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { displayApiErrorMsg, getMessageData, hideCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { GradeBook } from "./TenantAdminGradeBookListController.web";
const configJSON = require("./config.js");
const baseURL = require("../../../framework/src/config.js").baseURL;
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface BtnConfig {
  id: string;
  label: string;
  type: string;
  handleClick: () => void;
}

interface S {
  // Customizable Area Start
  loading: boolean;
  tabValue: number;
  newSchoolYearDialog: boolean;
  newGradeDialogOpen: boolean;
  newSubjectDialog: boolean;
  newCurriculumDialog: boolean;
  newClassManagementDialog: boolean;
  newSchoolCalendarDailog: boolean;
  buttonConfig: Array<BtnConfig>;
  csvDialog: boolean;
  newAssignmentDialog: boolean;
  showNewGrade:boolean,
  gradeId:number,
  gradeBookCount:number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TenantAdminSetupDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = "";
  createGradeBookTenantApiCallId:string="";
  importCSVClassManagementId:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
   
    this.state = {
      loading: false,
      tabValue: 0,
      csvDialog: false,
      newSchoolYearDialog: false,
      newGradeDialogOpen: false,
      newSubjectDialog: false,
      newCurriculumDialog: false,
      newClassManagementDialog: false,
      newSchoolCalendarDailog: false,
      newAssignmentDialog: false,
      showNewGrade:false,
      gradeId:-1,
      buttonConfig: [{
        id: "addNewSchoolYearBtn",
        label: "Add New School Year",
        type: "School Year",
        handleClick: () => {
          this.setState({
            newSchoolYearDialog: true
          })
        }
      },
      {
        id: "addNewGradeBtn",
        type: "Grade",
        label: "Add New Grade",
        handleClick: () => {
          this.setState({
            newGradeDialogOpen: true
          })
        }
      },
      {
        id: "addNewSubjectBtn",
        type: "Subject",
        label: "Add New Subject",
        handleClick: () => {
          this.setState({
            newSubjectDialog: true
          })
        }
      },
      {
        id: "addNewCurriculumBtn",
        type: "Curriculum",
        label: "Add New Curriculum",
        handleClick: () => {
          this.setState({
            newCurriculumDialog: true
          })
        }
      },
      {
        id: "addNewClassManagementBtn",
        type: "Class Management",
        label: "Add New Class",
        handleClick: () => {
          this.setState({
            newClassManagementDialog: true
          })
        }
      },
      {
        id: "newAssignmentBtn",
        type: "Teacher Assignment",
        label: "Add New Assignment",
        handleClick: () => {
          this.setState({
            newAssignmentDialog: true
          })
        }
      },
      {
        id: "gradeBookBtn",
        type: "Grade book",
        label: "Add New Grade Book",
        handleClick: () => {
          const request ={
            title:`Grade Book ${this.state.gradeBookCount}`,
            reporting_type: 0,
          }
          this.handleCreatePostGradeBookTenantApi(request)
        }
      },
      {
        id: "newSchoolCalendarBtn",
        type: "School Calendar",
        label: "Add New School Calendar",
        handleClick: () => {
          this.setState({
            newSchoolCalendarDailog: true
          })
        }
      },
      ],
      gradeBookCount: 1,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // const historyLocationState = this.props.navigation?.getHistoryState();
    // if(historyLocationState){
    //   this.setState({tabValue:historyLocationState.tabOpen,showNewGrade:true,gradeId:historyLocationState.gradeBookId},()=>{
    //     this.handleEditGrade(this.state.gradeId)
    //   })
    // }
      // console.log("opppppp",historyLocationState)
    const savedCount = await getStorageData('gradeBookCount');
    if(savedCount){
      const newCount =savedCount+1
      this.setState({ gradeBookCount: newCount },async () => {
        await setStorageData('gradeBookCount',newCount );
      });
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleCloseGradebook=()=>{
    this.setState({
      showNewGrade:false
    })
  }
  handleEditGrade = (gradeId:number) => {
    this.setState({
      showNewGrade: true,
      gradeId: gradeId, 
    });
  };
  handleNavigate = ()=>{
   this.props.navigation?.navigate("ManageEvents")
  }

  handleChange = (event: any, newValue: number) => {
    this.setState({
      tabValue: newValue,
    });
  };

  handleCloseCsv = () => {
    this.setState({ csvDialog: false })
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = getMessageData(
        message
      );
 
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.createGradeBookTenantApiCallId) {
          this.handlePostGradeBookTenantResponse(responseJson);
        }else if(apiRequestCallId === this.importCSVClassManagementId){
          this.handleImportCSVResponse(responseJson)
        }
      }
    }
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const sessionData = message.getData(getName(MessageEnum.SessionResponseData));
      if (sessionData) {
       
        const {tabOpen,gradeBookId} = sessionData
      this.setState({tabValue:tabOpen,showNewGrade:true,gradeId:gradeBookId},()=>{
        this.handleEditGrade(this.state.gradeId)
      })
      }
      
    }
  }

  handleCreatePostGradeBookTenantApi =async (requestBody:{title:string}) =>{
    const requestUrl = configJSON.gradeBookTenantEndpoint;
    const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createGradeBookTenantApiCallId= requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  handlePostGradeBookTenantResponse = async(responseJson: {
    data:GradeBook;
    errors: Array<{}>;})=>{
      if (responseJson?.data) {  
   
      this.setState({
        showNewGrade: true,
        gradeId:responseJson.data.attributes.id
      })
      toast.success("Grade Book created successfully!");
      } else {
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      }
      hideCustomLoader();
  }
  handleImportCSVApi =async (requestBody:any) =>{
    const requestUrl = this.state.tabValue === 4 ? configJSON.importCSVClassManagement : configJSON.importCSVTeacherAssignment;
    const token = await getStorageData("token");
      const header = {
        token: token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.importCSVClassManagementId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        requestBody
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  handleImportCSVResponse = async(responseJson: {
    data:any;
    errors: {message:Array<string>;}})=>{
      if (responseJson?.data?.success?.length > 0) {
        this.handleCloseCsv();
        let prevTabValue = this.state.tabValue
        this.setState({ tabValue: 3 }, () => {
          this.setState({ tabValue: prevTabValue });
        });
        toast.success(`${responseJson?.data?.success[0]?.message}`);
      }else if(responseJson?.data?.errors?.length > 0){
        displayApiErrorMsg(responseJson?.data?.errors[0]?.message, this.props.navigation);
      }
        else {
        displayApiErrorMsg(responseJson?.errors?.message[0], this.props.navigation);
      }
      hideCustomLoader();
  }
  downloadCSVFile =async () => {
    const token = await getStorageData("token");
    let requestType = this.state.tabValue===4?configJSON.downloadCSVClassMangement:configJSON.downloadCSVTeacherAssignment
    let requestUrl = `${requestType}?token=${token}`
    window.open(`${baseURL}/${requestUrl}`, '_blank');
  };

  // Customizable Area End
}
// Customizable Area End