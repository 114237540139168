// Customizable Area Start
import React from "react";
import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import ParentAssessmentController, {
  Props,
} from "./ParentAssessmentController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import TabPanel from "../../../components/src/TabPanel.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import AssessmentsAll from "./AssessmentsAll.web";
import AssessmentAssigned from "./AssessmentAssigned.web";
import AssessmentGraded from "./AssessmentGraded.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    assesmentBreadcrumb: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    newAssessmentBtn: {
      width: "218px",
      height: "50px",
      borderRadius: "12px",
      backgroundColor: "#0D46BC",
      color: "#ffffff",
      textTransform: "capitalize",
      marginRight: "30px",
      "&:hover": {
        backgroundColor: "#0D46BC",
      },
    },
    boxRoot: {
      marginTop: "30px",
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.spacing(2.5, 2.5, 2.5, 2.5),
    },
    assessmentAppBar: {
      borderRadius: "12px 12px 0 0",
      backgroundColor: "#ffffff",
      borderBottom: "1px solid #F1F1F1",
    },
    profileActiveTab: {
      color: "#0D46BC",
      fontWeight: 500,
      fontSize: 20,
      textTransform: "capitalize",
    },
    tabLabel: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: "23px",
      color: "#090909",
      textTransform: "capitalize",
      padding: theme.spacing(2.5, 0),
    },
    profileNameBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    dividerWidth: {
      height: "5px",
    },
    tabButton: {
      backgroundColor: "transparent",
      padding: "10px 4px",
      textAlign: "center",
      textDecoration: "none",
      textTransform: "capitalize",
      display: "inline-block",
      fontSize: "16px",
      opacity: 1,
      minWidth: "25%",
      "&.Mui-selected": {
        backgroundColor: "transparent",
        color: "#0D46BC !important",
        outline: "none",
        borderBottom: "1px solid #0D46BC",
        fontWeight: 600,
      },
      "&.MuiTab-root": {
        minHeight: "60px",
      },
    },
  });
// Customizable Area End

// Customizable Area Start
export class ParentAssessment extends ParentAssessmentController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      tabValue,
      currentRole,
    } = this.state;
    return (
      <>
        <Box
          className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor
            } ${classes.secondaryBgColor}`}
          height={"100%"}
        >
          <Box className={classes.assesmentBreadcrumb}>
            <Typography
              className={`${classes.textCommonColor} ${classes.fontText14}`}
              component={"span"}
            >
              {`Assessment`}{" "}
            </Typography>
            <Typography
              className={`${classes.primaryColor5} ${classes.fontBold600} ${classes.fontText12
                }`}
              component={"span"}
            >
              {`/ ${this.state.tabName}`}
            </Typography>
          </Box>
          <Box className={classes.profileNameBox}>
            <Box>
              <Box display={"flex"} alignItems={"center"}>
                <Typography
                  style={{ fontWeight: 700 }}
                  className={`${classes.textPrimaryColor} ${classes.fontText34}`}
                  component={"span"}
                >
                  {`Assessment`}
                </Typography>
              </Box>
            </Box>
            <Box>
            </Box>
          </Box>
          <Box display={"flex"} mt={"30px"}>
              <Box display={"flex"} flexDirection={"column"}>
              <Typography
                data-test-id="student"
                className={`${classes.textCommonColor} ${classes.fontText14}`}
                component={"span"}
                gutterBottom
              >Student</Typography>
              <CustomSelectDropdown
                fieldId="select-dropdown-assessment-component"
                fieldValue={this.state.selectedStudent}
                fieldName="selectedStudent"
                handleChange={this.handleStudentSelect}
                renderValue={this.renderSelectedStudentName}
                fieldError={false}
                fieldMenuItems={this.state.studentDropdownData}
                fieldStyles={GradeDropdown}
                iconComponent={
                  <DropdownIcon
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke={"#888888"}
                    strokeWidth="1.5"
                    fill={"none"}
                  />
                }
              />
            </Box>
          </Box>
          <Box mt={3} style={{ width: "98%" }}>
            <Box className={classes.boxRoot}>
              <AppBar
                className={`${classes.assessmentAppBar} ${classes.bgColor}`}
                position="static"
                color="default"
              >
                <Tabs
                  id="tab-change-wrapper"
                  variant="fullWidth"
                  TabIndicatorProps={{ style: { backgroundColor: "#0D46BC" }}}
                  classes={{ indicator: classes.profileActiveTab }}
                  value={tabValue}
                  onChange={this.handleTabChange}
                >
                  <Tab
                    id="All"
                    classes={{
                      root:
                        tabValue === 0
                          ? classes.profileActiveTab
                          : classes.tabLabel,
                    }}
                    className={`${classes.textPrimaryColor} ${classes.tabButton}`}
                    label={"All"}
                  />
                  <Tab
                    id="Assigned"
                    classes={{
                      root:
                        tabValue === 1
                          ? classes.profileActiveTab
                          : classes.tabLabel,
                    }}
                    className={`${classes.textPrimaryColor} ${classes.tabButton}`}
                    label={"Assigned"}
                  />
                  <Tab
                    id="Graded"
                    classes={{
                      root:
                        tabValue === 2
                          ? classes.profileActiveTab
                          : classes.tabLabel,
                    }}
                    className={`${classes.textPrimaryColor} ${classes.tabButton}`}
                    label={"Graded"}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={tabValue} index={1}>
                <AssessmentAssigned
                  currentRole={currentRole}
                  searchByStudent={this.state.selectedStudent}
                  navigation={this.props.navigation}
                  classId={this.state.filteredClassId}
                  studentIds={this.state.studentId}
                  getStudentData={this.state.getSearchStudentData}
                  searchstu={this.state.search}
                  classes={this.props.classes}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={0}>
                <AssessmentsAll
                  studentIds={this.state.studentId}
                  currentRole={currentRole}
                  searchByStudent={this.state.selectedStudent}
                  classId={this.state.filteredClassId}
                  gradingComponentsdata={this.state.gradingComponent}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <AssessmentGraded
                  currentRole={currentRole}
                  searchByStudent={this.state.selectedStudent}
                  classId={this.state.filteredClassId}
                  studentIds={this.state.studentId}
                  searchstu={this.state.search}
                  getStudentData={this.state.getGradeStudentData}
                  handleInputStudentChange={()=> {}}
                />
              </TabPanel>
            </Box>
          </Box>
        </Box>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const addNewSchoolBtn = {
  width: "241px",
  textAlign: "center",
  height: "50px",
  marginRight: "25px",
  fontSize: "18px",
  borderRadius: "12px",
  padding: "10px 10px",
  fontWeight: 500,
} as const;
const dropdownStudent = {
  width: "218px",
  borderRadius: "8px",
  height: "50px",
} as const;
const GradeDropdown = {
  width: "200px",
  borderRadius: "8px",
  height: "48px",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ParentAssessment);
// Customizable Area End
