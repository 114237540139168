import React from "react";

// Customizable Area Start
import { Box } from "@material-ui/core";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import {
  createStyles,
  createTheme,
  Theme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import SubjectTabController, { Props } from "./SubjectTabController.web";
import CustomTableWeb from "../../../components/src/CustomTable.web";
import CustomEmptyResultComponentWeb from "../../../components/src/CustomEmptyResultComponent.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    mainSubjectTabBox: {
      padding: "10px 20px",
    },
  });

const tableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "8px !important",
        paddingBottom: "8px !important",
        paddingRight: "0px !important",
        paddingLeft: "0px !important",
      },
      head: {
        padding: "16px 0px",
      },
    },
  },
});
// Customizable Area End

export class SubjectTab extends SubjectTabController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { subjectList, subjectTableHeader,subjectsTableActionButtonConfig } = this.state;
    return (
      <Box className={`${classes.mainSubjectTabBox}`}>
        {subjectList?.length ? (
          <ThemeProvider theme={tableTheme}>
            <CustomTableWeb
            showMenuIcon
            tableBodyData={subjectList}
            tableHeaderColumns={subjectTableHeader}
            tableId="appearance-management-subject-table"
            actionButtonConfig={subjectsTableActionButtonConfig}
          />
          </ThemeProvider>
        ) : (
          <CustomEmptyResultComponentWeb message="No Subjects added yet." />
        )}
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(SubjectTab);
// Customizable Area End
