// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  IDropdownMenuItemProps,
  ITableActionBtnConfigProps,
  TOASTER_NOTIFICATION_MSG,
} from "../../../components/src/CommonType.web";
import {
  displayApiErrorMsg,
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
} from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import { toast } from "react-toastify";

export const configJSON = require("./config");
// Customizable Area End
// Customizable Area Start
export interface DropDownValues {
  id: number;
  label: string;
  value: any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  openCreateEventDialog: boolean;
  openDeleteEventDialog: boolean;
  editEventFlag: boolean;
  manageEventsActionBtnConfig: Array<ITableActionBtnConfigProps>;
  eventsList: Array<any>;
  selectedEventDetails: any;
  currentPage: number;
  perPageEvents: number;
  totalNumberOfEvents: number;
  token: string;
  curriculumsListing: Array<IDropdownMenuItemProps>;
  classesListing: Array<IDropdownMenuItemProps>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ManageEventsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetManageEventsList: string = "";
  apiPostNewEvent: string = "";
  apiUpdateEvent: string = "";
  apiDeleteEvent: string = "";
  apiGetCurriculumsListing: string = "";
  apiGetClassesListing: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
      loading: false,
      openCreateEventDialog: false,
      openDeleteEventDialog: false,
      editEventFlag: false,
      manageEventsActionBtnConfig: [
        {
          label: "Edit",
          action: (value?: any) => this.handleOpenEditEventDialog(value),
        },
        {
          label: "Delete",
          action: (value?: any) => this.handDeleteEventDialog(value),
        },
      ],
      selectedEventDetails: "",
      currentPage: 1,
      perPageEvents: 10,
      totalNumberOfEvents: 0,
      token: "",
      eventsList: [],
      curriculumsListing: [],
      classesListing: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  handleGetClassesList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetClassesListing = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.classesEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetCurriculumList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const curriculumRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCurriculumsListing = curriculumRequestMessage.messageId;
    curriculumRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.curriculumsEndpoint
    );

    curriculumRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    curriculumRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(
      curriculumRequestMessage.id,
      curriculumRequestMessage
    );
    return true;
  };

  handleSetMenuItem = (list: any) => {
    const menuItems = list?.map((item: any) => {
      return {
        id: item?.id,
        value: item?.id,
        label: item?.attributes?.name,
      };
    });
    return menuItems;
  };

  handleSetCurriculumsDropdownList = (responseJson: any) => {
    if (responseJson?.data) {
      const allCurriculumOption = {
        id: "All",
        value: "All",
        label: "All Curriculums",
      };
      const curriculumList = this.handleSetMenuItem(responseJson?.data);
      this.setState({
        curriculumsListing:
          [{ ...allCurriculumOption }, ...curriculumList] || [],
      });
    }
  };

  handleGetCurriculumsDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      this.handleSetCurriculumsDropdownList(responseJson);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleSetClassesDropdownList = (responseJson: any) => {
    if (responseJson?.data) {
      const allClassesOption = {
        id: "All",
        value: "All",
        label: "All Classes",
      };
      const classList = this.handleSetMenuItem(responseJson?.data);
      this.setState({
        classesListing: [{ ...allClassesOption }, ...classList] || [],
      });
    }
  };

  handleGetClassesDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      this.handleSetClassesDropdownList(responseJson);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleGetEventsList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const { currentPage, perPageEvents } = this.state;
    let manageEventsEndpoint = configJSON.eventsEndpoint;
    manageEventsEndpoint = `${manageEventsEndpoint}?page=${currentPage}&per_page=${perPageEvents}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetManageEventsList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      manageEventsEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSetEventsDataItem = (item: any) => {
    const curriculumTitlesList: [] = item?.attributes?.curriculum?.map(
      (item: any) => {
        return item?.name;
      }
    );
    const curriculumIdsList: [] = item?.attributes?.curriculum?.map(
      (item: any) => {
        return (item?.id).toString();
      }
    );
    const classTitlesList: [] = item?.attributes?.class?.map((item: any) => {
      return item?.name;
    });
    const classIdsList: [] = item?.attributes?.class?.map((item: any) => {
      return (item?.id).toString();
    });
    
    let dataItemObj = {
      id: item?.id,
      event: {
        title: item?.attributes?.title,
        color: item?.attributes?.color,
      },
      curriculumTitle: curriculumTitlesList.join(","),
      curriculumId: curriculumIdsList,
      classTitle: classTitlesList.join(","),
      classId: classIdsList,
      startDate: moment(item?.attributes?.start_date_and_time).format(
        "MMM DD, YYYY hh:mm a"
      ),
      endDate: moment(item?.attributes?.end_date_and_time).format(
        "MMM DD, YYYY hh:mm a"
      ),
    };
    return dataItemObj;
  };

  handleSetEventsData = (list: any) => {
    const eventData = list?.map((item: any) =>
      this.handleSetEventsDataItem(item)
    );
    this.setState({ eventsList: eventData });
  };

  handleGetEventsDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      this.handleSetEventsData(responseJson.data);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleAddNewEvent = (requestBody: any) => {
    showCustomLoader();
    this.handleCloseCreateEditEvent();
    const { token } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostNewEvent = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.eventsEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleUpdateEvent = (requestBody: any) => {
    this.handleCloseCreateEditEvent();
    showCustomLoader();
    const { token, selectedEventDetails } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateEvent = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.eventsEndpoint}/${selectedEventDetails.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePostEventDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data?.attributes) {
      toast.success(TOASTER_NOTIFICATION_MSG.EVENT_CREATION_SUCCESS);
      this.handleGetEventsList();
    } else if (responseJson?.errors) {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      hideCustomLoader();
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleUpdateEventResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data?.attributes) {
      this.handleGetEventsList();
      toast.success(TOASTER_NOTIFICATION_MSG.EVENT_UPDATE_SUCCESS);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleOpenEditEventDialog = (value: any) => {
    const { eventsList } = this.state;
    const selectedEventDetails = eventsList.find(
      (item) => parseInt(item.id) === parseInt(value)
    );
    this.setState(
      {
        selectedEventDetails,
      },
      () => {
        this.setState({ openCreateEventDialog: true, editEventFlag: true });
      }
    );
  };

  handleOpenCreateEventDialog = () => {
    this.setState({ openCreateEventDialog: true });
  };

  handDeleteEventDialog = (value?: any) => {
    const { eventsList } = this.state;
    const selectedEventDetails = eventsList.find(
      (item) => parseInt(item.id) === parseInt(value)
    );
    this.setState(
      {
        selectedEventDetails,
      },
      () => {
        this.setState({ openDeleteEventDialog: true });
      }
    );
  };

  handleDeleteEvent = async () => {
    this.handleCloseDeleteEvent();
    showCustomLoader();
    const { token, selectedEventDetails } = this.state;
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteEvent = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.eventsEndpoint}/${selectedEventDetails.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDeleteEventResponse = (responseJson: any) => {
    if (responseJson) {
      this.handleGetEventsList();
      toast.success(TOASTER_NOTIFICATION_MSG.EVENT_DELETE_SUCCESS);
    } else {
      toast.error(TOASTER_NOTIFICATION_MSG.EVENT_DELETE_FAILURE);
    }
    hideCustomLoader();
  };

  handleCloseCreateEditEvent = () => {
    this.setState({ openCreateEventDialog: false, editEventFlag: false });
  };

  handleCloseDeleteEvent = () => {
    this.setState({ openDeleteEventDialog: false });
  };

  handleSetUserTokenEvents = async () => {
    const token = await getStorageData("token");
    this.setState({ token });
  };

  handlePageChange = (event: any, page: any) => {
    this.setState({ currentPage: page }, () => {
      this.handleGetEventsList();
    });
  };

  handleGoBackToPreviousScreen = () => {
    this.props?.navigation?.goBack()
  }

  // Customizable Area End
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetEventsList();
    this.handleGetCurriculumList();
    this.handleGetClassesList();
    this.handleSetUserTokenEvents();
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetManageEventsList:
            {
              if(responseJson?.meta?.total_records){
                this.setState({
                  totalNumberOfEvents: responseJson.meta.total_records
                })
              }
              this.handleGetEventsDataResponse(responseJson, errorResponse);
            }
            break;
          case this.apiGetClassesListing:
            {
              this.handleGetClassesDataResponse(responseJson, errorResponse);
            }
            break;
          case this.apiGetCurriculumsListing:
            {
              this.handleGetCurriculumsDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiPostNewEvent:
            {
              this.handlePostEventDataResponse(responseJson, errorResponse);
            }
            break;
          case this.apiDeleteEvent:
            {
              this.handleDeleteEventResponse(responseJson);
            }
            break;
          case this.apiUpdateEvent:
            {
              this.handleUpdateEventResponse(responseJson, responseJson);
            }
            break;
        }
      }
    }
  }

  // Customizable Area End
}
// Customizable Area End
