// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  displayApiErrorMsg,
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
} from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";

// Customizable Area End
// Customizable Area Start
const configJSON = require("./config.js");

// Customizable Area End
export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface BtnConfig {
  id: string;
  label: string;
  type: string;
  handleClick: () => void;
}

interface S {
  // Customizable Area Start
  studentList:any;
  loading: boolean;
  teacherCoursesList: Array<{
    id: string;
    subjectName: string;
    subjectMarks: number;
    lessonsCovered: number;
    lessonsTotal: number;
    status: string;
    subjectIcon: string;
    timeSpentInSec: number;
    subjectBgColor: string;
    completionProgress: number | string;
    dueDate: string;
  }>;
  selectedCourseId: string;
  selectedCourse: any;
  selectedTeacherClassDDValue: string;
  classesList: Array<{
    id: string;
    label: string;
    value: string;
  }>;
  openModelBox: boolean;
  progressStudentsTableBody: Array<{
    id: number;
    Name: {
      icon: string;
      name: string;
      id: number;
    };
    Progress: {
      value1: number | undefined;
      value2: number | undefined;
      value3: number | undefined;
    };
    TermAvg: string;
    YTD: number;
    LastAccess: string;
  }>;
  assignedAssessmentsList: Array<{
    id: string,
    Title: string,
    Type: string,
    Submitted: string,
    AvgGrade: {
      grade: number,
      avg: number,
      infoMsg: string,
    },
    DueDate: string,
  }>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetTeacherCourses: string = "";
  apiGetProgressPerformanceList: string = "";
  apiGetTeachersAssignedClasses: string = "";
  apiGetTeacherAssignedAssessments: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      studentList:[],
      loading: false,
      teacherCoursesList: [],
      selectedCourseId: "",
      selectedCourse: null,
      classesList: [],
      selectedTeacherClassDDValue: "all",
      openModelBox: false,
      progressStudentsTableBody: [],
      assignedAssessmentsList: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetTeachersClassesListApi();
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = getMessageData(
        message
      );

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetTeacherCourses:
            {
              this.handleGetTeacherCoursesResponse(responseJson);
            }
            break;

          case this.apiGetProgressPerformanceList:
            {
              this.handleGetProgressPerformanceStudentsListResponse(responseJson)
            }
            break;

          case this.apiGetTeachersAssignedClasses:
            {
              this.handleGetTeachersClassesListResponse(responseJson);
            }
            break;
          case this.apiGetTeacherAssignedAssessments:
            {
              this.handleGetAssignedAssessmentsResponse(responseJson)
            }
        }
      }
    }
  }

  handleSelectCourse = (courseId: string) => {
    const { teacherCoursesList } = this.state;
    const selectedCourse = teacherCoursesList.find(
      (course) => course?.id === courseId
    );
    this.setState({ selectedCourseId: courseId, selectedCourse }, () => {
      this.handleGetProgressPerformanceStudentsListApi();
    });
  };

  handleChangeDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
          selectedCourse: null,
          selectedCourseId: "",
        };
      }, () => {
        this.handleGetTeacherCoursesApi();
        this.handleGetProgressPerformanceStudentsListApi();
        this.handleGetAssignedAssessmentsApi();
      });
    }
  };

  renderGradingComponenttDDValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "All";
    }
    const { classesList } = this.state;
    const selectedClass = classesList.find(
      (grade: any) => grade?.id === selected
    );
    return selectedClass?.label;
  };

  onCatchUpCancel = () => {
    this.setState({
      openModelBox: false,
    });
  };

  handleModalOPen = () => {
    this.setState({
      openModelBox: true,
    });
  };

  handleViewActivityList = (type: string) => {
    const { studentList } = this.state;
    this.props.navigation?.navigate(
      "TeacherDashboardViewAll",
      {},
      {
        studentId: studentList,       
      }
    );
  };

  handleViewActivityListt = (type: string) => {
    const { studentList } = this.state;
    this.props.navigation?.navigate(
      "TeacherAssinedAssesment",
      {},
      {
        studentId: studentList,       
      }
    );
  };

  handleGetApiBaseUrl = (baseUrl: string) => {
    const { classesList, selectedTeacherClassDDValue } = this.state;
    let requestUrl = baseUrl;
    if (selectedTeacherClassDDValue !== "all") {
      requestUrl = requestUrl + `?class_id=[${selectedTeacherClassDDValue}]`;
    }

    if (selectedTeacherClassDDValue && selectedTeacherClassDDValue == "all") {
      const classIds = classesList
        .filter(
          (classItem: { id: string; value: string; label: string }) =>
            classItem.value !== "all"
        )
        .map((item: { id: string; value: string; label: string }) => {
          return item.value;
        });
      requestUrl = requestUrl + `?class_id=[${classIds}]`;
    }
    return requestUrl;
  }

  handleGetTeachersClassesListApi = async () => {
    const requestUrl = configJSON.teacherDashboardClassesEndpoint;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetTeachersAssignedClasses = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetTeachersClassesListResponse = (response: {
    data: Array<{
      name: string;
      id: number;
    }>;
    errors: Array<{}>;
  }) => {
    if (response?.data) {
      const classesDropdownAll = [
        {
          id: "all",
          label: "All",
          value: "all",
        },
      ];
      let classesDropdownData = response.data.map(
        (item: { name: string; id: number }) => {
          return {
            label: item?.name,
            value: (item?.id).toString(),
            id: (item?.id).toString(),
          };
        }
      );
      this.setState(
        {
          classesList: classesDropdownAll.concat(classesDropdownData),
          selectedCourse: null,
          selectedCourseId: "",
        },
        () => {
          this.handleGetTeacherCoursesApi();
          this.handleGetProgressPerformanceStudentsListApi();
          this.handleGetAssignedAssessmentsApi();
        }
      );
    } else {
      displayApiErrorMsg(response?.errors, this.props.navigation);
    }
  };

  handleGetTeacherCoursesApi = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = { token };

    let requestUrl = this.handleGetApiBaseUrl(configJSON.getTeacherCoursesEndpoint);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetTeacherCourses = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetTeacherCoursesResponse = (responseJson: {
    data: Array<{
      id: string;
      attributes: {
        course_title: string;
        total_no_of_lessons: number;
        teacher_course_status: string;
        time_spent: number;
        due_date: string;
        subject: {
          name: string;
          icon: string;
          color: string;
        };
        school_year: {
          start_date: string;
          end_date: string;
        };
        completed_lessons: Array<{
          course_id: number;
        }>;
      };
    }>;
    errors: Array<{}>;
  }) => {
    if (responseJson.data) {
      const courseList = responseJson.data.map((item) => {
        const teacherCourse = item.attributes;
        const lessonsCompleted = teacherCourse?.completed_lessons?.length || 0;
        const totalLessons = teacherCourse?.total_no_of_lessons;
        const lessonCompletionPercent =
          lessonsCompleted === 0
            ? 0
            : Math.ceil((lessonsCompleted / totalLessons) * 100);
        return {
          id: item.id,
          subjectName: teacherCourse?.course_title,
          subjectMarks: 10,
          lessonsCovered: teacherCourse?.completed_lessons?.length || 0,
          lessonsTotal: teacherCourse?.total_no_of_lessons,
          status: teacherCourse?.teacher_course_status,
          subjectIcon: teacherCourse?.subject?.icon,
          timeSpentInSec: teacherCourse?.time_spent || 0,
          subjectBgColor: teacherCourse?.subject?.color,
          completionProgress: lessonCompletionPercent,
          dueDate: teacherCourse?.due_date
            ? moment(teacherCourse?.due_date).format("MMMM DD, YYYY")
            : "NA",
        };
      });
      this.setState({ teacherCoursesList: courseList });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleGetProgressPerformanceStudentsListApi = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = { token };
    const { selectedCourseId } = this.state;
    let requestUrl = this.handleGetApiBaseUrl(configJSON.teacherClassCourseStudentsApi);

    if(selectedCourseId) {
      requestUrl = requestUrl + `&course_id=${selectedCourseId}`;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetProgressPerformanceList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetProgressPerformanceStudentsListResponse = (responseJson: {
    data: Array<{
      id: number;
      attributes: {
        profile_url: {
          url: string;
        };
        first_name: string;
        last_name: string;
        progress: {
          completed: number;
          not_tackled: number;
          remaining: number;
          course_wise_progress: Array<{
            id: number,
            completed_percentage: number,
            not_tackled_percentage: number,
            remaining_percentage: number,
          }>;
        };
        last_access: string;
      };
    }>;
    errors: Array<{}>;
  }) => {
    if (responseJson?.data) {
      const { selectedCourseId } = this.state;
      const studentList = responseJson.data.map((item) => {
        const studentDetails = item.attributes;
        const progressDetails = studentDetails?.progress?.course_wise_progress?.find((courseItem) => (courseItem.id).toString() === selectedCourseId);
        return {
          id: item.id,
          Name: {
            icon: studentDetails?.profile_url?.url || "",
            name: `${studentDetails.first_name} ${studentDetails.last_name}`,
            id: item.id,
          },
          Progress: {
            value1: !selectedCourseId ? studentDetails?.progress?.completed : progressDetails?.completed_percentage,
            value2: !selectedCourseId ? studentDetails?.progress?.remaining : progressDetails?.remaining_percentage,
            value3: !selectedCourseId ? studentDetails?.progress?.not_tackled : progressDetails?.not_tackled_percentage,
          },
          TermAvg: "0",
          YTD: 0,
          LastAccess: studentDetails?.last_access
            ? moment(studentDetails?.last_access).format("MMMM DD, YYYY")
            : "NA",
        };
      });
      const limitStudentList = studentList.slice(0, 7);
      this.setState({ progressStudentsTableBody: limitStudentList });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleGetAssignedAssessmentsApi = async () => {
    showCustomLoader();
    let apiConfig = configJSON.teacherAssessmentEndpoint;
    let requestUrl = apiConfig + "?type=assigned";
    const { selectedTeacherClassDDValue } = this.state;
    if (selectedTeacherClassDDValue !== "all") {
      requestUrl = requestUrl + `&class_id=${selectedTeacherClassDDValue}`;
    }

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetTeacherAssignedAssessments = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetAssignedAssessmentsResponse = (responseJson: {
    data: Array<{
      id: string,
      attributes: {
        subject: {
          color: string
          icon: string,
          id: string,
        },
        grading_component: string,
        submission_percenttage: number,
        student_received_grade: {
          grade: number,
          avg: number
        },
        due_date: string,
        due_days: number,
        activities_title: string,
      }
    }>, errors: Array<{}>
  }) => {
    if (responseJson.data) {
      let assessmentList = responseJson.data.map(
        (item) => {
          const activity = item.attributes;
          return {
            Submitted: `${activity?.submission_percenttage}%`,
            id: item.id,
            Title: activity?.activities_title,
            subject: {
              name: activity.activities_title,
              icon: activity.subject.icon,
              bgColor: activity.subject.color,
            },
            subjectId: activity.subject.id,
            Type: activity.grading_component,
            AvgGrade: {
              grade: activity?.student_received_grade?.grade || 0,
              avg: activity?.student_received_grade?.avg || 0,
              infoMsg: "None of the submitted response has been graded."
            },
            DueDate: activity?.due_date ? moment(activity?.due_date).format('MMMM DD, YYYY') : 'NA',
          };
        }
      );
      const limitedAssessmentList = assessmentList.slice(0, 7);
      this.setState({ assignedAssessmentsList: limitedAssessmentList });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  // Customizable Area End
}
// Customizable Area End
