// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import TeacherCalendarController, {
  Props,
} from "./TeacherCalendarController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import TeacherCalendar from "../src/TeacherCalendar.web";
import ReschedullingModal from "./ReschedullingModal.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CalendarIcon from "../../cfcurriculmschoolyearcreation2/src/assets/CalendarIcon.web";
import CustomTextEditor from "../../../components/src/CustomTextEditor.web";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./calendar.css";
import CustomButton from "../../../components/src/CustomButton.web";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";
import CreateEventSuccess from "./CreateEventSuccess.web";
import CustomColorPickerFieldWeb from "../../../components/src/CustomColorPickerField.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    profileNameBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
      marginTop: "7px",
    },
    errorBorder: {
      border: "1px solid red !important",
    },
    reschedulingBtn: {
      fontWeight:'bold',
      width: "160px",
      height: "50px",
      fontSize:'18px',
      borderRadius: "12px",
      backgroundColor: "#0D46BC",
      color: "#ffffff",
      textTransform: "capitalize",
      marginRight: "30px",
      "&:hover": {
        backgroundColor: "#0D46BC",
      },
    },
  });
const time = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "transparent",
        },
        "&:hover $notchedOutline": {
          borderColor: "transparent",
        },
        "&.Mui-focused $notchedOutline": {
          borderColor: "transparent",
        },
      },
    },
  },
});
const theme = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        width: "880px",
      },
      paperWidthSm: {
        maxWidth: "880px",
      },
    },
  },
});
const enterTitleTheme = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        width: "16px",
        height: "16px",
      },
    },
    MuiInputBase: {
      input: {
        "&::placeholder": {
          fontSize: "14px",
        },
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
        height: "38px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        paddingLeft: "5px",
      },
    },
    MuiTextField: {
      root: {
        backgroundColor: "black",
      },
    },
  },
});

const events2 = [
  {
    id: 1,
    title: "[2D][CS] Lesson 4",
    allDay: true,
    start: new Date(2023, 8, 4),
    end: new Date(2023, 8, 4),
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas quis magna posuere, facilisis est vel",
    color: "rgba(0, 157, 255, 0.05)",
    textColor: "#009DFF",
  },
  {
    id: 2,
    title: "[3B][CS] Lesson 5",
    allDay: true,
    start: new Date(2023, 8, 4),
    end: new Date(2023, 8, 4),
    color: "rgba(143, 38, 50, 0.05)",
    textColor: "#8F2632",
  },
  {
    title: "[2D][MA] Lesson 8",
    allDay: true,
    start: new Date(2023, 8, 4),
    end: new Date(2023, 8, 4),
    color: "rgba(156, 104, 23, 0.05)",
    textColor: "#9C6817",
  },
  {
    title: "[2D][SC] Lesson 4",
    allDay: true,
    start: new Date(2023, 8, 4),
    end: new Date(2023, 8, 4),
    color: "rgba(255, 158, 0, 0.05)",
    textColor: "#FF9E00",
  },
  {
    title: "[2D][MA] Lesson 8",
    start: new Date(2023, 8, 7),
    end: new Date(2023, 8, 7),
    color: "rgba(255, 158, 0, 0.05)",
    textColor: "#9C6817",
  },
  {
    title: "[2D][SC] Lesson 9",
    allDay: true,
    start: new Date(2023, 8, 7),
    end: new Date(2023, 8, 7),
    color: "rgba(255, 158, 0, 0.05)",
    textColor: "#FF9E00",
  },
  {
    title: "Dummy Event",
    start: new Date(2023, 8, 17),
    end: new Date(2023, 8, 17),
    color: "rgba(255, 158, 0, 0.05)",
    textColor: "#FF9E00",
  },

  {
    title: "DTS STARTS",
    start: new Date(2016, 2, 13, 0, 0, 0),
    end: new Date(2016, 2, 20, 0, 0, 0),
  },
];
// Customizable Area End

// Customizable Area Start
export class TeacherCalReshedulling extends TeacherCalendarController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {isvalidationErrors,validationErrors, isParent} = this.state
    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"auto"}
      >
        <Box className={classes.assesmentBreadcrumb}>
          <Typography
            className={`${classes.textCommonColor} ${classes.fontText14}`}
            component={"span"}
          >
            {`Calendar`}
          </Typography>
        </Box>
        <Box className={classes.profileNameBox}>
          <Box>
            <Box display={"flex"} alignItems={"center"}>
              <Typography
                style={{ fontWeight: 700 }}
                className={`${classes.textPrimaryColor} ${classes.fontText34}`}
                component={"span"}
              >
                {`Calendar`}
              </Typography>
            </Box>
          </Box>
          {!isParent && 
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <Typography
                style={{
                  fontWeight: 700,
                  color: "#0D46BC",
                  marginRight: "25px",
                  cursor: "pointer",
                }}
                className={`${classes.fontText18} ${classes.textClickColors}`}
                onClick={this.createEventModal}
              >
                {`Create an Event`}
              </Typography>
              <Button
                data-test-id="ReschedulModal"
                variant="outlined"
                onClick={this.handleOpenReschedulModal}
                className={`${classes.reschedulingBtn}`}
              >
                Reschedule
              </Button>
            </Box>
          } 
        </Box>
        <Box display={"flex"} mt={"30px"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            className={classes.textPrimaryColor}
          >
            {isParent
              ? this.renderStudentDropdown()
              : this.renderClassDropdown()}
          </Box>
        </Box>
        <Box>
          <TeacherCalendar handleUpdateAnswer={undefined} events={this.state.events} />
        </Box>
        <Box>
          <ReschedullingModal
            open={this.state.openAssignCourseModal}
            onCancel={this.handleCloseAssignCourseModal}
            onConfirm={this.handleCloseAssignCourseModal}
          />
        </Box>
        <ThemeProvider theme={theme}>
          <Dialog
            className={`${classes.highlightBgColor} ${classes.quizViewAnswerConfirmDialog}`}
            aria-labelledby="quiz-view-answer-confirm-dialog-title"
            style={{ width: "100%" }}
            open={this.state.isCreateModal}
            onClose={this.closeCreateModal}
          >
            <Box
              padding={"21px 30px 30px 30px"}
              className={`${classes.bgColor}`}
            >
              <Grid direction="row" container>
                <Grid xs={12} item>
                  <Typography
                    className={`${classes.fontText24} ${classes.fontBold600} ${classes.textPrimaryColor}`}
                    component={"span"}
                  >
                    Create an Event
                  </Typography>
                  <IconButton
                    data-test-id="pop"
                    style={{ float: "right" }}
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={this.closeCreateModal}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <DialogContent
                style={{
                  padding: "0px",
                  marginTop: "24px",
                  overflow: "hidden",
                }}
              >
                <Grid container spacing={2} direction="row">
                  <Grid item xs={6}>
                    <Box className={classes.DateName}>
                      <Typography
                        className={`${classes.textCommonColor} ${classes.fontText14}`}
                        component={"span"}
                      >
                        {`Event Title`}
                      </Typography>
                      <ThemeProvider theme={enterTitleTheme}>
                        <Box mt={"6px"}>
                          <CustomTextField
                            placeHolderText="Enter Event Title"
                            fieldName="SearchStudents"
                            fieldId="search-students"
                            fieldValue={this.state.searchStudent}
                            fieldError={isvalidationErrors.searchStudent}
                            helperText={validationErrors.searchStudent}
                            fieldWidth={"100%"}
                            fieldType="text"
                            handleChange={this.handleChange}
                          />
                        </Box>
                      </ThemeProvider>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className={classes.DateName}>
                      <Typography
                        component={"span"}
                        className={`${classes.textCommonColor} ${classes.fontText14}`}
                      >
                        {`Event Date`}
                      </Typography>
                      <div
                        className="DateContainers"
                        style={{ marginTop: "6px" }}
                      >
                        <span className="dateInput">
                          {" "}
                          <div style={{ position: 'relative', zIndex: 1000 }}>
                          <DatePicker
                            onChange={this.selectEndDate}
                            popperPlacement="left-start"
                            popperClassName="example-datepicker-class"
                            selected={this.state.selectEndDate}
                          />
                          </div>
                        </span>
                        <span className="icon">
                          <CalendarIcon />
                        </span>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className={classes.DateName}>
                      <Typography
                        className={`${classes.textCommonColor} ${classes.fontText14}`}
                        component={"span"}
                      >
                        {`Color`}
                      </Typography>
                        <ThemeProvider theme={enterTitleTheme}>
                          <Box mt={"9px"}>
                        <CustomColorPickerFieldWeb
                          fieldId="add-event-color-picker"
                          fieldName="selectedColor"
                          handleColorChange={this.colorChangeUpdate}
                          fieldWidth="100%"
                          fieldValue={this.state.selctedColorName}
                          fieldValueHexCode={this.state.colorChange}

                          textFieldStyles={{ width: "100%" }}
                          placeHolderText={"Select Color"}
                          fieldError={isvalidationErrors.colorChange}
                          helperText={validationErrors.colorChange}
                        />
                        </Box>
                        </ThemeProvider>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className={classes.DateName}>
                      <Typography
                        component={"span"}
                        className={`${classes.textCommonColor} ${classes.fontText14}`}
                      >
                        {`Event time`}
                      </Typography>
                      <Box style={{ marginTop: "8px" }}>
                        <ThemeProvider theme={time}>
                          <form className={classes.container} noValidate>
                            <TextField
                              fullWidth
                              id="time"
                              type="time"
                              onChange={this.handleTimeChange}
                              value={this.state.eventTime}
                                error={isvalidationErrors.eventTime}
                                helperText={validationErrors.eventTime}
                              style={{
                                height: "50px",
                                width: "99%",
                                borderRadius: "6px",
                              }}
                              variant="outlined"
                              defaultValue={this.getCurrentTime()}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className={`${classes.textPrimaryColor} ${classes.bgColor} 
                              ${ this.state.isvalidationErrors.eventTime ? classes.errorBorder : classes.primaryBorder}`}
                              inputProps={{
                                className: `${classes.textCommonColor}`,
                                style: { height: "15px" },
                                step: 1, 
                              }}
                            />
                          </form>
                        </ThemeProvider>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={classes.DateName}>
                      <Typography
                        component={"span"}
                        className={`${classes.textCommonColor} ${classes.fontText14}`}
                      >
                        {`Description`}
                      </Typography>
                      <Box
                        mt={"8px"}
                        overflow={"auto"}
                        height={"fit-content"}
                        style={{ marginBottom: "6px"}}
                      >
                        <CustomTextEditor
                          editorContent={this.state.description}
                          editorId={`due-assignment-`}
                          onWysEditorStateChange={this.handleUpdateAnswer}
                          placeHolderText="Add Activity Description"
                          editorError={isvalidationErrors.description}
                          helperText={validationErrors.description}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid xs={12} item>
                    <Box marginBottom={"10px"} style={{ width: "100%" }}>
                      <CustomButton
                        btnText="Save"
                        btnStyle={createNextBtn}
                        buttonId={`my-assessment-search-mobile`}
                        buttonVariant={"contained"}
                        type={"button"}
                        data-testId="create-event"
                        handleButtonClick={this.handleCreateEvent}
                        isDisabled={false}
                      />
                      <CustomButton
                        btnText="Cancel"
                        btnStyle={createCancelBtn}
                        buttonId={`my-assessment-search-mobile`}
                        buttonVariant={"contained"}
                        type={"button"}
                        handleButtonClick={this.closeCreateModal}
                        isDisabled={false}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
            </Box>
          </Dialog>
        </ThemeProvider>
        <CreateEventSuccess
          data-testId="event-success"
          open={this.state.openCreateSuccessPopUp}
          onCancel={this.CloseEventSuccessPopUp}
          eventTime={this.state.selectEndDate}
          eventTitle={this.state.searchStudent}
          onConfirm={this.handleConfirmEvent}
        />
      </Box>
    );
    // Customizable Area End
  }

  renderClassDropdown = () => {
    const { classes } = this.props;

    return (
      <>
        <Typography
          className={`${classes.fontText14} ${classes.textCommonColor}`}
          component={'span'}
          gutterBottom
        >
          {`Class`}
        </Typography>
        <Box>
          <CustomSelectDropdown
            fieldId="select-dropdown-teacher-calendar"
            handleChange={this.handleCalendarDropdownValues}
            fieldValue={this.state.selectedClassComponentDDValue}
            fieldError={false}
            renderValue={this.renderCalendarComponenttDDValues}
            fieldName="selectedClassComponentDDValue"
            fieldMenuItems={this.state.gradingComponent}
            fieldStyles={calendarDropdown}
            iconComponent={
              <DropdownIcon
                stroke={'#888888'}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                fill={'none'}
              />
            }
          />
        </Box>
      </>
    );
  }; 

  renderStudentDropdown = () => {
    const { classes } = this.props;

    return (
      <>
        <Typography
          className={`${classes.fontText14} ${classes.textCommonColor}`}
          component="span"
          gutterBottom
        >
          Student
        </Typography>
        <Box>
          <CustomSelectDropdown
            fieldId="select-dropdown-parent-calendar"
            handleChange={this.handleCalendarDropdownValues}
            fieldValue={this.state.selectedClassComponentDDValue}
            fieldError={false}
            renderValue={this.renderCalendarComponenttDDValues}
            fieldName="selectedStudentComponentDDValue"
            fieldMenuItems={this.state.gradingComponent}
            fieldStyles={calendarDropdown}
            iconComponent={
              <DropdownIcon
                stroke="#888888"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                fill="none"
              />
            }
          />
        </Box>
      </>
    );
  };  
}
// Customizable Area End
const createCancelBtn = {
  width: "120px",
  border: "1px solid #0D46BC",
  marginLeft: "15px",
  height: "50px",
  fontSize: "18px",
  borderRadius: "12px",
  textAlign: "center",
  marginTop: "32px",
  color: "#0D46BC",
  fontWeight: 500,
  padding: "10px 10px",
  float: "right",
  backgroundColor: "#FFF",
} as const;

const createNextBtn = {
  width: "120px",
  textAlign: "center",
  height: "50px",
  color: "#ffffff",
  backgroundColor: "#0D46BC",
  marginLeft: "15px",
  fontSize: "18px",
  borderRadius: "12px",
  marginTop: "32px",
  padding: "10px 10px",
  fontWeight: 500,
  float: "right",
} as const;
const calendarDropdown = {
  width: "200px",
  borderRadius: "8px",
  height: "48px",
} as const;
// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherCalReshedulling);
// Customizable Area End
