// Customizable Area Start
import React from "react";
import { Box, Divider, Grid, Typography,ThemeProvider, IconButton, MenuItem, Menu } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
} from "@material-ui/core/styles";
import CustomTable from "../../../components/src/CustomTable.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import AssessmentGradedController, {
  ITableProps,
  Props,
  StudentProfile,
} from "./AssessmentGradedController.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CustomButton from "../../../components/src/CustomButton.web";
import AddRemarkModal from "./AddRemarkModal.web";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";
import CustomPaginationComponent from "../../../components/src/CustomPaginationComponent.web";
import MoreVertIcon from '@material-ui/icons/MoreVert';
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    dividerWidthAll: {
      height: "5px",
    },
    gradeMainContainer: {
      maxHeight: "100%",
      overflowX: "auto",
      borderRadius: "10px",
      padding: "15px 20px",
    },
    gradesMainBox: {
      display: "flex",
      width: "100%",
    },
    gradeCoursesTableBox: {
      display: "flex",
      padding: "0px 0px 0px 14px",
      borderRadius: "12px",
      width: "85%",
      maxHeight: "600px",
      overflow: "auto",
      [theme.breakpoints.down("xs")]: {
        width: "85% !important",
      },
      '&.MuiTableCell-root' : {
        padding : "5px !important"
      }
      
    },
    gradeLogoBg: {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2px",
    },
    gradeCourseLogo: {
      width: "30px",
      height: "30px",
      objectFit: "contain",
    },
    gradeTableHeadRow: {
      display: "flex",
      alignItems: "center",
      borderBottom: "#979797",
      "& > div:nth-child(1)": {
        width: "50%",
      },
      "& > div:nth-child(2)": {
        width: "20%",
      },
      "& > div:nth-child(3)": {
        width: "20%",
      },
      "& > div:nth-child(4)": {
        width: "10%",
      },
    },
    subjectGradeRow: {
      display: "flex",
      flexDirection: "column",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    gradeDiv: {
      "& > div:nth-child(1)": {
        width: "50%",
      },
      "& > div:nth-child(3)": {
        width: "20%",
      },
      "& > div:nth-child(2)": {
        width: "20%",
      },
      "& > div:nth-child(4)": {
        width: "10%",
      },
    },
    cell: {
      padding: "10px",
      '&.MuiTableCell-root' : {
        padding : "5px !important"
      }
    },
    gradeSubjectTitleCell: {
      width: "30%",
      display: "flex",
      alignItems: "center",
    },
    gradeSubjectName: {
      display: "block",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    gradeQuarterCell: {
      width: "14%",
      display: "flex",
      alignItems: "center",
    },
    subjectCoursesDetailTable: {
      overflowX: "auto",
      width: "96%",
      marginBottom: "12px",
      marginTop: "15px",
      '&.MuiTableCell-root' : {
        padding : "5px !important"
      }
    },
    show: {
      display: "block",
      transition: "display 0.3s ease-out",
    },
    hide: {
      display: "none",
      transition: "display 0.3s ease-in",
    },
    dividerTable: {
      height: "1px",
      background: "#F1F1F1",
      marginTop: "15px",
      marginBottom: "5px",
    },
    textEllipsis: {
      maxWidth: "300px",
      display: "block",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    '&.MuiTableCell-root' : {
      padding : "5px !important"
    },
    
    ".MuiTableCell-root" : {
      padding : "5px !important"},

    customWidth: {
      maxWidth: '150px',
      backgroundColor: '#FFE4E4',
      color: '#ED1111',
      padding: '12px 30px 12px 6px',
  },
  cellpadding : {
    border: "10px solid black",
    "&.MuiTableCell-root" : { 
      td : {
      padding : "5px !important"
      }
    },
  }
  
  });
const gradeSearchTheme = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        "&::placeholder": {
          fontSize: "14px"
         
        },
      },
    },
    MuiSvgIcon: {
      root: {
        height: "16px",
        width: "16px",
      },
    },
    MuiFormControl: {
      root: {
        justifyContent: "center",
        height: "35px",
        display: "flex",
        marginLeft: "24px",
        alignItems: "center",
      },
    },
  },
});
const gradeTableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "5px !important",
        paddingBottom: "5px !important",
        paddingRight: "5px !important",
        paddingLeft: "5px !important",
      },
      head: {
        padding: "16px 0px",
      },
    },
  },
});
// Customizable Area End

// Customizable Area Start
export class AssessmentGraded extends AssessmentGradedController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    
    // Customizable Area Start
    const { classes } = this.props;
    const {
      gradeSubjectList,
      selectedGradeSubId,
      GradeSubjCoursesDetailsTableColumns,
      gradeSubjectTableColumns,
      selectedSubCoursesGradesDetails,
      openGradeAndRemarkModal,
      paginationData,
      editGradeStudentId,
      editGradeStudentName,
      editGradeMaxScore,
      editGradeTotalMarks,
    } = this.state;
  
   
    return (

      <>
        <Box
          display={"flex"}
          flexWrap={"wrap"}
          px={3.5}
          py={3}
          className={classes.bgColor}
        >
          <Box display={"flex"} alignItems={"center"} data-test-id="gradeDiv" gridGap={"25px"}>
            <Box display={"flex"} alignItems={"center"}>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText14}`}
                  component={"span"}
                  gutterBottom
                  data-test-id="gradeText"
                >
                  {`Grading Component`}
                </Typography>
                <CustomSelectDropdown
                  fieldId="select-dropdown-assessment-grading-component"
                  fieldValue={this.state.selectedClassComponentDDValue}
                  fieldName="selectedClassComponentDDValue"
                  handleChange={this.handleGradedDropdownValues}
                  renderValue={this.renderGradedComponenttDDValues}
                  fieldError={false}
                  fieldMenuItems={this.state.gradedAssessmentComponent}
                  fieldStyles={dropdownStudent}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                />
              </Box>
            </Box>
            <Box>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText14}`}
                  component={"span"}
                  gutterBottom
                  data-test-id={"SearchText"}
                >
                  {`Search`}
                </Typography>
                <CustomTextField
                  placeHolderText="Type here to search"
                  fieldName="SearchStudents"
                  fieldId="search-student-graded"
                  fieldValue={this.state.searchStudent}
                  fieldWidth={"200px"}
                  fieldType="text"
                  handleChange={this.handleSearchStudent}
                  textFieldStyles={{ height: "36px" }}
                />
              </Box>
            </Box>
            <Box
              alignItems={"center"}
              pt={"24px"}
              data-test-id="searchBtn"
            >
              <CustomButton
                btnText="Search"
                btnStyle={searchBtn}
                buttonId={`my-assessment-search-mobile`}
                buttonVariant={"contained"}
                type={"button"}
                handleButtonClick={this.handleSearchGradedAssessment}
                isDisabled={false}
              />
            </Box>
          </Box>
        </Box>
        <Divider className={classes.dividerWidthAll} />
        <Box  className={classes.bgColor}
          style={{
              borderRadius: "0px 0px 10px 10px",
              padding: "0px 10px",
            }}  >
        {gradeSubjectList.length > 0 ?
          <Grid item xs={12} data-test-id="gradeGrid">
            <Box className={`${classes.bgColor} ${classes.gradeMainContainer}`}>
              <Box className={`${classes.gradesMainBox}`} data-test-id="GradeSub">
                <Box width={"inherit"}>
                  <Box
                    className={`${classes.gradeTableHeadRow}`}
                    data-test-id="GradeInner"
                  >
                    {this.getGradefilterColumn()?.map((heading: ITableProps) => (
                      <Box
                        width={heading.width}
                        key={heading.columnId}
                        className={`${classes.cell}`}
                        data-test-id="GradeReturn"
                      >
                        <Typography
                          component={"span"}
                          className={`${classes.fontText16} ${classes.textCommonColor}`}
                          data-test-id="LableGrade"
                        >
                          {heading.label}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Box className={classes.dividerTable} />
                  {gradeSubjectList.map((subjectItem: any) => (
                    <Box key={subjectItem.id} className={classes.subjectGradeRow}>
                      <Box display={"flex"} className={classes.gradeDiv}>
                        <Box
                          className={`${classes.gradeSubjectTitleCell} ${classes.cell}`}
                          // style={{width:'34%'}}
                        >
                          <Box
                            mr={"10px"}
                            bgcolor={subjectItem?.gradeSubject?.bgColor}
                            className={classes.gradeLogoBg}
                          >
                            <img
                              src={subjectItem?.gradeSubject?.icon}
                              className={classes.gradeCourseLogo}
                              alt="Subject Logo"
                            />
                          </Box>
                          <Box className={classes.gradeSubjectName}>
                            <Typography
                              className={`${classes.textPrimaryColor} ${classes.fontText18} ${classes.textEllipsis}`}
                              component="span"
                            >
                              {subjectItem?.gradeSubject?.name}
                            </Typography>
                          </Box>
                          {this.handleCondition(!this.props.searchstu, <Box
                            data-testid={`subject-grade-details-expand`}
                            onClick={() =>
                              this.handleGradeSubjectAssignedTable(subjectItem.id)
                            }
                          >
                            {this.props.currentRole !== "parent" && (subjectItem.id === selectedGradeSubId ? (
                              <RemoveIcon
                                className={`${classes.textCommonColor} ${classes.cursorPointer}`}
                              />
                            ) : (
                              <AddIcon
                                className={`${classes.textCommonColor} ${classes.cursorPointer}`}
                              />
                            ))}
                          </Box>, "")}
                        </Box>
                        <Box
                          className={`${classes.gradeQuarterCell} ${classes.cell}`}
                       
                        >
                          <Typography
                            className={`${classes.textPrimaryColor} ${classes.fontText18}`}
                            component="span"
                          >
                            {subjectItem?.gradeComponent}
                          </Typography>
                        </Box>
                        <Box
                          className={`${classes.gradeQuarterCell} ${classes.cell}`} 
                        
                        >
                          <Typography
                            className={`${classes.textPrimaryColor} ${classes.fontText18}`}
                            component="span"
                          >
                            {subjectItem?.gradeDueDate}
                          </Typography>
                        </Box>
                        {this.handleCondition(this.props.searchstu, <>
                        <Box
                          className={`${classes.gradeQuarterCell} ${classes.cell}`}
                        
                        >
                          <Typography
                            className={`${classes.textPrimaryColor} ${classes.fontText18}`}
                            component="span"
                          >
                            {this.maxScores(subjectItem.maxScore)}                           
                          </Typography>
                        </Box>
                        <Box
                          className={`${classes.gradeQuarterCell} ${classes.cell}`}>
                            {this.isGradeEdit(subjectItem)}                            
                        </Box>
                        <Box
                          className={`${classes.gradeQuarterCell} ${classes.cell}`}
                        >
                          <Typography
                            className={`${classes.textPrimaryColor} ${classes.fontText18}`}
                            component="span"
                          >
                            {subjectItem?.average}
                          </Typography>
                        </Box>
                        <Box className={`${classes.gradeQuarterCell} ${classes.cell}`}>
                            <Menu
                              id="action-menu"
                              anchorEl={this.state.menuAnchorEl}
                              open={Boolean(this.state.menuAnchorEl)}
                              onClose={this.handleMenuClose}
                              style={{ boxShadow: 'none' }}
                              className={`${classes.menuAction}`}
                              PaperProps={
                                {
                                  className: `${classes.menuAction}`
                                }
                              }
                            >
                             
                             <MenuItem onClick={() => this.handleEditGrade(this.state.selectedRowId)}>
                                Edit Grade
                            </MenuItem>
                            </Menu>
                          <IconButton
                          data-test-id="handleMenuOpen"
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(event) => this.handleMenuOpen(event,subjectItem.id)}
                          >
                            <MoreVertIcon className={`${classes.textCommonColor}`}  />
                          </IconButton>
                        </Box>
                        </>, "")}
                        {this.handleCondition(!this.props.searchstu, <Box
                          className={`${classes.gradeQuarterCell} ${classes.cell}`}
                        >
                          <Typography
                            className={`${classes.textPrimaryColor} ${classes.fontText18}`}
                            component="span"
                          >
                            {this.props.currentRole === "parent" ? subjectItem?.average : subjectItem?.students?.class_avg}%
                          </Typography>
                        </Box>, "")}
                      </Box>
                      {!this.props.searchstu && (selectedSubCoursesGradesDetails.length > 0 ?
                        <Box
                          className={
                           this.getGradeClassName(subjectItem.id)
                           
                          }
                        > 
                        <ThemeProvider theme={gradeTableTheme}>
                          <CustomTable
                            tableHeaderColumns={GradeSubjCoursesDetailsTableColumns}
                            tableBodyData={selectedSubCoursesGradesDetails}
                            tableId={`my-gradebook-subject-courses-table-${subjectItem.id}`}
                            showMenuIcon={true}
                            showEditMenuIcon={false}
                            isHandleCellAction={true}
                            handleAction={(data:StudentProfile)=>this.studentCellSearch(data)}
                            actionButtonConfig={[
                              {
                                label: "Edit Grade",
                                action: (data: any) => this.handleEditEventDialog(data),
                              },
                              {
                                label: "Add Remark",
                                action: this.handleEditEventDialog,
                              },
                            ]}
                          />
                           </ThemeProvider>
                          </Box>
                      
                        :
                        <Box className={this.getGradeClasses(subjectItem.id)}>
                          <CustomEmptyResultComponent message="No students found in this assessment." />
                        </Box>
                      )}
                    </Box>
                     
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>
          :
          <Box style={{height: "268px"}}>
          <CustomEmptyResultComponent marginStyle="0px 0px !important" message="No record found." />
          </Box>
        }
        {gradeSubjectList.length > 0 && (
          <CustomPaginationComponent
            paginationData={paginationData}
            handlePaginationChange={this.handlePagination}
          />
        )}
        </Box>
        {openGradeAndRemarkModal &&
          <AddRemarkModal 
            open={openGradeAndRemarkModal}
            onCancel={this.handleCloseGradeAndRemarkModal}
            onConfirm={this.handleCloseGradeAndRemarkModal}
            activityId={selectedGradeSubId}
            studentId={editGradeStudentId}
            studentName={editGradeStudentName}
            maxScore={editGradeMaxScore}
            totalScore={editGradeTotalMarks}
            handleSubmitGrade={(data: any) => { this.handleSaveEditGrade(data) }}
          />
        }
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const dropdownStudent = {
  width: "218px",
  borderRadius: "8px",
  height: "50px",
} as const;
const searchBtn = {
  width: "120px",
  height: "50px",
  textAlign: "center",
  fontSize: "18px",
  padding: "10px 10px",
  borderRadius: "12px",            
  float: "right",
  fontWeight: 500,
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);  
export default withStyles(combinedStyle)(AssessmentGraded);
// Customizable Area End
