import React from "react";

// Customizable Area Start
import { Grid, Box, Typography, FormHelperText } from "@material-ui/core";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import BrandingController, { Props } from "./BrandingController.web";
import CustomColorRadioButtonWeb from "../../../components/src/CustomColorRadioButton.web";
import { logo } from "./assets";
import { IInputConfigProps } from "../../../components/src/CommonType.web";
import CustomTextFieldWeb from "../../../components/src/CustomTextField.web";
import { getFileSrc } from "../../../components/src/CommonHelper.web";
import CustomButtonWeb from "../../../components/src/CustomButton.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    mainGrid: {
      padding: "10px 20px",
    },
    mainGridItem: {
      padding: "0px 0px 25px 0px",
      marginBottom: "20px",
    },
    mainGridItemWithBottomBorder: {
      borderBottom: "1px solid #f1f1f1",
    },
    leftMargin: {
      marginLeft: "10px",
    },
    optionBox: {
      display: "flex",
      alignItems: "center",
    },
    defaultDetailsBox: {
      display: "flex",
      padding: "0px 10px",
      marginBottom: "8px",
    },
    defaultLogo: {
      width: "100px",
      objectFit: "contain",
    },
    inputConfigGrid: {
      marginTop: "20px",
    },
    textfieldBox: {
      width: "92%",
      [theme.breakpoints.down("xs")]: {
        width: "98%",
      },
    },
    dropzone: {
      border: `1px solid #D8D8D8`,
      borderRadius: "12px",
      padding: "15px",
      marginTop: "15px",
      width: "400px",
      height: "150px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        width: "auto",
        height: "100px",
      },
    },
    dragging: {
      borderColor: theme.palette.text.disabled,
    },
    customLogo: {
      width: "inherit",
      height: "130px",
      padding: "2px 2px",
      objectFit: "contain",
      [theme.breakpoints.down("xs")]: {
        height: "80px",
      },
    },
    dropArea: {
      color: theme.palette.text.secondary,
      textAlign: "center",
    },
    dropAreaImg: {
      width: "inherit",
      height: "inherit",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    fileInput: {
      display: "none",
    },
    saveBtnGrid: {
      display: "flex",
      justifyContent: "flex-end",
      paddingBottom: "15px",
    },
  });
// Customizable Area End

export class Branding extends BrandingController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      selectedBrandingType,
      defaultBrandingDetails,
      customBrandingInputConfigs,
      isDragging,
      customizedLogo,
    } = this.state;
    return (
      <Grid className={`${classes.mainGrid}`} container direction="row">
        <Grid className={`${classes.mainGridItem} ${classes.mainGridItemWithBottomBorder}`} item xs={12}>
          <Box className={`${classes.optionBox}`}>
            <CustomColorRadioButtonWeb
              checked={selectedBrandingType === "default_branding"}
              radioColor="#FFA500"
              fieldName={"default_branding"}
              handleChange={(e: any) =>
                this.handleChangeBrandingType("default_branding")
              }
            />
            <Typography
              className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText24} ${classes.leftMargin}`}
              component={"span"}
            >
              Default Branding
            </Typography>
          </Box>
          <Box padding={"4px 10px 15px 10px"}>
            <img
              className={`${classes.defaultLogo}`}
              src={logo}
              alt="Default Logo"
            />
          </Box>
          <Box className={`${classes.defaultDetailsBox}`}>
            <Typography
              component={"span"}
              className={`${classes.textCommonColor} ${classes.fontText18}`}
            >
              Company:
            </Typography>
            <Typography
              component={"span"}
              className={`${classes.textPrimaryColor} ${classes.fontText18} ${classes.leftMargin}`}
            >
              {defaultBrandingDetails.company}
            </Typography>
          </Box>
          <Box className={`${classes.defaultDetailsBox}`}>
            <Typography
              component={"span"}
              className={`${classes.textCommonColor} ${classes.fontText18}`}
            >
              Address:
            </Typography>
            <Typography
              component={"span"}
              className={`${classes.textPrimaryColor} ${classes.fontText18} ${classes.leftMargin}`}
            >
              {defaultBrandingDetails.address}
            </Typography>
          </Box>
          <Box className={`${classes.defaultDetailsBox}`}>
            <Typography
              className={`${classes.textCommonColor} ${classes.fontText18}`}
            >
              Contact:
            </Typography>
            <Typography
              component={"span"}
              className={`${classes.textPrimaryColor} ${classes.fontText18} ${classes.leftMargin}`}
            >
              {defaultBrandingDetails.contact}
            </Typography>
          </Box>
          <Box className={`${classes.defaultDetailsBox}`}>
            <Typography
              component={"span"}
              className={`${classes.textCommonColor} ${classes.fontText18}`}
            >
              Email:
            </Typography>
            <Typography
              component={"span"}
              className={`${classes.textPrimaryColor} ${classes.fontText18} ${classes.leftMargin}`}
            >
              {defaultBrandingDetails.email}
            </Typography>
          </Box>
          <Box className={`${classes.defaultDetailsBox}`}>
            <Typography
              component={"span"}
              className={`${classes.textCommonColor} ${classes.fontText18}`}
            >
              Website:
            </Typography>
            <Typography
              component={"span"}
              className={`${classes.textPrimaryColor} ${classes.fontText18} ${classes.leftMargin}`}
            >
              {defaultBrandingDetails.website}
            </Typography>
          </Box>
        </Grid>
        <Grid className={`${classes.mainGridItem}`} item xs={12}>
          <Box className={`${classes.optionBox}`}>
            <CustomColorRadioButtonWeb
              checked={selectedBrandingType === "customized_branding"}
              radioColor="#FFA500"
              fieldName={"customized_branding"}
              handleChange={(e: any) =>
                this.handleChangeBrandingType("customized_branding")
              }
            />
            <Typography
              className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText24} ${classes.leftMargin}`}
              component={"span"}
            >
              Customized Branding
            </Typography>
          </Box>
          <Box
            style={{borderColor:this.state.isError["customizedLogo"]?'red':'#D8D8D8'}}
            className={`${classes.dropzone} ${
              isDragging ? classes.dragging : ""
            }`}
            data-testid="custom-logo-file-drop-area"
            onDragEnter={this.handleDragEnter}
            onDragLeave={this.handleDragLeave}
            onDragOver={this.handleDragOver}
            onDrop={this.handleDrop}
          >
            {isDragging ? (
              <Box
                id="custom-branding-drag-drop-box"
                className={classes.dropArea}
              >
                <Typography
                  id="custom-branding-drag-drop-label-1"
                  className={`${classes.fontText18} ${classes.textCommonColor}`}
                >
                  Drag and Drop your logo file for{" "}
                </Typography>
                <Typography
                  id="custom-branding-drag-drop-label-2"
                  className={`${classes.fontText18} ${classes.textCommonColor}`}
                >
                  customized branding or{" "}
                </Typography>
                <Typography
                  id="custom-branding-drag-drop-upload-label"
                  className={`${classes.fontText18} ${classes.cursorPointer} ${classes.textButton}`}
                >
                  Upload Logo
                </Typography>
              </Box>
            ) : (
              <>
                {(customizedLogo || this.state.selectedIconUrl) ? (
                  <Box className={classes.dropAreaImg} onClick={this.handleOpenFileBrowse}>
                    
                    <img
                      className={`${classes.customLogo}`}
                      src={getFileSrc(customizedLogo)?getFileSrc(customizedLogo)  : this.state.selectedIconUrl}
                      alt="Customized Logo"

                    />
                    <Typography
                      className={`${classes.fontText18} ${classes.textCommonColor}`}
                      component={"span"}
                    >
                      {this.state.selectedIconName}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    id="custom-branding-logo-box"
                    className={`${classes.dropArea}`}
                  >
                    <Typography
                      className={`${classes.fontText18} ${classes.textCommonColor}`}
                      id="custom-branding-logo-upload-label-1"
                    >
                      Drag and Drop your logo file for{" "}
                    </Typography>
                    <Typography
                      id="custom-branding-logo-upload-label-2"
                      className={`${classes.fontText18} ${classes.textCommonColor}`}
                    >
                      customized branding or
                    </Typography>
                    <Typography
                      id="custom-branding-logo-label"
                      className={`${classes.fontText18} ${classes.cursorPointer} ${classes.textButton}`}
                      onClick={this.handleOpenFileBrowse}
                    >
                      Upload Logo
                    </Typography>
                  </Box>
                 
                )}
              </>
            )}
            <input
              type="file"
              data-testid={"custom-logo-file-input"}
              ref={this.customLogoInputRef}
              className={classes.fileInput}
              accept="image/"
              onChange={this.handleFileInputChange}
            />
          </Box>
          {this.state.isError["customizedLogo"] && (  <FormHelperText style={{color:'red'}} className={`${classes.helperText}`}>
              {this.state.errors["customizedLogo"] }
            </FormHelperText>
          )}
          <Box>
            <Grid container direction="row">
              {customBrandingInputConfigs.map(
                (inputItem: IInputConfigProps) => (
                  <Grid
                    className={`${classes.inputConfigGrid}`}
                    key={inputItem.id}
                    item
                    xs={12}
                    md={6}
                  >
                    <Box marginBottom={"10px"}>
                      <Typography
                        className={`${classes.textCommonColor} ${classes.fontText16}`}
                        id={`appearance-management-branding-${inputItem.id}`}
                        component={"span"}
                      >
                        {inputItem.label}
                      </Typography>
                  
                    </Box>
                    <Box className={`${classes.textfieldBox}`}>
                      <CustomTextFieldWeb
                        fieldName={inputItem.fieldName}
                        fieldWidth={"100%"}
                        fieldId={inputItem.id}
                        fieldError={this.state.isError[inputItem.fieldName]}
                        helperText={this.state.errors[inputItem.fieldName]}
                        fieldValue={inputItem.value}
                        isDisabled={false}
                        textFieldStyles={textfieldStyles}
                        placeHolderText={inputItem.placeHolderText}
                        fieldType="text"
                        handleChange={this.handleTextChange}
                      />
                    </Box>
                  </Grid>
                )
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid className={`${classes.saveBtnGrid}`} item xs={12}>
          <CustomButtonWeb
            btnText="Save"
            btnStyle={saveBtn}
            buttonId="appearance-management-branding-save-btn"
            buttonVariant="contained"
            isDisabled={false}
            type={"button"}
            handleButtonClick={this.handleSaveDetails}
          />
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const textfieldStyles = {
  width: "100%",
  padding: "8px 0px",
} as const;

const saveBtn = {
  width: "160px",
  padding: "10px 10px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  height: "45px",
  textAlign: "center",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(Branding);
// Customizable Area End
