// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
import {
  IDropdownMenuItemProps,
  ITableActionBtnConfigProps,
  TOASTER_NOTIFICATION_MSG,
} from "../../../../components/src/CommonType.web";
import moment from "moment";
import {
  displayApiErrorMsg,
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
} from "../../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End
// Customizable Area Start
export const configJSON = require("../config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  openNewTeacherAssignmentDialog: boolean;
  handleCloseTeacherAssignmentDialog: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  teachersList: Array<any>;
  teachersMenuList: Array<IDropdownMenuItemProps>;
  selectTeacher: string;
  selectGrade: string;
  selectGradeItems: Array<IDropdownMenuItemProps>;
  selectSubject: string;
  selectSubjectItems: Array<IDropdownMenuItemProps>;
  selectClass: string;
  selectClassItems: Array<IDropdownMenuItemProps>;
  selectCurriculum: string;
  selectCurriculumItems: Array<IDropdownMenuItemProps>;
  teacherAssignmentTableData: Array<any>;
  teacherAssignmentTableBtnActionConfig: Array<ITableActionBtnConfigProps>;
  openEditTeacherAssignmentDialog: boolean;
  currentPage: number;
  perPageRecords: number;
  totalNumberOfTeacherAssignmentsRecords: number;
  showLoaderInTeacherAssignmentDialog: boolean;
  selectedTeacherAssignmentDetails: any;
  openDeleteTeacherAssignmentConfirmModal: any;
  teacherAssignmentDetailsFromStep1: any;
  selectedRegularTeachersId: Array<any>;
  selectedOtherTeachersId: Array<any>;
  subjectsListBasedOnGrade: Array<any>;
  curriculumListBasedOnGradeSubjects: Array<any>;
  classesListBasedOnGradeSubjectsCurriculum: Array<any>;
  teachersListBasedOnGradeSubjectCurriculum: Array<any>;
  otherTeachersListBasedOnGradeSubjectCurriculum: Array<any>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherAssignmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetTeacherAssignmentList: string = "";
  apiPostTeacherAssignment: string = "";
  apiUpdateTeacherAssignment: string = "";
  apiDeleteTeacherAssignment: string = "";
  apiFetchFilters: string = "";
  apiFetchSubjectsBasedOnGrade: string = "";
  apiFetchClassesBasedOnCurriculumGradeSubject: string = "";
  apiFetchCurriculumBasedOnGradeSubject: string = "";
  apiFetchAllTeachersBasedOnGradeSubjectsCurriculumList: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      selectTeacher: "",
      loading: false,
      selectGrade: "",
      teachersList: [],
      teachersMenuList: [],
      selectGradeItems: [],
      selectClassItems: [],
      selectSubjectItems: [],
      selectClass: "",
      selectCurriculum: "",
      selectCurriculumItems: [],
      currentPage: 1,
      openEditTeacherAssignmentDialog: false,
      perPageRecords: 10,
      selectedTeacherAssignmentDetails: "",
      selectSubject: "",
      showLoaderInTeacherAssignmentDialog: false,
      teacherAssignmentTableBtnActionConfig: [
        {
          label: "Edit",
          action: (value: any) =>
            this.handleOpenEditTeacherAssignmentPopup(value),
        },
        {
          label: "Delete",
          action: (value: any) =>
            this.handleOpenTeacherAssignmentDeleteConfirmModal(value),
        },
      ],
      teacherAssignmentTableData: [],
      totalNumberOfTeacherAssignmentsRecords: 0,
      openDeleteTeacherAssignmentConfirmModal: false,
      teacherAssignmentDetailsFromStep1: "",
      selectedOtherTeachersId: [],
      selectedRegularTeachersId: [],
      classesListBasedOnGradeSubjectsCurriculum: [],
      curriculumListBasedOnGradeSubjects: [],
      subjectsListBasedOnGrade: [],
      teachersListBasedOnGradeSubjectCurriculum: [],
      otherTeachersListBasedOnGradeSubjectCurriculum: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  handleChangeDropDownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldVal: any = value;
    if (fieldVal) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldVal,
        };
      });
    }
  };

  renderTeacherValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Teacher";
    }
    const { teachersMenuList } = this.state;
    const selectedTeacher = teachersMenuList.find(
      (teacher: any) => teacher?.value === selected
    );
    return selectedTeacher?.label;
  };

  renderSubjectValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Subject";
    }
    const { selectSubjectItems } = this.state;
    const selectedSubject = selectSubjectItems.find(
      (subject: any) => subject?.value === selected
    );
    return selectedSubject?.label;
  };

  renderClassValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Class";
    }
    const { selectClassItems } = this.state;
    const selectedClass = selectClassItems.find(
      (classItem: any) => classItem?.value === selected
    );
    return selectedClass?.label;
  };

  renderCurriculumValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Curriculum";
    }
    const { selectCurriculumItems } = this.state;
    const selectedCurriculum = selectCurriculumItems.find(
      (curriculumItem: any) => curriculumItem?.value === selected
    );
    return selectedCurriculum?.label;
  };

  handleCloseTeacherAssignmentDialog = (isFromApiCall?: boolean) => {
    this.setState({
      openEditTeacherAssignmentDialog: false,
    });
    if(!isFromApiCall){
      this.setState({
        selectedOtherTeachersId: [],
        selectedRegularTeachersId: [],
      })
    }
    this.props.handleCloseTeacherAssignmentDialog();
  };

  handleOpenEditTeacherAssignmentPopup = (value: any) => {
    this.setState({
      selectedOtherTeachersId: [],
      selectedRegularTeachersId: [],
    });
    const { teacherAssignmentTableData } = this.state;
    const selectedTeacherAssignmentDetails = teacherAssignmentTableData.find(
      (item) => parseInt(item.id) === parseInt(value)
    );
    this.setState(
      {
        selectedTeacherAssignmentDetails,
        selectedOtherTeachersId: [
          ...selectedTeacherAssignmentDetails?.otherTeachers,
        ],
        selectedRegularTeachersId: [
          ...selectedTeacherAssignmentDetails?.teachers,
        ],
      },
      () => {
        this.setState({ openEditTeacherAssignmentDialog: true });
      }
    );
  };

  handleOpenTeacherAssignmentDeleteConfirmModal = (value?: any) => {
    const { teacherAssignmentTableData } = this.state;
    const selectedTeacherAssignment = teacherAssignmentTableData.find(
      (item) => parseInt(item.id) === parseInt(value)
    );
    this.setState(
      {
        selectedTeacherAssignmentDetails: selectedTeacherAssignment,
      },
      () => {
        this.setState({ openDeleteTeacherAssignmentConfirmModal: true });
      }
    );
  };

  handleCloseDeleteTeacherAssignmentConfirmModal = () => {
    this.setState({ openDeleteTeacherAssignmentConfirmModal: false });
  };

  handleSetTeacherAssignmentTableDataItem = (item: any) => {
    const subjectsList = item?.attributes?.subjects?.map((subject: any) => {
      return subject?.name;
    });
    const otherTeachers = item?.attributes?.other_teachers?.map(
      (otherTeacher: any) => {
        return {
          id: otherTeacher?.id,
          name: `${otherTeacher?.first_name} ${otherTeacher?.last_name}`,
          email: otherTeacher?.email,
          role: "teacher",
          profileImg: otherTeacher?.profile_pic,
        };
      }
    );
    const teachers = item?.attributes?.teachers?.map((teacher: any) => {
      return {
        id: teacher?.id,
        name: `${teacher?.first_name} ${teacher?.last_name}`,
        email: teacher?.email,
        role: "teacher",
        profileImg: teacher?.profile_pic,
      };
    });
    const teachersName = item?.attributes?.teachers?.map((teacher: any) => {
      return `${teacher?.first_name} ${teacher?.last_name}`;
    });
    let dataItemObj = {
      id: item?.id,
      grade: item?.attributes?.grade?.name,
      gradeId: item?.attributes?.grade?.id?.toString(),
      curriculum: item?.attributes?.curriculum?.name,
      curriculumId: item?.attributes?.curriculum?.id?.toString(),
      subjectIds: item?.attributes?.subjects?.map((subject: any) => {
        return subject?.id?.toString();
      }),
      subjects: subjectsList?.join(","),
      teachersName: teachersName?.join(","),
      otherTeachers: otherTeachers,
      teachers: teachers,
      className: item?.attributes?.class_management?.name,
      classId: item?.attributes?.class_management?.id?.toString(),
      noOfStudents: item?.attributes?.no_of_students_per_class?.toString(),
      createdOn: moment(item?.attributes?.created_at).format("MMM DD, YYYY"),
      modifiedOn: moment(item?.attributes?.updated_at).format("MMM DD, YYYY"),
      status: item?.attributes?.save_as_draft ? "Draft" : "Active",
    };
    return dataItemObj;
  };

  handleSetFilterDropdownMenuItem = (list: any) => {
    const menuItems = list?.map((item: any) => {
      return {
        id: item?.id,
        value: item?.id,
        label: item?.attributes?.name,
      };
    });
    return menuItems;
  };

  handleSetTeacherDropdownMenuItem = (list: any) => {
    const menuItems = list?.map((item: any) => {
      return {
        id: item?.id,
        value: item?.id,
        label: `${item?.attributes?.first_name} ${item?.attributes?.last_name}`,
      };
    });
    return menuItems;
  };

  handleSetTeacherAssignmentData = (list: any) => {
    const teacherAssignmentData = list?.map((item: any) =>
      this.handleSetTeacherAssignmentTableDataItem(item)
    );
    this.setState({ teacherAssignmentTableData: teacherAssignmentData });
  };

  handleGetTeacherAssignmentDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      this.handleSetTeacherAssignmentData(responseJson.data);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleGetTeacherAssignmentList = async (isFilterApplied?: boolean) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const {
      selectSubject,
      selectClass,
      selectTeacher,
      selectCurriculum,
      perPageRecords,
      currentPage,
    } = this.state;
    let queryString = "";
    let page = currentPage;
    if (isFilterApplied) {
      this.setState({ currentPage: 1 });
      page = 1;
    }
    let teacherAssignmentEndpoint = configJSON.teacherAssignmentEndpoint;
    if (selectClass || selectCurriculum) {
      queryString = `&class_management_id=${selectClass}&curriculum_id=${selectCurriculum}`;
    }
    if (selectTeacher?.length > 0) {
      queryString += `&teacher_ids=[${selectTeacher}]`;
    }
    if (selectSubject?.length > 0) {
      queryString += `&subject_ids=[${selectSubject}]`;
    }

    teacherAssignmentEndpoint = `${teacherAssignmentEndpoint}?page=${page}&per_page=${perPageRecords}${queryString}`;
    this.apiGetTeacherAssignmentList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      teacherAssignmentEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleAddNewTeacherAssignment = async (isSaveAsDraft?: boolean) => {
    this.props.handleCloseTeacherAssignmentDialog(true);
    showCustomLoader();
    const {
      teacherAssignmentDetailsFromStep1,
      selectedRegularTeachersId,
      selectedOtherTeachersId,
    } = this.state;
    const requestBody = {
      data: {
        attributes: {
          subject_ids: teacherAssignmentDetailsFromStep1?.subjects,
          grade_id: teacherAssignmentDetailsFromStep1?.grade,
          curriculum_id: teacherAssignmentDetailsFromStep1?.curriculum,
          teacher_ids: selectedRegularTeachersId.map((item: any) => {
            return item?.id;
          }),
          other_teacher_ids: selectedOtherTeachersId.map((item: any) => {
            return item?.id;
          }),
          class_management_id: teacherAssignmentDetailsFromStep1?.class,
          no_of_students_per_class: parseInt(
            teacherAssignmentDetailsFromStep1?.noOfStudents
          ),
          save_as_draft: !!isSaveAsDraft,
        },
      },
    };
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostTeacherAssignment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teacherAssignmentEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleUpdateTeacherAssignment = async (isSaveAsDraft?: boolean) => {
    this.handleCloseTeacherAssignmentDialog(true);
    showCustomLoader();
    const token = await getStorageData("token");
    const { selectedTeacherAssignmentDetails } = this.state;
    const {
      teacherAssignmentDetailsFromStep1,
      selectedRegularTeachersId,
      selectedOtherTeachersId,
    } = this.state;
    const editRequestBody = {
      data: {
        attributes: {
          subject_ids: teacherAssignmentDetailsFromStep1?.subjects,
          grade_id: teacherAssignmentDetailsFromStep1?.grade,
          curriculum_id: teacherAssignmentDetailsFromStep1?.curriculum,
          teacher_ids: selectedRegularTeachersId.map((item: any) => {
            return item?.id;
          }),
          other_teacher_ids: selectedOtherTeachersId.map((item: any) => {
            return item?.id;
          }),
          class_management_id: teacherAssignmentDetailsFromStep1?.class,
          no_of_students_per_class: parseInt(
            teacherAssignmentDetailsFromStep1?.noOfStudents
          ),
          save_as_draft: !!isSaveAsDraft,
        },
      },
    };
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateTeacherAssignment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teacherAssignmentEndpoint}/${selectedTeacherAssignmentDetails.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(editRequestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePostTeacherAssignmentDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data?.attributes) {
      toast.success(TOASTER_NOTIFICATION_MSG.TEACHER_ASSIGNMENT_CREATION_SUCCESS);
      this.handleGetTeacherAssignmentList();
    } else if (responseJson?.errors) {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      hideCustomLoader();
    }
    this.setState({
      selectedRegularTeachersId: [],
      selectedOtherTeachersId: [],
    });
  };

  handleUpdateTeacherAssignmentDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data?.attributes) {
      this.handleGetTeacherAssignmentList();
      toast.success(TOASTER_NOTIFICATION_MSG.TEACHER_ASSIGNMENT_UPDATE_SUCCESS);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      hideCustomLoader();
    }
    this.setState({
      selectedRegularTeachersId: [],
      selectedOtherTeachersId: [],
      selectedTeacherAssignmentDetails: "",
    });
  };

  handleDeleteTeacherAssignment = async () => {
    this.handleCloseDeleteTeacherAssignmentConfirmModal();
    showCustomLoader();
    const { selectedTeacherAssignmentDetails } = this.state;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteTeacherAssignment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teacherAssignmentEndpoint}/${selectedTeacherAssignmentDetails.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleTeacherAssignmentDeleteResponse = (responseJson: any) => {
    if (responseJson) {
      toast.success(TOASTER_NOTIFICATION_MSG.TEACHER_ASSIGNMENT_DELETE_SUCCESS);
      this.handleGetTeacherAssignmentList();
    } else {
      toast.error(TOASTER_NOTIFICATION_MSG.TEACHER_ASSIGNMENT_DELETE_FAILURE);
      hideCustomLoader();
    }
    this.setState({ selectedTeacherAssignmentDetails: "" });
  };

  handleTeacherAssignmentFetchFiltersResponse = (responseJson: any) => {
    if (responseJson) {
      const teachersMenu = this.handleSetTeacherDropdownMenuItem(
        responseJson?.teacher?.data
      );
      const subjectsMenu = this.handleSetFilterDropdownMenuItem(
        responseJson?.subjects?.data
      );
      const gradesMenu = this.handleSetFilterDropdownMenuItem(
        responseJson?.grades?.data
      );
      const curriculumMenu = this.handleSetFilterDropdownMenuItem(
        responseJson?.curriculum?.data
      );
      const classesMenu = this.handleSetFilterDropdownMenuItem(
        responseJson?.class_management?.data
      );
      this.setState({
        teachersMenuList: [...teachersMenu],
        selectClassItems: [...classesMenu],
        selectCurriculumItems: [...curriculumMenu],
        selectGradeItems: [...gradesMenu],
        selectSubjectItems: [...subjectsMenu],
      });
    }
  };

  handleGetDropdownFiltersList = async () => {
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiFetchFilters = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teacherAssignmentFiltersEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleUpdateAddedTeacherAssignmentDetails = (details: any) => {
    this.setState({ teacherAssignmentDetailsFromStep1: details });
  };

  handleSetSubjectsAsPerGradeDropdownList = (responseJson: any) => {
    if (responseJson?.data) {
      const subjectListAsPerGrade = this.handleSetFilterDropdownMenuItem(
        responseJson?.data
      );
      this.setState({
        subjectsListBasedOnGrade: subjectListAsPerGrade || [],
        showLoaderInTeacherAssignmentDialog: false,
      });
    }
  };

  handleGetSubjectsAsPerGradeDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      this.handleSetSubjectsAsPerGradeDropdownList(responseJson);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.setState({ showLoaderInTeacherAssignmentDialog: false });
  };

  handleGetSubjectsList = async (gradeId: any) => {
    const token = await getStorageData("token");
    const header = {
      token,
    };
    this.setState({ showLoaderInTeacherAssignmentDialog: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiFetchSubjectsBasedOnGrade = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.subjectsEndpoint}?grade_id=${gradeId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSetCurriculumBasedOnGradeSubjectDropdownList = (responseJson: any) => {
    if (responseJson?.data) {
      const curriculumList = this.handleSetFilterDropdownMenuItem(
        responseJson?.data
      );
      this.setState({
        curriculumListBasedOnGradeSubjects: curriculumList || [],
        showLoaderInTeacherAssignmentDialog: false,
      });
    }
  };

  handleGetCurriculumDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      this.handleSetCurriculumBasedOnGradeSubjectDropdownList(responseJson);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.setState({ showLoaderInTeacherAssignmentDialog: false });
  };

  handleGetCurriculumsList = async (gradeId: any, subjectIds: any) => {
    const token = await getStorageData("token");
    const header = {
      token,
    };
    this.setState({ showLoaderInTeacherAssignmentDialog: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiFetchCurriculumBasedOnGradeSubject = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.curriculumEndpoint}?grade_id=${gradeId}&subject_id=[${subjectIds}]`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSetClassesListBasedOnGradeSubjectCurriculumDropdownList = (
    responseJson: any
  ) => {
    if (responseJson?.data) {
      const classesList = this.handleSetFilterDropdownMenuItem(
        responseJson?.data
      );
      this.setState({
        classesListBasedOnGradeSubjectsCurriculum: classesList || [],
        showLoaderInTeacherAssignmentDialog: false,
      });
    }
  };

  handleGetClassesListBasedOnGradeSubjectCurriculumDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      this.handleSetClassesListBasedOnGradeSubjectCurriculumDropdownList(
        responseJson
      );
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.setState({ showLoaderInTeacherAssignmentDialog: false });
  };

  handleGetClassesListBasedOnGradeSubjectCurriculum = async (
    gradeId: any,
    subjectIds: any,
    curriculumId: any
  ) => {
    const token = await getStorageData("token");
    const header = {
      token,
    };
    this.setState({ showLoaderInTeacherAssignmentDialog: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiFetchClassesBasedOnCurriculumGradeSubject =
      requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.classesEndpoint}?grade_id=${gradeId}&subject_id=[${subjectIds}]&curriculum_id=${curriculumId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSetRegularOtherTeachersListBasedOnGradeSubjectCurriculumData = (
    list: any
  ) => {
    const { selectedOtherTeachersId, selectedRegularTeachersId } = this.state;
    const teachersData = list?.map((teacher: any) => {
      return {
        id: teacher?.id,
        name: `${teacher?.attributes?.first_name} ${teacher?.attributes?.last_name}`,
        email: teacher?.attributes?.email,
        role: "teacher",
        profileImg: teacher?.attributes?.profile_url || "",
      };
    });
    const remainingTeachersList = teachersData.filter(
      (teacher: any) =>
      selectedOtherTeachersId.find(
          (selectedTeacher: any) =>
            parseInt(selectedTeacher.id) === parseInt(teacher.id)
        ) === undefined
    );
    const remainingOtherTeachersList = teachersData.filter(
      (teacher: any) =>
        selectedRegularTeachersId.find(
          (selectedTeacher: any) =>
            parseInt(selectedTeacher.id) === parseInt(teacher.id)
        ) === undefined
    );
    this.setState({
      teachersListBasedOnGradeSubjectCurriculum: remainingTeachersList,
      otherTeachersListBasedOnGradeSubjectCurriculum:
        remainingOtherTeachersList,
    });
  };

  handleGetRegularOtherTeachersListBasedOnGradeSubjectCurriculumDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      this.handleSetRegularOtherTeachersListBasedOnGradeSubjectCurriculumData(
        responseJson.data
      );
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.setState({ showLoaderInTeacherAssignmentDialog: false });
  };

  handleGetRegularOtherTeachersListBasedOnGradeSubjectCurriculum = async (
    gradeId: any,
    subjectIds: any,
    curriculumId: any,
    searchName: string
  ) => {
    const token = await getStorageData("token");
    const header = {
      token,
    };
    this.setState({ showLoaderInTeacherAssignmentDialog: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiFetchAllTeachersBasedOnGradeSubjectsCurriculumList =
      requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teacherBasedOnGradeSubjectCurriculum}?grade_id=[${gradeId}]&subject_id=[${subjectIds}]&curriculum_id=${curriculumId}&name=${searchName}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSetRegularTeacherList = (selectedTeachers: any) => {
    this.setState({ selectedRegularTeachersId: selectedTeachers });
  };

  handleSetOtherTeacherList = (selectedTeachers: any) => {
    this.setState({ selectedOtherTeachersId: selectedTeachers });
  };

  handlePageChange = (event: any, page: any) => {
    this.setState({ currentPage: page }, () => {
      this.handleGetTeacherAssignmentList();
    });
  };

  // Customizable Area End
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetDropdownFiltersList();
    this.handleGetTeacherAssignmentList();
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    console.log("rece-self");
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetTeacherAssignmentList:
            {
              if (responseJson?.meta?.pagination?.total_count) {
                this.setState({
                  totalNumberOfTeacherAssignmentsRecords:
                    responseJson.meta.pagination.total_count,
                });
              }
              this.handleGetTeacherAssignmentDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiFetchFilters:
            {
              this.handleTeacherAssignmentFetchFiltersResponse(responseJson);
            }
            break;
          case this.apiDeleteTeacherAssignment:
            {
              this.handleTeacherAssignmentDeleteResponse(responseJson);
            }
            break;
          case this.apiUpdateTeacherAssignment:
            {
              this.handleUpdateTeacherAssignmentDataResponse(
                responseJson,
                responseJson
              );
            }
            break;
          case this.apiPostTeacherAssignment:
            {
              this.handlePostTeacherAssignmentDataResponse(
                responseJson,
                responseJson
              );
            }
            break;
          case this.apiFetchAllTeachersBasedOnGradeSubjectsCurriculumList:
            {
              this.handleGetRegularOtherTeachersListBasedOnGradeSubjectCurriculumDataResponse(
                responseJson,
                responseJson
              );
            }
            break;
          case this.apiFetchSubjectsBasedOnGrade:
            {
              this.handleGetSubjectsAsPerGradeDataResponse(
                responseJson,
                responseJson
              );
            }
            break;
          case this.apiFetchCurriculumBasedOnGradeSubject:
            {
              this.handleGetCurriculumDataResponse(responseJson, responseJson);
            }
            break;
          case this.apiFetchClassesBasedOnCurriculumGradeSubject:
            {
              this.handleGetClassesListBasedOnGradeSubjectCurriculumDataResponse(
                responseJson,
                responseJson
              );
            }
            break;
        }
      }
    }
  }

  // Customizable Area End
}
// Customizable Area End
