// Customizable Area Start
import React from "react";
import { Avatar, Box, Grid, Paper, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ParentTeacherProfileController, {
  Props,
  Children
} from "./ParentTeacherProfileController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { studentProfile, man2, man5, man4 } from "./assets";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import GearIconDropDown from "../../../components/src/GearIconDropDown.web";
import CustomImageComponentWeb from "../../../components/src/CustomImageComponent.web";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    fieldValue: {
      color: "#090909",
      fontWeight: 600,
      fontSize: "16px"
    },
    parentHeader: {
      fontSize: "20px",
      color: "#090909",
      padding: "25px",
      fontWeight: 600
    },
   
    inputLabel: {
      color: "#888888",
     
      // margin: theme.spacing(2.5, 0),
      fontWeight: 400,
      fontSize: "16px"
    },
    profileHeading: {
      fontWeight: 700,
      color: "#090909",
      fontSize: "34px"
    },
    fieldBox: {
      backgroundColor: "#FFFFFF",
      margin: theme.spacing(2.5, 0),
      borderRadius: "12px"
    },
    studentHeader: {
      fontSize: "22px",
      color: "#090909",
      fontWeight: 600
    },
    backIcon: {
      fontSize: "34px",
      cursor: "pointer"
    },
    headerWrappper: {
      display: "flex",
      justifyContent: "space-between",
      margin: "15px 0 30px 0",
      alignItems: "center"
    },
    fieldWrapper: {
      display: "flex",
      alignItems: "center",
      padding: "25px",
      gap: "15px"
    },
    headLineWrapper: {
      alignItems: "center",
      display: "flex",
      cursor: "pointer",
      gap: "10px"
    },
    boxWrapper: {
      display: "flex",
      justifyContent: "space-between",
      padding: "0 25px",
      flexDirection: "row"
    },
    contentCenter: {
      display: "flex",
      alignItems: "center"
    },
    parentHeaderWrapper: {
      height: "1px",
      backgroundColor: "#F1F1F1"
    },
    parentBodyWrapper: {
      display: "flex",
      justifyContent: "space-between",
      padding: "0 25px",
      flexDirection: "row"
    },
    imageMargin: {
      margin: "0 8px"
    },
    rootBox: {
      padding: "40px"
    },
    flexBox: {
      display: "flex",
      flexDirection: "column"
    },
    paper: {
      padding: theme.spacing(2),
      borderRadius: "12px",
      boxShadow: "none",
      background: "#f1f1f1",
    },
    gridBoxWrapper: {
      padding: "0 25px",
    },
    fontBold600: {
      fontWeight: 600
    },
    gridElement: {
      padding: "12px",
      borderRadius: "12px",
      background: "#f1f1f1"
    },
    profileImgBox: {
      borderRadius: "50%",
      height: "50px",
      width: "50px",
    },
    emptyProfileImg: {
      height: "inherit",
      width: "inherit",
      color: "#ccc",
    },
    wordwrap: {
      wordBreak: "break-all",
    },
    capitalText: {
      textTransform: "capitalize",
    },
  });
// Customizable Area End

// Customizable Area Start
export class ParentTeacherProfile extends ParentTeacherProfileController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { userInfo, profileSettingOption } = this.state;

    return (
      <Box
        height={"100%"}
        className={`${classes.secondaryBgColor} ${classes.childMainWrapperPadding
          } ${classes.highlightBgColor} ${classes.rootBox}`}
      >
        <Box marginTop={"1%"}>
          <Typography
            component={"span"}
            className={`${classes.textCommonColor} ${classes.fontText14}`}
          >
            {`Dashboard /`}
          </Typography>
          <Typography
            component={"span"}
            className={`${classes.fontText14} ${classes.primaryColor5} ${classes.fontBold600
              }`}
          >
            {` My Profile `}
          </Typography>
        </Box>
        <Box className={classes.headerWrappper}>
          <Box onClick={this.handleGoBack} className={classes.headLineWrapper}>
            <KeyboardBackspaceIcon
              data-testid="Parent-Teacher-go-back"
              className={`${classes.textPrimaryColor} ${classes.backIcon}`}
            />
            <Typography
              className={`${classes.textPrimaryColor} ${classes.profileHeading
                }`}
            >
              My Profile
            </Typography>
          </Box>
          <GearIconDropDown
            options={profileSettingOption}
            data-testid="profile-settings"
          />
        </Box>
        <Box className={`${classes.fieldBox} ${classes.bgColor}`}>
          <Box className={classes.fieldWrapper}>
            <Box className={classes.profileImgBox}>
              {userInfo?.profileImg ? (
                <CustomImageComponentWeb
                  altText="teacher-profile"
                  src={userInfo.profileImg}
                  imgStyles={{
                    width: "50px",
                    height: "50px",
                    objectFit: "cover",
                    borderRadius: "50%"
                  }}
                  isUserProfileImg
                />
              ) : (
                <AccountCircleIcon className={`${classes.emptyProfileImg}`} />
              )}
            </Box>
            <Typography
              className={`${classes.studentHeader} ${classes.textPrimaryColor}`}
            >
              {`${userInfo.firstName} ${userInfo.lastName}`}
            </Typography>
          </Box>
          <Grid
            spacing={2}
            className={classes.gridBoxWrapper}
            container
            direction="row"
          >
            <Grid className={classes.wordwrap} item xs={12} md={6} lg={4}>
              <Typography className={classes.inputLabel}>
                Email ID:{" "}
                <Typography
                  component={"span"}
                  className={`${classes.textPrimaryColor} ${classes.fieldValue}`}
                >
                  {userInfo.email}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className={classes.inputLabel}>
                ID Number:{" "}
                <Typography
                  component={"span"}
                  className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                >
                  {userInfo.uniqId}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className={classes.inputLabel}>
                Role:{" "}
                <span
                  className={`${classes.textPrimaryColor} ${classes.fieldValue} ${classes.capitalText}`}
                >
                  {userInfo.role}
                </span>
              </Typography>
            </Grid>
            {userInfo.customFields.map((item: any) => (
              <Grid key={item?.id} item xs={12} md={6} lg={4}>
                <Typography className={classes.inputLabel}>
                  {item?.label}:{" "}
                  <Typography
                    component={"span"}
                    className={`${classes.textPrimaryColor} ${classes.fieldValue} `}
                  >
                    {item?.value || "NA"}
                  </Typography>
                </Typography>
              </Grid>
            ))}
            <Grid item xs={12} md={6} lg={4}>
              <Typography className={classes.inputLabel}>
                Status:{" "}
                <span
                  className={`${classes.textPrimaryColor} ${classes.fieldValue} ${classes.capitalText}`}
                >
                  {userInfo.status}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box className={`${classes.fieldBox} ${classes.bgColor}`}>
          <Box>
            <Typography
              className={`${classes.parentHeader} ${classes.textPrimaryColor}`}
            >
              Curriculum
            </Typography>
          </Box>
          <Box className={`${classes.parentHeaderWrapper} `} />
          <Box className={`${classes.bgColor} ${classes.parentHeader}`} borderRadius={"12px"}>
            {userInfo?.curriculums?.name ?
              <Grid container direction="row">
                {userInfo?.curriculums?.name && (
                  <Grid item xs={12} sm={12} md={6} lg={3}>
                    <Paper className={classes.paper}>
                      <Typography className={classes.fontBold600}>
                        {userInfo.curriculums.name}
                      </Typography>
                    </Paper>
                  </Grid>
                )}
              </Grid>
              :
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={`${classes.textCommonColor}`}>
                    No record found.
                  </Typography>
                </Grid>
              </Grid>}
          </Box>
        </Box>
        <Box className={`${classes.bgColor} ${classes.fieldBox} `}>
          <Box>
            <Typography
              className={`${classes.parentHeader} ${classes.textPrimaryColor}`} data-test-id="children"
            >
              Children
            </Typography>
          </Box>
          <Box className={classes.parentHeaderWrapper} />
          <Box className={`${classes.bgColor} ${classes.parentHeader}`} borderRadius={"12px"}>
            {userInfo?.childrenList.length > 0 ?
              <Grid container spacing={2}>
                {userInfo?.childrenList && userInfo?.childrenList?.map(
                  (item: Children) => (
                    <Grid key={item?.id} item xs={12} sm={12} md={6} lg={3}>
                      <Paper className={classes.paper}>
                        <Box style={{display:'flex', alignItems:'center', gap:"10px"}}>
                <Avatar  src={item.profile_url} className={`${classes.emptyProfileImg}`} style={{width:"50px", height:"50px", objectFit: 'cover'}}/>
                        <Typography className={classes.fontBold600}>
                          {`${item.first_name}  ${item.last_name}`}
                        </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  )
                )}
              </Grid>
              :
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={`${classes.textCommonColor}`}>
                    No record found.
                  </Typography>
                </Grid>
              </Grid>
            }
          </Box>
        </Box>
        <Box className={`${classes.bgColor} ${classes.fieldBox} `}>
          <Box>
            <Typography className={`${classes.parentHeader} ${classes.textPrimaryColor}`}>
              Subjects Handled
            </Typography>
          </Box>
          <Box className={classes.parentHeaderWrapper} />
          <Box className={`${classes.bgColor} ${classes.parentHeader}`} borderRadius={"12px"}>
            {userInfo?.subjectsHandled.length > 0 ?
              <Grid container spacing={2}>
                {userInfo?.subjectsHandled?.map(
                  (subject: { id?: number; name?: string }) => (
                    <Grid key={subject?.id} item xs={12} sm={12} md={6} lg={3}>
                      <Paper className={classes.paper}>
                        <Typography className={classes.fontBold600}>
                          {subject?.name}
                        </Typography>
                      </Paper>
                    </Grid>
                  )
                )}
              </Grid>
              :
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={`${classes.textCommonColor}`}>
                    No record found.
                  </Typography>
                </Grid>
              </Grid>
            }
          </Box>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ParentTeacherProfile);
// Customizable Area End
